import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';

import api from '~/services/api';
import { hasPermission } from '../AccessControl';

const institutionSpec = applySpec({
  id: prop('id'),
  titulo: prop('nome'),
});

const getInstitutions = pipe(prop('docs'), map(institutionSpec));

export default function useInstitutions() {
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (hasPermission('read_institutions', { toast: false })) {
        setLoading(true);
        api
          .get('/institutions?limit=100')
          .then(({ data }) => {
            if (data) setInstitutions(getInstitutions(data));
          })
          .finally(() => setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return { institutions, loading };
}
