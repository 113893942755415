import { useState, useEffect, useCallback } from 'react';
import { applySpec, prop, map, pipe, isEmpty } from 'ramda';

import api from '~/services/api';

// spec
const feedbackSpec = applySpec({
  id: prop('id'),
  pessoa: prop('pessoa'),
  titulo: prop('titulo'),
  descricao: prop('descricao'),
  data: prop('data'),
  status: prop('statusText'),
});

const getFeedbacks = pipe(prop('docs'), map(feedbackSpec));

const paginationSpec = applySpec({
  totals: prop('total'),
  limit: prop('limit'),
  page: prop('page'),
  pages: prop('pages'),
});

export default function useFeedbackList({ setPagination, ...custom }) {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const timeId = setTimeout(() => {
      setLoading(true);

      // query string params

      const params = {
        ...filters,
        page: custom.pagination.page,
        limit: custom.pagination.limit,
        q: /^\d{3}.\d{3}.\d{3}-\d{2}/g.test(filters.q)
          ? String(filters.q).replace(/D+/g, '')
          : filters.q,
      };
      api
        .get(`/winners-feedback`, { params })
        .then(({ data = {} } = {}) => {
          if (!isEmpty(data)) {
            setFeedbacks(getFeedbacks(data));
            setPagination(paginationSpec(data));
            // scroll if exists feedbacks
            if (data.docs.length) {
              document.querySelector('main').scroll(0, 0);
              window.scroll(0, 0);
            }
          }
        })
        .finally(() => setLoading(false));
    }, 120);

    return () => clearTimeout(timeId);
  }, [custom.pagination.page, custom.pagination.limit, filters, setPagination]);

  // update feedback inside list
  function onFeedbackUpdated({ id, titulo, descricao, statusText: status }) {
    const feedbacksCopy = [...feedbacks];
    const feedback = feedbacks.find(({ id: fId }) => id === fId);
    const idx = feedbacks.findIndex(({ id: fId }) => id === fId);
    feedbacksCopy.splice(idx, 1, { ...feedback, titulo, descricao, status });
    setFeedbacks(feedbacksCopy);
  }

  // update person inside feedback list
  function onFeedbackPersonUpdated({ id, pessoa }) {
    const idx = feedbacks.findIndex(({ id: fId }) => id === fId);
    const feedback = feedbacks.find(({ id: fId }) => id === fId);
    const copy = [...feedbacks];

    // -- update feedback
    copy.splice(idx, 1, {
      ...feedback,
      pessoa: {
        ...pessoa,
        avatar: pessoa.avatar && `${pessoa.avatar}?v=${Date.now()}`,
      },
    });

    setFeedbacks(copy);
  }

  const sendFilters = async atributeFilters => {
    setFilters(atributeFilters);
  };

  // handler change filter
  const handleFilters = useCallback(
    (field, value) => {
      custom.setPagination({ page: 1 });
      setFilters({ ...filters, [field]: value });
    },
    [filters, custom]
  );

  return {
    feedbacks,
    filters,
    loading,
    onFeedbackUpdated,
    onFeedbackPersonUpdated,
    handleFilters,
    sendFilters,
  };
}
