import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import {
  Container,
  Content,
  Header,
  Footer,
  Chart,
  List,
  Label,
} from './styles';

export const CardChart = ({
  chart,
  value,
  total,
  gerados,
  confirmados,
  enviados,
}) => {
  return (
    <Container>
      <Header>
        <Chart>{chart}</Chart>
        <List>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label color="#a6a6a6">R$ {total}</Label>
            <Label color="#FFB547">R$ {gerados}</Label>
            <Label color="#2196F3">R$ {confirmados}</Label>
            <Label color="#4CAF50">R$ {enviados}</Label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label>total</Label>
            <Label>gerados</Label>
            <Label>confirmados</Label>
            <Label>enviados</Label>
          </div>
        </List>
      </Header>
      <Content>
        <div>
          <h1>{value ? `R$ ${value}` : 'TUDO CERTO'}</h1>
          <span>
            {value ? `de R$ ${total} restantes hoje` : 'nada restante hoje'}
          </span>
        </div>
      </Content>
      <Footer>
        <Button variant="contained" color="primary">
          Atualizar todos
        </Button>
      </Footer>
    </Container>
  );
};

CardChart.defaultProps = {
  chart: <></>,
  value: '',
  total: '',
  gerados: '',
  confirmados: '',
  enviados: '',
};

CardChart.propTypes = {
  chart: PropTypes.element,
  value: PropTypes.string,
  total: PropTypes.string,
  gerados: PropTypes.string,
  confirmados: PropTypes.string,
  enviados: PropTypes.string,
};
