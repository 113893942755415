import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaEye } from 'react-icons/fa';

import {
  Icon,
  Button,
  IconButton,
  Switch,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import api from '~/services/api';
import history from '~/services/history';
import { hasPermission } from '~/components/AccessControl';

function Item({ cg, setCgsSusep, cgsSusep }) {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idLog, setIdLog] = useState();
  const [dataLog, setDataLog] = useState({ user: { name: '', email: '' } });
  const [renderButton, setRenderButton] = useState(true);

  const infoLogsCgSusep = useCallback(async () => {
    try {
      setLoading(true);
      if (idLog) {
        const { data } = await api.get(`/cgs-susep/${idLog}/logs`);
        if (data && data.docs && data.docs.length > 0) {
          setDataLog(data.docs.shift());
        }
      }
      setIdLog();
    } finally {
      setLoading(false);
    }
  }, [idLog]);

  useEffect(() => {
    infoLogsCgSusep();
  }, [infoLogsCgSusep]);

  const renderLog = log => {
    setIdLog(log);
  };
  const renderTd = () => {
    return loading ? (
      <Grid container justifyContent="center">
        <Grid item align="center">
          <CircularProgress style={{ padding: 10 }} size={45} />
        </Grid>
      </Grid>
    ) : (
      <>
        {dataLog.user.name}
        <br />
        {dataLog.user.email}
      </>
    );
  };

  const handleClickCgSusep = cgSusep => {
    if (cgSusep && cgSusep.id) {
      history.push(`/cg-susep/${cgSusep.id}`);
    }
  };

  const handleChange = async () => {
    let updated = false;
    try {
      setLoadingStatus(true);
      if (
        hasPermission('delete_cg_susep') ||
        hasPermission('undelete_cg_susep')
      ) {
        const verb = cg.status === 0 ? 'put' : 'delete';
        const { status } =
          (await api[verb](
            `/cgs-susep/${cg.id}${cg.status === 0 ? '/undelete' : ''}`
          )) || {};
        updated = status === 204;
        if (updated) {
          setCgsSusep(
            cgsSusep.map(cgs => {
              if (cgs.id === cg.id) {
                cgs.status = cg.status ? 0 : 1;
              }
              return cgs;
            })
          );
        }
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const chance = value => {
    if (value === 'D') return 'DUPLA';
    if (value === 'T') return 'TRIPLA';
    return 'SIMPLES';
  };

  return (
    <tr key={cg.id} style={{ opacity: cg.status ? 1 : 0.6 }}>
      <td>{cg.processoSusep}</td>
      <td>{cg.nomePlano}</td>
      <td>{cg.nomeComercial}</td>
      <td>{cg.produto.nome}</td>
      <td>
        <Button
          style={{ widh: '100%' }}
          size="small"
          variant="text"
          onClick={() => handleClickCgSusep(cg)}
        >
          {chance(cg.chance)}
        </Button>
      </td>
      <td>{format(parseISO(cg.dataInsert), 'dd/MM/yyyy HH:mm:ss')}</td>

      {renderButton ? (
        <td>
          <Grid container justifyContent="center">
            <Grid item align="center">
              <IconButton
                style={{ margin: 10, color: '#3f51b5' }}
                variant="outlined"
                onClick={() => {
                  setRenderButton(false);
                  renderLog(cg.id);
                }}
              >
                <FaEye />
              </IconButton>
            </Grid>
          </Grid>
        </td>
      ) : (
        <td>{renderTd()}</td>
      )}

      <td style={{ textAlign: 'center' }}>
        {loadingStatus ? (
          <CircularProgress size={32} />
        ) : (
          <Switch
            disabled={loadingStatus}
            checked={cg.status === 1}
            onChange={() => handleChange(cg)}
            color={cg.status ? 'primary' : 'secondary'}
          />
        )}
      </td>
      <td style={{ width: 1 }}>
        <Button
          size="small"
          variant="text"
          onClick={() => handleClickCgSusep(cg)}
        >
          <Icon color="primary">edit</Icon>
        </Button>
      </td>
    </tr>
  );
}
Item.propTypes = {
  cg: PropTypes.oneOfType([PropTypes.object]).isRequired,
  cgsSusep: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setCgsSusep: PropTypes.func.isRequired,
};
export default Item;
