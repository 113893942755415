import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  CircularProgress,
  Icon,
  InputLabel,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { get } from 'lodash';
import TextField from '~/components/unform/v2/InputText';

import {
  format as formatDate,
  isValid,
  format,
  parseISO,
  subDays,
  subHours,
  addHours,
  startOfDay,
  endOfDay,
  setHours,
  setMinutes,
  setSeconds,
  add,
} from 'date-fns';

import { AccessControl, NewUnformDatePicker, IDatePicker } from '~/components';

import api from '~/services/api';

import TextMaskCurrency from '~/components/masks/TextMaskCurrency';

import { TextMaskTime, TextMaskNumber } from '~/components/masks';
import InstitutionsAutocomplete from '~/components/InstitutionsAutocomplete';
import CgsSusepAutocomplete from '~/components/CgsSusepAutocomplete';
import { promotionDraftFormSchema } from '~/schemas/promotionDraftFormSchema';
import { ValidationError } from 'yup';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import { Form } from '@unform/web';
import { hasPermission } from '~/components/AccessControl';
import { Content, FormError } from './styles';

export default function DoubleCheckPromotionForm({
  product,
  draft,
  config,
  setStepsStatus,
}) {
  const isEbook = product?.cod === 'ebook';
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);

  const [initialData, setInitialData] = useState(null);

  const [institution, setInstitution] = useState(isEbook ? 24 : '');
  const [cgSusep, setCgSusep] = useState(null);
  const [category, setCategory] = useState(0);
  const [modalidade, setModalidade] = useState('');
  const [dataReferencia, setDataReferencia] = useState(null);
  const [dataVigencia, setDataVigencia] = useState({
    inicial: '',
    final: '',
  });
  const [extracao, setExtracao] = useState('');
  const [calculatingExtraction, setCalculatingExtraction] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const currentConfig = useRef(null);
  const formRef = useRef(null);

  const getConfig = useCallback(async () => {
    let isMounted = true;
    try {
      if (hasPermission('read_promotion_config')) {
        const { data } = await api.get(
          `/promotions/config/${product.id}?byProduct=true`
        );

        if (isMounted && data) {
          currentConfig.current = { ...data };
        }
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [product]);

  useEffect(() => {
    if (!currentConfig.current) {
      getConfig();
    }
  }, [currentConfig, getConfig]);

  const handleDivergentFields = divergentFields => {
    const errors = {};

    Object.entries(divergentFields).forEach(([key, value]) => {
      if (value.isDivergent) {
        errors[key] = `Valor divergente`;
      }
    });

    if (divergentFields.dataSorteioPrincipal) {
      const { input, original } = divergentFields.dataSorteioPrincipal;

      if (!isValid(input) || !isValid(original)) {
        errors.horarioSorteio = 'Valor divergente';
      } else {
        const formatTime = date => format(parseISO(date), 'HH:mm');

        if (formatTime(input) !== formatTime(original)) {
          errors.horarioSorteio = 'Valor divergente';
        }
      }
    }

    toast.error(
      <Toast
        message="Alguns campos não estão corretos, verifique os dados."
        error
      />
    );

    throw new ValidationError(errors);
  };

  const handleFormError = error => {
    if (error instanceof ValidationError) {
      const errorMessages = {};

      if (error.inner.length) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });
      }

      Object.entries(error.message).forEach(([field, message]) => {
        errorMessages[`data.promotion.${field}`] = message;
      });

      setFormErrors(errorMessages);

      formRef.current.setErrors(errorMessages);
    }
  };

  const handleDoubleCheck = useCallback(
    async userInput => {
      try {
        const userPayload = {
          ...userInput,
          dataSorteioPrincipal: new Date(
            `${userInput.dataReferencia} ${userInput.horarioSorteio}`
          ),
        };

        const { data } = await api.post(
          `/promotions/drafts/${draft._id}/double-check`,
          {
            userInput: userPayload,
          }
        );

        if (data?.allFieldsMatch) {
          setFormErrors({});
          formRef.current.setErrors({});
          toast.success(
            <Toast message="Os dados foram confirmados com sucesso." />
          );
          setStepsStatus(prevStatus => ({ ...prevStatus, promotion: true }));
          return true;
        }

        handleDivergentFields(data.divergentFields);
        return false;
      } catch (error) {
        setStepsStatus(prevStatus => ({ ...prevStatus, promotion: false }));
        handleFormError(error);
        return false;
      } finally {
        setLoadingForm(false);
      }
    },
    [draft._id, setStepsStatus]
  );

  const createObj = userInput => {
    return {
      qtdMinimaCupons: parseFloat(userInput.data.promotion.qtdMinimaCupons),
      qtdMaximaCupons: parseFloat(userInput.data.promotion.qtdMaximaCupons),
      qtdSugerida1: parseFloat(userInput.data.promotion.qtdSugerida1),
      qtdSugerida2: parseFloat(userInput.data.promotion.qtdSugerida2),
      produto: product.id,
      tituloPromocao: userInput.data.promotion.tituloPromocao,
      valorProduto: parseFloat(
        userInput.data.promotion.valorProduto
          .replace('R$ ', '')
          .replace(',', '.')
      ),
      categoria: category,
      dataReferencia: isValid(dataReferencia)
        ? formatDate(dataReferencia, 'yyyy-MM-dd')
        : null,
      modalidade: isEbook ? '' : modalidade,
      codigoProduto: +product.cod,
      idInstituicao: isEbook ? 24 : +institution,
      extracao: +extracao,
      config: JSON.stringify(config),
      horarioSorteio: userInput.data.promotion.horarioSorteio,
    };
  };

  const handleOptionalFields = (obj, userInput) => {
    const newObj = obj;
    if (
      product.cod !== 'instantaneo' &&
      product.cod !== 'diadasorte' &&
      product.cod !== 'sabadaodasorte' &&
      (userInput.data.promotion.modalidade === 'susep' ||
        modalidade === 'susep')
    ) {
      newObj.numeroSerie = userInput.data.promotion.numeroSerie;
    } else {
      newObj.numeroSerie = '';
    }

    if (product.cod === 'instantaneo') {
      newObj.instantanea = 1;
    }
    if (!isEbook) {
      newObj.idCgSusep = null;
    }
    if (cgSusep && cgSusep !== '') {
      newObj.idCgSusep = `${cgSusep}`;
    }
    if (product.cod !== 'instantaneo' && isValid(dataReferencia)) {
      newObj.dataSorteioPrincipal = `${formatDate(
        dataReferencia,
        'yyyy-MM-dd'
      )} ${userInput.data.promotion.horarioSorteio}`;
    }

    return newObj;
  };

  const handleSubmit = async userInput => {
    setLoadingForm(true);
    try {
      userInput = promotionDraftFormSchema.cast(userInput, {
        context: { produto: product },
      });

      const obj = createObj(userInput);
      const parsedObj = handleOptionalFields(obj, userInput);

      const copyDataVigencia = { ...dataVigencia };

      if (
        typeof copyDataVigencia.inicial === 'string' &&
        isValid(parseISO(copyDataVigencia.inicial))
      )
        copyDataVigencia.inicial = parseISO(copyDataVigencia.inicial);

      if (
        typeof copyDataVigencia.final === 'string' &&
        isValid(parseISO(copyDataVigencia.final))
      )
        copyDataVigencia.final = parseISO(copyDataVigencia.final);

      const {
        inicial: dataVigenciaInicio,
        final: dataVigenciaFim,
      } = copyDataVigencia;

      if (dataVigenciaInicio && isValid(dataVigenciaInicio)) {
        parsedObj.dataVigenciaInicio = formatDate(
          dataVigenciaInicio,
          'yyyy-MM-dd HH:mm:ss'
        );
      }
      if (dataVigenciaFim && isValid(dataVigenciaFim)) {
        parsedObj.dataVigenciaFim = formatDate(
          dataVigenciaFim,
          'yyyy-MM-dd HH:mm:ss'
        );
      }

      return handleDoubleCheck(parsedObj);
    } catch (err) {
      return handleFormError(err);
    }
  };

  const getDoubleCheckData = useCallback(async () => {
    setLoading(true);
    try {
      const { data: doubleCheckedData } = await api.get(
        `/promotions/drafts/${draft._id}/get-double-check-data`
      );

      if (doubleCheckedData) {
        setCategory(doubleCheckedData.promotion.categoria);

        setDataReferencia(
          add(new Date(doubleCheckedData.promotion.dataReferencia), { days: 1 })
        );
        setDataVigencia({
          inicial: doubleCheckedData.promotion.dataVigenciaInicio,
          final: doubleCheckedData.promotion.dataVigenciaFim,
        });
        setExtracao(doubleCheckedData.promotion.extracao);
        setModalidade(doubleCheckedData.promotion.modalidade);
        setCgSusep(doubleCheckedData.promotion.idCgSusep);
        setInstitution(doubleCheckedData.promotion.idInstituicao);

        setInitialData({
          data: {
            promotion: {
              ...doubleCheckedData.promotion,
              valorProduto: String(
                doubleCheckedData.promotion.valorProduto
              ).replace('.', ','),
            },
          },
        });
      }
    } catch (error) {
      setInitialData({
        data: {
          promotion: {
            categoria: 0,
            horarioSorteio: undefined,
            dataVigenciaInicio: undefined,
            dataVigenciaFim: undefined,
            pulo: undefined,
            idInstituicao: undefined,
            idCgSusep: undefined,
            qtdMinimaCupons: undefined,
            qtdMaximaCupons: undefined,
            qtdSugerida1: undefined,
            qtdSugerida2: undefined,
            extracao: undefined,
            dataReferencia: undefined,
          },
        },
      });
    } finally {
      setLoading(false);
    }
  }, [draft._id, setInitialData, setLoading, setDataReferencia]);

  useEffect(() => {
    getDoubleCheckData();
  }, [getDoubleCheckData]);

  const calculateDataVigencia = useCallback(refDate => {
    const {
      horaVigenciaInicial: horaInicial,
      horaVigenciaFinal: horaFinal,
      tempoDuracao,
      utc,
    } = get(currentConfig, 'current.config', {});

    const setTime = (date, strTime = '') => {
      const [hours, minutes, seconds] = strTime.split(':');
      const dateFinal = new Date(
        setHours(
          setMinutes(setSeconds(date, Number(seconds)), Number(minutes)),
          Number(hours)
        )
      );

      let newDate;
      if (utc && utc.length > 0) {
        newDate = utc.startsWith('+')
          ? addHours(dateFinal, utc.substr(1))
          : subHours(dateFinal, utc.substr(1));
      } else {
        newDate = dateFinal;
      }

      return newDate;
    };

    const inicial = setTime(
      subDays(startOfDay(refDate), tempoDuracao || 7),
      horaInicial
    );
    const final = setTime(subDays(endOfDay(refDate), 1), horaFinal);
    setDataVigencia(() => ({ inicial, final }));
  }, []);

  const calculateExtractionNumber = useCallback(() => {
    const referenceDate = isValid(dataReferencia)
      ? format(dataReferencia, 'yyyy-MM-dd')
      : null;
    const productId = product.id || '';

    if (!productId || !referenceDate || calculatingExtraction || extracao)
      return;

    const horarioSorteio = get(
      document.getElementById('data.promotion.horarioSorteio'),
      'value',
      ''
    );

    setCalculatingExtraction(true);
    api
      .get('/promotions/drafts/next-extraction', {
        params: {
          produto: productId,
          data_referencia: `${referenceDate}T${horarioSorteio}Z`,
          ignore: draft._id,
          isDoubleCheck: true,
          draftId: draft._id,
        },
      })
      .then(res => {
        const extraction = get(res, 'data.extraction', '');
        setExtracao(extraction);
      })
      .finally(() => {
        setCalculatingExtraction(false);
      });
  }, [product, dataReferencia, extracao, draft, calculatingExtraction]);

  useEffect(() => {
    if (dataReferencia && isValid(dataReferencia)) calculateExtractionNumber();
  }, [dataReferencia]);

  const renderForm = () => {
    return (
      <Form
        autoComplete="off"
        autoCapitalize="off"
        onSubmit={handleSubmit}
        initialData={initialData}
        ref={formRef}
      >
        <Grid container spacing={2}>
          {/* Data Referencia */}
          <Grid item xs={12} md={3}>
            <NewUnformDatePicker
              disabled={!config.fields.promotions.dataReferencia}
              label="Data Referência"
              name="data.promotion.dataReferencia"
              margin="normal"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              className="inputLarge"
              value={dataReferencia}
              onChange={date => {
                setDataReferencia(date);
                calculateDataVigencia(date);
                return isValid(date) ? format(date, 'yyyy-MM-dd') : null;
              }}
            />
          </Grid>
          <Grid item xs={6} md={9}>
            <TextField
              disabled={!config.fields.promotions.tituloPromocao}
              className="inputLarge"
              name="data.promotion.tituloPromocao"
              label="Título da Promoção"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} md={2}>
            <TextField
              disabled={!config.fields.promotions.horarioSorteio}
              InputProps={{
                inputComponent: TextMaskTime,
              }}
              name="data.promotion.horarioSorteio"
              label="Horário do Sorteio"
              margin="normal"
              variant="outlined"
              id="horarioSorteio"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={2} style={{ paddingTop: 24 }}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!formErrors['data.promotion.categoria']}
              disabled={!config.fields.promotions.categoria}
            >
              <InputLabel id="data.promotion.categoria">Categoria</InputLabel>
              <Select
                labelId="data.promotion.categoria"
                id="data.promotion.categoria"
                value={category}
                name="data.promotion.categoria"
                label="data.promotion.categoria"
                onChange={({ target }) => {
                  setCategory(+target.value);
                }}
                error={!!formErrors['data.promotion.categoria']}
              >
                <MenuItem value={0}>Sem categoria</MenuItem>
                <MenuItem value={1}>Simples</MenuItem>
                <MenuItem value={2}>Dupla</MenuItem>
                <MenuItem value={3}>Tripla</MenuItem>
              </Select>
              {formErrors['data.promotion.categoria'] && (
                <FormError>Valor divergente</FormError>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <IDatePicker
              label="Inicio da Vigência"
              name="data.promotion.dataVigenciaInicio"
              format="dd/MM/yyyy HH:mm:ss"
              margin="normal"
              variant="inline"
              inputVariant="outlined"
              value={dataVigencia.inicial}
              error={formErrors['data.promotion.dataVigenciaInicio']}
              onChange={date => {
                setDataVigencia(oldDataVigencia => ({
                  ...oldDataVigencia,
                  inicial: date,
                }));
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <IDatePicker
              label="Fim da Vigência"
              name="data.promotion.dataVigenciaFim"
              format="dd/MM/yyyy HH:mm:ss"
              margin="normal"
              variant="inline"
              inputVariant="outlined"
              value={dataVigencia.final}
              error={formErrors['data.promotion.dataVigenciaFim']}
              onChange={date => {
                setDataVigencia(oldDataVigencia => ({
                  ...oldDataVigencia,
                  final: date,
                }));
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              disabled={!config.fields.promotions.extracao}
              name="data.promotion.extracao"
              label="Número da Extração"
              margin="normal"
              variant="outlined"
              value={extracao}
              onChange={e => {
                setExtracao(get(e, 'currentTarget.value', ''));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => calculateExtractionNumber()}
                      disabled={!!extracao || !isValid(dataReferencia)}
                    >
                      {!calculatingExtraction && <Icon>calculate</Icon>}
                      {calculatingExtraction && <CircularProgress size={25} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              disabled={!config.fields.promotions.valor}
              InputProps={{
                inputComponent: TextMaskCurrency,
              }}
              name="data.promotion.valorProduto"
              label="Valor do título"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="data.promotion.qtdMinimaCupons"
              label="Quantidade mínima"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="data.promotion.qtdMaximaCupons"
              label="Quantidade máxima"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="data.promotion.qtdSugerida1"
              label="Quantidade sugerida 1"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="data.promotion.qtdSugerida2"
              label="Quantidade sugerida 2"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2} style={{ paddingTop: 24 }}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!formErrors['data.promotion.modalidade']}
              disabled={!config.fields.promotions.modalidade}
            >
              <InputLabel id="data.promotion.modalidade">Modalidade</InputLabel>
              <Select
                labelId="data.promotion.modalidade"
                id="data.promotion.modalidade"
                value={modalidade}
                label="data.promotion.modalidade"
                onChange={({ target }) => {
                  setModalidade(target.value);
                }}
              >
                <MenuItem value="secap">Secap</MenuItem>
                <MenuItem value="susep">Susep</MenuItem>
              </Select>
              {formErrors['data.promotion.modalidade'] && (
                <FormError>Valor divergente</FormError>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <CgsSusepAutocomplete
              disabled={!config.fields.promotions.cgSusep}
              produto={product.id}
              onChange={({ target }) => {
                setCgSusep(target.value);
              }}
              value={cgSusep}
              error={formErrors['data.promotion.idCgSusep']}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            {product.cod !== 'instantaneo' &&
              product.cod !== 'diadasorte' &&
              product.cod !== 'sabadaodasorte' && (
                <TextField
                  name="data.promotion.numeroSerie"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  label="Número Série"
                  onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value, 10))
                      .toString()
                      .slice(0, 3);
                  }}
                />
              )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InstitutionsAutocomplete
              disabled={!config.fields.promotions.instituicao}
              onChange={({ target }) => {
                setInstitution(+target.value);
              }}
              value={+institution}
              error={formErrors['data.promotion.idInstituicao']}
            />
          </Grid>
        </Grid>

        {/* Botoes  */}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4} align="right">
            {draft.status !== 'approved' && (
              <AccessControl can={['double_check_promotion_draft']}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loadingForm || draft.status === 'approved'}
                  color="primary"
                >
                  {loadingForm ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <Icon>save</Icon>&nbsp;Conferir
                    </>
                  )}
                </Button>
              </AccessControl>
            )}
          </Grid>
        </Grid>
      </Form>
    );
  };

  return (
    <Content>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {loading && <CircularProgress />}
          {product && initialData && renderForm()}
        </Grid>
      </Grid>
    </Content>
  );
}

DoubleCheckPromotionForm.defaultProps = {
  draft: {},
};

DoubleCheckPromotionForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]),
  config: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setStepsStatus: PropTypes.func.isRequired,
};
