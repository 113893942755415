import React, { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import AutoComplete from '@material-ui/lab/Autocomplete';

function ApplicationsAutoComplete({
  name,
  options,
  label,
  onChange,
  disabled,
  defaultValue,
}) {
  const { fieldName, registerField, error } = useField(name);

  const [value, setValue] = useState(defaultValue || null);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        getValue() {
          return value || '';
        },
        clearValue() {
          setValue(null);
        },
      });
    }
  }, [fieldName, registerField, value]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <AutoComplete
      value={value}
      options={options}
      getOptionLabel={option => option.descricao || ''}
      onChange={handleChange}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          error={!!error}
          label={label ?? 'Selecione'}
          variant="outlined"
          helperText={error}
        />
      )}
    />
  );
}

ApplicationsAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      descricao: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    descricao: PropTypes.string,
  }),
};

ApplicationsAutoComplete.defaultProps = {
  label: 'Aplicações',
  onChange: null,
  disabled: false,
  defaultValue: null,
};

export default ApplicationsAutoComplete;
