import 'date-fns';
import React from 'react';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider, TimePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { TextField as MUITextField } from '@material-ui/core';
import { pickAll } from 'ramda';

function ITimePicker({
  inputRef,
  onChange,
  format,
  value: defaultValue = null,
  ...rest
}) {
  const [selectedDate, setSelectedDate] = React.useState(defaultValue);

  const handleTimeChange = time => {
    setSelectedDate(time);
    onChange(time);
  };

  const { inputVariant, ...inputProps } = pickAll(
    [
      'id',
      'label',
      'style',
      'className',
      'inputVariant',
      'InputLabelProps',
      'InputProps',
      'inputProps',
      'fullWidth',
      'margin',
      'name',
    ],
    rest
  );

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ptBR}>
      <TimePicker
        {...rest}
        renderInput={props => (
          <MUITextField
            {...inputProps}
            {...props}
            InputLabelProps={{
              shrink: !!selectedDate,
              ...inputProps.InputLabelProps,
            }}
            variant={inputVariant}
            helperText={undefined}
          />
        )}
        inputProps={{ ref: inputRef }}
        inputFormat={format}
        value={selectedDate}
        onChange={handleTimeChange}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
      />
    </LocalizationProvider>
  );
}

ITimePicker.defaultProps = {
  value: null,
  id: 'time-picker-inline',
  format: 'HH:mm',
};

ITimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.string,
  format: PropTypes.string,
  inputRef: PropTypes.any, // eslint-disable-line
};

export default ITimePicker;
