import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import api from '~/services/api';
import { isEmpty } from 'lodash';
import { MdOutlineImage, MdOutlineShoppingCart } from 'react-icons/md';
import { Container, Box } from './styles';

export default function EbooksPurchaseDialog({
  purchaseId,
  personId,
  open,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const [ebooks, setEbooks] = useState([]);

  const getEbooks = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/persons/${personId}/purchases/${purchaseId}/ebooks`
      );
      if (!isEmpty(data)) {
        setEbooks(data);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEbooks();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>E-books comprados</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Container>
            {ebooks &&
              ebooks.map(el => {
                return (
                  <Box>
                    <div className="thumbnail">
                      {el.promocaoEbook.ebook.urlThumbnail ? (
                        <img
                          src={el.promocaoEbook.ebook.urlThumbnail}
                          alt="thumbnail"
                          width="160px"
                        />
                      ) : (
                        <MdOutlineImage
                          style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                          size={80}
                        />
                      )}
                    </div>
                    <div>
                      <h1>{el.promocaoEbook.ebook.nome}</h1>
                      <span>{el.promocaoEbook.ebook.autor}</span>
                      <div>
                        <MdOutlineShoppingCart />
                        <span>{el.count}</span>
                      </div>
                    </div>
                  </Box>
                );
              })}
          </Container>
        )}
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}

EbooksPurchaseDialog.propTypes = {
  purchaseId: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
