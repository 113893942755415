import React, { createContext, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

export const BannerFormContext = createContext({
  data: {},
  addNewBanner: () => {},
  editBanner: () => {},
  closeForm: () => {},
});

export const BannerFormProvider = ({ children }) => {
  const [formOpened, setFormOpened] = useState(false);
  const [data, setData] = useState({});

  const addNewBanner = useCallback(additionalData => {
    setFormOpened(true);
    setData(additionalData);
  }, []);

  const editBanner = useCallback(bannerData => {
    setData(oldData => ({ ...oldData, ...bannerData }));
    setFormOpened(true);
  }, []);

  const closeForm = useCallback(() => {
    setFormOpened(false);
    setData({});
  }, []);

  return (
    <BannerFormContext.Provider
      value={{ data, formOpened, addNewBanner, editBanner, closeForm }}
    >
      {children}
    </BannerFormContext.Provider>
  );
};

BannerFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBannerForm = () => {
  const context = useContext(BannerFormContext);
  return context;
};
