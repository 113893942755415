import React, { useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useBannerForm } from './context';
import { useBannersHome } from '../context';

const BannerFormDialog = ({
  children,
  title,
  okText,
  cancelText,
  onOk,
  onCancel,
  formId,
  loadingText,
}) => {
  const { formOpened, closeForm } = useBannerForm();
  const { creating, updating } = useBannersHome();

  const handleCancel = useCallback(() => {
    closeForm();
    if (typeof onCancel === 'function') onCancel();
  }, [closeForm, onCancel]);

  const handleOk = useCallback(() => {
    if (typeof onOk === 'function') onOk();
  }, [onOk]);

  return (
    <Dialog
      open={formOpened}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      disableEnforceFocus /* @see https://github.com/mui-org/material-ui-pickers/issues/1852 */
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions>
        <Button
          onClick={handleCancel}
          color="secondary"
          disabled={creating || updating}
        >
          {cancelText}
        </Button>
        <Button
          onClick={handleOk}
          type="submit"
          form={formId}
          color="primary"
          disabled={creating || updating}
        >
          {creating || updating ? loadingText : okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BannerFormDialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  formId: PropTypes.string,
  loadingText: PropTypes.string,
};

BannerFormDialog.defaultProps = {
  okText: 'Salvar',
  cancelText: 'Cancelar',
  loadingText: 'Salvando...',
  onOk: () => {},
  onCancel: () => {},
  formId: '',
};

export default BannerFormDialog;
