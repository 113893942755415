import styled from 'styled-components';

export const Row = styled.div`
  padding: 20px 0px;
`;

export const FirstLetter = styled.p`
  &::first-letter {
    text-transform: uppercase;
  }
`;
