import styled from 'styled-components';
import { Form as Unform } from '@rocketseat/unform';

export const Container = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Thumbnail = styled.div`
  width: 160px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputFile = styled.div``;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;
