import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel } from './styles';

export const EnhancedTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'pessoa',
      numeric: false,
      disablePadding: false,
      label: 'Nome',
    },
    {
      id: 'ultimaDataCompra',
      numeric: false,
      disablePadding: false,
      label: 'Última Compra',
    },
    {
      id: 'totalCompras',
      numeric: true,
      disablePadding: false,
      label: 'Qtd de Transações',
    },

    {
      id: 'totalCartoes',
      numeric: true,
      disablePadding: false,
      label: 'Qtd cartões',
    },
    { id: 'produto', numeric: false, disablePadding: false, label: 'Produto' },
    {
      id: 'valorTotalCompras',
      numeric: true,
      disablePadding: false,
      label: 'Valor Total Compras',
    },

    {
      id: 'tipoCartao',
      numeric: false,
      disablePadding: false,
      label: 'Forma de pagamento',
    },
    {
      id: 'tag',
      numeric: false,
      disablePadding: false,
      label: 'Tag',
    },
  ];

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: '#3f51b5' }}>
        {headCells.map(headCell => (
          <TableCell
            style={{ padding: '5px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{ color: 'white' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
