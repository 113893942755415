import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label, ContentWrapper } from './styles';

function CardLabelContent({ label, children, ...props }) {
  return (
    <Container {...props}>
      <Label className="card-label-content label">{label}</Label>
      <ContentWrapper className="card-label-content content-wrapper">
        {children}
      </ContentWrapper>
    </Container>
  );
}

CardLabelContent.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

export default CardLabelContent;
