import React from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { format } from 'date-fns';
import { BsCheck } from 'react-icons/bs';

export default function CallContactPopover({
  open,
  handlePopoverClose,
  anchorEl,
  ultimoContato,
}) {
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        style={{ pointerEvents: 'none' }}
        disableRestoreFocus
      >
        <div style={{ padding: 10 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Último contato
          </Typography>
          <div>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      {format(new Date(ultimoContato), 'dd/MM/yyyy HH:mm')}
                    </span>
                  }
                />
              </ListItem>
            </List>
          </div>
        </div>
      </Popover>
    </>
  );
}

CallContactPopover.defaultProps = {
  anchorEl: null,
  ultimoContato: null,
};

CallContactPopover.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool]).isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  ultimoContato: PropTypes.oneOfType([PropTypes.string]),
};
