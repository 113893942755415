import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 230px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h1 {
    color: #303f9f;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const IconRefresh = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const CircleIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: rgba(63, 81, 181, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogContent = styled.div`
  display: flex;

  h1 {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
  }
`;
