import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { isAfter, parseISO, format, subMinutes } from 'date-fns';
import { get } from 'lodash';
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from 'recharts';

import {
  MdWarning,
  MdError,
  MdCheckCircle,
  MdNewReleases,
  MdHourglassEmpty,
} from 'react-icons/md';
import { PushPreview } from '~/components';
import { getMessage } from '~/helpers/fcm';

import {
  Title,
  TitleMgTop,
  Content,
  Alert,
  SectionTitle,
  SimpleTable,
} from './styles';

export default function PushDetail({ push, app, onClose }) {
  const [open, setOpen] = useState(true);

  const { stats, sendDate, name } = app;
  const { done, failure, processing, waiting } = stats;
  const date = parseISO(sendDate);

  const totalSuccess = useMemo(() => get(stats, 'result.success', 0), [stats]);
  const totalFailure = useMemo(() => get(stats, 'result.failure', 0), [stats]);

  const isSchedule =
    !done && !failure && !processing && isAfter(date, Date.now()) && !waiting;

  const isFailure = done && failure;
  const isPartialSent = done && !failure && get(stats, 'result.failure') > 0;
  const toTopics = done && get(push, 'topics', []).length > 0;
  const topics = toTopics && push.topics.join(', ');
  const isNotStarted =
    !waiting && !done && !failure && get(stats, 'result.failure', 0) === 0;

  const totals = {
    sent: totalSuccess,
    fail: totalFailure,
  };

  const totalMessages = totals.sent + totals.fail;

  const errors = get(stats, 'result.errors', []).map(error => ({
    ...error,
    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    message: getMessage(error.error),
  }));

  const totalMessagesLabel =
    totalMessages > 1 || totalMessages === 0
      ? `${totalMessages} dispositivos`
      : `${totalMessages} dispositivo`;

  const totalErrorsLabel =
    totals.fail > 1 || totals.fail === 0
      ? `${totals.fail} falhas no envio`
      : `${totals.fail} falha no envio`;

  const chartdata = [
    {
      name: 'Enviadas',
      value: totals.sent,
      color: '#56a887',
    },
    {
      name: 'Não Enviadas',
      value: totals.fail,
      color: '#bbbbbb',
    },
  ];

  const sendDateText = useMemo(() => {
    if (!processing && !isSchedule && isNotStarted) {
      return 'Agendado para: ';
    }
    if (isSchedule) {
      return 'Agendado para: ';
    }
    if (toTopics && topics.length > 0) {
      return 'Enviado às: ';
    }
    return '';
  }, []);

  /** component did unmount */
  useEffect(() => () => setOpen(false), []);
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Content>
        <section>
          <Title>
            Detalhamento de Notificação{' '}
            {!!name && <Chip label={name} size="small" />}
          </Title>

          {waiting && (
            <Alert>
              <MdHourglassEmpty /> A notificação está pré agendada, ainda pode
              ser removida até às{' '}
              {format(subMinutes(date, 6), 'dd/MM/yyyy HH:mm')}
            </Alert>
          )}
          {!processing && !isSchedule && isNotStarted && (
            <Alert>
              <MdNewReleases /> A notificação ainda será enviada, porém o
              trabalho na fila ainda não começou a processar.
            </Alert>
          )}

          {isSchedule && (
            <Alert>
              <MdWarning /> Esta notificação está agendada para ser enviada em{' '}
              <strong>{format(date, 'dd/MM/yyyy HH:mm')}</strong>.
            </Alert>
          )}

          {isFailure && (
            <Alert failure>
              <MdError /> Houve uma falha no processo de envio e a notificação
              não foi enviada.{' '}
              {stats.error.message && 'A mensagem de erro capturada é: '}
              <strong>{stats.error.message}</strong>
            </Alert>
          )}

          {toTopics && topics.length > 0 && !isFailure && (
            <Alert success>
              <MdCheckCircle /> OK, as notificações para esta aplicação foram
              enviada para os tópicos <strong>{topics}</strong>
            </Alert>
          )}

          {!waiting &&
            !isSchedule &&
            !isFailure &&
            !isPartialSent &&
            !toTopics &&
            !processing &&
            !isNotStarted && (
              <Alert success>
                <MdCheckCircle /> OK, para esta aplicação{' '}
                <strong>
                  100% ({stats.result.success}{' '}
                  {stats.result.success > 1 ? 'dispositivos' : 'dispositivo'})
                </strong>{' '}
                das mensagens foram enviadas com sucesso.
              </Alert>
            )}

          {(isPartialSent || processing) && (
            <>
              {!processing && (
                <Alert>
                  {/* <MdWarning /> O firebase conseguiu enviar as notificações
                parcialmente, confira o resultado em detalhes abaixo. */}
                  <MdWarning /> A solicitação chegou ao firebase mas foi
                  processada com erros. Confira mais detalhes abaixo!
                </Alert>
              )}

              {processing && (
                <Alert>
                  <CircularProgress size={16} /> Este trabalho ainda está em
                  execução.
                </Alert>
              )}

              {/* Number of Sent and Fails */}
              <SectionTitle>
                Enviadas x Não Enviadas <small>{totalMessagesLabel}</small>
              </SectionTitle>

              <div style={{ width: '100%', height: '250px' }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend
                      align="center"
                      verticalAlign="bottom"
                      margin={{ top: 20 }}
                    />

                    <Tooltip
                      itemStyle={{
                        fontSize: '12px',
                      }}
                      contentStyle={{
                        padding: '3px 6px',
                        borderRadius: 4,
                        borderColor: '#f4f4f4',
                      }}
                    />

                    <Pie
                      label={({ percent, value }) => {
                        const percentage = (percent * 100).toFixed(2);
                        return `${percentage}% (${value} dispositivos)`;
                      }}
                      dataKey="value"
                      nameKey="name"
                      data={chartdata}
                      isAnimationActive={false}
                      outerRadius="60%"
                    >
                      {chartdata.map(dataset => (
                        <Cell
                          key={`cell-${dataset.value}`}
                          fill={dataset.color}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>

              {/* Reasons */}
              <SectionTitle>
                Motivo x Quantidade <small>{totalErrorsLabel}</small>
              </SectionTitle>

              <div style={{ width: '100%', height: '250px' }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend
                      align="center"
                      verticalAlign="bottom"
                      margin={{ top: 20 }}
                    />

                    <Pie
                      label={({ percent, value }) => {
                        const percentage = (percent * 100).toFixed(2);
                        return `${percentage}% (${value} total)`;
                      }}
                      dataKey="total"
                      nameKey="error"
                      data={errors}
                      isAnimationActive={false}
                      outerRadius="60%"
                      fill="#888"
                      paddingAngle={2}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <SimpleTable style={{ marginTop: 20 }}>
                <header>
                  <span>Código</span>
                  <span>Motivo</span>
                </header>

                {errors.map(({ error, message }) => (
                  <div key={error}>
                    <span>{error}</span>
                    <span>{message}</span>
                  </div>
                ))}
              </SimpleTable>
            </>
          )}

          <SimpleTable>
            <TitleMgTop>Informações Push</TitleMgTop>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell style={{ color: '#000' }}>
                    <span style={{ fontWeight: 'bold' }}>Criado por: </span>
                    {push.createdBy ? push.createdBy.name : 'Não informado'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ color: '#000' }}>
                    <span style={{ fontWeight: 'bold' }}>{sendDateText}</span>
                    {format(parseISO(push.sendDate), 'dd/MM/yyyy HH:mm')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {push && push.topics && (
                    <TableCell style={{ color: '#000' }}>
                      <span style={{ fontWeight: 'bold' }}>Tópico: </span>
                      {push &&
                        push.topics &&
                        push.topics.map(topic => <span>{topic}&nbsp; </span>)}
                    </TableCell>
                  )}
                  {push && push.data && push.data.redirectLink && (
                    <TableCell style={{ color: '#000' }}>
                      <span style={{ fontWeight: 'bold' }}>Link: </span>
                      {push.data.redirectLink}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </SimpleTable>
        </section>

        <section>
          <PushPreview title={push.data.title} body={push.data.body} />
        </section>
      </Content>
    </Dialog>
  );
}

PushDetail.defaultProps = {
  push: {
    data: {},
  },
  app: {
    name: 'Hipercap Mogi',
    sendDate: '',
    stats: {},
  },
  onClose: () => {},
};

PushDetail.propTypes = {
  push: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      redirectLink: PropTypes.string,
    }),
    topics: PropTypes.instanceOf(PropTypes.array),
    createdBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    sendDate: PropTypes.string,
  }),
  app: PropTypes.shape({
    name: PropTypes.string,
    sendDate: PropTypes.string,
    stats: PropTypes.shape({
      done: PropTypes.bool,
      failure: PropTypes.bool,
      processing: PropTypes.bool,
      waiting: PropTypes.bool,
      result: PropTypes.shape({
        success: PropTypes.number,
        failure: PropTypes.number,
        errors: PropTypes.arrayOf(
          PropTypes.shape({
            error: PropTypes.string,
            total: PropTypes.number,
          })
        ),
      }),
      error: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  }),
  onClose: PropTypes.func,
};
