import styled from 'styled-components';

export const HistoryContainer = styled.ul`
  margin: 0px;
  padding: 8px;
  width: 300px;
`;

export const HistoryItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  padding: 8px;
  justify-content: space-between;

  & + & {
    border-top: 1px solid #f1f1f1;
  }

  span:first-child {
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }

  .MuiIcon-root {
    text-transform: initial !important;
  }
`;
