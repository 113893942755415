import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  tbody td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #3f51b520;
  }
  thead {
    background-color: #3f51b5;
    color: white;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const FirstLetter = styled.p`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const OverFlowWidth = styled.p`
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
