import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import formatCpf from '@brazilian-utils/format-cpf';
import { MdExpandMore, MdSave, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';

import Header from './header';
import { Table } from './styles';
import { Paginator, UnauthorizedGhost } from '~/components';
import AccessControl, { hasPermission } from '~/components/AccessControl';
import Attachment from '../Attachment';
import Toast from '~/components/Toast';
import history from '~/services/history';

export default function List({ location }) {
  const statusLabel = useRef(null);

  // -- permissions
  const can = useMemo(
    () => ({
      createNotes: hasPermission('create_screening_notes', { toast: false }),
      createAttachs: hasPermission('create_screening_attachments', {
        toast: false,
      }),
      readAttachs: hasPermission('read_screening_attachments', {
        toast: false,
      }),
      update: hasPermission('update_screening', { toast: false }),
    }),
    []
  );

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

  const [loading, setLoading] = useState(true);
  const [loadingTriagem, setLoadingTriagem] = useState([]);
  const [list, setList] = useState();
  const [promocao, setPromocao] = useState();
  const [paginator, setPaginator] = useState({
    page: 1,
    limit: 10,
  });
  const [files, setFiles] = useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState('0');

  const [statusValues, setStatusValues] = useState([]);

  const handleSaveStatusChange = async status => {
    try {
      setLoadingTriagem([...loadingTriagem, status.idTriagem]);
      await api.put(
        `/promotions/${promocao.idPromocao}/immediate-screening/${status.idTriagem}`,
        {
          status: status.status,
        }
      );
    } finally {
      setLoadingTriagem(loadingTriagem.filter(l => l !== status.idTriagem));
    }
  };

  const handleChange = (event, item) => {
    setStatusValues(oldValues => [
      ...oldValues.filter(o => o.idTriagem !== item.idTriagem),
      { idTriagem: item.idTriagem, status: event.target.value },
    ]);
    handleSaveStatusChange({
      idTriagem: item.idTriagem,
      status: event.target.value,
    });
  };

  const types = [
    { type: 'selfie', name: 'Selfie' },
    { type: 'document', name: 'Documento' },
    { type: 'proof-residence', name: 'Comprovante de Residência' },
    { type: 'others', name: 'Outros' },
  ];

  const loadList = async ({ page = 1, limit = 10 } = {}) => {
    try {
      if (promocao) {
        setLoading(true);
        const { data } =
          (await api.get(
            `/promotions/${promocao.idPromocao}/immediate-screening?page=${page}&limit=${limit}&status=${selectedFilter}`
          )) || {};
        setList(data);

        // set paginator.
        if (data && (data.page || data.limit)) {
          setPaginator({ page: data.page || 1, limit: data.limit || 10 });
        }

        if (data && data.docs) {
          setStatusValues(
            data.docs.map(d => ({ idTriagem: d.idTriagem, status: d.status }))
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = event => {
    setSelectedFilter(event.target.value);
  };

  const getAttachments = async item => {
    try {
      if (item.idTriagem && can.readAttachs) {
        const { data } =
          (await api.get(
            `/promotions/${promocao.idPromocao}/immediate-screening/${item.idTriagem}/attachments`
          )) || {};
        setFiles([
          ...files.filter(f => f.idTriagem !== item.idTriagem),
          { idTriagem: item.idTriagem, anexos: data },
        ]);
      }
    } finally {
      //
    }
  };

  function handleChangeLimit(evt) {
    const limit = evt.target.value;

    loadList({ limit, page: 1 });
  }

  function handleChangePage(_, page) {
    const { limit } = paginator;

    loadList({ limit, page: page + 1 });
  }

  useEffect(() => {
    setPromocao((location.state && location.state.promocao) || null);
  }, [location]);

  useEffect(() => {
    loadList();
    // TODO:
    // eslint-disable-next-line
  }, [promocao, selectedFilter]);

  const handleClick = item => {
    if (item.anexos.length) {
      getAttachments(item);
    }
  };

  const onChangeFiles = (type, idTriagem) => {
    const attachs = files.find(f => f.idTriagem === idTriagem);
    if (attachs && attachs.anexos && attachs.anexos.length) {
      return attachs.anexos.find(a => a.type === type.type) || {};
    }
    return {};
  };

  const handleSaveNotesClick = async item => {
    try {
      if (document.getElementById(`notes-${item.idTriagem}`).value) {
        setLoadingTriagem([...loadingTriagem, item.idTriagem]);
        await api.post(
          `/promotions/${promocao.idPromocao}/immediate-screening/${item.idTriagem}/notes`,
          {
            content: document.getElementById(`notes-${item.idTriagem}`).value,
          }
        );
      } else {
        toast.error(
          <Toast message="Favor informar alguma anotação para salvar." error />
        );
      }
    } finally {
      setLoadingTriagem(loadingTriagem.filter(l => l !== item.idTriagem));
    }
  };

  const handleEditPerson = cpf => {
    history.push(`/person/${cpf}`);
  };

  const renderList = () => {
    if (list && list.docs && list.docs.length > 0) {
      return (
        <>
          <Header />
          {list.docs.map(i => (
            <ExpansionPanel key={i.idTriagem}>
              <ExpansionPanelSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => handleClick(i)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} md={1}>
                    <h3>{String(i.idTriagem).padStart(4, '0')}&nbsp;</h3>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography>
                      {i.dataContemplacao &&
                        format(
                          parseISO(i.dataContemplacao),
                          'dd/MM/yyyy HH:mm'
                        )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>{i.pessoa.nomeCompleto}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography>
                      {i.pessoa.ddd} {i.pessoa.celular}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography>
                      <b style={{ textTransform: 'capitalize' }}>
                        {i.statusText}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ backgroundColor: 'rgb(248, 248, 248)' }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <b>Cidade</b>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        {i.pessoa.cidade}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <b>Bairro</b>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        {i.pessoa.bairro}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <b>Email</b>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        {i.pessoa.email}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <b>CPF</b>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        {formatCpf(i.pessoa.cpf)}
                        <MdEdit
                          size={18}
                          style={{
                            opacity: 0.7,
                            cursor: 'pointer',
                            marginLeft: 5,
                          }}
                          onClick={() => handleEditPerson(i.pessoa.cpf)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      {types.map(type => (
                        <Grid
                          key={`${i.idTriagem}${type.type}`}
                          item
                          xs={12}
                          md={3}
                        >
                          <Attachment
                            type={type}
                            anexo={onChangeFiles(type, i.idTriagem)}
                            screening={i.idTriagem}
                            promotion={promocao.idPromocao}
                            getAttachments={getAttachments}
                            disabled={!can.createAttachs}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" disabled={!can.update}>
                      <InputLabel ref={statusLabel} htmlFor="status">
                        Status
                      </InputLabel>
                      <Select
                        labelWidth={
                          (statusLabel.current &&
                            statusLabel.current.offsetWidth) ||
                          45
                        }
                        value={
                          statusValues.find(s => s.idTriagem === i.idTriagem)
                            .status
                        }
                        onChange={event => handleChange(event, i)}
                        inputProps={{
                          name: `status-${i.idTriagem}`,
                          id: `status-${i.idTriagem}`,
                        }}
                      >
                        <MenuItem value={0}>
                          <em>Aguardando</em>
                        </MenuItem>
                        <MenuItem value={1}>Aprovado</MenuItem>
                        <MenuItem value={2}>
                          Identificado Inconsistência
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} md={7}>
                    <TextField
                      label="Anotações"
                      multiline
                      margin="normal"
                      variant="outlined"
                      id={`notes-${i.idTriagem}`}
                      defaultValue={i.notas}
                      disabled={
                        loadingTriagem.includes(i.idTriagem) || !can.createNotes
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={1}>
                    <Fab
                      color="primary"
                      onClick={() => can.createNotes && handleSaveNotesClick(i)}
                      aria-label="save"
                      disabled={!can.createNotes}
                    >
                      {loadingTriagem.includes(i.idTriagem) ? (
                        <CircularProgress
                          style={{ color: 'white' }}
                          size={24}
                        />
                      ) : (
                        <MdSave size={24} />
                      )}
                    </Fab>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
          <Table>
            <tfoot style={{ backgroundColor: '#FFF9' }}>
              <tr>
                {loading ? (
                  <td align="right">
                    <CircularProgress size={24} />
                  </td>
                ) : (
                  <Paginator
                    page={paginator.page}
                    limit={paginator.limit}
                    totals={list.total}
                    onChangePage={handleChangePage}
                    onChangeLimit={handleChangeLimit}
                  />
                )}
              </tr>
            </tfoot>
          </Table>
        </>
      );
    }
    return <NoRecord />;
  };

  const renderHead = () => {
    return (
      <>
        {promocao ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <h1>
                Triagem - Promoção&nbsp;
                {promocao.tituloPromocao}&nbsp;
                {promocao.dataSorteioPrincipal &&
                  format(
                    parseISO(promocao.dataSorteioPrincipal),
                    'dd/MM/yyyy HH:mm'
                  )}
              </h1>
            </Grid>
            <Grid item xs={12} md={4} align="right">
              <Radio
                checked={selectedFilter === '0'}
                onChange={handleFilterChange}
                value="0"
                name="radio-filter"
                inputProps={{ 'aria-label': 'Aguardando' }}
              />
              Aguardando
              <GreenRadio
                checked={selectedFilter === '1'}
                onChange={handleFilterChange}
                value="1"
                name="radio-filter"
                inputProps={{ 'aria-label': 'Aprovado' }}
              />
              Aprovado
              <Radio
                checked={selectedFilter === '2'}
                onChange={handleFilterChange}
                value="2"
                name="radio-filter"
                inputProps={{ 'aria-label': 'Inconsistência' }}
              />
              Inconsistência
            </Grid>
          </Grid>
        ) : (
          <NoRecord message="Nenhuma Promoção foi informada!" />
        )}
      </>
    );
  };

  const renderMain = () => {
    return (
      <AccessControl
        can="read_screenings"
        displayError
        renderNoAccess={UnauthorizedGhost}
      >
        {renderHead()}
        {promocao && !loading ? renderList() : <CircularProgress size={30} />}
      </AccessControl>
    );
  };

  // Main return
  return renderMain();
}
