import styled, { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { Paper } from '@material-ui/core';

export const Content = styled(Paper)`
  padding: 16px;
  /* min-height: 300px; */
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled.h1`
  color: gray;
  font-weight: 100;
`;

export default createGlobalStyle`
 @import url(/animate.css);
 .animated.slowest {
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
}
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
    height: 100%;
  }

  body {
    background-color: #e4e4e4;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    color: #222;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  button {
    cursor: pointer;
  }

  /* .MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    background-color: white;
  } */

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiDialog-paperWidthSm {
    min-width: 400px;
  }

  /* .MuiTabs-flexContainer {
    background-color: #f6f6f6;
  } */

  .inputLarge input {
    font-size: 40px;
  }

  .MuiAutocomplete-popup {
    z-index: 9999999 !important;
  }
`;
