import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PaymentIcon from 'react-payment-icons';
import { parse, format, parseISO } from 'date-fns';

import { CircularProgress } from '@material-ui/core';
import api from '~/services/api';
import {
  TransactionContainer,
  Alert,
  StatusBullet,
  TooltipContainer,
} from './styles';

// eslint-disable-next-line
const StatusTooltip = ({ status, statusText }) => {
  const reasons = () =>
    ({
      '0': ['Aguardando atualização de status'],
      '2': [
        'Capturada (cartão de crédito e débito)',
        'Compensado (boleto bancário)',
        'Pagamento confirmado (transferência online)',
      ],
      '3': ['Negada (cartão de crédito e débito)'],
      '10': [
        'Desfeita no mesmo dia (cartão de crédito e débito)',
        'Transação de boleto cancelada',
        'Estorno da transação (cartão de crédito e débito)',
      ],
      '11': [
        'Desfeita no mesmo dia (cartão de crédito e débito)',
        'Transação de boleto cancelada',
        'Estorno da transação (cartão de crédito e débito)',
      ],
    }[status]);

  return (
    <TooltipContainer
      arrow
      placement="right"
      title={
        <>
          <h2>Status da Transação</h2>

          <ul>
            {reasons() &&
              reasons().map(reason => <li key={reason}>{reason}</li>)}
          </ul>
        </>
      }
    >
      <StatusBullet status={status}>{statusText}</StatusBullet>
    </TooltipContainer>
  );
};

export default function DialogTransacao({ open, purchase, person, onClose }) {
  const [loading, setLoading] = useState(false);
  const [cieloTransaction, setCieloTransaction] = useState({});
  const [error, setError] = useState(false);
  const errorMessage = useRef();

  useEffect(() => {
    const { idCompra } = purchase;
    setLoading(true);
    api
      .get(`/persons/${person.idCliente}/purchases/${idCompra}/transaction`)
      .then(res => {
        if (res && res.data) {
          setCieloTransaction(res.data);
        } else if (res.response.status >= 400) {
          setError(true);
          setCieloTransaction({});
        }
      })
      .catch(err => {
        if (err.response.status === 404)
          errorMessage.current = 'Esta compra não gerou transação.';
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [purchase, person]);

  const { Customer = {}, Payment = {}, customer = {}, metadata = {} } =
    cieloTransaction || {};

  const tipoCartao = useMemo(
    () =>
      ({
        CreditCard: 'Cartão de Crédito',
        DebitCard: 'Cartão de Débito',
      }[Payment.Type]),
    [Payment]
  );

  const statusText = useMemo(
    () =>
      ({
        '0': 'Não Finalizada',
        '2': 'Paga',
        '3': 'Não Autorizada',
        '10': 'Estornada',
        '11': 'Estornada',
      }[Payment.Status]),
    [Payment]
  );

  const statusTextGateway = useMemo(
    () =>
      ({
        pending: 'Pendente',
        paid: 'Paga',
        canceled: 'Não Autorizada',
        refunded: 'Estornada',
        expired: 'Expirada',
        denied: 'Negada',
        authorized: 'Autorizada',
      }[cieloTransaction.status]),
    [cieloTransaction]
  );

  const getStatusGate = () => {
    if (cieloTransaction.status === 'paid') return '2';
    if (cieloTransaction.status === 'authorized') return '2';
    if (cieloTransaction.status === 'refunded') return '11';
    if (cieloTransaction.status === 'pending') return '0';
    if (cieloTransaction.status === 'canceled') return '10';
    if (cieloTransaction.status === 'expired') return '3';
    if (cieloTransaction.status === 'denied') return '3';
    return null;
  };

  const getCardBrand = useCallback(() => {
    const brand = get(Payment, 'CreditCard.Brand', 'generic').toLowerCase();

    if (brand === 'master') return 'mastercard';

    return brand;
  }, [Payment]);

  const getCaptureDateType = () => {
    if (Payment.CapturedDate) {
      const date = format(
        parse(Payment.CapturedDate, 'yyyy-MM-dd HH:mm:ss', Date.now()),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    if (cieloTransaction.capturedDate && !cieloTransaction.createdAt) {
      const date = format(
        parseISO(cieloTransaction.capturedDate),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    if (!cieloTransaction.capturedDate && cieloTransaction.createdAt) {
      const date = format(
        parseISO(cieloTransaction.createdAt),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    if (cieloTransaction.capturedDate) {
      const date = format(
        parseISO(cieloTransaction.capturedDate),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    return 'Não capturada';
  };

  const getAmount = () => {
    let value;
    if (Payment.Amount) {
      value = parseFloat(Payment.Amount / 100).toFixed(2);
    } else {
      value = parseFloat(cieloTransaction.amount / 100).toFixed(2);
    }

    // eslint-disable-next-line no-restricted-globals
    return isNaN(value) ? '----' : value;
  };

  const getReceivedDate = () => {
    if (Payment.ReceivedDate) {
      return format(
        parse(Payment.ReceivedDate, 'yyyy-MM-dd HH:mm:ss', Date.now()),
        'dd/MM/yyyy HH:mm'
      );
    }
    if (cieloTransaction.paidAt) {
      const date = format(
        parseISO(cieloTransaction.paidAt),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    if (cieloTransaction.createdAt) {
      const date = format(
        parseISO(cieloTransaction.createdAt),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    return '----';
  };

  const getRefundedDate = () => {
    if (Payment.VoidedDate) {
      format(
        parse(Payment.VoidedDate, 'yyyy-MM-dd HH:mm:ss', Date.now()),
        'dd/MM/yyyy HH:mm'
      );
    }

    if (cieloTransaction.refundedDate) {
      const date = format(
        parseISO(cieloTransaction.refundedDate),
        'dd/MM/yyyy HH:mm'
      );
      return date;
    }

    return '----';
  };

  const getAmountRefunded = () => {
    if (Payment.VoidedAmount) {
      return parseFloat(Payment.VoidedAmount / 100).toFixed(2);
    }
    if (cieloTransaction.refundedAmount) {
      return parseFloat(cieloTransaction.refundedAmount / 100).toFixed(2);
    }
    return '----';
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Histórico da Transação #
        {purchase.transacao ? purchase.transacao.idTransacao : ''} <br />
        {!loading && !error && !!Payment.Status && (
          <StatusTooltip
            status={String(Payment.Status) || null}
            statusText={statusText}
          />
        )}
        {!loading && !error && !!cieloTransaction && (
          <StatusTooltip
            status={getStatusGate() || null}
            statusText={statusTextGateway}
          />
        )}
      </DialogTitle>

      <DialogContent style={{ paddingBottom: 15 }}>
        {loading && <CircularProgress />}

        {!loading && error === true && (
          <Alert>
            {errorMessage.current || 'Não foi possível consultar a transação'}
          </Alert>
        )}

        {!loading && !error && !!cieloTransaction && (
          <TransactionContainer>
            <h3>Cliente</h3>
            {Customer || customer ? (
              <strong>
                {(Customer && Customer.Name) || (customer && customer.name)}
              </strong>
            ) : (
              <p> --- </p>
            )}

            {(Payment.Tid || metadata.tid) && (
              <>
                <h3>TID</h3>
                <strong>{Payment.Tid || metadata.tid}</strong>
              </>
            )}

            {(Payment.AuthorizationCode || metadata.authorizationCode) && (
              <>
                <h3>Código de Autorização</h3>
                <strong>
                  {Payment.AuthorizationCode || metadata.authorizationCode}
                </strong>
              </>
            )}

            <h3>Payment ID</h3>
            <strong>{Payment.PaymentId || metadata.paymentId}</strong>

            <h3>Forma de Pagamento</h3>
            <strong>{tipoCartao}</strong>
            {Payment.CreditCard && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <PaymentIcon id={getCardBrand()} style={{ width: 35 }} />
                <span style={{ marginLeft: 10 }}>
                  {Payment.CreditCard.CardNumber}
                </span>
              </div>
            )}
            {metadata.qrCode ? 'PIX' : ''}

            <h3>Valor</h3>
            <strong>R${getAmount()}</strong>

            <h3>Data de Captura</h3>
            <strong>{getCaptureDateType()}</strong>

            <h3>Data de Recebimento</h3>
            <strong>{getReceivedDate()}</strong>

            {(!!Payment.VoidedAmount && !!Payment.VoidedDate) ||
              (cieloTransaction.refundedDate &&
                cieloTransaction.refundedAmount && (
                  <section className="estorno">
                    <h3>Transação Estornada</h3>
                    <strong>{getRefundedDate()}</strong>

                    <h3>Valor do Estorno</h3>
                    <strong>R${getAmountRefunded()}</strong>
                  </section>
                ))}
          </TransactionContainer>
        )}
      </DialogContent>
    </Dialog>
  );
}

DialogTransacao.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  purchase: PropTypes.oneOfType([PropTypes.object]).isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
