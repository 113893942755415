import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { MdChevronLeft } from 'react-icons/md';
import { format, parseISO, isBefore } from 'date-fns';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import io from 'socket.io-client';
import { hasPermission } from '~/components/AccessControl';
import history from '~/services/history';
import api, { baseURL } from '~/services/api';
import { PromotionContainer, CsvDownload } from '../../styles';
import Promotion from '../../promotion';
import { usePromotionContext } from '../../context';

const ColorButton = withStyles(() => ({
  root: {
    backgroundColor: '#05c46b',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#16a085',
    },
  },
}))(Button);

export default function PromotionResultsContext() {
  const { promotion, loading, handleUpdateStatus } = usePromotionContext();
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [processUpdate, setProcessUpdate] = useState([]);
  const [socketId, setSocketId] = useState();

  let fileIdDataTitulo;
  let fileName;
  if (promotion.produto && promotion.produto.codigo) {
    fileIdDataTitulo = `${promotion.produto.codigo} - ${promotion.extracao} - ${promotion.dataReferencia}`;
    fileName = fileIdDataTitulo.replace(/[/\\]/gi, '');
  } else {
    fileIdDataTitulo = `Arquivo-${promotion.extracao} - ${promotion.dataReferencia}`;
    fileName = fileIdDataTitulo.replace(/[/\\]/gi, '');
  }
  const canUpdate = useMemo(
    () => hasPermission('update_promotion', { toast: false }),
    []
  );

  const validateGenerateFile = isBefore(
    new Date(),
    parseISO(promotion.dataVigenciaFim)
  );

  const getWinnersPromotion = useCallback(async () => {
    setLoadingCsv(true);
    if (promotion.idPromocao) {
      const response = await api.get(
        `/promotions/winner/${promotion.idPromocao}?socketId=${socketId.id}`
      );
      if (response && response.data) {
        setLoadingCsv(false);
        return response.data;
      }
    }
    return [];
  }, [promotion, socketId]);

  useEffect(() => {
    const socket = io(baseURL);
    setSocketId(socket.connect());
    socket.on('file/generator', update => {
      setProcessUpdate(update);
    });

    return () => socket.close();
  }, []);

  function getDataSorteioPrincipal() {
    const data = promotion.dataSorteioPrincipal;
    return data && format(parseISO(data), 'dd/MM/yyyy HH:mm');
  }
  return (
    <Grid container spacing={4} alignItems="flex-start" direction="row">
      <Grid item xs={6} md={8}>
        {promotion.idPromocao && (
          <Typography
            variant="h5"
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <MdChevronLeft size={32} onClick={history.goBack} />
            {promotion.produto.titulo}:&nbsp;Sorteio do dia&nbsp;
            <span>{getDataSorteioPrincipal()}</span>
          </Typography>
        )}
      </Grid>
      <Grid align="right" item xs={6} md={4}>
        {canUpdate && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateStatus(2)}
            disabled={loading || [1, 2].includes(promotion.status)}
          >
            Iniciar Apuração
          </Button>
        )}
        {canUpdate && (
          <>
            <CsvDownload
              disabled={
                loading ||
                loadingCsv ||
                processUpdate.status === 'processing' ||
                validateGenerateFile
              }
              datas={getWinnersPromotion}
              filename={fileName}
              id="csvDowload"
            >
              <ColorButton
                variant="contained"
                color="primary"
                disabled={
                  loading ||
                  loadingCsv ||
                  processUpdate.status === 'processing' ||
                  validateGenerateFile
                }
              >
                {processUpdate.status === 'processing'
                  ? 'Processando...'
                  : 'Gerar Arquivo'}
              </ColorButton>
            </CsvDownload>
          </>
        )}

        {canUpdate && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleUpdateStatus(0)}
            disabled={loading || [1, 0].includes(promotion.status)}
            style={{ marginLeft: 10 }}
          >
            Encerrar
          </Button>
        )}
      </Grid>
      <PromotionContainer>
        {loading && (
          <div style={{ marginBottom: 20, textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}

        {promotion.idPromocao && <Promotion />}
      </PromotionContainer>
    </Grid>
  );
}
