/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { Button, CircularProgress } from '@material-ui/core';
import { MdDelete, MdFormatListBulleted, MdOutlineImage } from 'react-icons/md';
import api from '~/services/api';
import ItemTypes from '../itemTypes';
import {
  Container,
  Thumbnail,
  ContainerActions,
  ContainerInfos,
  Title,
} from './styles';

export default function CardEbook({
  ebook,
  draft,
  setRefetch,
  refetch,
  findCard,
  moveCard,
  onDrop,
  isDoubleCheck,
  updateDraft,
  setDoubleCheckEbooks,
}) {
  const [loading, setLoading] = useState(false);
  const [clickDelete, setClickDelete] = useState(0);
  const { _id } = ebook;
  const originalIndex = findCard(_id).index;

  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !loading,
    item: { type: ItemTypes.CARD, _id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { _id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop() {
      onDrop();
    },
    hover({ _id: draggedId }) {
      if (draggedId !== _id) {
        const { index: overIndex } = findCard(_id);
        moveCard(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;

  const handleDeleteEbookDraft = async () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      setTimeout(() => setClickDelete(0), 3000);
    } else {
      try {
        setLoading(true);
        await api.delete(
          `/promotions/drafts/${draft._id}/ebook/${ebook._id}?isDoubleCheck=${isDoubleCheck}`
        );
        if (!isDoubleCheck) {
          updateDraft({ ...draft, status: 'draft' });
        } else {
          setDoubleCheckEbooks(prevState =>
            prevState.filter(ebook => ebook._id !== _id)
          );
        }
      } finally {
        setLoading(false);
        setRefetch(!refetch);
      }
    }
  };

  return (
    <Container ref={node => drag(drop(node))} style={{ opacity }}>
      <ContainerInfos>
        <MdFormatListBulleted size={20} />
        <Thumbnail>
          {ebook.urlThumbnail ? (
            <img src={ebook.urlThumbnail} alt="thumbnail" width="40px" />
          ) : (
            <MdOutlineImage
              style={{ color: 'rgba(0, 0, 0, 0.26)' }}
              size={20}
            />
          )}
        </Thumbnail>
        <Title>
          <p style={{ fontWeight: '500' }}>{ebook.nome}</p> &nbsp;
          <p>({ebook.autor})</p>
        </Title>
      </ContainerInfos>
      <ContainerActions>
        {draft.status !== 'approved' && (
          <Button
            onClick={handleDeleteEbookDraft}
            disabled={loading}
            color="secondary"
            variant="contained"
          >
            {loading ? (
              <CircularProgress size={25} style={{ color: 'white' }} />
            ) : (
              <>
                <MdDelete size={25} />
                {clickDelete === 1 && 'Confirma ?'}
              </>
            )}
          </Button>
        )}
      </ContainerActions>
    </Container>
  );
}

CardEbook.defaultProps = {
  isDoubleCheck: false,
  setDoubleCheckEbooks: () => {},
  updateDraft: () => {},
};

CardEbook.propTypes = {
  ebook: PropTypes.oneOfType([PropTypes.object]).isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setRefetch: PropTypes.func.isRequired,
  refetch: PropTypes.bool.isRequired,
  findCard: PropTypes.func.isRequired,
  moveCard: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  isDoubleCheck: PropTypes.bool,
  setDoubleCheckEbooks: PropTypes.func,
  updateDraft: PropTypes.func,
};
