import styled from 'styled-components';

export const TextError = styled.span`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 14px;
  margin-right: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;
