import { TextMaskDate } from '~/components/masks';

export default [
  {
    name: 'renda',
    label: 'Renda',
    placeholder: 'Informe a renda',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'profissao',
    label: 'Profissão',
    placeholder: 'Informe a profissão',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'orgaoEmissor',
    label: 'Órgão Emissor',
    placeholder: 'Informe órgão emissor',
    cardLabelProps: {},
    textFieldProps: { inputProps: { maxLength: 6 } },
  },
  {
    name: 'tipoDocumento',
    label: 'Tipo Documento',
    placeholder: 'Informe o tipo documento: ',
    typeSelect: { options: ['rg', 'rgcpf', 'cnh', 'outro'] },
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'numeroDocumento',
    label: 'Número Documento',
    placeholder: 'Informe o número do documento',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'dataEmissaoDocumento',
    label: 'Data Emissão Documento',
    placeholder: 'Informe o número do documento',
    cardLabelProps: {},
    textFieldProps: {
      InputProps: { inputComponent: TextMaskDate },
    },
  },
];
