import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';

import { isNaN, toNumber } from 'lodash';
import api from '~/services/api';
import { hasPermission } from '../AccessControl';

const cgSusepSpec = applySpec({
  id: prop('id'),
  titulo: prop('processoSusep'),
});

const getCgsSusep = pipe(prop('docs'), map(cgSusepSpec));

export default function useCgsSusep(produto) {
  const [cgsSusep, setCgsSusep] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (hasPermission('read_cgs_susep', { toast: false })) {
        setLoading(true);
        api
          .get(`/cgs-susep`, {
            params: {
              status: 1,
              limit: !produto || isNaN(toNumber(produto)) ? undefined : 100,
              produto,
            },
          })
          .then(({ data }) => {
            if (data) setCgsSusep(getCgsSusep(data));
          })
          .finally(() => setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  }, [produto]);

  return { cgsSusep, loading };
}
