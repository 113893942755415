import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent } from '@material-ui/core';

// -- components
import SweepstakeDozens from './sweepstakeDozens';
import SweepstakeWinners from './sweepstakeWinners';
import { SweepstakePicture } from './styles';

export default function PromotionSweepstake({ data: sorteio, promotion }) {
  return (
    <Grid item xs={12} md={12} key={sorteio.idSorteio}>
      <Card style={{ maxWidth: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SweepstakePicture image={sorteio.imagem} />
          </Grid>
          <Grid item xs={8}>
            <CardContent>
              <SweepstakeDozens data={sorteio} promotion={promotion} />
              <SweepstakeWinners data={sorteio} />
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

PromotionSweepstake.propTypes = {
  data: PropTypes.shape({
    idSorteio: PropTypes.number,
  }).isRequired,
  promotion: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
