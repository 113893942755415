import React, { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import useProducts from '~/components/ProductList/useProducts';

export default function PermissionsSelect({ itemsChecks, setItems }) {
  const { products, loading } = useProducts();

  const setProductsToEditUser = () => {
    if (!isEmpty(itemsChecks) && !isEmpty(products)) {
      setItems(filter(products, ({ id }) => includes(itemsChecks, id)));
    }
  };

  useEffect(() => {
    setProductsToEditUser();
  }, [loading, products]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    setItems(uniqBy(value, 'id'));
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">Produtos</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={itemsChecks}
            onChange={handleChange}
            renderValue={selected => selected.map(x => x.titulo).join(', ')}
          >
            {products.map(variant => (
              <MenuItem key={variant.id} value={variant}>
                <Chip
                  label={variant.titulo}
                  avatar={<Avatar src={variant.imagem} />}
                  style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                />
                <Checkbox
                  checked={
                    itemsChecks.findIndex(item => item.id === variant.id) >= 0
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

PermissionsSelect.propTypes = {
  itemsChecks: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setItems: PropTypes.func.isRequired,
};
