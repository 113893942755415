/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  TableContainer,
  Paper,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import { format, parseISO } from 'date-fns';
import { MdCheckCircle, MdRefresh, MdError } from 'react-icons/md';
import { GiSandsOfTime } from 'react-icons/gi';
import io from 'socket.io-client';
import { IDatePicker, NoRecord } from '~/components';
import api, { baseURL } from '~/services/api';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import { Container, Header, FilterContainer, ButtonConsult } from './styles';
import DetailJob from './detail';
import ConsultPurchases from './Dialog';

function Contestation() {
  const [contestation, setContestations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [statusJob, setStatusJob] = useState(null);
  const [detail, setDetail] = useState(false);
  const [propDetail, setPropDetail] = useState([]);
  const [modaConsult, setModalConsult] = useState(false);
  const [processUpdate, setProcessUpdate] = useState([]);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  useEffect(() => {
    const socket = io(baseURL);

    socket.on('contestations/updated', update => {
      setProcessUpdate(update);
    });

    return () => socket.close();
  }, []);

  const loadingData = useCallback(async () => {
    setLoading(true);

    const params = {
      page: customPaginator.pagination.page,
      date: date ? format(date, 'yyyy-MM-dd') : undefined,
      limit: customPaginator.pagination.limit,
      status: statusJob,
    };

    const result = await api.get('/contestation', { params });

    if (result.data) {
      const { limit, page, pages, total } = result.data;
      setPagination({
        ...{ limit, page, pages, totals: total },
      });
      setContestations(result.data.docs);
      setLoading(false);
    }
  }, [
    date,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    statusJob,
    setPagination,
  ]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      loadingData();
    }, 300);
    return () => clearTimeout(timeId);
  }, [loadingData]);

  useEffect(() => {
    setPagination({ page: 1 });
  }, [date, statusJob, setPagination]);

  useEffect(() => {
    if (processUpdate.status === 'done') {
      loadingData();
    }
  }, [processUpdate, loadingData]);

  function handleSelectDate(dateForm) {
    setDate(dateForm);
  }

  function handleAutoComplete(value) {
    setStatusJob(value);
  }

  function getStatus(status, _id) {
    if (processUpdate.id === _id) {
      status = processUpdate.status;
    }
    if (status === 'created') {
      return {
        label: 'Pendente',
        icon: <MdRefresh fontSize="11px" />,
        className: 'statuses processing',
        title: 'O trabalho será executado em breve.',
      };
    }

    if (status === 'done') {
      return {
        label: 'Completo',
        icon: <MdCheckCircle fontSize="11px" />,
        className: 'statuses sent',
      };
    }

    if (status === 'processing') {
      return {
        label: 'Processando...',
        icon: <GiSandsOfTime fontSize="11px" />,
        className: 'statuses processing',
      };
    }

    return {
      label: 'Não realizado',
      icon: <MdError fontSize="11px" />,
      className: 'statuses not-sent',
      title: 'Erro ao executar o trabalho.',
    };
  }

  // -- handle to update pagination

  function handleDetails(data) {
    setDetail(true);
    setPropDetail(data);
  }

  function handleConsultPurchase() {
    setModalConsult(true);
  }

  return (
    <>
      <Container>
        <Header>
          <h1 style={{ fontWeight: 400, fontSize: 26 }}>Contestações</h1>
          <div>
            <ButtonConsult onClick={handleConsultPurchase} variant="contained">
              Consultar Compras
            </ButtonConsult>
            <Link to="/constestation/create">
              <Button color="primary" variant="contained">
                Inserir novo
              </Button>
            </Link>
          </div>
        </Header>

        <TableContainer component={Paper} color="primary">
          <FilterContainer>
            <IDatePicker
              label="Filtrar por data"
              onChange={debounce(e => handleSelectDate(e), 1000)}
            />

            <Autocomplete
              size="small"
              options={[
                { value: 'created', label: 'Pendente' },
                { value: 'done', label: 'Completo' },
                { value: 'error', label: 'Não realizado' },
              ]}
              getOptionLabel={opt => opt.label}
              onChange={(_, value) => handleAutoComplete(value)}
              renderInput={params => (
                <TextField {...params} label="Filtrar por status" />
              )}
            />
          </FilterContainer>

          <Table size="small">
            <TableHead style={{ backgroundColor: '#3f51b5' }}>
              <TableRow>
                <TableCell style={{ color: '#fff' }}>Data Referência</TableCell>
                <TableCell style={{ color: '#fff' }}>Quantidade TIDs</TableCell>
                <TableCell style={{ color: '#fff' }}>Status</TableCell>
                <TableCell style={{ color: '#fff' }}>Criado em</TableCell>
                <TableCell style={{ color: '#fff' }}>Criado por</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}

              {!loading && !contestation.length && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <NoRecord message="Nenhum registro encontrado." />
                  </TableCell>
                </TableRow>
              )}

              {!!contestation.length &&
                contestation.map(dados => (
                  <TableRow key={dados._id}>
                    <TableCell>
                      <b>{format(parseISO(dados.sendDate), 'dd/M/yyyy')}</b>
                    </TableCell>
                    <TableCell>
                      {processUpdate.status === 'processing' &&
                        processUpdate.id === dados._id &&
                        processUpdate.total}
                      {dados.status === 'done' && dados.tids.length}
                      &nbsp;/&nbsp;
                      {dados.tids.length}
                    </TableCell>
                    <TableCell>
                      <Chip
                        {...getStatus(dados.status, dados._id)}
                        onClick={() => handleDetails(dados)}
                      />
                    </TableCell>
                    <TableCell>
                      {format(
                        parseISO(dados.createdAt),
                        'dd/MM/yyyy - HH:mm:ss'
                      )}
                    </TableCell>
                    <TableCell>
                      {dados.createdBy ? dados.createdBy.name : 'Não informado'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {/* Detail of current selected push */}
        {detail && (
          <DetailJob prop={propDetail} onClose={() => setDetail(null)} />
        )}

        {modaConsult && (
          <ConsultPurchases onClose={() => setModalConsult(false)} />
        )}
      </Container>
    </>
  );
}

export default Contestation;
