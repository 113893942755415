import { useState } from 'react';

// -- services
import api from '~/services/api';

export default function useFeedbackForm({
  promocao,
  pessoa,
  provisorio,
  onSave,
}) {
  const [feedback, setFeedback] = useState({});
  const [loading, setLoading] = useState(false);

  function handleSubmitForm({ titulo, descricao }) {
    setLoading(true);

    // -- payload
    const data = {
      titulo,
      descricao,
      promocao,
      pessoa,
      provisorio: [1, '1', true].includes(provisorio),
    };

    api
      .post(`/winners-feedback`, data)
      .then(res => {
        if (res && res.data) {
          setFeedback(data);
        }

        // -- close modal
        if (onSave && typeof onSave === 'function') {
          onSave();
        }
      })
      .finally(() => setLoading(false));
  }

  return { feedback, loading, handleSubmitForm };
}
