import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import AwardsForm from '../Form';

export default function FormDialog({
  award,
  opened,
  handleCloseModal,
  product,
  type,
}) {
  const [open, setOpen] = React.useState(opened);

  const handleClose = _award => {
    setOpen(false);
    handleCloseModal(_award);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cadastro de Prêmios</DialogTitle>
        <DialogContent>
          <AwardsForm
            award={award}
            product={product}
            handleClose={handleClose}
            type={type}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(null)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

FormDialog.defaultProps = {
  opened: false,
  type: 0,
};

FormDialog.propTypes = {
  award: PropTypes.oneOfType([PropTypes.object]).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  type: PropTypes.number,
};
