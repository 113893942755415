/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton } from '@material-ui/core';
import { FaEdit, FaTrash, FaList } from 'react-icons/fa';
import { MdOutlineImage, MdContentCopy } from 'react-icons/md';
import { useDrop, useDrag } from 'react-dnd';
// import { usePromotionContext } from '../../context';
import { useConfirm } from 'material-ui-confirm';
import api from '~/services/api';
import { isBoolean } from 'lodash';
import { EbookItemContainer, Thumbnail } from './styles';
import NewEbook from '../../../Draft/Ebook/NewEbook/NewEbook';

export default function EbookItem({
  ebook,
  findCard,
  moveCard,
  onDrop,
  refetch,
  setRefetch,
}) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const confirm = useConfirm();
  const { ebook: ebookInfos } = ebook;
  const { id } = ebook;
  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !loading,
    item: { type: 'card', id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { _id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: 'card',
    drop() {
      onDrop();
    },
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await confirm({
        title: 'Tem certeza que deseja excluir o ebook?',
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        confirmationText: 'Sim, continuar!',
      });
      setLoading(true);
      await api.delete(
        `/promotions/${ebook.idPromocao}/ebook/${ebook.idEbook}`
      );
      setRefetch(!refetch);
    } catch (err) {
      // nothing...
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = isRefetch => {
    setIsOpen(false);
    if (isBoolean(isRefetch)) {
      setRefetch(!refetch);
    }
  };

  const opacity = isDragging ? 0 : 1;

  return (
    <EbookItemContainer ref={node => drag(drop(node))} style={{ opacity }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <header>
            <div className="order">
              <FaList size={15} />
              <p>{ebook.ordem}</p>
            </div>
            <div>
              <IconButton size="small" onClick={() => setIsOpen(true)}>
                <FaEdit size={15} />
              </IconButton>
              <IconButton size="small" onClick={handleDelete}>
                <FaTrash size={15} />
              </IconButton>
            </div>
          </header>
          <Thumbnail>
            {ebookInfos.urlThumbnail ? (
              <img
                src={ebookInfos.urlThumbnail}
                alt="thumbnail"
                width="160px"
              />
            ) : (
              <MdOutlineImage
                style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                size={80}
              />
            )}
          </Thumbnail>
          <footer>
            <h4>{ebookInfos.nome}</h4>
            <span>{ebookInfos.autor}</span>
            <div>
              <a href={ebookInfos.url} target="_blank" rel="noreferrer">
                {`${ebookInfos.url.substr(0, 34)}...`} &nbsp;
              </a>
              <IconButton
                onClick={() => navigator.clipboard.writeText(ebookInfos.url)}
              >
                <MdContentCopy size={15} color="#757DE8" />
              </IconButton>
            </div>
          </footer>
          {isOpen && (
            <NewEbook
              open={isOpen}
              onClose={handleCloseModal}
              ebook={ebookInfos}
              promotionId={ebook.idPromocao}
            />
          )}
        </>
      )}
    </EbookItemContainer>
  );
}

EbookItem.propTypes = {
  ebook: PropTypes.oneOfType([PropTypes.object]).isRequired,
  findCard: PropTypes.func.isRequired,
  moveCard: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  refetch: PropTypes.bool.isRequired,
  setRefetch: PropTypes.func.isRequired,
};
