import React, { useMemo, useState } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  CircularProgress,
  Chip,
  TextField,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import {
  MdCheckCircle,
  MdError,
  MdSchedule,
  MdHourglassEmpty,
  MdReport,
  MdNewReleases,
  MdControlPointDuplicate,
} from 'react-icons/md';
import { format, parseISO, isAfter, isValid, subMinutes } from 'date-fns';
import { get, debounce as debounceFn } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import history from '~/services/history';

import { UnauthorizedGhost, IDatePicker, NoRecord } from '~/components';
import AccessControl, { hasPermission } from '~/components/AccessControl';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import PushDetail from './detail';
import usePushs from './usePushs';
import { Container, FilterContainer } from './styles';

export default function PushNotificationsList() {
  const confirm = useConfirm();
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const {
    pushs,
    applications,
    loading,
    handleFilters,
    deletePushApplication,
  } = usePushs({
    setPagination,
    ...customPaginator,
  });
  const debounce = debounceFn((...args) => handleFilters(...args), 400);
  const [pushDetail, setPushDetail] = useState(null);

  const can = useMemo(
    () => ({
      create: hasPermission('create_push', { toast: false }),
    }),
    []
  );

  const currentPushApp = useMemo(() => {
    if (!pushDetail || !pushs.length) return {};

    const { _id: pushId } = pushDetail.push;
    const { id: appId } = pushDetail.app;

    const push = pushs.find(p => p._id === pushId);
    const app = push.applications.find(a => a.id === appId);

    return { push, app };
  }, [pushs, pushDetail]);

  function getStatuses({ waiting, done, failure, result = {}, sendDate }) {
    // for schedule app
    if (waiting === true) {
      return {
        label: 'Pré Agendado',
        icon: <MdHourglassEmpty fontSize="11px" />,
        className: 'statuses schedule',
        title: `Agendamento previsto para ${format(
          subMinutes(parseISO(sendDate), 5),
          'dd/MM/yyyy HH:mm'
        )}`,
      };
    }

    // for schedule app
    if (done === false && isAfter(parseISO(sendDate), Date.now())) {
      return {
        label: 'Agendado',
        icon: <MdSchedule fontSize="11px" />,
        className: 'statuses schedule',
        title: `Próximo envio em ${format(
          parseISO(sendDate),
          'dd/MM/yyyy HH:mm'
        )}`,
      };
    }

    // for failure job
    if (done === true && failure === true) {
      return {
        label: 'Não enviado',
        icon: <MdError fontSize="11px" />,
        className: 'statuses not-sent',
      };
    }

    // for warning content
    if (done === true && get(result, 'failure', 0) > 0) {
      return {
        label: 'Enviado parcialmente',
        icon: <MdReport fontSize="11px" />,
        className: 'statuses partial-sent',
      };
    }

    // for sent stats
    if (done === true && failure === false && get(result, 'failure', 0) === 0) {
      return {
        label: 'Enviado',
        icon: <MdCheckCircle fontSize="11px" />,
        className: 'statuses sent',
      };
    }

    return {
      label: 'Trabalho ainda não começou',
      icon: <MdNewReleases fontSize="11px" />,
      className: 'statuses new-releases',
      title: 'A notificação para esta aplicação ainda será processado.',
    };
  }

  function getApplicationName(appId) {
    if (applications && appId) {
      const app = applications.find(({ id }) => id === appId);
      return (app || {}).title;
    }
    return '';
  }

  async function handleApplicationDelete({ push, app }) {
    const messageConfirm =
      'Você deseja remover o envio deste push para esta aplicação?';

    try {
      await confirm({
        title: 'Confirmação',
        description: messageConfirm,
        dialogProps: { fullWidth: false, maxWidth: 'md' },
        cancellationText: 'Não, Cancelar!',
        cancellationButtonProps: { color: 'primary' },
        confirmationText: 'Sim, Excluir!',
        confirmationButtonProps: { color: 'secondary' },
      });
      deletePushApplication({
        pushId: push._id,
        appId: app.id,
      }); // close with delete promotion.
    } catch (err) {
      // close without any action.
    }
  }

  function handleClone(push) {
    window.localStorage.setItem('clonedPush', JSON.stringify(push));
    history.push('/push-notifications/create');
  }

  return (
    <AccessControl
      can="read_pushs"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <Container>
        <Header>
          <h1 style={{ fontWeight: 400, fontSize: 26 }}>Notificações Push</h1>
          {can.create && (
            <Link to="/push-notifications/create">
              <Button color="primary" variant="contained">
                Nova notificação
              </Button>
            </Link>
          )}
        </Header>

        <TableContainer component={Paper} color="primary">
          <FilterContainer>
            <IDatePicker
              label="Filtrar por data"
              onChange={date => {
                if (isValid(date)) {
                  debounce({
                    date: (date && format(date, 'yyyy-MM-dd')) || undefined,
                  });
                } else if (!date) {
                  debounce({ date: undefined });
                }
              }}
            />

            <Autocomplete
              multiple
              size="small"
              options={applications}
              getOptionLabel={opt => opt.title}
              onChange={(_, value) =>
                debounce({
                  applications: value.map(app => app.id).join(',') || undefined,
                })
              }
              renderInput={params => (
                <TextField {...params} label="Filtrar por aplicação" />
              )}
            />

            <Autocomplete
              size="small"
              options={[
                { value: 'waiting', label: 'Pré Agendado' },
                { value: 'schedule', label: 'Agendado' },
                { value: 'sent', label: 'Enviado' },
                { value: 'failure', label: 'Não Enviado' },
                { value: 'processing', label: 'Processando' },
              ]}
              getOptionLabel={opt => opt.label}
              onChange={(_, opt) =>
                debounce({
                  status: (opt && opt.value) || undefined,
                })
              }
              renderInput={params => (
                <TextField {...params} label="Filtrar por status" />
              )}
            />
          </FilterContainer>

          <Table size="small">
            <TableHead style={{ backgroundColor: '#3f51b5' }}>
              <TableRow>
                <TableCell style={{ color: '#fff' }}>Titulo</TableCell>
                <TableCell style={{ color: '#fff' }}>Conteúdo</TableCell>
                <TableCell style={{ color: '#fff' }}>
                  Aplicações / Praça
                </TableCell>
                <TableCell style={{ color: '#fff' }} align="right" width={200}>
                  Criado em <br /> Enviará em
                </TableCell>
                <TableCell style={{ color: '#fff' }}>Ação</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}

              {!loading && !pushs.length && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <NoRecord message="Nenhum registro encontrado." />
                  </TableCell>
                </TableRow>
              )}

              {!!pushs.length &&
                pushs.map(push => (
                  <TableRow key={push._id}>
                    <TableCell width="20%">{push.data.title}</TableCell>
                    <TableCell width="25%">{push.data.body}</TableCell>

                    <TableCell>
                      {!!push.applications.length &&
                        push.applications.map(app => (
                          <Chip
                            {...getStatuses({
                              ...app.stats,
                              sendDate: app.sendDate,
                            })}
                            key={app.id}
                            style={{ marginRight: 5, marginBottom: 5 }}
                            label={getApplicationName(app.id)}
                            size="small"
                            {...(isAfter(
                              subMinutes(parseISO(app.sendDate), 5),
                              Date.now()
                            ) || app.stats.waiting
                              ? {
                                  onDelete: async () =>
                                    handleApplicationDelete({
                                      push,
                                      app,
                                    }),
                                }
                              : {})}
                            onClick={() => {
                              setPushDetail({
                                push,
                                app: {
                                  ...app,
                                  name: getApplicationName(app.id),
                                },
                              });
                            }}
                            clickable
                          />
                        ))}
                    </TableCell>

                    <TableCell align="right">
                      {format(parseISO(push.createdAt), 'dd/MM/yyyy HH:mm')}
                      <br />
                      <strong>
                        {format(parseISO(push.sendDate), 'dd/MM/yyyy HH:mm')}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label="clone"
                        icon={<MdControlPointDuplicate size={20} />}
                        onClick={() => {
                          handleClone(push);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {/* Detail of current selected push */}
        {!!pushDetail && (
          <PushDetail
            {...pushDetail}
            {...currentPushApp}
            onClose={() => setPushDetail(null)}
          />
        )}
      </Container>
    </AccessControl>
  );
}
