import { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper';

import { get } from 'lodash';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';

export default function useDrafts({
  defaultFilters = {},
  customPaginator,
  setPagination,
}) {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    const timeId = setTimeout(async () => {
      setPromotions([]);

      // -- create query params
      const params = {
        ...filters,
      };

      if (hasPermission('read_promotion_draft_banners') && params.produto) {
        setLoading(true);
        const res = await api.get(
          `/promotions/drafts?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`,
          { params }
        );

        if (res && res.data) {
          setPromotions(res.data.docs);

          const { limit, page, pages, total } = res.data;
          setPagination({
            ...{ limit, page, pages, totals: total },
          });
        }

        setLoading(false);
      }
    }, 200);

    return () => clearTimeout(timeId);
  }, [
    filters,
    customPaginator.pagination.limit,
    customPaginator.pagination.page,
    setPagination,
  ]);

  // -- handle to update filters
  const handleFilters = useCallback(
    (field, value) => {
      setFilters({ ...filters, [field]: value });
    },
    [filters]
  );

  // -- reset filters
  function resetFilters() {
    setFilters({});
  }

  // -- update filters value
  const updateFilters = useCallback(value => {
    setFilters(value);
  }, []);

  const onDraftDelete = useCallback(
    async draft => {
      const { _id: draftId } = draft;
      setDeleting(draftId);
      try {
        const { data: newDraft } = await api.delete(
          `/promotions/drafts/${draftId}`
        );

        const status = get(draft, 'status');
        const isPermanentlyDelete = !['approved', 'deleted'].includes(status);
        const draftIdx = promotions.findIndex(({ _id }) => _id === draftId);
        const hasInArr = draftIdx !== -1;

        if (isPermanentlyDelete && hasInArr) {
          const newPromotions = update(promotions, {
            $splice: [[draftIdx, 1]],
          });
          setPromotions(newPromotions);
        } else if (!isPermanentlyDelete && hasInArr) {
          const newPromotions = update(promotions, {
            $splice: [[draftIdx, 1, newDraft]],
          });
          setPromotions(newPromotions);
        }
      } finally {
        setDeleting(null);
      }
    },
    [promotions, setPromotions, setDeleting]
  );

  const onDraftClone = useCallback(
    async draft => {
      setLoading(true);
      const result = await api
        .get(`promotions/draft/clone/${draft._id}`)
        .finally(() => setLoading(false));
      const newsPromotions = update(promotions, {
        $push: [result.data],
      });
      setPromotions(newsPromotions);
    },
    [promotions, setPromotions]
  );

  return {
    promotions,
    loading,
    filters,
    handleFilters,
    resetFilters,
    updateFilters,
    onDraftDelete,
    onDraftClone,
    deleting,
    setPagination,
    customPaginator,
  };
}
