import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import useReasons from './useReasons';

export default function ReasonAutocomplete({
  onChange,
  getOption,
  handleValue,
  valueKey,
  label,
  value: defaultValue,
}) {
  const { reasons, loading } = useReasons();
  const [value, setValue] = useState(null);
  const rendered = useRef(false);

  useEffect(() => {
    // call on change
    if (
      !!value &&
      onChange &&
      typeof onChange === 'function' &&
      rendered.current
    ) {
      onChange({ target: { value: value && value[valueKey] } });
    }

    // empty value
    if (!value && rendered.current) {
      onChange({ target: { value: null } });
    }

    // -- set rendered
    if (!rendered.current) rendered.current = true;
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (defaultValue) {
      const stringValue = String(defaultValue);
      const selected =
        reasons.find(p => String(p[valueKey]) === stringValue) || null;
      if (selected !== undefined) setValue(selected);
    }
  }, [reasons, defaultValue, valueKey]);

  return (
    <Autocomplete
      freeSolo
      id="reasonSelect"
      options={reasons}
      loading={loading}
      getOptionLabel={getOption}
      value={value}
      className="ReasonAutoComplete"
      onChange={(_, selectValue) => setValue(selectValue)}
      renderInput={params => (
        <TextField
          variant="outlined"
          onChangeCapture={e => handleValue(e.target.value)}
          {...params}
          label={label}
        />
      )}
    />
  );
}

ReasonAutocomplete.defaultProps = {
  onChange: () => {},
  getOption: opt => opt.description,
  handleValue: () => {},
  valueKey: 'description',
  value: undefined,
  label: 'Justificativa...',
};

ReasonAutocomplete.propTypes = {
  onChange: PropTypes.func,
  getOption: PropTypes.func,
  handleValue: PropTypes.func,
  valueKey: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
};
