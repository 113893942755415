import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { TextField } from '@unform/material-ui';
import { Form } from '@unform/web';
import toFormdata from 'object-to-formdata';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Upload from '~/components/unform/v2/UploadField';
import api from '~/services/api';
import { Row } from './styles';

function DialogPix({ items, onClose, onSuccess }) {
  const { idPessoa, idGateway, valor, situacao, dataInsert } = items;
  const [submitStatus, setSubmitStatus] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async (data, { reset }) => {
    try {
      setSubmitStatus(true);
      const schema = Yup.object().shape({
        file: Yup.mixed().test(
          'is-file',
          'Você deve anexar um arquivo',
          file => {
            return file instanceof File;
          }
        ),
        message: Yup.string().required('Campo obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { file, message } = data;
      const dados = toFormdata({
        file,
        message,
        idGateway,
        valor,
        situacao,
        dataInsert,
      });
      const result = await api.post(`/persons/${idPessoa}/pix/`, dados);
      if (result) setSubmitStatus(false);
      reset();
      onClose();
      onSuccess();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
        setSubmitStatus(false);
      }
    }
  };

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>Confirmar Pagamento</DialogTitle>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <DialogContent>
            <Row>
              <Upload name="file" />
            </Row>
            <Row>
              <TextField name="message" placeholder="Justifique..." multiline />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button type="submit" disabled={submitStatus} color="primary">
              Confirma
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

DialogPix.propTypes = {
  items: PropTypes.shape(),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

DialogPix.defaultProps = {
  items: null,
  onClose: false,
  onSuccess: false,
};

export default DialogPix;
