import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
`;

export const SubTitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: center; */
  color: rgba(0, 0, 0, 0.87);
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 19.92px;
  color: rgba(0, 0, 0, 0.6);
`;

export const HintPassword = styled.li`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${({ valid }) =>
    valid ? 'rgba(46, 125, 50, 1)' : 'rgba(0, 0, 0, 0.6)'};
`;
