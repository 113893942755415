import React from 'react';
import { Link } from '@material-ui/core';

import { Container } from './styles';

export default function Copyright() {
  const year = new Date().getFullYear();

  return (
    <Container>
      Copyright &copy;{' '}
      <Link href="http://ideamaker.com.br/">ideamaker.com.br</Link> {year}.
      Development By{' '}
      <Link href="http://ifabrica.com.br/">ifabrica.com.br</Link>
    </Container>
  );
}
