import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import { TextareaAutosize } from '@material-ui/core';

export default function UnformTextareaAutosize({ name, ...rest }) {
  const ref = useRef();
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };

  return <TextareaAutosize {...props} />;
}

UnformTextareaAutosize.propTypes = {
  name: PropTypes.string.isRequired,
};
