import React from 'react';
import { BsChevronDown } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Button } from './styles';

export default function ExpandIcon({ func, state }) {
  return (
    <Button onClick={func} type="button" state={state}>
      <BsChevronDown size={20} />
    </Button>
  );
}

ExpandIcon.propTypes = {
  func: PropTypes.func.isRequired,
  state: PropTypes.bool.isRequired,
};
