export function getMessage(code) {
  const message = () =>
    ({
      MissingRegistration: 'Token de registro não encontrado',
      InvalidRegistration: 'Token de registro inválido',
      NotRegistered: 'Dispositivo não registrado',
      InvalidPackageName: 'Nome do pacote inválido',
      MismatchSenderId: 'Remetente incompatível',
      MessageTooBig: 'Mensagem grande demais',
      InvalidDataKey: 'Chave de dados inválida',
      InvalidTtl: 'Vida útil inválida',
      InternalServerError: 'Erro interno do servidor',
      DeviceMessageRateExceeded: 'Taxa de mensagens do dispositivo excedida',
      TopicsMessageRateExceeded: 'Taxa de mensagens de tópico excedida',
      InvalidApnsCredential: 'Credenciais de APNs inválidas',
    }[code]);

  return message();
}
