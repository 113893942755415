import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import { hasPermission } from '../AccessControl';

const awardSpec = applySpec({
  id: prop('id'),
  titulo: prop('descricao'),
  url: prop('imagem'),
  valor: prop('valor'),
  key: {
    descricao: prop('descricao'),
    imagemSorteio: prop('imagem'),
    imagemPremio: prop('imagemPremio'),
    valorSugerido: prop('valor'),
    numeroPlano: prop('numeroPlano'),
    numeroSerie: prop('numeroSerie'),
  },
});

const getAwards = pipe(prop('docs'), map(awardSpec));

export default function useAwards({ product: productId } = {}) {
  const [awards, setAwards] = useState([]);
  const [loading, setLoading] = useState(false);
  const product = useSelector(({ user }) => user.product);
  useEffect(() => {
    if (hasPermission('read_sweepstakes_awards', { toast: false })) {
      setLoading(true);
      try {
        api
          .get(
            `/sweepstake/awards?product=${productId ||
              product.id}&page=1&limit=200`
          )
          .then(({ data }) => {
            if (data) setAwards(getAwards(data));
          })
          .finally(() => setLoading(false));
      } catch (error) {
        setLoading(false);
      }
    }
  }, [product.id, productId]);

  return { awards, loading };
}
