/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Grid, CircularProgress, Button, TextField } from '@material-ui/core';
import io from 'socket.io-client';
import { debounce as debounceFn } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogConfirm from './dialogConfirm';
import { Content } from '~/styles/global';
import { Table } from './styles';
import api, { baseURL } from '~/services/api';
import history from '~/services/history';
import { hasPermission } from '~/components/AccessControl';
import { AccessControl, ProductAutocomplete } from '~/components';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import { FilterContainer } from '../../PushNotification/List/styles';
import Item from './item';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import DialogCgSusep from '../Dialog';

export default function CgSusepList() {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState({ name: '' });
  const [status, setStatus] = useState([]);
  const [progress, setProgress] = useState(0);
  const [totals, setTotals] = useState({ total: 0, done: 0 });
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const [cgsSusep, setCgsSusep] = useState([]);
  const [start, setStart] = useState(false);
  const [filters, setFilters] = useState({});
  const debounce = debounceFn((...args) => setFilters(...args), 400);
  const inputRef = useRef(null);
  const [socketId, setSocketId] = useState();

  const handleClose = () => {
    inputRef.current.value = '';
    setFile({});
    setStart(false);
    setTotals({ total: 0, done: 0 });
    setProgress(0);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (totals.total > 0) setProgress((totals.done / totals.total) * 100);
  }, [totals]);

  const getCgsSusep = useCallback(async () => {
    try {
      setLoading(true);
      if (hasPermission('read_cgs_susep')) {
        const { data } =
          (await api.get('/cgs-susep', {
            params: {
              page: customPaginator.pagination.page,
              limit: customPaginator.pagination.limit,
              ...filters,
            },
          })) || {};
        if (data) {
          setCgsSusep(data.docs);
          const { limit, page, pages, total } = data;
          setPagination({
            ...{ limit, page, pages, totals: total },
          });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [
    filters,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
  ]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      getCgsSusep();
    }, 300);
    return () => {
      clearTimeout(timeId);
    };
  }, [getCgsSusep]);

  useEffect(() => {
    const socket = io(baseURL);
    setSocketId(socket.connect());
    socket.on('cgsusep/updated', update => {
      setStatus(oldStatus => {
        if (update.error) {
          return [...oldStatus.map(st => ({ ...st, warning: true })), update];
        }
        return [...oldStatus, update];
      });
      if (update.item) {
        setTotals(prevTotals => ({ ...prevTotals, done: prevTotals.done + 1 }));
      }
      if (update.total) {
        setTotals(prevTotals => ({ ...prevTotals, total: update.total }));
      }
    });
    return () => socket.close();
  }, []);

  const handleNewClick = () => {
    history.push(`/cg-susep`);
  };

  const renderCgsSusep = useCallback(() => {
    return cgsSusep.map(cg => (
      <Item key={cg.id} cgsSusep={cgsSusep} setCgsSusep={setCgsSusep} cg={cg} />
    ));
  }, [cgsSusep]);

  const handleUploadFile = e => {
    setStatus([]);
    setFile(e.target.files[0]);
  };

  const handleProcessCgsusep = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (hasPermission('upload_cg_susep') && socketId) {
        await api.post(`/cgs-susep/upload?socketId=${socketId.id}`, formData);
      }
    } finally {
      handleCloseDialog();
    }
  }, [file, socketId]);

  useEffect(() => {
    if (file && start) {
      handleProcessCgsusep();
    }
  }, [file, handleProcessCgsusep, start]);
  return (
    <>
      <DialogCgSusep
        progress={progress}
        status={status}
        onOpen={start}
        onClose={handleClose}
      />
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6} md={9}>
          <Header>
            <h1 style={{ fontWeight: 400, fontSize: 24, marginBottom: 20 }}>
              CGs Susep
            </h1>
          </Header>
        </Grid>

        <Grid item xs={6} md={3} align="right">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', marginRight: '.5rem' }}>
              <DialogConfirm
                handleProcessCgsusep={handleProcessCgsusep}
                open={openDialog}
                inputRef={inputRef}
                setFile={setFile}
                file={file.name}
                setStart={setStart}
                onClose={() => handleCloseDialog()}
              />
              <AccessControl can="upload_cg_susep">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                >
                  UPLOAD
                </Button>
              </AccessControl>
              <input
                name="file"
                onChange={e => {
                  handleUploadFile(e);
                }}
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
              />
            </div>

            <AccessControl can="create_cg_susep">
              <Button
                type="submit"
                onClick={handleNewClick}
                variant="contained"
                color="primary"
              >
                Novo
              </Button>
            </AccessControl>
          </div>
        </Grid>

        <Grid item xs={12}>
          <FilterContainer>
            <ProductAutocomplete
              margin="none"
              onChange={e => debounce({ produto: e.target.value })}
            />

            <Autocomplete
              margin="none"
              options={[
                { value: 0, label: 'Inativa' },
                { value: 1, label: 'Ativa' },
              ]}
              getOptionLabel={opt => opt.label}
              onChange={(_, opt) =>
                debounce({
                  status:
                    opt && (opt.value === 1 || opt.value === 0)
                      ? opt.value
                      : undefined,
                })
              }
              renderInput={params => (
                <TextField {...params} label="Filtrar Status" />
              )}
            />
            <TextField
              margin="none"
              label="Processo Susep"
              type="text"
              onChange={event => {
                return debounce({
                  processoSusep: event.target.value || undefined,
                });
              }}
            />
          </FilterContainer>
        </Grid>

        <Grid item xs={12}>
          <Content>
            <Table>
              <thead>
                <tr>
                  <th>Processo Susep</th>
                  <th>Nome do Plano</th>
                  <th>Nome Comercial</th>
                  <th>Produto</th>
                  <th>Chance</th>
                  <th>Data de cadastro</th>
                  <th>Log</th>
                  <th>Ativo</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>{renderCgsSusep()}</tbody>
            </Table>
            <CustomPaginator
              {...{ loading, setPagination, ...customPaginator }}
            />
          </Content>
          {loading && <CircularProgress />}
        </Grid>
      </Grid>
    </>
  );
}
