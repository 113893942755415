import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { format as formatDate, parseISO, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MdMenu, MdDescription } from 'react-icons/md';
import NoRecord from '~/components/NoRecord';
import DetailInfo from '../Dialog';
import { decimalFormat } from '~/helpers/number';
import ChargebackDialog from './chargebackDialog';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import api from '~/services/api';

import { Table, FirstLetter, OverFlowWidth } from './styles';

import Copy from './CopyPix';

function List({ person }) {
  const [loading, setLoading] = useState(false);
  const [pixTransactions, setPixTransactions] = useState([]);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [detail, setDetail] = useState(false);
  const [openChargeback, setOpenChargeback] = useState(false);

  const { setPagination, ...customPaginator } = useCustomPaginator();

  const fetchData = useCallback(async () => {
    if (!person) return;
    setLoading(true);
    const { idCliente } = person;
    const params = {
      page: customPaginator.pagination.page,
      limit: customPaginator.pagination.limit,
    };
    const result = await api.get(`/persons/${idCliente}/pix/`, {
      params,
    });
    setLoading(false);
    if (result.data) {
      setPixTransactions(result.data.rows);
      const { count } = result.data;
      setPagination({
        pages: Math.ceil(count / customPaginator.pagination.limit),
        totals: count,
      });
    }
  }, [
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getColorStatus = status => {
    const style = { textTransform: 'capitalize' };
    switch (status) {
      case 'pago':
        style.color = 'blue';
        break;
      case 'pendente':
        style.color = 'grey';
        break;
      case 'vencido':
      case 'cancelado':
      case 'estornado':
        style.color = 'red';
        break;

      default:
        break;
    }
    return style;
  };
  const renderStatus = status => {
    if (status) {
      return <Typography style={getColorStatus(status)}>{status}</Typography>;
    }

    return <Typography align="center"> - </Typography>;
  };

  function handleCloseMenu() {
    setAnchorElMenu(null);
  }

  function loadchargeback() {
    setOpenChargeback(true);
  }

  const loadInfo = useCallback(async () => {
    setDetail(true);
    handleCloseMenu();
  }, []);

  const handleClickMenu = (event, item) => {
    setAnchorElMenu(event.currentTarget);
    setSelectedMenu(item);
  };

  const renderOptionsMenu = () => {
    if (!selectedMenu && !anchorElMenu) return '';
    return (
      <Menu
        d="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        {selectedMenu && selectedMenu.situacao === 'pendente' && (
          <MenuItem onClick={loadInfo}>
            <MdDescription size={25} />
            &nbsp;Confirmar pagamento
          </MenuItem>
        )}
        {selectedMenu &&
          selectedMenu.tipo === 'saldo' &&
          selectedMenu.situacao === 'pago' && (
            <MenuItem onClick={loadchargeback}>
              <MdDescription size={25} />
              &nbsp;Estornar Pix
            </MenuItem>
          )}
      </Menu>
    );
  };

  if (loading) {
    return <CircularProgress align="center" size={24} />;
  }
  if (!loading && pixTransactions.length > 0) {
    const getDataNotificacao = card => {
      const date = card.dataNotificacao;
      if (!!date && isValid(parseISO(date)))
        return formatDate(parseISO(date), 'dd/MM/yyyy HH:mm', {
          locale: ptBR,
        });
      return '-';
    };

    return (
      <>
        {openChargeback && (
          <ChargebackDialog
            item={selectedMenu}
            onClose={() => setOpenChargeback(false)}
          />
        )}
        {renderOptionsMenu()}
        <Table size="small">
          <thead>
            <tr>
              <th>Data Transação</th>
              <th>Data Notificação</th>
              <th>Situação</th>
              <th>QR Code</th>
              <th style={{ width: '10%' }}>Tipo</th>
              <th style={{ width: '10%', textAlign: 'right' }}>Valor</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {pixTransactions.map(item => (
              <tr key={item.id}>
                <td>
                  {formatDate(parseISO(item.dataInsert), 'dd/MM/yyyy HH:mm', {
                    locale: ptBR,
                  })}
                </td>
                <td>{getDataNotificacao(item)}</td>
                <td align="center">{renderStatus(item.situacao)}</td>
                <td>
                  <div align="center" style={{ display: 'flex' }}>
                    {item.qrCode ? (
                      <OverFlowWidth>{item.qrCode}</OverFlowWidth>
                    ) : (
                      '-'
                    )}
                    {item.qrCode ? <Copy code={item.qrCode} /> : ''}
                  </div>
                </td>
                <td style={{ width: '10%' }}>
                  {item.tipo ? <FirstLetter>{item.tipo}</FirstLetter> : '-'}
                </td>
                <td
                  style={{
                    width: '10%',
                    textAlign: 'right',
                  }}
                >
                  {item.situacao === 'pago'
                    ? decimalFormat(item.valorPago)
                    : decimalFormat(item.valor)}
                </td>
                <td style={{ alignItems: 'right' }}>
                  {(item.tipo === 'saldo' && item.situacao === 'pago') ||
                  item.situacao === 'pendente' ? (
                    <MdMenu
                      size={30}
                      onClick={event => handleClickMenu(event, item)}
                    />
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {detail && (
          <DetailInfo
            items={selectedMenu}
            onSuccess={() => fetchData()}
            onClose={() => setDetail(false)}
          />
        )}
      </>
    );
  }
  return <NoRecord />;
}

List.propTypes = {
  person: PropTypes.shape(),
};

List.defaultProps = {
  person: null,
};

export default List;
