/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Form } from '@rocketseat/unform';
import { Grid, Button, CircularProgress, Icon } from '@material-ui/core';

import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { AccessControl } from '~/components';
// import api from '~/services/api';
import { Content } from '~/styles/global';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import TextMaskCurrency from '~/components/masks/TextMaskCurrency';
import AwardPhoto from './photo';
import { Label } from './styles';

export default function AwardForm({ award, handleClose, product, type }) {
  const [loading, setLoading] = useState(false);
  const [imagem, setImagem] = useState(award && award.imagem);
  const [imagemRaffle, setImagemRaffle] = useState(award && award.imagemPremio);

  let produto = false;
  let produtoNumeroPlano = false;

  if (product) {
    produto = product.cod === 'instantaneo';
    produtoNumeroPlano =
      product.cod === 'instantaneo' ||
      product.cod === 'sabadaodasorte' ||
      product.cod === 'diadasorte';
  }

  // Yup form schema validation
  const schema = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório'),
    valor: Yup.string().required('Campo Obrigatório'),
    numeroPlano: Yup.string()
      .nullable()
      .optional(),
    numeroSerie: Yup.string()
      .nullable()
      .optional(),
    type: Yup.number()
      .nullable()
      .optional(),
  });

  // Send form to api
  const handleSubmit = async dataUpdate => {
    const formdata = new FormData();
    formdata.append('imagem', imagem);
    if (produto) {
      formdata.append('imagemSorteio', imagemRaffle);
    }
    formdata.append('produto', award.produto.id);
    let updated = false;
    try {
      setLoading(true);
      const verb = award.id ? 'put' : 'post';
      const { descricao, valor, numeroPlano, numeroSerie } = dataUpdate;

      formdata.append('descricao', descricao);
      if (numeroPlano) {
        formdata.append('numeroPlano', numeroPlano);
      }
      if (numeroSerie) {
        formdata.append('numeroSerie', numeroSerie);
      }

      formdata.append(
        'valor',
        parseFloat(valor.replace('R$ ', '').replace(',', '.'))
      );

      formdata.append('tipoSorteio', type);

      if (
        (hasPermission('create_sweepstakes_award') && verb === 'post') ||
        (hasPermission('update_sweepstakes_award') && verb === 'put')
      ) {
        const { data } =
          (await api[verb](`/sweepstake/awards/${award.id || ''}`, formdata)) ||
          {};
        updated = data;
      }
    } finally {
      setLoading(false);
      if (updated) {
        updated.imagem += `?v=${new Date().getTime()}`;
        award = updated;
        handleClose(award);
        // history.push(`/awards`);
      }
    }
    return true;
  };

  // Main Render
  return (
    <Content>
      <Form
        schema={schema}
        autoComplete="off"
        autoCapitalize="off"
        noValidate
        onSubmit={handleSubmit}
        initialData={award}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Label>Foto Premiado:</Label>
            <AwardPhoto data={award.imagem} onUpdated={img => setImagem(img)} />
          </Grid>
          {produto && (
            <Grid item xs={12} md={12}>
              <Label>Foto Balão:</Label>
              <AwardPhoto
                data={award.imagemPremio}
                onUpdated={img => setImagemRaffle(img)}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <TextField
              name="descricao"
              id="descricao"
              label="Descrição"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              InputProps={{
                inputComponent: TextMaskCurrency,
              }}
              name="valor"
              id="valor"
              label="Valor"
              margin="normal"
              variant="outlined"
              type="text"
              fullWidth
            />
          </Grid>
          {produtoNumeroPlano && (
            <>
              <Grid item xs={12} md={12}>
                <TextField
                  name="numeroSerie"
                  id="numeroSerie"
                  label="Número Serie"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  name="numeroPlano"
                  id="numeroPlano"
                  label="Número Plano"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>

        {/* Botoes */}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={12} align="right">
            <AccessControl
              can={['update_sweepstakes_award', 'create_sweepstakes_award']}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Salvar
                  </>
                )}
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </Form>
    </Content>
  );
}

AwardForm.defaultProps = {
  type: 0,
};

AwardForm.propTypes = {
  award: PropTypes.oneOfType([PropTypes.object]).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.number,
};
