import React, { useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { CircularProgress, Grid, Button } from '@material-ui/core';

// -- services
import { useSelector, useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import history from '~/services/history';
import qs from '~/services/qs';
import { setParams } from '~/store/modules/user/actions';

// -- components
import { NoRecord, AccessControl, UnauthorizedGhost } from '~/components';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import PromotionsFilter from './filter';
import Promotion from './promotion';
// -- hooks
import useDrafts from './useDrafts';

export default function PromotionsList() {
  const { context } = useParams();
  const { search } = useLocation();
  const filters = useMemo(() => qs.parse(search), [search]);

  const product = useSelector(({ user }) => user.product);
  const params = useSelector(({ user }) => user.params);
  const dispatch = useDispatch();
  const { setPagination, ...customPaginator } = useCustomPaginator();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setParams({ ...params, useFilterByProduct: true }));
    }, 200);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPagination({ limit: 15 });

    return () => dispatch(setParams({ ...params, useFilterByProduct: false }));
    // eslint-disable-next-line
  }, []);

  const {
    promotions,
    loading,
    updateFilters,
    onDraftDelete,
    onDraftClone,
    deleting,
  } = useDrafts({
    context,
    defaultFilters: filters,
    setPagination,
    customPaginator,
  });

  // -- filter from query string
  useEffect(() => {
    updateFilters({ ...filters, produto: product.id });
  }, [filters, product]); // eslint-disable-line

  // -- navigate to promotion item
  function navigateToPromotion(id) {
    history.push(`/promotion/draft/${id}`);
  }

  function newPromotion() {
    history.push(`/promotion/draft/`);
  }

  // -- get filters type
  function getFilters() {
    return ['produto', 'dataReferencia', 'status'];
  }

  // modify query string
  function handleQueryString(field, value) {
    history.push({
      pathname: `/promotion/drafts`,
      search: qs.build({ ...filters, [field]: value }),
    });
  }

  return product && product.id ? (
    <AccessControl
      can="read_promotion_drafts"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} md={11}>
            <Header>
              <h1 style={{ fontWeight: 400, fontSize: 26 }}>
                Rascunhos de promoções
              </h1>
            </Header>
          </Grid>
          <Grid item xs={6} md={1} align="right">
            <AccessControl can="create_promotion_draft">
              <Button
                type="submit"
                onClick={() => newPromotion()}
                variant="contained"
                color="primary"
              >
                <MdAdd size={20} />
                Nova
              </Button>
            </AccessControl>
          </Grid>
        </Grid>

        <PromotionsFilter
          handleFilters={() => handleQueryString()}
          filters={getFilters()}
        />

        {/* loading */}
        {loading && (
          <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}

        {/* no record */}
        {!loading && !promotions.length && <NoRecord />}

        {/* promotions */}
        {!loading && !!promotions.length && (
          <Grid container spacing={4} alignItems="flex-start">
            {promotions.map(({ _id, ...promotion }) => (
              <Promotion
                key={String(_id)}
                data={{ ...promotion, _id }}
                product={product}
                onClick={() => navigateToPromotion(_id)}
                onDelete={onDraftDelete}
                onClone={onDraftClone}
                isDeleting={deleting}
              />
            ))}
          </Grid>
        )}
        <CustomPaginator {...{ setPagination, ...customPaginator }} />
      </>
    </AccessControl>
  ) : (
    <NoRecord message="Favor Selecionar o produto" />
  );
}
