import React, { useMemo } from 'react';
import { useModalGallery } from 'react-router-modal-gallery';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { MdEdit, MdSave } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';

// -- services
import qs from '~/services/qs';

// -- hooks
import useFeedbackForm from './useFeedbackForm';

// -- components
import { hasPermission } from '~/components/AccessControl';
import { Container, Title, Content, Footer } from './styles';

export default function Provisional() {
  const { isModal, redirectToBack } = useModalGallery();
  const { search } = useLocation();
  const params = useMemo(() => qs.parse(search), [search]);
  const { loading, handleSubmitForm } = useFeedbackForm({
    ...params,
    onSave: redirectToBack,
  });

  const canCreate = useMemo(
    () => hasPermission('create_winner_feedback', { toast: false }),
    []
  );

  return (
    <Container open={isModal} onClose={redirectToBack}>
      <Form onSubmit={(...args) => canCreate && handleSubmitForm(...args)}>
        <Title>
          <MdEdit /> Informações Pessoais
        </Title>

        <Content>
          <TextField name="titulo" label="Titulo" />
          <TextField name="descricao" label="Descrição" multiline />
        </Content>

        <Footer>
          <Button
            onClick={redirectToBack}
            variant="text"
            color="secondary"
            disabled={loading}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={loading || !canCreate}
            type="submit"
          >
            {loading ? (
              <span>Salvando...</span>
            ) : (
              <>
                <MdSave /> Salvar
              </>
            )}
          </Button>
        </Footer>
      </Form>
    </Container>
  );
}
