/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Grid,
  Button,
  CircularProgress,
  Icon,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { MdDelete } from 'react-icons/md';
import ReactPlayer from 'react-player';
import Img from 'react-image';
import Skeleton from '@material-ui/lab/Skeleton';
import { cloneDeep, set } from 'lodash';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import { identifyLinkType, isYouTubeURL } from '~/helpers/url';
import { CardPlayerImageBanner } from './styles';
import ItemTypes from './ItemTypes';

import DialogConfirmUrl from '../../../BannersHome/Form/ConfirmUrlDialog';

const style = {
  border: '1px dashed gray',
  padding: '1rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};
const Card = ({
  id,
  url,
  urlVideoDraft,
  updateDraft,
  moveCard,
  findCard,
  onDrop,
  loading,
  draft,
  onDelete,
  appRoutesValue,
  onOpenAppRoutes,
}) => {
  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !loading,
    item: { type: ItemTypes.CARD, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => !loading,
    drop() {
      onDrop();
    },
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [clickDelete, setClickDelete] = useState(0);
  const [urlPath, setUrlPath] = useState();
  const [urlPathInitialValue, setUrlPathInitialValue] = useState();
  const [captionButton, setCaptionButton] = useState();
  const [openInWebviewSwitch, setOpenInWebviewSwitch] = useState(true);
  const [confirmUrlDialogOpen, setConfirmUrlDialogOpen] = useState(false);

  const [showOpenUrlInWebviewSwitch, setShowOpenUrlInWebviewSwitch] = useState(
    false
  );

  const [upVideo, setUpVideo] = useState(false);
  const [buttonSubmit, setButtonSubmit] = useState(true);
  const canDeleteBanner = useMemo(
    () => hasPermission('delete_promotion_draft_banner', { toast: false }),
    []
  );

  useEffect(() => {
    if (appRoutesValue.id === id) {
      setUrlPath(appRoutesValue.route);
    }
  }, [appRoutesValue]);

  useEffect(() => {
    const currentBannerData = draft.data.banners[originalIndex];

    if (urlVideoDraft) {
      setUrlPathInitialValue(urlVideoDraft);
      setUrlPath(urlVideoDraft);
      setUpVideo(true);
      setButtonSubmit(false);
      setCaptionButton(currentBannerData.captionButton);
      setOpenInWebviewSwitch(
        currentBannerData.useWebview === undefined
          ? true
          : currentBannerData.useWebview
      );
    }
  }, [urlVideoDraft]);

  useEffect(() => {
    if (urlPath) {
      const urlType = identifyLinkType(urlPath);

      if (urlType === 'url') {
        const isYoutubeUrl = isYouTubeURL(urlPath);
        setShowOpenUrlInWebviewSwitch(!isYoutubeUrl);
      } else {
        setShowOpenUrlInWebviewSwitch(false);
      }
    }
  }, [urlPath]);

  const handleSubmitVideo = async () => {
    const submitVideoBody = {
      urlPath: urlPath || null,
    };

    const isYoutubeUrl = isYouTubeURL(urlPath);

    if (!isYoutubeUrl) {
      submitVideoBody.useWebview = openInWebviewSwitch;
      submitVideoBody.captionButton = captionButton;
    }

    const result = await api.post(
      `/promotions/drafts/${draft._id}/video/${id}`,
      submitVideoBody
    );

    const draftCopy = cloneDeep(draft);

    set(
      draftCopy,
      `data.banners.${originalIndex || 0}.urlPath`,
      result.data.urlPath
    );

    updateDraft(draftCopy);

    if (result.data && result.data.urlPath) {
      setButtonSubmit(false);
      setUpVideo(true);
    }

    setUrlPath(result.data.urlPath);
    setCaptionButton(result.data.captionButton);
  };

  const handleValidateAndSubmitVideo = () => {
    const isSameUrlPath = urlPathInitialValue === urlPath;

    if (!urlPath || isSameUrlPath) {
      handleSubmitVideo();
      return;
    }

    setConfirmUrlDialogOpen(true);
  };

  const handleDeleteVideo = async () => {
    await api.delete(`/promotions/drafts/${draft._id}/video/${id}`);

    const draftCopy = cloneDeep(draft);

    set(draftCopy, `data.banners.${originalIndex || 0}.urlPath`, '');
    updateDraft(draftCopy);

    setUrlPath('');
    setUpVideo(false);
    setCaptionButton('');
    setButtonSubmit(true);
    setUrlPathInitialValue('');
    setShowOpenUrlInWebviewSwitch(false);
  };

  const handleDeleteBanner = () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      setTimeout(() => setClickDelete(0), 3000);
    } else if (hasPermission('delete_promotion_draft_banner')) {
      setLoadingDelete(true);

      api
        .delete(`/promotions/drafts/${draft._id}/banners/${id}`)
        .then(res => {
          if (res) onDelete(id);
        })
        .finally(() => setLoadingDelete(false));
    }
  };

  return (
    <div ref={node => drag(drop(node))} style={{ ...style, opacity }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={1}>
          <h2>{originalIndex + 1}</h2>
        </Grid>
        <Grid item xs={2}>
          <Img
            width={100}
            src={url}
            alt="Banner Home"
            loader={<Skeleton variant="rect" width={100} height={80} />}
          />
        </Grid>
        <Grid item xs={5} container>
          <TextField
            disabled={draft.status === 'approved' || upVideo}
            id="outlined-required"
            label="URL/Deeplink"
            value={urlPath}
            onChange={item => setUrlPath(item.currentTarget.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: Boolean(urlPath),
            }}
            size="small"
          />

          <DialogConfirmUrl
            open={confirmUrlDialogOpen}
            toggleDialog={() => setConfirmUrlDialogOpen(false)}
            onSubmit={handleSubmitVideo}
          />

          <Grid
            xs={8}
            container
            justifyContent="flex-start"
            direction="column"
            marginBottom={2}
            marginTop={2}
          >
            {draft.status !== 'approved' && (
              <>
                {buttonSubmit && (
                  <button
                    type="button"
                    onClick={() => onOpenAppRoutes(id)}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      marginTop: 6,
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        cursor: 'pointer',
                        color: 'gray',
                        textDecoration: 'underline',
                        fontSize: 12,
                        gap: 2,
                      }}
                    >
                      <Icon fontSize="inherit" color="primary">
                        info_outlined
                      </Icon>
                      Lista de deeplinks
                    </span>
                  </button>
                )}

                {urlPath && !isYouTubeURL(urlPath) && (
                  <Grid marginBottom={10} style={{ marginTop: 15 }}>
                    <TextField
                      disabled={draft.status === 'approved' || upVideo}
                      id="outlined-required"
                      label="Texto botão"
                      value={captionButton}
                      onChange={item =>
                        setCaptionButton(item.currentTarget.value)
                      }
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                      InputLabelProps={{
                        shrink: Boolean(urlPath),
                      }}
                      size="small"
                    />
                  </Grid>
                )}
                {showOpenUrlInWebviewSwitch && (
                  <>
                    <RadioGroup row>
                      <FormControlLabel
                        label="Abrir no app"
                        checked={openInWebviewSwitch}
                        onChange={() => setOpenInWebviewSwitch(true)}
                        control={<Radio color="primary" />}
                        disabled={draft.status === 'approved' || upVideo}
                      />
                      <FormControlLabel
                        label="Abrir no navegador"
                        checked={!openInWebviewSwitch}
                        onChange={() => setOpenInWebviewSwitch(false)}
                        control={<Radio color="primary" />}
                        disabled={draft.status === 'approved' || upVideo}
                      />
                    </RadioGroup>
                  </>
                )}
              </>
            )}

            {draft.status !== 'approved' && buttonSubmit && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ width: '27%', top: 10 }}
                  onClick={handleValidateAndSubmitVideo}
                >
                  <Icon>cloud_upload</Icon>&nbsp;Enviar
                </Button>
              </>
            )}

            {draft.status !== 'approved' && upVideo && (
              <>
                <Button
                  style={{
                    width: '27%',
                    top: 10,
                  }}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => handleDeleteVideo()}
                >
                  <MdDelete>&nbsp;</MdDelete>Excluir
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {urlPath && isYouTubeURL(urlPath) && (
            <CardPlayerImageBanner>
              <ReactPlayer url={urlPath} light height={100} width={100} />
            </CardPlayerImageBanner>
          )}
        </Grid>

        <Grid item xs={2} align="right">
          {canDeleteBanner && draft.status !== 'approved' && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleDeleteBanner}
            >
              {loadingDelete ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : (
                <>
                  <MdDelete />
                  &nbsp;{clickDelete ? 'Confirma ?' : 'Excluir'}
                </>
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Card;
