import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import {
  Grid,
  Button,
  CircularProgress,
  Icon,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from '@material-ui/core';
import * as Yup from 'yup';
import { AccessControl } from '~/components';
import { Content, Title } from '~/styles/global';
import api from '~/services/api';
import history from '~/services/history';
import { hasPermission } from '~/components/AccessControl';
import useActions from '~/components/ActionsAutocomplete/useActions';
import Copy from '~/pages/Backoffice/Person/View/Dialog/copy';

import useProducts from '~/components/ProductAutocomplete/useProducts';
import Input from '~/components/unform/v2/InputText';
import AutoComplete from '~/components/unform/v2/CodePromotionAutoComplete';
import ProductAutoComplete from '~/components/unform/v2/ProductAutoComplete';
import DateTimePicker from '~/components/unform/v2/DateTimePicker';
import UploadField from '~/components/unform/v2/UploadField';
import toFormdata from 'object-to-formdata';
import Toast from '~/components/Toast';
import { toast } from 'react-toastify';
import { Container, TabIcon, CsvDownload, ColorButton, Cell } from './styles';

export default function PromotionCodeForm({ match }) {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isImport, setIsImport] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [promotionCode, setPromotionCode] = useState();
  const [isCustomCode, setIsCustomCode] = useState(false);
  const [promotionCodeHash, setPromotionCodeHash] = useState(null);
  const [hasValidation, setHasValidation] = useState(false);
  const [actionsArray, setActionsArray] = useState([]);
  const { gifts } = useActions();
  const { products } = useProducts();
  const [productsArray, setProductsArray] = useState([]);
  const [importResult, setImportResult] = useState([]);
  const [validationProductQuantity, setValidationProductQuantity] = useState(1);

  const exampleData = useCallback(async () => {
    const response = await api.get(`/promotion-codes/example-data-import`);
    if (response && response.data) {
      return response.data;
    }

    return [];
  });

  useEffect(() => {
    if (gifts) {
      const action = gifts.map(item => ({
        idAcao: item.id,
        nome: item.titulo,
      }));
      setActionsArray(action);
    }

    if (products) {
      const action = products.map(item => ({
        idProduto: item.id,
        name: item.titulo,
      }));
      setProductsArray(action);
    }
  }, [gifts, products]);

  const getSchema = useCallback(() => {
    if (isImport) {
      return Yup.object().shape({
        action: Yup.string().required('Campo obrigatório'),
        file: Yup.mixed().test(
          'is-file',
          'Você deve anexar um arquivo',
          file => {
            return file instanceof File;
          }
        ),
      });
    }

    return Yup.object().shape(
      {
        action: Yup.string().required('Campo obrigatório'),
        hash: Yup.mixed().when([], validation => {
          return isCustomCode
            ? Yup.string().required('Campo é obrigatório')
            : validation;
        }),
        uses: Yup.number()
          .typeError('Campo obrigatório')
          .required('Campo obrigatório')
          .min(0, 'Valor inválido'),
        startDate: Yup.date()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório.'),
        endDate: Yup.date()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório.')
          .when('startDate', (startDate, validation) => {
            return startDate instanceof Date &&
              !Number.isNaN(startDate.getTime())
              ? Yup.date()
                  .required('Campo obrigatório')
                  .min(startDate, 'Data final deve ser maior que a inicial')
                  .typeError('Campo obrigatório.')
              : validation;
          }),
        message: Yup.string().required('Campo obrigatório'),
        validationProductId: Yup.mixed().when([], validation => {
          return hasValidation
            ? Yup.string().required('Campo é obrigatório')
            : validation;
        }),
        validationProductQuantity: Yup.string().when(
          'validationProductId',
          (validationProductId, validation) => {
            return validationProductId
              ? Yup.number()
                  .typeError('Campo obrigatório')
                  .min(1, 'Valor inválido')
                  .required('Campo quantidade é obrigatório')
              : validation;
          }
        ),
        validationStart: Yup.mixed().when(
          'validationEnd',
          (validationEnd, validation) => {
            return validationEnd
              ? Yup.date()
                  .required()
                  .typeError('O campo data inicio é obrigatório')
              : validation;
          }
        ),
        validationEnd: Yup.mixed().when(
          'validationStart',
          (validationStart, validation) => {
            return validationStart
              ? Yup.date()
                  .required()
                  .min(
                    validationStart,
                    'A data final precisa ser maior que a inicial'
                  )
                  .typeError('O campo data fim é obrigatório')
              : validation;
          }
        ),
      },
      ['validationStart', 'validationEnd']
    );
  }, [isImport, hasValidation, isCustomCode]);

  const handleSubmit = async formData => {
    let updated = false;
    try {
      setLoading(true);

      formRef.current.setErrors({});

      await getSchema().validate(formData, {
        abortEarly: false,
      });

      if (isImport) {
        const { file, action } = formData;
        const requestData = toFormdata({
          file,
          action,
        });

        const { data } =
          (await api.post(`/promotion-codes/import`, requestData)) || {};
        updated = data;
      } else {
        if (!hasValidation) {
          formData.validationProductId = null;
          formData.validationStart = null;
          formData.validationEnd = null;
          formData.errorMessage = null;
        }

        const requestData = {
          ...formData,
        };

        setLoading(true);
        const verb = promotionCode.idCodigoPromocional ? 'put' : 'post';

        const { data } =
          (await api[verb](
            `/promotion-codes/${promotionCode.idCodigoPromocional || ''}`,
            requestData
          )) || {};
        updated = data;
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setLoading(false);
    } finally {
      setLoading(false);
      if (isImport) {
        if (updated.some(item => item.result === 'error')) {
          toast.warn(
            <Toast message="Alguns códigos não foram criados." warn />
          );
        }
        setImportResult(updated);
      } else if (updated) {
        history.push(`/promotion-codes`);
      }
    }
  };

  const getPromotionCode = async id => {
    try {
      setLoading(true);
      if (hasPermission('read_promotion_codes')) {
        const { data } = (await api.get(`/promotion-codes/${id}`)) || {};
        if (data) {
          const promotionCodeData = {
            idCodigoPromocional: data.idCodigoPromocional,
            action: { idAcao: data.idAcao, nome: data.acao.nome },
            hash: data.codigoPromocional,
            uses: data.limiteValidacao ? data.limiteValidacao : 0,
            startDate: new Date(data.dataInicioVigencia),
            endDate: new Date(data.dataFimVigencia),
            message: data.mensagemRetorno,
            errorMessage: data.mensagemRegraValidacao,
          };

          setIsCustomCode(true);

          if (data.validacao) {
            setHasValidation(true);
            promotionCodeData.validationProduct = {
              idProduto: data.validacao.validacaoProduto.idProduto,
              name: data.validacao.validacaoProduto.produto.titulo,
            };
            promotionCodeData.validationProductQuantity =
              data.validacao.validacaoProduto.quantidade;

            promotionCodeData.validationStart =
              data.validacao.dataHoraInicio &&
              new Date(data.validacao.dataHoraInicio);
            promotionCodeData.validationEnd =
              data.validacao.dataHoraFim &&
              new Date(data.validacao.dataHoraFim);

            setValidationProductQuantity(
              promotionCodeData.validationProductQuantity
            );
          }

          setPromotionCode(promotionCodeData);
          setIsDisabled(!data.allowEdit);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (match && match.params && match.params.id) {
      getPromotionCode(match.params.id);
    } else {
      setPromotionCode({});
    }
  }, [match]);

  const alphaNumericValidate = e => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, '')
      : '';

    if (e.target.value !== value) {
      e.target.value = value;
    }
    setPromotionCodeHash(value);
  };

  return !promotionCode ? (
    <></>
  ) : (
    <Content>
      <Form
        schema={getSchema()}
        autoComplete="off"
        autoCapitalize="off"
        noValidate
        onSubmit={handleSubmit}
        initialData={promotionCode}
        ref={formRef}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Container>
              <Title className="title">Código Promocional</Title>
            </Container>
          </Grid>

          <Grid item xs={12} md={6} style={{ marginTop: 15 }}>
            <AutoComplete
              id="action"
              name="action"
              variant="outlined"
              margin="normal"
              options={actionsArray}
              defaultValue={promotionCode.action}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Container>
              <h4>Dados do código promocional</h4>
            </Container>
          </Grid>
          <Grid item xs={12} md={12}>
            <Container>
              <TabIcon
                item
                xs={12}
                md={1}
                style={{
                  marginTop: 15,
                }}
                className={!isImport ? 'active' : ''}
                onClick={() => setIsImport(false)}
              >
                MANUAL
              </TabIcon>
              {!promotionCode.idCodigoPromocional && (
                <TabIcon
                  item
                  xs={12}
                  md={1}
                  style={{
                    marginTop: 15,
                  }}
                  className={isImport ? 'active' : ''}
                  onClick={() => setIsImport(true)}
                >
                  IMPORTAR
                </TabIcon>
              )}
            </Container>
          </Grid>
        </Grid>
        {!isImport && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                marginTop: 15,
              }}
            >
              <span style={{ height: '30px' }}>
                Texto do código promocional:
              </span>
              <RadioGroup row>
                <FormControlLabel
                  label="Aleatório"
                  value="random"
                  checked={!isCustomCode}
                  onChange={() => {
                    setIsCustomCode(false);
                    setPromotionCodeHash('');
                  }}
                  control={<Radio color="primary" />}
                  disabled={promotionCode.idCodigoPromocional}
                />
                <FormControlLabel
                  label="Personalizado"
                  value="custom"
                  checked={isCustomCode}
                  onChange={() => setIsCustomCode(true)}
                  control={<Radio color="primary" />}
                  disabled={isDisabled}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                name="hash"
                id="hash"
                label="Texto do código promocional"
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={!isCustomCode || isDisabled}
                onChange={e => alphaNumericValidate(e)}
                inputProps={{ maxlength: 20 }}
                value={promotionCodeHash}
              />
            </Grid>
            <Grid item xs={6} sm={6} style={{ marginTop: 35 }}>
              <Copy />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                type="number"
                name="uses"
                id="uses"
                label="Quantidade"
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={isDisabled}
                inputProps={{
                  min: 0,
                }}
                helperText="Preencha com 0 para uso ilimitado"
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: 15 }}>
              <DateTimePicker
                name="startDate"
                label="Início da validade"
                defaultValue={promotionCode.startDate}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: 15 }}>
              <DateTimePicker
                name="endDate"
                label="Fim da validade"
                defaultValue={promotionCode.endDate}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Input
                autoFocus
                margin="dense"
                id="message"
                name="message"
                label="Mensagem de sucesso"
                fullWidth
                multiline
                variant="outlined"
                minRows={3}
                disabled={isDisabled}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                marginTop: 15,
              }}
            >
              <span style={{ height: '30px' }}>Condições de uso:</span>
              <RadioGroup row>
                <FormControlLabel
                  label="Irrestrito"
                  checked={!hasValidation}
                  onChange={() => setHasValidation(false)}
                  control={<Radio color="primary" />}
                  disabled={isDisabled}
                />
                <FormControlLabel
                  label="Restrito"
                  checked={hasValidation}
                  onChange={() => setHasValidation(true)}
                  control={<Radio color="primary" />}
                  disabled={isDisabled}
                />
              </RadioGroup>
            </Grid>
            {hasValidation && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} style={{ marginTop: 15 }}>
                  <ProductAutoComplete
                    id="validationProductId"
                    name="validationProductId"
                    label="Produto"
                    variant="outlined"
                    margin="normal"
                    options={productsArray}
                    disabled={!hasValidation || isDisabled}
                    defaultValue={promotionCode.validationProduct}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Input
                    type="number"
                    name="validationProductQuantity"
                    id="validationProductQuantity"
                    label="Quantidade"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onChange={e => setValidationProductQuantity(e.target.value)}
                    value={validationProductQuantity}
                    inputProps={{
                      min: 1,
                    }}
                    disabled={!hasValidation || isDisabled}
                  />
                </Grid>
                <Grid item xs={12} md={3} style={{ marginTop: 15 }}>
                  <DateTimePicker
                    name="validationStart"
                    label="Início da restrição"
                    disabled={!hasValidation || isDisabled}
                    defaultValue={promotionCode.validationStart}
                  />
                </Grid>
                <Grid item xs={12} md={3} style={{ marginTop: 15 }}>
                  <DateTimePicker
                    name="validationEnd"
                    label="Fim da restrição"
                    disabled={!hasValidation || isDisabled}
                    defaultValue={promotionCode.validationEnd}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Input
                    autoFocus
                    margin="dense"
                    id="errorMessage"
                    name="errorMessage"
                    label="Mensagem de erro"
                    fullWidth
                    multiline
                    variant="outlined"
                    minRows={3}
                    disabled={!hasValidation || isDisabled}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {isImport && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                marginTop: 15,
              }}
            >
              <UploadField name="file" />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 15,
              }}
            >
              <CsvDownload
                datas={exampleData}
                filename="exemplo.csv"
                id="csvDowload"
                separator=";"
              >
                <ColorButton variant="contained" color="primary">
                  Gerar modelo
                </ColorButton>
              </CsvDownload>
            </Grid>
          </Grid>
        )}
        {isImport && (
          <Grid item xs={12} md={12} style={{ marginTop: 15 }}>
            {importResult.length > 0 && (
              <Container>
                <h4>Resultado da importação</h4>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableBody>
                      {importResult.map((item, index) => (
                        <TableRow>
                          <TableCell align="center">
                            Linha {index + 1}
                          </TableCell>
                          <Cell align="center">
                            <Chip
                              className={
                                item.result === 'success' ? 'success' : 'error'
                              }
                              label={
                                item.result === 'success'
                                  ? 'Código criado'
                                  : 'Erro'
                              }
                            />
                          </Cell>
                          {item.result === 'success' && (
                            <TableCell style={{ fontWeight: 'bold' }}>
                              {item.hash}
                            </TableCell>
                          )}
                          {item.result === 'error' && (
                            <TableCell>{item.message}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4} align="right">
            <AccessControl
              can={['update_promotion_codes', 'create_promotion_codes']}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Salvar
                  </>
                )}
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </Form>
    </Content>
  );
}
