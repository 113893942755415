import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';

import api from '~/services/api';
import { hasPermission } from '../AccessControl';

const raffleTypesSpec = applySpec({
  id: prop('id'),
  titulo: prop('descricao'),
  cod: prop('codigo'),
});

const mapRaffleTypes = pipe(prop('docs'), map(raffleTypesSpec));

export default function useRaffleTypes() {
  const [raffleTypes, setRaffleTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRaffleTypes = async () => {
    setLoading(true);
    try {
      await api
        .get('/sweepstake/types')
        .then(({ data }) => {
          if (data) setRaffleTypes(mapRaffleTypes(data));
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasPermission('read_sweepstakes_types', { toast: false })) {
      getRaffleTypes();
    }
  }, []);

  return { raffleTypes, loading };
}
