import { Button, CircularProgress, Popover } from '@material-ui/core';
import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FaRegClock, FaHistory } from 'react-icons/fa';
import { parseISO, format } from 'date-fns';
import api from '~/services/api';
import { usePromotionContext } from '../../context';
import { HistoryContainer } from './styles';

function getLogActionName(log) {
  switch (log.action.name) {
    case 'update_promotion':
      return 'atualizou esta promoção';
    case 'update_promotion_sweepstake':
      return 'atualizou um sorteio';
    case 'create_promotion_sweepstake':
      return 'adicionou um sorteio';
    case 'remove_promotion_sweepstake':
      return 'removeu um sorteio';
    case 'update_promotion_regulation':
      return 'atualizou o regulamento';
    case 'update_promotion_ebook':
      return 'atualizou um ebook';
    case 'create_promotion_ebook':
      return 'adicionou um ebook';
    case 'remove_promotion_ebook':
      return 'removeu um ebook';
    default:
      return 'unknown';
  }
}

function getLogActionUser(log) {
  return log.user.name;
}

/* eslint-disable */
function LogRecord({ log }) {
  const date = format(parseISO(log.createdAt), 'dd/MM/yyyy HH:mm');
  const user = getLogActionUser(log);
  const action = getLogActionName(log);
  const data = get(log, 'action.data', null);
  const [displayData, setDisplayData] = useState(false);

  const onDetail = useCallback(() => {
    setDisplayData(oldState => !oldState);
  }, []);

  return (
    <div className="log-action">
      <div className="info">
        <strong>{user}</strong>
        <span className="action" onClick={onDetail}>
          {action}
        </span>
        <span className="date">
          <FaRegClock /> {date}
        </span>
      </div>
      {data && displayData && (
        <div className="detail">
          {
            <pre>
              <code>{JSON.stringify(data, null, 2)}</code>
            </pre>
          }
        </div>
      )}
    </div>
  );
}
/* eslint-enable */

export default function HistoryButton() {
  const { promotion } = usePromotionContext();
  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(null);
  const [logs, setLogs] = useState([]);

  const hasPromotion = useMemo(() => {
    return !!get(promotion, 'idPromocao', null);
  }, [promotion]);

  const handleLogs = useCallback(
    async evt => {
      setLoading(true);
      setAnchor(evt.currentTarget);
      try {
        const id = get(promotion, 'idPromocao');
        const { data: res } = await api.get(`/promotions/${id}/logs`, {
          params: { limit: 50 },
        });
        const allLogs = get(res, 'docs', []);
        setLogs(allLogs);
      } finally {
        setLoading(false);
      }
    },
    [promotion]
  );

  const open = Boolean(anchor);

  return (
    <>
      <Button
        size="small"
        startIcon={<FaHistory size="20" />}
        onClick={handleLogs}
        disabled={loading || !hasPromotion}
        type="button"
      >
        Histórico de Alterações
      </Button>
      <Popover
        anchorEl={anchor}
        open={open}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <HistoryContainer elevation={3}>
          {loading && (
            <div className="loading-container">
              <CircularProgress />
            </div>
          )}
          {!loading && !logs.length && (
            <div className="empty-logs">
              Não há registro de logs disponíveis.
            </div>
          )}
          <ul className="logs-container">
            {logs.map(log => (
              <li>
                <LogRecord key={log._id} log={log} />
              </li>
            ))}
          </ul>
        </HistoryContainer>
      </Popover>
    </>
  );
}
