import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

export default function Input({ name, containerStyle, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div style={containerStyle}>
      <TextField
        inputRef={inputRef}
        error={!!error}
        helperText={error}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  containerStyle: PropTypes.objectOf({}),
};

Input.defaultProps = {
  name: null,
  containerStyle: null,
};
