import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { TextField, Select } from 'unform-material-ui';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import DialogResetPassword from '../DialogResetPassword';

export default function DialogReason({ open, toggleDialog, person, action }) {
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [loading, setLoading] = useState(false);
  const [sendType, setSendType] = useState('');
  const [reasonReset, setReasonReset] = useState();
  const [tags, setTags] = useState([
    { value: 'SUSPEITO', label: 'Suspeito' },
    { value: 'FRAUDE', label: 'Fraude' },
    { value: 'SOLICITACAO', label: 'Solicitação do Cliente' },
    { value: 'SEMTAG', label: 'Sem marcação' },
  ]);
  let schema = Yup.object().shape();
  if (action !== 'reset_password') {
    schema = Yup.object().shape({
      tag:
        action === 'person_attendance'
          ? Yup.string()
          : Yup.string().required('Campo obrigatório'),

      reason: Yup.string()
        .min(10, 'No mímino 10 caracteres')
        .required('Campo obrigatório'),
    });
  }

  useEffect(() => {
    setTags(oldTags => {
      const hasOK = oldTags.filter(o => o.value === 'OK');
      if (!hasOK.length) {
        return [
          {
            value: 'OK',
            label: 'Documentação confirmada',
          },
          ...oldTags,
        ];
      }
      return oldTags;
    });
    if (action === 'lock') {
      setTitulo(person.bloqueado ? 'Desbloqueio' : 'Bloqueio');
      setDescricao('Justifique o motivo para o');
      if (!person.bloqueado) {
        setTags(oldTags => oldTags.filter(t => t.value !== 'OK'));
      }
    }
    if (action === 'delete') {
      setTitulo(person.status ? 'Excluir' : 'Reativar');
      setDescricao('Justifique o motivo para');
    }
    if (action === 'reset_password') {
      setTitulo('Reset de Senha');
      setDescricao('Justifique o motivo para o');
    }
    if (action === 'person_attendance') {
      setTitulo('Ocorrência');
      setDescricao('Justifique a');
    }
  }, [person, person.bloqueado, action, person.status]);

  const handleConfirmar = async ({ reason, tag }) => {
    setLoading(true);
    if (action === 'lock') {
      try {
        if (
          (!person.bloqueado && hasPermission('block_person')) ||
          (person.bloqueado && hasPermission('unblock_person'))
        ) {
          const { data } =
            (await api.put(`/persons/${person.idCliente}/blocking`, {
              reason,
              tag,
              blocked: !person.bloqueado,
            })) || {};
          if (data && data.cpf) {
            person.bloqueado = !person.bloqueado;
            person.tag = data.tag;
          }
        }
      } finally {
        setLoading(false);
        toggleDialog();
      }
    }
    if (action === 'delete') {
      try {
        if (hasPermission('delete_person')) {
          const { data } =
            (await api.put(`/persons/${person.idCliente}/blocking`, {
              reason,
              blocked: person.bloqueado,
              tag,
              unregister: person.status,
            })) || {};
          if (data && data.cpf) {
            person.status = !person.status;
            person.tag = tag;
          }
        }
      } finally {
        toggleDialog();
        setLoading(false);
      }
    }
    if (action === 'reset_password') {
      try {
        if (hasPermission('reset_password')) {
          await api.put(`/persons/${person.idCliente}/reset-password `, {
            reason: reasonReset,
            type: sendType,
          });
        }
      } finally {
        toggleDialog();
        setLoading(false);
      }
    }
    if (action === 'person_attendance') {
      try {
        if (hasPermission('create_logs')) {
          await api.post(`/persons/${person.idCliente}/logs`, {
            action,
            reason,
          });
        }
      } finally {
        toggleDialog();
        setLoading(false);
      }
    }
  };

  const handeSubmit = async values => {
    await handleConfirmar(values);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <Form schema={schema} onSubmit={handeSubmit}>
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            {action !== 'reset_password' && (
              <>
                <DialogContentText>
                  {descricao} {titulo.toLowerCase()}
                </DialogContentText>

                <TextField
                  autoFocus
                  margin="dense"
                  id="reason"
                  name="reason"
                  label="Motivo"
                  fullWidth
                  multiline
                  maxRows={4}
                />
                {tags && action !== 'person_attendance' && (
                  <Select
                    id="tag"
                    name="tag"
                    label="Escolha a Tag"
                    options={tags}
                  />
                )}
              </>
            )}
            {action === 'reset_password' && (
              <DialogResetPassword
                person={person}
                setSendType={setSendType}
                setReasonReset={reasonValue => setReasonReset(reasonValue)}
                sendType={sendType}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog} color="secondary" disabled={loading}>
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

DialogReason.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  action: PropTypes.string.isRequired,
};
