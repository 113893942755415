/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get, set } from 'lodash';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import Upload from './upload';

export default function Regulation({ draft, updateDraft, setNextStep }) {
  const [loading, setLoading] = useState(false);
  const [imagem, setImagem] = useState(draft.data.regulation);
  const [file, setFile] = useState();

  useEffect(() => {
    setImagem(draft.data.regulation);
  }, [draft]);

  useEffect(() => {
    setNextStep(
      (draft.data.regulation || draft.status === 'draft') && draft._id && !file
    );
  }, [draft, setNextStep, file]);

  function getDateFormat(data) {
    const newDate = parseISO(`${data}T23:00:00.000Z`);
    return format(newDate, 'yyyy-MM-dd HH:mm', { locale: ptBR });
  }

  // Send form to api
  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append('regulamento', file);

    setLoading(true);
    if (hasPermission('update_promotion_draft')) {
      api
        .post(
          `/promotions/drafts/${(draft && draft._id) || ''}/regulation`,
          formdata
        )
        .then(res => {
          const oldDraft = get(res, 'data', {});
          const { promotion, regulation } = get(res, 'data.data', {});
          const copyDraft = { ...oldDraft };

          set(copyDraft, 'data.regulation', `${regulation}?v=${Date.now()}`);

          const dataRef = getDateFormat(get(promotion, 'dataReferencia'));
          set(copyDraft, 'data.promotion.dataReferencia', dataRef);

          updateDraft(copyDraft);
          setFile(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Main Render
  return (
    <form autoComplete="off" autoCapitalize="off" noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Upload
            data={(!loading && imagem) || ''}
            onUpdate={_file => setFile(_file)}
            draft={draft}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}

Regulation.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
};
