/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { memo, useState, useEffect } from 'react';
import { List, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import useProducts from './useProducts';
import ProductListItem from './ProductListItem';

function ProductList({ handleSelectProduct, limited }) {
  const { products, loading } = useProducts();

  const { profile: userProfile, product: selected } = useSelector(
    ({ user }) => user
  );

  const handleClick = _prod => {
    handleSelectProduct(_prod);
  };

  const [userProducts, setUserProducts] = useState();

  useEffect(() => {
    if (!limited || userProfile.productPermissions === -1) {
      setUserProducts(products.map(p => p.id));
    } else {
      setUserProducts(userProfile.productPermissions);
    }
  }, [userProfile.productPermissions, limited, products]);

  return !userProducts || loading ? (
    <CircularProgress />
  ) : (
    <List dense style={{ backgroundColor: '#CCCCCC20' }}>
      {products
        .filter(p => userProducts.includes(p.id))
        .map(product => (
          <ProductListItem
            key={product.id}
            handleClick={() => handleClick(product)}
            product={product}
            selected={selected}
          />
        ))}
    </List>
  );
}

ProductList.defaultProps = {
  limited: false,
};

ProductList.propTypes = {
  handleSelectProduct: PropTypes.func.isRequired,
  limited: PropTypes.bool,
};

export default memo(ProductList);
