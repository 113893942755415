import React, { useCallback, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { CircularProgress, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';

export default function DeleteButton({ item, loadingData }) {
  const [clickDelete, setClickDelete] = useState(0);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleRemoveGift = useCallback(async () => {
    let timeClick;
    if (clickDelete === 0) {
      setClickDelete(1);
      timeClick = setTimeout(() => setClickDelete(0), 3000);
    } else {
      setLoadingDelete(true);
      if (timeClick) clearTimeout(timeClick);
      setDisable(true);
      try {
        await api.put(`/promotions/action/${item.idPromocaoAcaoBrinde}`, {
          status: 0,
        });
        loadingData();
      } finally {
        setLoadingDelete(false);
      }
    }
  }, [loadingData, item, clickDelete]);

  return (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      fullWidth
      onClick={handleRemoveGift}
      disabled={disable}
      startIcon={
        loadingDelete || disable ? (
          <CircularProgress size={20} style={{ color: 'white' }} />
        ) : (
          <MdDelete />
        )
      }
    >
      {clickDelete || disable ? 'Confirma ?' : 'Excluir'}
    </Button>
  );
}

DeleteButton.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loadingData: PropTypes.func.isRequired,
};
