import React from 'react';
import {
  MdAccountBalanceWallet,
  MdCreditCard,
  MdPointOfSale,
} from 'react-icons/md';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Card } from './card';
import { CardContainer, Container, Header } from './styles';
import { CardChart } from './cardChart';

ChartJS.register(ArcElement, Tooltip, Legend);

const Financial = () => {
  const total = 200000;
  const gerados = 12500;
  const confirmados = 25000;
  const enviados = 75000;

  const data = {
    datasets: [
      {
        label: '# of Votes',
        data: [total, gerados, confirmados, enviados],
        backgroundColor: ['#a6a6a6', '#FFB547', '#2196F3', '#4CAF50'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <Header>
        <h1>Extrato de liquidação</h1>
      </Header>

      <CardContainer>
        <Card
          icon={<MdAccountBalanceWallet size={20} color="#303F9F" />}
          buttonName="Gerar Liquidação"
          value="150000"
          title="Saldo"
        />
        <Card
          icon={<MdCreditCard size={20} color="#303F9F" />}
          buttonName="Gerar Liquidação"
          value="60000"
          title="Cartão"
        />
        <Card
          icon={<MdCreditCard size={20} color="#303F9F" />}
          buttonName="Gerar Liquidação"
          title="PIX"
        />
        <Card
          icon={<MdPointOfSale size={20} color="#303F9F" />}
          buttonName="Gerar Liquidação"
          value="15000"
          title="Venda Mínima"
        />
        <CardChart
          total={total}
          gerados={gerados}
          confirmados={confirmados}
          enviados={enviados}
          chart={<Doughnut data={data} />}
          value="112500"
        />
      </CardContainer>
    </Container>
  );
};

export default Financial;
