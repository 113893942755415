import { isEmpty } from 'lodash';

/**
 * Compares two arrays of objects to check if they are identical based on specified fields.
 *
 * @param {Array<Object>} arr1 - The first array of objects to compare.
 * @param {Array<Object>} arr2 - The second array of objects to compare.
 * @param {Object} keys - An object containing the comparison keys.
 * @param {string} keys.id - The unique identifier key in each object used to match objects between arrays.
 * @param {Array<string>} keys.fields - An array of field names to compare in each object.
 *
 * @returns {boolean} - Returns `true` if both arrays have the same length, and each object in `arr1` matches its corresponding object in `arr2` based on the specified fields; otherwise, returns `false`.
 *
 * @throws {TypeError} - Throws an error if either `arr1` or `arr2` is not an array, or if `keys` is not properly structured.
 *
 * @example
 * // Sample arrays
 * const arr1 = [{ id: 1, name: 'Alice', age: 30 }, { id: 2, name: 'Bob', age: 25 }];
 * const arr2 = [{ id: 1, name: 'Alice', age: 30 }, { id: 2, name: 'Bob', age: 25 }];
 *
 * // Define keys to compare by ID and check the 'name' and 'age' fields
 * const keys = { id: 'id', fields: ['name', 'age'] };
 *
 * // Compare arrays
 * const result = compareArrays(arr1, arr2, keys);
 * console.log(result); // Outputs: true
 */
export function compareArrays(arr1, arr2, keys) {
  const emptyArrays = isEmpty(arr1) && isEmpty(arr2);

  if (emptyArrays) return true;

  if (arr1?.length !== arr2?.length) {
    return false;
  }

  const arr2Map = new Map(arr2.map(item => [item[keys.id], item]));

  return !arr1.some(item1 => {
    const item2 = arr2Map.get(item1[keys.id]);
    return !item2 || keys.fields.some(field => item1[field] !== item2[field]);
  });
}
