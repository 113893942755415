import styled from 'styled-components';

export const H2 = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  opacity: 0.5;
  svg {
    margin-right: 20px;
  }
`;
