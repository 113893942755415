/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InputLabel,
  Tabs,
  Tab,
  Typography,
  Box,
  CircularProgress,
  Chip,
  Icon,
  withStyles,
} from '@material-ui/core';

import { Form } from '@rocketseat/unform';
import { TextField, Select } from 'unform-material-ui';
import formatCpf from '@brazilian-utils/format-cpf';
import {
  FaUserAlt,
  FaLock,
  FaTimes,
  FaBarcode,
  FaQrcode,
  FaWhatsapp,
  FaRegListAlt,
} from 'react-icons/fa';
import { GoKey, GoAlert } from 'react-icons/go';
import { GiMoneyStack } from 'react-icons/gi';
import Dialog from '@material-ui/core/Dialog';

import {
  parseISO,
  isValid as isValidDate,
  format as formatDate,
} from 'date-fns';

import * as Yup from 'yup';

import { AiOutlinePartition } from 'react-icons/ai';

import {
  MdCreditCard,
  MdLocalGroceryStore,
  MdLocalOffer,
  MdHistory,
  MdNotifications,
  MdNotificationsActive,
  MdBook,
  MdFaceRetouchingOff,
} from 'react-icons/md';

import { BiDonateHeart } from 'react-icons/bi';

import {
  TextMaskCPF,
  TextMaskDate,
  TextMaskDDD,
  TextMaskCelular,
  TextMaskCEP,
} from '~/components/masks';

import { Content } from '~/styles/global';
import CardList from '~/pages/Backoffice/Card/List';
import PurchaseList from '~/pages/Backoffice/Purchase/List';
import ExtractList from '~/pages/Backoffice/Person/Extract/List';
import HistoricAddress from '~/pages/Backoffice/Person/HistoricAddress/List';
import OwnershipHistory from '~/pages/Backoffice/Person/OwnershipHistory';
import PostOfficeList from '~/pages/Backoffice/PostOfficeSter/List';
import PixTransaction from '~/pages/Backoffice/PixTransaction/List';
import BankSlip from '~/pages/Backoffice/BankSlips/List';
import AccessControl, { hasPermission } from '~/components/AccessControl';
import { yupDateTransformer } from '~/helpers/date';
import api from '~/services/api';
import Coupons from '~pages/Backoffice/Coupons';
import NoRecord from '~/components/NoRecord';
import Push from '~/pages/Backoffice/Push';
import ReasonAutocomplete from '~/components/ReasonAutocomplete';
import TextMaskCurrency from '~/components/masks/TextMaskCurrency';
import isEmpty from 'lodash/isEmpty';
import CallContactPopover from './CallContactPopover';
import Avatar from './avatar';
import DialogTag from '../Dialog/tag';
import DialogCallContact from '../Dialog/callContact';
import DialogReason from '../Dialog';
import { LoadingContainer } from '../Search/styles';
import {
  Container,
  TextFieldLarge,
  Title,
  Button,
  Bloqueado,
  SemCadastro,
  Excluido,
  BtnWhats,
} from './styles';
import PromoModal from './Dialog';
import NotificationModal from './NotificationModal';
import LogPerson from '../Log';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`person-tabpanel-${index}`}
      aria-labelledby={`person-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
const TabContent = withStyles({
  root: {
    padding: 5,
  },
})(Tab);

function a11yProps(index) {
  return {
    id: `person-tab-${index}`,
    'aria-controls': `person-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const schema = Yup.object().shape({
  nomeCompleto: Yup.string()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']*$/, {
      message: 'Nome inválido',
    })
    .required('Campo obrigatório'),
  nomeSocial: Yup.string()
    .nullable()
    .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']*$/, {
      message: 'Nome social inválido',
    })
    .transform((value, originalValue) => {
      return originalValue.trim() === '' ? null : value;
    }),
  cpf: Yup.string().required('Campo obrigatório'),
  dataNascimento: Yup.mixed()
    .required('Informe uma data de nascimento')
    .transform(yupDateTransformer())
    .test('is-date', 'Data Inválida', isValidDate),
  sexo: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Email inválido.'),
  ddd: Yup.number().required('Campo obrigatório'),
  celular: Yup.number().required('Campo obrigatório'),
  cep: Yup.string()
    .test('len', 'Deve ter oito caracteres', val => {
      return val.replace(/[^0-9]+/g, '').length === 8;
    })
    .required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  cidade: Yup.string().required('Campo obrigatório'),
  estado: Yup.string().required('Campo obrigatório'),
  complemento: Yup.string(),
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function PersonView({ match }) {
  const [readOnly, setReadOnly] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [reason, setReason] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openModalSaldo, setOpenModalSaldo] = useState(false);
  const [openModalPromo, setOpenModalPromo] = useState(false);
  const [openNotificationOptIn, setOpenNotificationOptIn] = useState(false);
  const [openTagDialog, setOpenTagDialog] = useState(false);
  const [openCallContactDialog, setOpenCallContactDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSaldo, setLoadingSaldo] = useState(false);
  const [loadingBlackList, setLoadingBlackList] = useState(false);
  const [action, setAction] = useState();
  const [person, setPerson] = useState();
  const [initialData, setInitialData] = useState();
  const [saldo, setSaldo] = useState();
  const [statusBlackList, setStatusBlackList] = useState(() => {
    if (hasPermission('person_black_list', { toast: false })) {
      return '';
    }
    return 'BLACKLIST';
  });
  const [address, setAddress] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  async function getPerson({ search } = {}) {
    if (!search) return;

    try {
      setLoading(true);
      if (hasPermission(['read_persons', 'support_reset_password'])) {
        let { data } = (await api.get(`/persons/${search}`)) || {};

        if (data && data.dataNascimento) {
          data = {
            ...data,
            dataNascimento: formatDate(
              parseISO(data.dataNascimento),
              'dd/MM/yyyy'
            ),
          };
        }

        // update data.
        if (data) {
          setPerson(data);
          setAddress(data);
        }
      }
    } finally {
      setLoading(false);
    }
  }

  async function loadSaldo() {
    if (loadingSaldo) return;

    try {
      setLoadingSaldo(true);
      if (hasPermission('read_balance')) {
        const { data } =
          (await api.get(`/persons/${person.idCliente}/balance`)) || {};

        if (data && data.saldo) {
          setSaldo(String(parseFloat(data.saldo).toFixed(2)).replace('.', ','));
        }
      }
    } finally {
      setLoadingSaldo(false);
    }
  }

  const handleFindPersonExistsInBlackList = async cpf => {
    if (loadingBlackList) return;

    try {
      setLoadingBlackList(true);
      const { data } = await api.get(`/persons/blackList/${cpf}`);

      if (!isEmpty(data.response)) {
        setStatusBlackList('REMOVER DA BLACKLIST');
        return;
      }

      setStatusBlackList('ADICIONAR NA BLACKLIST');
    } finally {
      setLoadingBlackList(false);
    }
  };

  const handleAddOrRemovePersonWithBlackList = async cpf => {
    if (hasPermission('person_black_list', { toast: false })) {
      setLoadingBlackList(true);
      try {
        await api.post('/persons/blackList/add', { cpf });
        if (statusBlackList === 'REMOVER DA BLACKLIST') {
          setStatusBlackList('ADICIONAR NA BLACKLIST');
        } else {
          setStatusBlackList('REMOVER DA BLACKLIST');
        }
      } finally {
        setLoadingBlackList(false);
      }
    }
  };

  useEffect(() => {
    setPerson(null);
    setInitialData(null);
    setReadOnly(false);
    if (match && match.params && match.params.searchParam) {
      getPerson({ search: match.params.searchParam });
    }
  }, [match]);

  useEffect(() => {
    if (person) {
      setInitialData({ ...person, cpf: formatCpf(person.cpf) });
      setAddress({
        cidade: person.cidade,
        bairro: person.bairro,
        estado: person.estado,
        logradouro: person.logradouro,
      });
      if (hasPermission('person_black_list', { toast: false })) {
        handleFindPersonExistsInBlackList(person.cpf);
      }
    }
  }, [person]);

  const handleFormatCpf = e => {
    e.target.value = formatCpf(e.target.value);
  };

  const handleReasonChange = v => {
    document.getElementById('reason').value = v;
    person.reason = v;
    setReason(v);
  };
  const handleModalTag = p => {
    setOpenTagDialog(!openTagDialog);
    if (p && p.tag) {
      setPerson(p);
    }
  };

  const handleModalCallContact = () => {
    if (hasPermission('create_call_contact', { toast: false })) {
      setOpenCallContactDialog(!openCallContactDialog);
    }
  };

  const handleSubmit = async personObj => {
    try {
      setLoading(true);
      if (hasPermission(['update_person', 'support_reset_password'])) {
        personObj.dataNascimento = formatDate(
          personObj.dataNascimento,
          'yyyy-MM-dd'
        );

        personObj.cep = String(personObj.cep).replace(/[^0-9]+/g, '');
        personObj.nomeSocial =
          personObj.nomeSocial && personObj.nomeSocial.trim();
        const { data } =
          (await api.put(`/persons/${person.idCliente}`, personObj)) || {};
        if (data) {
          setPerson(data);
          document.getElementById('reason').value = '';
          setReason('');
          // TODO: Melhorar
          document
            .querySelector(
              'div.MuiAutocomplete-root.ReasonAutoComplete.MuiAutocomplete-hasClearIcon > div > div'
            )
            .click();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCEP = async e => {
    if (
      e.target.value &&
      String(e.target.value).replace(/[^\d]/g, '').length === 8
    ) {
      const cep = String(e.target.value).replace(/[^\d]/g, '');
      try {
        setLoading(true);
        if (hasPermission('update_person')) {
          const { data } = await api.get(`/addresses/${cep}`);
          if (data) {
            document.getElementById('logradouro').value = data.logradouro;
            document.getElementById('bairro').value = data.bairro;
            document.getElementById('cidade').value = data.cidade;
            document.getElementById('estado').value = data.uf;
            setAddress(data);
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      document.getElementById('logradouro').value = '';
      document.getElementById('bairro').value = '';
      document.getElementById('cidade').value = '';
      document.getElementById('estado').value = '';
      setAddress({ cidade: null, bairro: null, logradouro: null, uf: null });
    }
  };
  const handleModalPromo = () => {
    setOpenModalPromo(true);
  };

  const handleNotificationsOptIn = () => {
    setOpenNotificationOptIn(true);
  };

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const handleLockPerson = () => {
    if (!openDialog) setAction('lock');
    setOpenDialog(!openDialog);
  };

  const handleDeletePerson = () => {
    setAction('delete');
    setOpenDialog(!openDialog);
  };

  const handleResetPassword = () => {
    setAction('reset_password');
    setOpenDialog(!openDialog);
  };

  const handleSaldo = () => {
    setOpenModalSaldo(true);
    loadSaldo();
  };
  const renderPersontag = () => {
    if (person.tag === 'OK') {
      return 'DOCUMENTAÇÃO CONFIRMADA';
    }
    if (person.tag === 'BLOQ CARTAO') {
      return 'CARTÃO BLOQUEADO';
    }
    return person.tag;
  };

  const renderPersonCallContact = () => {
    if (person.ultimoContato) {
      return 'CONTATADO';
    }
    return 'NÃO CONTATADO';
  };

  const handlePersonAttendance = () => {
    setAction('person_attendance');
    setOpenDialog(!openDialog);
  };

  const renderLoading = () => {
    return loading ? (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    ) : (
      <NoRecord />
    );
  };

  const renderColorTag = tag => {
    switch (tag) {
      case 'DOCUMENTAÇÃO CONFIRMADA':
        return { backgroundColor: 'green', color: 'white' };
      case 'SUSPEITO':
        return { backgroundColor: 'yellow', color: 'black' };
      default:
        return {};
    }
  };

  const hasPermissionEditPerson = useMemo(
    () => hasPermission('read_persons', { toast: false }),
    [person, initialData]
  );

  return person && initialData ? (
    <AccessControl can={['read_persons', 'support_reset_password']}>
      <>
        <Content>
          <Form
            schema={schema}
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
            initialData={initialData}
          >
            <Container>
              {person && (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Container>
                      <FaUserAlt size="25" color="gray" />
                      <Title className="title">&nbsp;Dados da Pessoa</Title>
                    </Container>
                  </Grid>
                  <Grid item xs={6} align="right">
                    {loadingBlackList ? (
                      <CircularProgress />
                    ) : (
                      <AccessControl can="person_black_list">
                        <Chip
                          label={statusBlackList}
                          style={{ marginRight: 10 }}
                          onClick={() =>
                            handleAddOrRemovePersonWithBlackList(person.cpf)
                          }
                          color="primary"
                        />
                      </AccessControl>
                    )}
                    <Chip
                      onMouseEnter={person.ultimoContato && handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      label={renderPersonCallContact()}
                      style={{ marginRight: 10 }}
                      onClick={() => handleModalCallContact()}
                      color={person.ultimoContato ? 'primary' : 'default'}
                    />
                    {person && person.tag ? (
                      <Chip
                        label={renderPersontag()}
                        style={renderColorTag(person.tag)}
                        onClick={() => handleModalTag()}
                        color={
                          person.tag === 'FRAUDE' ||
                          person.tag === 'BLOQ CARTAO'
                            ? 'secondary'
                            : 'primary'
                        }
                      />
                    ) : (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => handleModalTag()}
                        color={!person.tag ? 'primary' : 'secondary'}
                        size="small"
                      >
                        {person.tag ? person.tag : 'Marcar'}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <p>Nº APCAP: {person.idCliente}</p>
                  </Grid>
                  <Grid item xs={4} align="left">
                    {person.usuario && !person.usuario.autoCadastro && (
                      <p>REGISTRADO VIA: {person.descricao}</p>
                    )}
                  </Grid>
                  <Grid item xs={7} align="right">
                    <div>
                      USUÁRIO DESDE:
                      {person.dataInsert
                        ? formatDate(
                            parseISO(person.dataInsert),
                            ' dd/MM/yyyy - HH:mm'
                          )
                        : ' 00/00/0000'}
                    </div>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 20,
                  }}
                >
                  <Avatar
                    data={person}
                    onUpdated={urlImageProfile =>
                      setPerson({ ...person, urlImageProfile })
                    }
                    disabled={!hasPermissionEditPerson}
                  />

                  <TextFieldLarge
                    InputProps={{
                      readOnly,
                    }}
                    name="nomeCompleto"
                    id="nomeCompleto"
                    label="Nome Completo"
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                {person.autoCadastro === 1 && (
                  <Grid item xs={12} md={12}>
                    <SemCadastro>
                      <div>
                        <MdFaceRetouchingOff />
                        USUÁRIO SEM CADASTRO COMPLETO!
                      </div>
                    </SemCadastro>
                  </Grid>
                )}

                {!person.status ? (
                  <Grid item xs={12} md={12}>
                    <Excluido>
                      <div>
                        <FaTimes />
                        &nbsp; Pessoa excluida !
                      </div>
                    </Excluido>
                  </Grid>
                ) : (
                  ''
                )}

                {person.bloqueado ? (
                  <Grid item xs={12} md={12}>
                    <Bloqueado>
                      <div>
                        <FaLock />
                        &nbsp; CPF bloqueado !
                      </div>
                      <AccessControl can="unblock_person">
                        <Button
                          type="button"
                          variant="contained"
                          onClick={handleLockPerson}
                          color="secondary"
                          size="small"
                        >
                          {!person.bloqueado ? 'Bloquear' : 'Desbloquear'}
                        </Button>
                      </AccessControl>
                    </Bloqueado>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} md={2}>
                  <TextField
                    name="nomeSocial"
                    id="nomeSocial"
                    label="Nome Social"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                      inputComponent: TextMaskCPF,
                    }}
                    name="cpf"
                    id="cpf"
                    label="CPF"
                    margin="normal"
                    variant="outlined"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    onKeyUp={handleFormatCpf}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    InputProps={{
                      readOnly,
                      inputComponent: TextMaskDate,
                    }}
                    name="dataNascimento"
                    id="dataNascimento"
                    label="Data de Nascimento"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <InputLabel htmlFor="sexo">&nbsp;</InputLabel>
                  <Select
                    id="sexo"
                    name="sexo"
                    label="Sexo"
                    disabled={readOnly || !hasPermissionEditPerson}
                    variant="outlined"
                    options={[
                      { value: 'F', label: 'Feminino' },
                      { value: 'M', label: 'Masculino' },
                      { value: 'O', label: 'Outro' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    InputProps={{
                      readOnly,
                    }}
                    name="email"
                    id="email"
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={1}>
                  <TextField
                    InputProps={{
                      readOnly,
                      inputComponent: TextMaskDDD,
                    }}
                    name="ddd"
                    id="ddd"
                    label="DDD"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      InputProps={{
                        readOnly,
                        inputComponent: TextMaskCelular,
                      }}
                      name="celular"
                      id="celular"
                      label="Celular"
                      margin="normal"
                      variant="outlined"
                    />
                    <a
                      href={`https://api.whatsapp.com/send?phone=+55${person.ddd}${person.celular}`}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      title="WhatsApp"
                      rel="noreferrer"
                    >
                      <BtnWhats type="button" size="large" variant="contained">
                        <FaWhatsapp size={25} />
                      </BtnWhats>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    InputProps={{
                      readOnly,
                      inputComponent: TextMaskCEP,
                    }}
                    name="cep"
                    id="cep"
                    label="CEP"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    onChange={handleCEP}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: readOnly || !!address.logradouro,
                    }}
                    name="logradouro"
                    id="logradouro"
                    label="Logradouro"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    // ref={refLogradouro}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <TextField
                    InputProps={{
                      readOnly,
                    }}
                    name="numero"
                    id="numero"
                    label="Número"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    InputProps={{
                      readOnly: readOnly || !!address.bairro,
                    }}
                    name="bairro"
                    id="bairro"
                    label="Bairro"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    InputProps={{
                      readOnly: readOnly || !!address.cidade,
                    }}
                    name="cidade"
                    id="cidade"
                    label="Cidade"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <TextField
                    InputProps={{
                      readOnly: readOnly || !!address.estado || !!address.uf,
                    }}
                    name="estado"
                    id="estado"
                    label="UF"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    InputProps={{
                      readOnly,
                    }}
                    name="complemento"
                    id="complemento"
                    label="Complemento"
                    type={!hasPermissionEditPerson ? 'password' : null}
                    disabled={!hasPermissionEditPerson}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ReasonAutocomplete
                    variant="outlined"
                    onChange={e => handleReasonChange(e.target.value)}
                    handleValue={handleReasonChange}
                  />
                  <TextField
                    name="reason"
                    id="reason"
                    type="hidden"
                    label=""
                    value={reason}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              {/* Botoes */}
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10} align="right">
                  <AccessControl can="create_logs">
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleNotificationsOptIn}
                      color="default"
                      style={{ backgroundColor: '#9C27B0', color: '#fff' }}
                    >
                      <MdNotificationsActive size={24} />
                      &nbsp;Notificações
                    </Button>
                  </AccessControl>
                  <AccessControl can="create_logs">
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleModalPromo}
                      color="default"
                      style={{ backgroundColor: '#005eb7', color: '#fff' }}
                    >
                      <GiMoneyStack size={24} />
                      &nbsp;Promo
                    </Button>
                  </AccessControl>
                  <AccessControl can="create_logs">
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handlePersonAttendance}
                      color="default"
                      style={{ backgroundColor: 'yellow', color: 'black' }}
                    >
                      <GoAlert size={24} />
                      &nbsp;Ocorrência
                    </Button>
                  </AccessControl>
                  {person && person.idCliente && (
                    <AccessControl can="read_balance">
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleSaldo}
                        color="default"
                        style={{ backgroundColor: 'green', color: 'white' }}
                      >
                        <GiMoneyStack size={24} />
                        &nbsp;Ver Saldo
                      </Button>
                    </AccessControl>
                  )}
                  <AccessControl can="reset_password">
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleResetPassword}
                      color="default"
                    >
                      <GoKey size={20} />
                      &nbsp;Reset Senha
                    </Button>
                  </AccessControl>
                  {person.status && (
                    <AccessControl can="delete_person">
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleDeletePerson}
                        color="secondary"
                        style={{ backgroundColor: 'black' }}
                      >
                        <Icon>delete</Icon>
                        &nbsp;Excluir
                      </Button>
                    </AccessControl>
                  )}
                  {!person.bloqueado && (
                    <AccessControl can="block_person">
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleLockPerson}
                        color="secondary"
                      >
                        <Icon>block</Icon>
                        &nbsp;Bloquear
                      </Button>
                    </AccessControl>
                  )}
                  <AccessControl
                    can={['update_person', 'support_reset_password']}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      color="primary"
                    >
                      <Icon>save</Icon>&nbsp;
                      {loading ? <CircularProgress size={24} /> : 'Salvar'}
                    </Button>
                  </AccessControl>
                </Grid>
              </Grid>
            </Container>
          </Form>
        </Content>

        {/* <!-- Abas complementares --> */}
        <AccessControl can="read_persons">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            scrollButtons="auto"
            variant="scrollable"
          >
            <TabContent
              label="Histórico de Compras"
              icon={<MdLocalGroceryStore size={25} />}
              wrapped={false}
              {...a11yProps(0)}
            />
            <TabContent
              label="Transações Pix"
              icon={<FaQrcode size={25} />}
              {...a11yProps(1)}
            />
            <TabContent
              label="Boletos"
              icon={<FaBarcode size={25} />}
              {...a11yProps(2)}
            />
            <TabContent
              label="STER Correios"
              icon={<BiDonateHeart size={25} />}
              {...a11yProps(3)}
            />
            <TabContent
              label="Cupons"
              icon={<MdLocalOffer size={25} />}
              {...a11yProps(4)}
            />
            <TabContent
              label="Meios de Pagamento"
              icon={<MdCreditCard size={25} />}
              {...a11yProps(5)}
            />
            <TabContent
              label="Push"
              icon={<MdNotifications size={25} />}
              {...a11yProps(6)}
            />
            <TabContent
              label="Extrato"
              icon={<MdBook size={25} />}
              {...a11yProps(7)}
            />
            <TabContent
              label="Histórico de Alteração"
              icon={<FaRegListAlt size={25} />}
              {...a11yProps(8)}
            />
            <TabContent
              style={{ marginLeft: '40px' }}
              label="Histórico de Titularidade"
              icon={<AiOutlinePartition size={25} />}
              {...a11yProps(9)}
            />
            <TabContent
              label="Log"
              icon={<MdHistory size={25} />}
              {...a11yProps(10)}
            />
          </Tabs>

          {/* Conteudo das abas */}
          <Content>
            <TabPanel value={activeTab} index={0}>
              <PurchaseList person={activeTab === 0 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <PixTransaction person={activeTab === 1 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <BankSlip person={activeTab === 2 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <PostOfficeList person={activeTab === 3 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <Coupons person={activeTab === 4 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={5}>
              <CardList person={activeTab === 5 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={6}>
              <Push person={activeTab === 6 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={7}>
              <ExtractList person={activeTab === 7 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={8}>
              <HistoricAddress person={activeTab === 8 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={9}>
              <OwnershipHistory person={activeTab === 9 ? person : null} />
            </TabPanel>
            <TabPanel value={activeTab} index={10}>
              <LogPerson person={activeTab === 10 ? person : null} />
            </TabPanel>
          </Content>
        </AccessControl>
        {/* Dialog para Justificar bloqueio */}
        {action && (
          <DialogReason
            open={openDialog}
            toggleDialog={handleLockPerson}
            person={person}
            action={action}
          />
        )}

        <DialogTag
          open={openTagDialog}
          toggleDialog={handleModalTag}
          person={person}
        />
        <DialogCallContact
          open={openCallContactDialog}
          toggleDialog={handleModalCallContact}
          person={person}
        />
        <CallContactPopover
          open={open}
          ultimoContato={person.ultimoContato}
          handlePopoverClose={handlePopoverClose}
          anchorEl={anchorEl}
        />
        <Dialog
          open={openModalSaldo}
          onClose={() => setOpenModalSaldo(prev => !prev)}
          aria-labelledby="modal-saldo"
        >
          <Grid container spacing={2} style={{ padding: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!loadingSaldo && (
                <TextFieldLarge
                  InputProps={{
                    readOnly: true,
                    inputComponent: TextMaskCurrency,
                  }}
                  name="saldo"
                  id="saldo"
                  label="Saldo"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  value={saldo}
                />
              )}
              {loadingSaldo && <CircularProgress size={30} />}
            </Grid>
          </Grid>
        </Dialog>

        {/* modal promocao */}
        {openModalPromo && (
          <PromoModal
            person={person}
            onClose={() => setOpenModalPromo(false)}
          />
        )}

        {/* modal notificação */}
        {openNotificationOptIn && (
          <NotificationModal
            person={person}
            onClose={() => setOpenNotificationOptIn(false)}
          />
        )}
      </>
    </AccessControl>
  ) : (
    renderLoading()
  );
}

PersonView.defaultProps = {
  person: null,
};

PersonView.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};
