import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { Grid, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { isObject } from 'lodash';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { TextMaskCEP, TextMaskDDD } from '~/components/masks';
import Input from '~/components/unform/v2/InputText';
import api from '~/services/api';

const schema = Yup.object().shape({
  ddd: Yup.string(),
  cepRangeInicio: Yup.string()
    .min(9, 'No mínimo 8 caracteres')
    .required('Campo obrigatório'),
  cepRangeFim: Yup.string()
    .min(9, 'No mínimo 8 caracteres')
    .required('Campo obrigatório'),
});

export default function FormDialog({
  children,
  product,
  title,
  onEndEvent,
  rangeProduct = {},
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async data => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      const { ddd } = data;
      let { cepRangeInicio, cepRangeFim } = data;
      cepRangeInicio = cepRangeInicio.replace(/[^\d]/g, '');
      cepRangeFim = cepRangeFim.replace(/[^\d]/g, '');
      const { idProduto } = product;
      const formRegionProduct = {
        ddd,
        idProduto,
        cepRangeInicio,
        cepRangeFim,
      };
      setLoading(true);

      if (isObject(rangeProduct)) {
        const { idProdutoCompraRegiao } = rangeProduct;
        await api.post(
          `/purchase-regions/${idProdutoCompraRegiao}`,
          formRegionProduct
        );
      } else {
        await api.post(`/purchase-regions`, formRegionProduct);
      }
      setLoading(false);
      handleClose();
      onEndEvent();
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div>
      <Button {...props} onClick={handleClickOpen}>
        {children}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={rangeProduct}>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <Input
                  InputProps={{
                    inputComponent: TextMaskDDD,
                  }}
                  name="ddd"
                  id="ddd"
                  label="DDD"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <Input
                  InputProps={{
                    inputComponent: TextMaskCEP,
                  }}
                  name="cepRangeInicio"
                  id="cepRangeInicio"
                  label="CEP Início"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <Input
                  InputProps={{
                    inputComponent: TextMaskCEP,
                  }}
                  name="cepRangeFim"
                  id="cepRangeFim"
                  label="CEP Fim"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              {!loading ? 'Salvar' : <CircularProgress size={20} />}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

FormDialog.defaultProps = {
  product: null,
  rangeProduct: null,
  title: PropTypes.string,
  onEndEvent: PropTypes.func,
};

FormDialog.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]),
  editRange: PropTypes.oneOfType([PropTypes.object]).isRequired,
  rangeProduct: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string,
  onEndEvent: PropTypes.func,
  children: PropTypes.node.isRequired,
};
