import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import useProducts from './useProducts';

export default function ProductAutocomplete({
  onChange,
  getOption,
  valueKey,
  label,
  value: defaultValue,
  variant,
  margin,
  disableClearable,
  disabled,
}) {
  const { products, loading } = useProducts();
  const [value, setValue] = useState(null);
  const rendered = useRef(false);

  useEffect(() => {
    // call on change
    if (
      !!value &&
      onChange &&
      typeof onChange === 'function' &&
      rendered.current
    ) {
      onChange({ target: { value: value && value[valueKey] } });
    }

    // empty value
    if (!value && rendered.current) {
      onChange({ target: { value: null } });
    }

    // -- set rendered
    if (!rendered.current) rendered.current = true;
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (defaultValue) {
      const stringValue = String(defaultValue);
      const selected =
        products.find(p => String(p[valueKey]) === stringValue) || null;
      if (selected !== undefined) setValue(selected);
    }
  }, [products, defaultValue, valueKey]);

  return (
    <Autocomplete
      disableClearable={disableClearable}
      options={products}
      loading={loading}
      disabled={disabled}
      getOptionLabel={getOption}
      value={value}
      onChange={(_, selectValue) => setValue(selectValue)}
      renderInput={params => (
        <TextField
          {...params}
          margin={margin}
          variant={variant}
          label={label}
        />
      )}
    />
  );
}

ProductAutocomplete.defaultProps = {
  onChange: () => {},
  getOption: opt => opt.titulo,
  valueKey: 'id',
  value: undefined,
  label: 'Filtrar por produto',
  variant: 'standard',
  margin: 'dense',
  disableClearable: false,
  disabled: false,
};

ProductAutocomplete.propTypes = {
  onChange: PropTypes.func,
  getOption: PropTypes.func,
  valueKey: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};
