import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';

const schema = Yup.object().shape({
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function DialogReason({ open, toggleDialog, person, purchase }) {
  const [loading, setLoading] = useState(false);

  const handleConfirmar = async ({ reason }) => {
    setLoading(true);
    try {
      if (hasPermission('refund_purchase')) {
        const response = await api.put(
          `/persons/${person.idCliente}/purchases/${purchase.idCompra}/refund`,
          { reason }
        );
        if (response) {
          purchase.reprocessar = false;
          purchase.estornar = false;
          purchase.status = false;
          purchase.cancelado = true;
        }
      }
    } finally {
      toggleDialog();
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <Form schema={schema} onSubmit={handleConfirmar}>
          <DialogTitle id="form-dialog-title">
            Motivo do cancelamento
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Justifique o motivo do cancelamento
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="reason"
              name="reason"
              label="Motivo"
              fullWidth
              multiline
              rowsMax="4"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog} color="secondary" disabled={loading}>
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

DialogReason.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  purchase: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
