import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import useInstitutions from './useInstitutions';

function InstitutionsAutocomplete({
  onChange,
  getOption,
  valueKey,
  label,
  value: defaultValue,
  margin,
  error,
  disabled,
}) {
  const { institutions, loading } = useInstitutions();
  const [value, setValue] = useState(null);
  const rendered = useRef(false);

  useEffect(() => {
    // call on change
    if (
      !!value &&
      onChange &&
      typeof onChange === 'function' &&
      rendered.current
    ) {
      onChange({ target: { value: value && value[valueKey] } });
    }

    // empty value
    if (!value && rendered.current) {
      onChange({ target: { value: null } });
    }

    // -- set rendered
    if (!rendered.current) rendered.current = true;
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (defaultValue) {
      const stringValue = String(defaultValue);
      const selected =
        institutions.find(p => String(p[valueKey]) === stringValue) || null;
      if (selected !== undefined) setValue(selected);
    }
  }, [institutions, defaultValue, valueKey]);

  return (
    <Autocomplete
      disabled={disabled}
      options={institutions}
      loading={loading}
      getOptionLabel={getOption}
      value={value}
      onChange={(_, selectValue) => setValue(selectValue)}
      renderInput={params => (
        <TextField
          variant="outlined"
          margin={margin}
          {...params}
          error={!!error || !!params.error}
          helperText={error || params.helperText}
          label={label}
        />
      )}
    />
  );
}

InstitutionsAutocomplete.defaultProps = {
  onChange: () => {},
  getOption: opt => opt.titulo,
  valueKey: 'id',
  value: undefined,
  label: 'Selecione a Instituição',
  margin: 'normal',
  error: null,
};

InstitutionsAutocomplete.propTypes = {
  onChange: PropTypes.func,
  getOption: PropTypes.func,
  valueKey: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  margin: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default memo(InstitutionsAutocomplete);
