import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

export default function TextMaskTime(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
      placeholderChar="_"
      showMask={false}
    />
  );
}

TextMaskTime.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
