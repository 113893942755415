import styled from 'styled-components';
import { Button as ButtonReset } from '@material-ui/core';

export const Button = styled(ButtonReset)`
  &:active {
    background-color: #00008b !important;
  }
  &:focus {
    background-color: #00008b !important;
  }
`;
