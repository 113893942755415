import React, { useState, useEffect } from 'react';

import {
  Dialog,
  TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { AlignTitles } from './styles';
import Products from '../FaqList/products';
import { hasPermission } from '~/components/AccessControl';

const noop = () => {};

function ChangeAnswerDialog({
  isOpen = false,
  product,
  answer,
  question,
  onClose = noop,
  faq,
  onUpdate,
}) {
  const [pergunta, SetPergunta] = useState(question);
  const [resposta, SetResposta] = useState(answer);
  const [produtos, setProdutos] = useState([product]);
  const [loading, setLoading] = useState(false);
  const [onButton, setOnButton] = useState(false);

  useEffect(() => {
    setProdutos(product);
  }, [product]);

  const handleSubmit = async () => {
    if (hasPermission('update_faq')) {
      setLoading(true);
      setOnButton(true);

      await api
        .put(`/faqs/${faq.faq.idFaq}`, {
          pergunta,
          resposta,
          produtos,
        })
        .finally(() => {
          onUpdate();
          setLoading(false);
          setOnButton(false);
        });
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={(_, reason) => {
        if (reason === 'backdropClick') onClose();
      }}
    >
      <DialogTitle>
        <AlignTitles>
          <h5>Alterar</h5>
          <h5>Pergunta {faq.ordem}</h5>
        </AlignTitles>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Pergunta:</DialogContentText>
        <TextField
          id="outlined-basic"
          label={null}
          variant="outlined"
          defaultValue={question}
          onChange={event => {
            SetPergunta(event.target.value);
          }}
        />
        <DialogContentText>Resposta:</DialogContentText>
        <TextField
          id="outlined-basic"
          label={null}
          multiline
          rows={6}
          variant="outlined"
          defaultValue={answer}
          onChange={event => {
            SetResposta(event.target.value);
          }}
        />

        <Products
          onCatchProducts={value => {
            setProdutos(value);
          }}
          value={produtos}
          onChange={selectedProducts => {
            setProdutos(selectedProducts);
          }}
        />

        <Grid align="end" style={{ padding: 20 }}>
          <Button disabled={onButton} size="normal" onClick={onClose}>
            Cancelar
          </Button>

          <Button
            disabled={onButton}
            size="normal"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress color="white" size={24} /> : 'Salvar'}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

ChangeAnswerDialog.propTypes = {
  faq: PropTypes.number.isRequired,
  product: PropTypes.number.isRequired,
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ChangeAnswerDialog;
