import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { TextField } from 'unform-material-ui';
import { isAfter, parseISO } from 'date-fns';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import { ButtonChargeBack } from './styles';
import api from '~/services/api';

const schema = Yup.object().shape({
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function ChargeBackButton({ item, person, getCoupons }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyNumTitulo, setVerifyNumTtulo] = useState();

  const handleOpenChargeBack = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const refundThroughConfirmation = useCallback(
    async reason => {
      setLoading(true);
      if (
        parseInt(verifyNumTitulo, 10) === item.titulos[0].numTitulo &&
        person &&
        item
      ) {
        await api.post(
          `/persons/${person.idCliente}/coupons/${item.idTituloPromocao}/cancel`,

          reason
        );

        getCoupons();
        setOpen(false);
      }
      setLoading(false);
    },
    [person, item, getCoupons, verifyNumTitulo]
  );
  return (
    <Grid>
      <ButtonChargeBack
        onClick={handleOpenChargeBack}
        disabled={
          item.promocao.produto.codigo === 'ESTORNO' ||
          isAfter(new Date(), parseISO(item.promocao.dataSorteioPrincipal))
        }
        variant="contained"
        size="small"
      >
        ESTORNAR CUPOM
      </ButtonChargeBack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Estornar Cupom</DialogTitle>
        <Form
          context={{ item }}
          schema={schema}
          onSubmit={refundThroughConfirmation}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja executar essa ação? Este processo é
              irreversível.
            </DialogContentText>
            <div>
              <DialogContentText style={{ fontSize: 16, marginTop: 15 }}>
                Número do Cupom:
              </DialogContentText>
              <span>
                <DialogContentText type="button" className="titulo">
                  {item?.titulos[0]?.numTitulo}
                </DialogContentText>
                <DialogContentText
                  style={{ fontSize: 16, marginTop: 15 }}
                  id="alert-dialog-description"
                >
                  Digite o número do cupom para confirmar o estorno
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="numTitulo"
                  name="numTitulo"
                  label="Numero do cupom"
                  fullWidth
                  multiline
                  maxRows={4}
                  onKeyUp={e => setVerifyNumTtulo(e.target.value)}
                />
              </span>
            </div>
            <DialogContentText
              style={{ fontSize: 16, marginTop: 15 }}
              id="alert-dialog-description"
            >
              Justifique o motivo para o estorno
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              name="reason"
              label="Motivo"
              fullWidth
              multiline
              maxRows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ color: '#DD5A66' }} onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              disabled={
                parseInt(verifyNumTitulo, 10) !== item?.titulos[0]?.numTitulo
              }
              type="submit"
              style={{ color: '#4153AF' }}
              autoFocus
            >
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Grid>
  );
}

ChargeBackButton.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getCoupons: PropTypes.oneOfType([PropTypes.func]).isRequired,
};
