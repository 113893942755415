import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { MdEdit, MdThumbUp, MdThumbDown } from 'react-icons/md';

import { FeedbackActions } from './styles';

export default function FeedbackDetails({
  titulo,
  descricao,
  loading,
  canEdit,
  isDisapproved,
  isApproved,
  onEdit,
  onApprove,
  onDisapprove,
}) {
  return (
    <>
      {/* Display Feedback data */}
      {titulo && (
        <Typography variant="h3" onDoubleClick={onEdit}>
          {titulo}
        </Typography>
      )}

      {descricao && <Typography onDoubleClick={onEdit}>{descricao}</Typography>}

      <FeedbackActions>
        {canEdit && (
          <Button color="default" onClick={onEdit}>
            <MdEdit />
            &nbsp;Editar
          </Button>
        )}

        {canEdit && isDisapproved && (
          <Button color="primary" onClick={onApprove}>
            {!loading && (
              <>
                <MdThumbUp />
                &nbsp;Ativar
              </>
            )}

            {loading && <span>Alterando...</span>}
          </Button>
        )}

        {canEdit && isApproved && (
          <Button color="secondary" onClick={onDisapprove}>
            {!loading && (
              <>
                <MdThumbDown />
                &nbsp;Inativar
              </>
            )}

            {loading && <span>Alterando...</span>}
          </Button>
        )}
      </FeedbackActions>
    </>
  );
}

FeedbackDetails.defaultProps = {
  titulo: '',
  descricao: '',
  loading: false,
  canEdit: false,
  onEdit: () => {},
  onApprove: () => {},
  onDisapprove: () => {},
};

FeedbackDetails.propTypes = {
  titulo: PropTypes.string,
  descricao: PropTypes.string,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool,
  isDisapproved: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onApprove: PropTypes.func,
  onDisapprove: PropTypes.func,
};
