/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Form } from '@rocketseat/unform';
import { Grid, Button, CircularProgress, Switch } from '@material-ui/core';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { AccessControl } from '~/components';
import api from '~/services/api';
import { Content } from '~/styles/global';
import { hasPermission } from '~/components/AccessControl';
import { store } from '~/store';
import { Table } from '../List/styles';

// sign of the function compoment
export default function RoleForm({ role, setRole, permissions }) {
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState();
  const [rolePermissions, setRolePermissions] = useState([]);

  const { token } = store.getState().auth;

  // Yup form schema validation
  const schema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    permissions: Yup.string().test(
      'has-permission',
      'Selecionar ao menos uma permissão',
      () => rolePermissions.length
    ),
  });

  // Handle of switch click toggle
  const handleToggle = value => () => {
    const currentIndex = rolePermissions.indexOf(value);
    const newChecked = [...rolePermissions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // document.getElementById('permissions').value = newChecked;
    setRolePermissions(newChecked);
  };

  // Send form to api
  const handleSubmit = async ({ name, permissions: perms }, form) => {
    try {
      setLoading(true);
      const verb = role.roleId ? 'put' : 'post';
      if (
        (hasPermission('create_role') && verb === 'post') ||
        (hasPermission('update_role') && verb === 'put')
      ) {
        await api[verb](
          `/roles/${role.roleId || ''}`,
          {
            name,
            permissions: perms.split(','),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        form.resetForm();
        setRolePermissions([]);
        setRole(null);
      }
    } finally {
      setLoading(false);
    }
  };

  // Render switch options on view
  const renderPermissions = () => {
    return permissions.map(permission => (
      <tr key={permission.slug}>
        <td>{permission.name}</td>
        <td align="center">
          <Switch
            edge="end"
            onChange={handleToggle(permission.slug)}
            checked={rolePermissions.indexOf(permission.slug) !== -1}
          />
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    if (role.roleId) {
      const ps = permissions.filter(p =>
        role.roleAttributes?.permissions?.find(rp => rp === p.slug)
      );
      setRolePermissions(ps.map(p => p.slug));
      document.getElementById('name').value = role.roleAttributes.roleName;
      setInitialData({
        name: role.roleAttributes.roleName,
        permissions: role.roleAttributes.permissions,
      });
    }
  }, [role, permissions]);

  // Main Render
  return (
    <Content>
      <Form
        schema={schema}
        autoComplete="off"
        autoCapitalize="off"
        noValidate
        onSubmit={handleSubmit}
        initialData={initialData}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              id="name"
              label="Nome"
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="permissions"
              id="permissions"
              value={rolePermissions}
              type="hidden"
            />
            <Table>
              <thead>
                <tr>
                  <th>Ação</th>
                  <th align="center">Permitir ?</th>
                </tr>
              </thead>
              <tbody>{permissions && role ? renderPermissions() : ''}</tbody>
            </Table>
          </Grid>
        </Grid>

        {/* Botoes */}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4} align="right">
            <AccessControl can={['update_role', 'create_role']}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
              >
                {loading ? <CircularProgress size={24} /> : 'Salvar'}
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </Form>
    </Content>
  );
}

RoleForm.propTypes = {
  role: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setRole: PropTypes.func.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
