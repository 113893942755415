import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { hasPermission } from '~/components/AccessControl';

export const PromotionAllPermissionContext = createContext({
  can: () => {},
});

export const PromotionAllPermissionProvider = ({ children }) => {
  const can = useCallback(permission => {
    return hasPermission(permission, { toast: false });
  }, []);

  return (
    <PromotionAllPermissionContext.Provider value={{ can }}>
      {children}
    </PromotionAllPermissionContext.Provider>
  );
};

PromotionAllPermissionProvider.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line
};

export const usePromotionAllPermission = () => {
  const context = useContext(PromotionAllPermissionContext);
  return context;
};
