import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { Button } from '@material-ui/core';
import { TextField } from '@unform/material-ui';

import { ArchiveFieldContainer } from './styles';

const BannerFormArchiveField = ({ src = null, onChange }) => {
  const [imageSrc, setImageSrc] = useState(src);

  useUpdateEffect(() => {
    setImageSrc(src);
  }, [src]);

  const onDrop = useCallback(
    async files => {
      onChange(files[0]);
      setImageSrc(files[0]);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <TextField
        disabled
        name="sheet"
        label="Números capitalizadora"
        fullWidth
        variant="outlined"
        value={imageSrc?.name || null}
        InputLabelProps={{ shrink: true }}
      />
      <ArchiveFieldContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <Button
          size="small"
          variant="text"
          color="primary"
          disableRipple
          disableFocusRipple
        >
          {imageSrc ? 'Alterar' : 'Selecionar'}
        </Button>
      </ArchiveFieldContainer>
    </div>
  );
};

BannerFormArchiveField.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

BannerFormArchiveField.defaultProps = {
  src: null,
  title: '',
  onChange: () => {},
};

export default React.memo(BannerFormArchiveField);
