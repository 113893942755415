import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { MdNotifications } from 'react-icons/md';
import { Form } from '@unform/web';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { Content, Title } from '~/styles/global';
import api from '~/services/api';
import { Container } from './styles';
import { AccessControl } from '~/components';
import { hasPermission } from '~/components/AccessControl';
import NoRecord from '~/components/NoRecord';
import AutoComplete from '~/components/unform/v2/AutoComplete';
import Input from '~/components/unform/v2/InputText';

const schema = Yup.object().shape({
  application: Yup.string().required('Campo obrigatório'),
  title: Yup.string().required('Campo obrigatório'),
  message: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function Push({ person }) {
  const [applications, setApplications] = useState({ docs: [] });
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const getAplications = async () => {
    try {
      setLoading(true);
      if (hasPermission('send_push')) {
        const { data } =
          (await api.get(`/persons/${person.idCliente}/applications`)) || {};
        if (data) setApplications(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRedis = useCallback(
    async value => {
      const params = {
        appId: value.appId,
      };
      await api.get(`/persons/${person.idCliente}/applications/appVerify/`, {
        params,
      });
    },
    [person]
  );

  const handleSubmit = async data => {
    try {
      setLoading(true);
      if (hasPermission('send_push')) {
        const { status } =
          (await api.post(`/persons/${person.idCliente}/push`, data)) || {};
        if (status && status === 201) {
          formRef.current.clearField('application', '');
          formRef.current.setFieldValue('title', '');
          formRef.current.setFieldValue('message', '');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // TODO:
    if (person) getAplications();
    // eslint-disable-next-line
  }, [person]);

  return applications && applications.total ? (
    <>
      <Content>
        <Form
          ref={formRef}
          schema={schema}
          noValidate
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Container>
            <Container>
              <MdNotifications size="25" color="gray" />
              <Title className="title">&nbsp;Enviar Push</Title>
            </Container>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutoComplete
                  name="application"
                  onSelect={handleRedis}
                  options={applications.docs}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="title"
                  id="title"
                  label="Título do Push"
                  autoComplete="off"
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  margin="dense"
                  name="message"
                  id="message"
                  label="Mensagem"
                  variant="outlined"
                  rows={4}
                  fullWidth
                  multiline
                />
              </Grid>
            </Grid>

            {/* Botoes */}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={4} align="right">
                <AccessControl can="send_push">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    color="primary"
                  >
                    {loading ? <CircularProgress size={24} /> : 'Enviar'}
                  </Button>
                </AccessControl>
              </Grid>
            </Grid>
          </Container>
        </Form>
      </Content>
    </>
  ) : (
    <NoRecord message="Envio de push não disponível para essa pessoa." />
  );
}

Push.defaultProps = {
  person: null,
};

Push.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};
