import styled from 'styled-components';

export const LinkPerson = styled.span`
  color: #3f51b5;
  font-weight: 600;
  cursor: pointer;
`;

export const SubTitle = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
`;

export const Title = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
`;
