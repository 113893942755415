import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { format as formatDate, isValid, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaCopy, FaCheck } from 'react-icons/fa';
import { MdMenu, MdCancel } from 'react-icons/md';
import { ConfirmProvider } from 'material-ui-confirm';
import api from '~/services/api';
import { Table, ButtonGroup, LinhaDigitavel } from './styles';
import NoRecord from '~/components/NoRecord';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';

import RefundDialog from '../Dialog';

let timeCopy;

export default function List({ person }) {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [copyText, setCopyText] = useState('');
  const textAreaRef = useRef(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedBankSlip, setSelectedBankSlip] = useState(null);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const loadCards = async () => {
    setLoading(true);
    try {
      if (hasPermission('read_bankslips')) {
        const { data } =
          (await api.get(
            `/persons/${person.idCliente}/bank-slips?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`
          )) || {};
        if (data) {
          setCards(data);
          const { limit, page, pages, total } = data;
          setPagination({
            ...{ limit, page, pages, totals: total },
          });
        }
        // set paginator.
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (person) loadCards();
    // TODO:
    // eslint-disable-next-line
  }, [
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  function copyToClipboard(value) {
    const ld = String(value).replace(/[^\d]/g, '');
    setCopyText(ld);
  }

  useEffect(() => {
    if (timeCopy) clearTimeout(timeCopy);
    if (copyText) {
      textAreaRef.current.select();
      document.execCommand('copy');
      // This is just personal preference.
      // I prefer to not show the whole text area selected.
      setCopySuccess('Copiado!');
      timeCopy = setTimeout(() => {
        setCopySuccess('');
      }, 2000);
    }
    return () => {
      if (timeCopy) clearTimeout(timeCopy);
    };
  }, [copyText]);

  /**
   * Set required values in the menu selected.
   */
  function handleClickMenu(event, bankslip) {
    setAnchorElMenu(event.currentTarget);
    setSelectedBankSlip(bankslip);
  }

  /**
   * Close the menu.
   */
  function handleCloseMenu() {
    setAnchorElMenu(null);
  }

  /**
   * Function to open or close dialog for process to refund.
   */
  const handleRefundDialog = async () => {
    setOpenRefundDialog(!openRefundDialog);
    if (!openRefundDialog) {
      handleCloseMenu();
    }
  };

  /**
   * Deals with rendering bank slips.
   */
  // eslint-disable-next-line consistent-return
  const renderSituations = bankslip => {
    if (bankslip.voidedDate) {
      return (
        <Typography color="secondary" style={{ color: 'red' }}>
          Estornado
        </Typography>
      );
    }
    if (bankslip.situacao === 'pago') {
      return <Typography color="primary">Pago</Typography>;
    }
    if (bankslip.situacao === 'pendente') {
      return <Typography color="textSecondary">Pendente</Typography>;
    }
    if (bankslip.situacao === 'vencido') {
      return (
        <Typography color="secondary" style={{ color: 'red' }}>
          Vencido
        </Typography>
      );
    }
    if (bankslip.situacao === 'cancelado') {
      return (
        <Typography color="secondary" style={{ color: 'red' }}>
          Cancelado
        </Typography>
      );
    }
  };

  /**
   * Const with the necessary values to render menu.
   */
  const renderOptionsMenu = () => {
    // Check if the values of bank slip was assigned.
    if (!person || !selectedBankSlip) return '';
    // Mount and return the menu item according to 'voidedDate'.
    return !selectedBankSlip.voidedDate ? (
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleRefundDialog()}>
          <MdCancel size={25} />
          &nbsp;Estornar
        </MenuItem>
      </Menu>
    ) : null;
  };

  /**
   * List bank slips.
   */
  if (cards && cards.docs && cards.docs.length > 0) {
    const getDataNotificacao = card => {
      const date = card.dataNotificacao;
      if (!!date && isValid(parseISO(date)))
        return formatDate(parseISO(date), 'dd/MM/yyyy HH:mm', {
          locale: ptBR,
        });
      return '-';
    };
    return (
      <>
        {renderOptionsMenu()}
        {loading ? <CircularProgress size={24} /> : ''}
        <Table>
          <thead>
            <tr>
              <th>Data Geração</th>
              <th>Situação</th>
              <th>Linha digitável</th>
              <th>Data de Vencimento</th>
              <th>Data de Notificação</th>
              <th>Tipo</th>
              <th style={{ textAlign: 'right' }}>Valor</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {cards.docs.map(card => (
              <tr key={card.id}>
                <td>
                  {formatDate(parseISO(card.dataInsert), 'dd/MM/yyyy HH:mm', {
                    locale: ptBR,
                  })}
                </td>
                <td style={{ textTransform: 'capitalize' }}>
                  {renderSituations(card)}
                </td>
                <td>
                  <LinhaDigitavel>
                    {card.linhaDigitavel}
                    <ButtonGroup>
                      {document.queryCommandSupported('copy') &&
                        ((card.linhaDigitavel && !copySuccess) ||
                          (card.linhaDigitavel &&
                            copyText !==
                              String(card.linhaDigitavel).replace(
                                /[^\d]/g,
                                ''
                              ))) && (
                          <FaCopy
                            size={20}
                            color="#3f51b5"
                            title="Copiar linha digitável"
                            onClick={() => copyToClipboard(card.linhaDigitavel)}
                          />
                        )}
                      {copyText ===
                        String(card.linhaDigitavel).replace(/[^\d]/g, '') &&
                        copySuccess && <FaCheck size={20} color="green" />}
                    </ButtonGroup>
                  </LinhaDigitavel>
                </td>
                <td>
                  {formatDate(
                    parseISO(card.dataVencimento),
                    'dd/MM/yyyy HH:mm',
                    { locale: ptBR }
                  )}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {getDataNotificacao(card)}
                </td>
                <td style={{ textTransform: 'capitalize' }}>{card.tipo}</td>
                <td style={{ textAlign: 'right' }}>
                  R${' '}
                  {String(parseFloat(card.valor).toFixed(2)).replace('.', ',')}
                </td>
                <td>
                  <MdMenu
                    size={30}
                    onClick={event => handleClickMenu(event, card)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}

        <textarea
          ref={textAreaRef}
          defaultValue={copyText}
          style={{ display: 'block', opacity: 0, width: 0, height: 0 }}
        />
        {/* Popup to refund bnk slip */}
        {selectedBankSlip && person && (
          <ConfirmProvider>
            <RefundDialog
              open={openRefundDialog}
              toggleDialog={handleRefundDialog}
              person={person}
              bankslip={selectedBankSlip}
              loadingList={setLoading}
            />
          </ConfirmProvider>
        )}
      </>
    );
  }
  return (
    <>
      {!loading && (!cards || !cards.docs || !cards.docs.length) && (
        <NoRecord message="Nenhum boleto disponível para consulta" />
      )}
    </>
  );
}

List.defaultProps = {
  person: null,
};

List.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};
