import { useEffect, useState, useCallback } from 'react';

import api from '~/services/api';

export default function usePush() {
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .get('/pushs/applications')
      .then(res => {
        if (res && res.data) {
          const { docs } = res.data;
          setApplications(docs);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const createPush = useCallback(payload => {
    setCreating(true);

    return api.post('/pushs', payload).finally(() => setCreating(false));
  }, []);

  return { applications, loading, creating, createPush };
}
