/**
 * return float 2 decimals.
 *
 * @returns {float}
 */
export function decimalFormat(value) {
  return Number.parseFloat(value)
    .toFixed(2)
    .replace('.', ',');
}

export function brlToNumber(value) {
  return Number.parseFloat(value.replace('.', '').replace(',', '.')).toFixed(2);
}

export function formatNumberTicketImmadiate(string) {
  const value = `000000${string}`.slice(-6);
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function formatNumberTicket(numero, grouped = false) {
  numero = String(numero)
    .replace(/\D+/g, '')
    .padStart(6, '0');

  if (grouped) {
    const isOdd = numero.length % 2 > 0;
    if (isOdd) {
      numero = numero.padStart(numero.length + 1, '0');
    }
    return numero.match(/.{1,2}/g).join(' ');
  }
  return String(
    Number(numero)
      .toLocaleString()
      .replace(/,+/g, '.')
  ).padStart(7, '0');
}

export const toNumber = str => {
  return parseInt(String(str).replace(/\D+/g, ''), 10) || 0;
};
