import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { Button } from '@material-ui/core';
import { MdSave } from 'react-icons/md';

import {
  FeedbackActions,
  FeedbackFormInput,
  FeedbackFormTextarea,
} from './styles';

export default function FeedbackEditForm({
  titulo,
  descricao,
  loading,
  onSave,
  onCancel,
}) {
  function handleSubmit(data) {
    if (onSave && typeof onSave === 'function') {
      onSave(data);
    }
  }

  return (
    <Form onSubmit={handleSubmit} initialData={{ titulo, descricao }}>
      <FeedbackFormInput
        name="titulo"
        placeholder="Digite o titulo do feedback aqui..."
      />

      <FeedbackFormTextarea
        name="descricao"
        placeholder="Digite a descrição do feedback..."
      />

      <FeedbackActions>
        <Button size="small" color="primary" variant="contained" type="submit">
          {!loading && (
            <>
              <MdSave /> Salvar
            </>
          )}

          {loading && <span>Salvando...</span>}
        </Button>

        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={onCancel}
          style={{ marginLeft: 10 }}
        >
          Cancelar
        </Button>
      </FeedbackActions>
    </Form>
  );
}

FeedbackEditForm.defaultProps = {
  loading: false,
  onSave: () => {},
  onCancel: () => {},
};

FeedbackEditForm.propTypes = {
  titulo: PropTypes.string.isRequired,
  descricao: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
