import styled from 'styled-components';

export const Title = styled.span`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
`;

export const Subtitle = styled.span`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;
