import { useState, useEffect } from 'react';

import api from '~/services/api';

export default function useProducts() {
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .get('/reasons')
      .then(({ data }) => {
        setReasons(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return { reasons, loading };
}
