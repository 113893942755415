import styled, { css } from 'styled-components';
import { Form } from '@unform/web';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  padding: 30px;
  grid-gap: 30px;
`;

export const FormContainer = styled(Form)``;

export const SectionTitle = styled.h1`
  margin: 0px;
  padding: 0px;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
  align-items: center;
  display: flex;

  &:not(:first-child) {
    margin-top: 52px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 20px;

  ${({ cols }) =>
    cols &&
    css`
      grid-template-columns: repeat(${cols}, 1fr);
    `}

  & + & {
    margin-top: 15px;
  }
`;

export const Col = styled.div`
  .MuiFormControl-root + .MuiFormControl-root {
    margin-top: 15px;
  }
`;

export const FormGroupFile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  span {
    margin-top: 10px;
  }
`;

export const FormGroup = styled.div`
  margin: 10px 0px;
  border: solid 0px #000;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  flex: 1;
  margin-top: 10px;
`;
