import React, { useState } from 'react';
import { Menu, MenuItem as MenuItemBase } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import { statusDescriptionMap } from '../TooltipStatusDescription';
import { useScreening } from '../../ScreeningItem/context';

const MenuItem = styled(MenuItemBase)`
  && {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    span.status {
      font-size: 14px;
      font-weight: bold;
    }

    span.description {
      font-size: 12px;
      font-style: italic;
    }
  }
`;

function ChangeStatusMenu({ onChange }) {
  const [el, setEl] = useState(null);
  const screening = useScreening();

  const handleChange = React.useCallback(
    newStatus => {
      return () => {
        if (typeof onChange === 'function') onChange(newStatus);
        screening.changeStatus(newStatus);
        setEl(null);
      };
    },
    [screening, onChange]
  );

  const shouldDisableStatus = React.useCallback(
    status => {
      if (screening.situacao === 'entregue') return true;

      if (status === 'entregue') {
        const { urlComprovanteEnd, urlDoc } = screening;
        return !urlComprovanteEnd || !urlDoc;
      }
      return false;
    },
    [screening]
  );

  return (
    <>
      <IconButton
        disabled={screening.loading || screening.situacao === 'entregue'}
        iconName="edit"
        onClick={evt => setEl(evt.currentTarget)}
      >
        Alterar
        <br />
        Situação
      </IconButton>
      <Menu anchorEl={el} open={Boolean(el)} onClose={() => setEl(null)}>
        {[...statusDescriptionMap].map(([status, description]) => (
          <MenuItem
            key={`status-${status}`}
            onClick={handleChange(status)}
            disabled={shouldDisableStatus(status)}
          >
            <span className="status">{String(status).toUpperCase()}</span>
            <span className="description">{description}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ChangeStatusMenu.propTypes = {
  onChange: PropTypes.func,
};

ChangeStatusMenu.defaultProps = {
  onChange: () => {},
};

export default ChangeStatusMenu;
