import React from 'react';
import PropTypes from 'prop-types';
import { Icon, CircularProgress } from '@material-ui/core';

import { Container } from './styles';

function IconButton({ iconName, children, disabled, loading, ...props }) {
  return (
    <Container {...props} disabled={disabled || loading}>
      {!loading && <Icon>{iconName}</Icon>}
      {loading && <CircularProgress size={32} />}
      {children}
    </Container>
  );
}

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

IconButton.defaultProps = {
  loading: false,
  disabled: false,
};

export default IconButton;
