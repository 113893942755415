import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const PromotionContext = createContext({
  setPromotion: () => {},
  promotion: {},
  sweepstakes: {},
  ebooks: {},
  setSweepstakes: () => {},
  onUpdateWinner: () => {},
  loading: false,
  handleUpdateStatus: () => {},
  productConfig: {},
  replacePromotion: () => {},
  savePromotion: () => {},
  saving: false,
  changeRegulation: () => {},
  updatingRegulation: false,
  movePromotionSweepstake: () => {},
  sweepstakeTypeFiltered: [],
  appendSweepstakeTypeFilter: () => {},
  toggleSweepstakeTypeFilter: () => {},
  setSweepstakeTypeFiltered: () => {},
});

export function PromotionContextProvider({ children, ...props }) {
  return (
    <PromotionContext.Provider {...props}>
      {React.Children.toArray(children)}
    </PromotionContext.Provider>
  );
}

export function usePromotionContext() {
  const context = useContext(PromotionContext);
  return context;
}

PromotionContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
