import React, { useCallback } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import { useBannerForm } from '../Form';
import { Container } from './styles';

const AddBannerButton = () => {
  const { addNewBanner } = useBannerForm();
  const onDropImage = useCallback(
    files => {
      addNewBanner({ bannerImage: files[0] || null });
    },
    [addNewBanner]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropImage,
    accept: 'image/*',
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton size="medium" disableRipple>
        <FaPlusCircle />
      </IconButton>
      <span className="label">Adicionar</span>
    </Container>
  );
};

export default AddBannerButton;
