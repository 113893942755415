import styled from 'styled-components';
import { DialogContent } from '@material-ui/core';

export const Container = styled.div``;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 20px;
  padding: 0px;
  margin: 0px 0px 20px 0px;
`;

export const TitleMgTop = styled.h1`
  font-weight: 300;
  font-size: 20px;
  padding: 0px;
  margin: 10px 0px 20px 0px;
`;

export const SectionTitle = styled.h1`
  font-weight: 500;
  font-size: 16px;
  padding: 0px;
  margin: 20px 0px 7px 0px;

  > small {
    font-size: 11px;
    color: #999;
    font-weight: normal !important;
  }
`;

export const Content = styled(DialogContent)`
  && {
    display: flex;
    flex-direction: column;

    .MuiTable-root {
      border-collapse: initial;

      tr td:first-child {
        padding-left: 0px;
      }

      tr td:last-child {
        padding-right: 0px;
      }
    }
  }
`;
