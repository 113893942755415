import React from 'react';
import { Grid } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

export default function PromotionCodeContent({ ...promotionCode }) {
  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <h4>ID do Cupom</h4>
      </Grid>

      <Grid item xs={12} md={3}>
        <h4>Mensagem de sucesso</h4>
      </Grid>

      <Grid item xs={12} md={3}>
        <h4>Mensagem de erro</h4>
      </Grid>

      <Grid item xs={12} md={4}>
        <h4>Restrições</h4>
      </Grid>

      <Grid item xs={12} md={2}>
        {promotionCode.idCodigoPromocional}
      </Grid>

      <Grid item xs={12} md={3}>
        {promotionCode.mensagemRetorno}
      </Grid>

      <Grid item xs={12} md={3}>
        {promotionCode.mensagemRegraValidacao}
      </Grid>

      <Grid item xs={12} md={4}>
        {promotionCode.validacao &&
          promotionCode.validacao.validacaoProduto &&
          `${promotionCode.validacao.validacaoProduto.produto.nome} - ${promotionCode.validacao.validacaoProduto.quantidade} unidade(s)`}
        <br />
        {promotionCode.validacao &&
        promotionCode.validacao.dataHoraInicio &&
        promotionCode.validacao.dataHoraFim
          ? `De ${format(
              parseISO(promotionCode.validacao.dataHoraInicio),
              'dd/MM/yyyy HH:mm'
            )} até ${format(
              parseISO(promotionCode.validacao.dataHoraFim),
              'dd/MM/yyyy HH:mm'
            )}`
          : ''}
      </Grid>
    </Grid>
  );
}
