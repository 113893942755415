import React from 'react';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import history from '~/services/history';
// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';

import {
  signInFirstSuccess,
  signFailure,
  signInSuccess,
  resetPassword,
} from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const { data } = yield call(api.get, '/mfa');

    const url = data ? '/auth/pre-two-factor' : '/auth';

    const response = yield call(api.post, url, {
      email,
      password,
    });

    if (response?.data?.user?.allowApiUsage) {
      yield put(signFailure());
      toast.warn(<Toast message="Usuário não autorizado." />);
      throw new Error();
    }

    if (response && response.data && response.data.user) {
      const { user, token } = response.data;

      if (!user || !token) {
        yield put(signFailure());
      }

      if (!data) {
        yield put(signInSuccess(token, user));

        api.defaults.headers.Authorization = `Bearer ${token}`;

        history.push('/');
      } else {
        yield put(signInFirstSuccess(user, token));
      }
    } else if (response && response.data && response.data.resetPassword) {
      yield put(resetPassword({ email }));
    } else {
      // Forçando a cair no catch caso o login não seja bem sucedido.
      // Sem ele, o reducer "@auth/SIGN_FAILURE" não é chamado
      // e o loading do botão de login nao desaparece ao fim da requisição
      throw new Error();
    }
  } catch (error) {
    yield put(signFailure());
  }
}

export function* signInFinal({ payload }) {
  try {
    const { pin, user, token: payloadToken, password } = payload;

    const response = yield call(
      api.post,
      '/two-factor-authentication/validate-pin',
      { pin, email: user.email, password },
      {
        headers: { Authorization: `Bearer ${payloadToken}` },
      }
    );

    if (response && response.data) {
      const { token } = response.data;

      yield put(signInSuccess(token, user));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      history.push('/');
    } else {
      yield put(signFailure());
    }
  } catch (error) {
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/login');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_FINAL', signInFinal),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
