import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import { parseISO } from 'date-fns';

import IconButton from '../IconButton';
import { HistoryContainer, HistoryItem } from './styles';
import { useScreening } from '../../ScreeningItem/context';
import TooltipStatusDescription from '../TooltipStatusDescription';

function sortDesc([, a], [, b]) {
  return parseISO(b) - parseISO(a);
}

function HistoryPopoverButton() {
  const [el, setEl] = useState(null);
  const screening = useScreening();

  return (
    <>
      <IconButton
        iconName="history"
        onClick={evt => setEl(evt.currentTarget)}
        disabled={!screening.descricaoSituacao || screening.loading}
      >
        Histórico
        <br />
        de Situação
      </IconButton>
      <Popover
        anchorEl={el}
        open={Boolean(el)}
        onClose={() => setEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <HistoryContainer>
          {screening.descricaoSituacao && (
            <>
              {Object.entries(screening.descricaoSituacao)
                .sort(sortDesc)
                .map(([status, date]) => (
                  <HistoryItem key={`${screening.id}-history-${status}`}>
                    <span>
                      {status} <TooltipStatusDescription status={status} />
                    </span>
                    <span>{screening.parseDate(date)}</span>
                  </HistoryItem>
                ))}
            </>
          )}
        </HistoryContainer>
      </Popover>
    </>
  );
}

export default HistoryPopoverButton;
