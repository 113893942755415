import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableRow from '@material-ui/core/TableRow';

import TableContainer from '@material-ui/core/TableContainer';
import { debounce as debounceFn } from 'lodash';

import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { CircularProgress, Button, Typography } from '@material-ui/core';
import { format, isValid, parseISO } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MdEdit } from 'react-icons/md';
import {
  ProductAutocomplete,
  AccessControl,
  UnauthorizedGhost,
  IDatePicker,
  IDateTimePicker,
} from '~/components';

import { EnhancedTableHead } from './tableHead';
import useActivities from './useActivities';

import { Container, TitleTag } from '~/pages/_Layouts/Backoffice/styles';
import { FilterContainer } from '../PushNotification/List/styles';
import DialogTag from './Dialog';
import { hasPermission } from '~/components/AccessControl';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const { ...customPaginator } = useCustomPaginator();
  const {
    activities,
    loading,
    handleFilters,
    paginationHandle,
    renderFilters,
    handleRequestSort,
    order,
    orderBy,
    setActivities,
    handlePersonUpdate,
    setFilters,
    setPaginationHandle,
    setLoading,
    setPagination,
  } = useActivities(customPaginator);

  const classes = useStyles();
  const [dense, setDense] = React.useState(true);
  const [totalCartoes, setTotalCartoes] = React.useState('');
  const [optionsCartao, setOptionsCartao] = React.useState([]);
  const [optionsTag, setOptionsTag] = React.useState([]);
  const [optionsStatus, setOptionsStatus] = React.useState([]);
  const [optionsProduto, setOptionsProduto] = React.useState([]);
  const [optionsCalendarInitial, setOptionsCalendarInitial] = React.useState(
    false
  );
  const [optionsCalendarEnd, setOptionsCalendarEnd] = React.useState(false);
  const [optionsCalendarRegister, setOptionsCalendarRegister] = React.useState(
    false
  );
  const [amount, setAmount] = React.useState('');
  const [totalPurchases, setTotalPurchases] = React.useState('');
  const debounce = debounceFn((...args) => handleFilters(...args));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState();

  const clearAll = () => {
    setOptionsCartao([]);
    setOptionsTag([]);
    setOptionsStatus([]);
    setOptionsProduto([]);
    setActivities([]);
    setOptionsCalendarInitial(!optionsCalendarInitial);
    setOptionsCalendarEnd(!optionsCalendarEnd);
    setOptionsCalendarRegister(!optionsCalendarRegister);
    setAmount('');
    setTotalPurchases('');
    setTotalCartoes('');
    setFilters('');
    setPaginationHandle(false);
    setPagination({ page: 1, pages: 1, limit: 10, totals: 0 });
  };

  const canEditPerson = useMemo(
    () => hasPermission('update_person', { toast: false }),
    []
  );

  const handleFilterClick = () => {
    setLoading(true);
    handleFilters();
    renderFilters();
  };

  useEffect(() => {
    if (paginationHandle === true || order || orderBy) {
      renderFilters();
    }
  }, [renderFilters, order, orderBy, paginationHandle]);

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const handleModalTag = (activity, tag = null) => {
    setSelectedActivity(activity);
    setOpenDialog(!openDialog);
    if (tag) {
      activity.pessoa.tag = tag;
      setActivities([
        ...activities.filter(a => a.idCompra !== activity.idCompra),
        activity,
      ]);
    }
  };

  return (
    <AccessControl
      can="read_activities"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <>
        <Header>
          <h1 style={{ fontWeight: 400, fontSize: 26 }}>Atividades</h1>
          <div>
            <Button variant="text" color="secondary" onClick={clearAll}>
              Limpar filtros
            </Button>
            <Button
              onClick={handleFilterClick}
              color="primary"
              variant="contained"
            >
              Filtrar
            </Button>
          </div>
        </Header>

        <Container>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer>
                <FilterContainer>
                  <IDateTimePicker
                    clearCalendar={optionsCalendarInitial}
                    label="Data da Compra Inicial"
                    id="dpi-promocao"
                    onChange={date =>
                      isValid(date)
                        ? debounce({
                            beginPromotionPurchase: format(
                              date,
                              'yyyy-MM-dd HH:mm'
                            ),
                          })
                        : debounce({
                            beginPromotionPurchase: undefined,
                          })
                    }
                  />
                  <IDateTimePicker
                    clearCalendar={optionsCalendarEnd}
                    label="Data da Compra Final"
                    onChange={date =>
                      isValid(date)
                        ? debounce({
                            finalPromotionPurchase: format(
                              date,
                              'yyyy-MM-dd HH:mm'
                            ),
                          })
                        : debounce({
                            finalPromotionPurchase: undefined,
                          })
                    }
                  />

                  <IDatePicker
                    clearCalendar={optionsCalendarRegister}
                    label="Data de Cadastro a partir de"
                    onChange={date => {
                      return isValid(date)
                        ? debounce({
                            insertedSince: format(date, 'yyyy-MM-dd'),
                          })
                        : debounce({
                            insertedSince: undefined,
                          });
                    }}
                  />
                  <ProductAutocomplete
                    value={optionsProduto}
                    onChange={(e, opt) => {
                      debounce({ product: e.target.value });

                      setOptionsProduto(opt);
                    }}
                    label="Produto"
                  />

                  <Autocomplete
                    style={{ marginTop: 8 }}
                    size="small"
                    value={optionsCartao}
                    options={[
                      { value: 'debito', label: 'Débito' },
                      { value: 'credito', label: 'Crédito' },
                      { value: 'pix', label: 'Pix' },
                      { value: 'saldo', label: 'Saldo' },
                    ]}
                    getOptionLabel={opt => opt.label}
                    onChange={(_, opt) => {
                      debounce({
                        cardType: (opt && opt.value) || undefined,
                      });
                      setOptionsCartao(opt);
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Forma de pagamento" />
                    )}
                  />
                  <TextField
                    style={{ marginTop: 4 }}
                    label="Compras acima de R$"
                    type="number"
                    value={amount}
                    onChange={event => {
                      setAmount(event.target.value);
                      debounce({
                        amount: event.target.value || undefined,
                      });
                    }}
                  />
                  <TextField
                    label="Qtd de Transações acima de"
                    type="number"
                    value={totalPurchases}
                    onChange={event => {
                      debounce({
                        totalPurchases: event.target.value || undefined,
                      });
                      setTotalPurchases(event.target.value);
                    }}
                  />
                  <TextField
                    label="Qtd de cartões cadastrados"
                    type="number"
                    value={totalCartoes}
                    onChange={event => {
                      debounce({
                        totalCartoes: event.target.value || undefined,
                      });
                      setTotalCartoes(event.target.value);
                    }}
                  />
                  <Autocomplete
                    style={{ marginTop: 2 }}
                    size="small"
                    value={optionsTag}
                    options={[
                      {
                        value: 'Documentação confirmada',
                        label: 'Documentação confirmada',
                      },
                      { value: 'Suspeito', label: 'Suspeito' },
                      { value: 'Fraude', label: 'Fraude' },
                      { value: 'SEMTAG', label: 'Sem Tag' },
                    ]}
                    getOptionLabel={opt => opt.label}
                    onChange={(_, opt) => {
                      debounce({
                        tag: (opt && opt.value) || undefined,
                      });
                      setOptionsTag(opt);
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Tag" />
                    )}
                  />
                  <Autocomplete
                    value={optionsStatus}
                    size="small"
                    options={[
                      { value: 'ok', label: 'Aprovada' },
                      { value: 'cancel', label: 'Não Aprovada' },
                    ]}
                    getOptionLabel={opt => opt.label}
                    onChange={(_, opt) => {
                      debounce({
                        purchaseStatus: (opt && opt.value) || undefined,
                      });
                      setOptionsStatus(opt);
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Status" />
                    )}
                  />
                </FilterContainer>

                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={activities && activities.length}
                  />
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          align="center"
                          style={{ paddingTop: 10, paddingBottom: 10 }}
                        >
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {activities.map(row => (
                      <TableRow hover key={row.idCompra}>
                        <TableCell>
                          {canEditPerson && row.pessoa.cpf ? (
                            <Typography
                              style={{ cursor: 'pointer' }}
                              onClick={() => handlePersonUpdate(row)}
                            >
                              {row.pessoa.nome} <MdEdit />
                            </Typography>
                          ) : (
                            row.pessoa.nome
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {format(
                            parseISO(row.ultimaDataCompra),
                            'dd/MM/yyyy HH:mm'
                          )}
                        </TableCell>
                        <TableCell align="right">{row.totalCompras}</TableCell>
                        <TableCell align="right">{row.totalCartoes}</TableCell>
                        <TableCell>{row.produto.nome}</TableCell>
                        <TableCell align="right">
                          {parseFloat(row.valorTotalCompras).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {row.formaPagamento &&
                            row.formaPagamento.tipoCartao &&
                            row.formaPagamento.tipoCartao === 1 &&
                            'Débito'}
                          {row.formaPagamento &&
                            row.formaPagamento.tipoCartao &&
                            row.formaPagamento.tipoCartao === 2 &&
                            'Crédito'}
                          {row.idPix !== null && 'Pix'}
                          {row.idPdv === null &&
                            row.idPix === null &&
                            row.idFormaPagamento === null &&
                            'Saldo'}
                        </TableCell>
                        <TableCell>
                          <AccessControl can="tag_person">
                            <>
                              <TitleTag>
                                {row.pessoa.tag ? row.pessoa.tag : '-'}
                              </TitleTag>
                            </>
                          </AccessControl>
                          {!hasPermission('tag_person', { toast: false }) &&
                            row.pessoa.tag}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={dense}
                              onChange={handleChangeDense}
                            />
                          }
                          label="Compacto"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <CustomPaginator {...{ loading, ...customPaginator }} />
          </div>
        </Container>
        {selectedActivity && (
          <DialogTag
            open={openDialog}
            toggleDialog={handleModalTag}
            activity={selectedActivity}
          />
        )}
      </>
    </AccessControl>
  );
}
