import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { get, isNaN, toNumber } from 'lodash';
import { Form } from '@rocketseat/unform';
import { isValid, format } from 'date-fns';
import { useBannersHome } from '../context';
import {
  Container,
  FilterSection,
  FilterSectionContent,
  DateTimePicker,
} from './styles';

const BannerFilterBar = () => {
  const {
    colSize,
    setColSize,
    setDataInicial,
    setDataFinal,
    dataInicial,
    dataFinal,
    sendFilters,
  } = useBannersHome();

  const handleFilter = async () => {
    sendFilters({ dataInicio: dataInicial, dataFim: dataFinal });
  };

  return (
    <Container>
      <FilterSection>
        <h4>Filtros</h4>

        <Form onSubmit={handleFilter}>
          <FilterSectionContent>
            <DateTimePicker
              name="dataInicio"
              label="Data Início"
              format="dd/MM/yyyy"
              invalidDateMessage="Data inválida"
              value={dataInicial}
              onChange={date => {
                if (isValid(date)) {
                  setDataInicial(format(date, 'yyyy-MM-dd'));
                } else {
                  setDataInicial(undefined);
                }
              }}
            />
            <DateTimePicker
              name="dataFim"
              label="Data Fim"
              format="dd/MM/yyyy"
              invalidDateMessage="Data inválida"
              value={dataFinal}
              onChange={date => {
                if (isValid(date)) {
                  setDataFinal(format(date, 'yyyy-MM-dd'));
                } else {
                  setDataFinal(undefined);
                }
              }}
            />
            <Button type="submit" color="primary" variant="contained">
              Filtrar
            </Button>
          </FilterSectionContent>
        </Form>
      </FilterSection>

      <FilterSection>
        <h4>Layout</h4>

        <FilterSectionContent>
          <TextField
            type="number"
            label="Colunas"
            value={colSize}
            inputProps={{
              min: 3,
              max: 10,
            }}
            onChange={evt => {
              const cols = toNumber(get(evt, 'target.value', null));
              if (!isNaN(cols) && cols >= 3 && cols <= 10) setColSize(cols);
            }}
          />
        </FilterSectionContent>
      </FilterSection>
    </Container>
  );
};

export default BannerFilterBar;
