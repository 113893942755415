import React, { useCallback } from 'react';
import {
  TextField,
  Icon,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { first, get } from 'lodash';
import { parseISO, format } from 'date-fns';

import { hasPermission } from '../../../../../components/AccessControl';
import { CardLabelContent, UploadArea } from '../../components';
import {
  Container,
  LeftContainer,
  RightContainer,
  DataGridContainer,
  FormGridContainer,
  SectionTitle,
  LogContainer,
} from './styles';
import { useScreening } from '../context';
import addressFields from './addressFields';
import additionalInformations from './additionalInformations';

function ScreeningContent() {
  const screening = useScreening();

  const addFieldFile = useCallback(
    (field, file) => {
      screening.addFile(field, file);
      screening.save();
    },
    [screening]
  );

  const updateAddress = useCallback(
    (field, value) => {
      screening.updateAddress(field, value);
    },
    [screening]
  );

  const updateDadosCliente = useCallback(
    (field, value) => {
      screening.updateDadosCliente(field, value);
    },
    [screening]
  );

  const renderTicketNumber = () => {
    if (screening.ganhador && screening.ganhador.numTitulo) {
      return (
        <CardLabelContent label="Nº da Sorte">
          {screening.parseNumTitulo(screening.ganhador.numTitulo)}
        </CardLabelContent>
      );
    }
    if (screening.loadingTicketNumber) {
      return <CircularProgress />;
    }

    return (
      <>
        <CardLabelContent label="Nº da Sorte">
          {screening.parseNumTitulo(screening.ticketNumber)}
        </CardLabelContent>

        {screening.capitalizerNumber && (
          <CardLabelContent label="Nº da Capitalizadora">
            {screening.parseNumTitulo(screening.capitalizerNumber)}
          </CardLabelContent>
        )}
      </>
    );
  };

  return (
    <Container
      className="screening-content"
      loading={screening.loading ? 1 : 0}
    >
      <LeftContainer>
        <DataGridContainer>
          <CardLabelContent label="Prêmio">
            {screening.sorteio.descricao}
          </CardLabelContent>

          <CardLabelContent label="Edição">
            {screening.promocao.extracao}
          </CardLabelContent>
          {screening.winners > 1 && (
            <CardLabelContent label="Ganhadores">
              {screening.winners}
            </CardLabelContent>
          )}
          {renderTicketNumber()}
        </DataGridContainer>

        <SectionTitle>
          <Icon>chevron_right</Icon>
          Endereço de Entrega
        </SectionTitle>

        <FormGridContainer>
          {addressFields.map(addressField => (
            <CardLabelContent
              key={addressField.label}
              label={addressField.label}
              {...addressField.cardLabelProps}
            >
              <TextField
                fullWidth
                placeholder={addressField.placeholder}
                error={!!screening.addressErrors[addressField.name]}
                helperText={screening.addressErrors[addressField.name]}
                value={screening.address[addressField.name] || ''}
                onChange={e => updateAddress(addressField.name, e.target.value)}
                {...addressField.textFieldProps}
              />
            </CardLabelContent>
          ))}
        </FormGridContainer>

        <SectionTitle>
          <Icon>chevron_right</Icon>
          Informações Pessoais
        </SectionTitle>

        <FormGridContainer>
          {additionalInformations.map(info => (
            <CardLabelContent
              key={info.label}
              label={info.label}
              {...info.cardLabelProps}
            >
              {info.typeSelect ? (
                <Select
                  fullWidth
                  placeholder={info.placeholder}
                  error={!!screening.dadosClienteErrors[info.name]}
                  helperText={screening.dadosClienteErrors[info.name]}
                  onChange={e => updateDadosCliente(info.name, e.target.value)}
                  value={screening.dadosCliente[info.name] || ''}
                >
                  {info.typeSelect.options.map(option => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  fullWidth
                  placeholder={info.placeholder}
                  error={!!screening.dadosClienteErrors[info.name]}
                  helperText={screening.dadosClienteErrors[info.name]}
                  value={screening.dadosCliente[info.name] || ''}
                  onChange={e => updateDadosCliente(info.name, e.target.value)}
                  {...info.textFieldProps}
                />
              )}
            </CardLabelContent>
          ))}
        </FormGridContainer>

        <SectionTitle style={{ marginBottom: 0 }}>
          <Icon>chevron_right</Icon>
          Notas e observações
        </SectionTitle>

        <FormGridContainer>
          <CardLabelContent label="" className="notas">
            <TextField
              fullWidth
              multiline
              value={screening.notes}
              onChange={e => screening.updateNotes(e.target.value)}
              placeholder="Escreva aqui suas anotações..."
            />
          </CardLabelContent>
        </FormGridContainer>
      </LeftContainer>
      <RightContainer>
        <UploadArea
          label="Comprovante de Endereço"
          value={screening.urlComprovanteEnd}
          onChange={file => addFieldFile('compEndereco', file)}
        />
        <UploadArea
          label="Comprovante de Entrega"
          value={screening.urlExtra}
          onChange={file => addFieldFile('compEntrega', file)}
        />
        <UploadArea
          label="Cópia do Documento"
          value={screening.urlDoc}
          onChange={file => addFieldFile('compDocumento', file)}
        />

        <UploadArea
          label="Nota Fiscal"
          value={screening.urlNotaFiscal}
          onChange={file => addFieldFile('compNotaFiscal', file)}
        />
        <div style={{ flex: 1 }} />
        {!!screening.logs.length && hasPermission('read_logs') && (
          <LogContainer>
            <span className="last-updated-by">
              Última alteração em{' '}
              <strong>
                {format(
                  parseISO(get(first(screening.logs), 'createdAt')),
                  "dd/MM/yyyy 'às' HH'h'mm"
                )}
              </strong>{' '}
              por <strong>{get(first(screening.logs), 'user.name')}</strong>
            </span>
          </LogContainer>
        )}
      </RightContainer>
    </Container>
  );
}

export default ScreeningContent;