import React, { useCallback, useState, useEffect } from 'react';
import { FaLink, FaPlusCircle } from 'react-icons/fa';
import { CircularProgress, Button } from '@material-ui/core';
import api from '~/services/api';
import { isBoolean, isEmpty } from 'lodash';
import update from 'immutability-helper';
import { Section, SectionTitle, SectionContent } from './styles';
import { usePromotionContext } from '../../context';
import EbookItem from './EbookItem';
import NewEbook from '../../../Draft/Ebook/NewEbook/NewEbook';

import SelectEbook from '../../../Draft/Ebook/SelectEbook';


export default function PromotionEbooks() {
  const { promotion } = usePromotionContext();
  const [ebooks, setEbooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isNewEbookOpen, setIsNewEbookOpen] = useState(false);
  const [isSelectEbookOpen, setIsSelectEbookOpen] = useState(false);


  const findAllPromotionsEbooks = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/promotions/${promotion.idPromocao}/ebooks`
      );
      if (!isEmpty(data)) {
        return setEbooks(data);
      }
      return setEbooks([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    findAllPromotionsEbooks();
  }, [refetch]);

  const findCard = id => {
    const card = ebooks.filter(c => c.id === id)[0];
    return {
      card,
      index: ebooks.indexOf(card),
    };
  };

  const onMove = useCallback(
    (id, index) => {
      const cardIdx = ebooks.findIndex(currentCard => {
        return currentCard.id === id;
      });

      const card = ebooks[cardIdx] ? { ...ebooks[cardIdx] } : null;

      if (card) {
        setEbooks(() => {
          return update(ebooks, {
            $splice: [
              [cardIdx, 1],
              [index, 0, card],
            ],
          });
        });
      }
    },
    [ebooks]
  );

  const updateOrder = async () => {
    try {
      setLoading(true);
      const data = ebooks.map(c => c.id);
      await api.put(`/promotions/${promotion.idPromocao}/ebooks`, { data });
      setRefetch(!refetch);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = isRefetch => {
    setIsNewEbookOpen(false);
    if (isBoolean(isRefetch)) {
      setRefetch(!refetch);
    }
  };

  const handleCloseModalSelectEbook = isRefetch => {
    setIsSelectEbookOpen(false);

    if (isBoolean(isRefetch)) {
      setRefetch(!refetch);
    }
  };

  const onDrop = () => {
    updateOrder();
  };

  return (
    <Section>
      <SectionTitle>
        <FaLink size={18} />
        <h3>Ebooks</h3>

        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<FaPlusCircle size={18} />}
          onClick={() => setIsSelectEbookOpen(true)}
        >
          Escolher
        </Button>

        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<FaPlusCircle size={18} />}
          onClick={() => setIsNewEbookOpen(true)}

        >
          Novo ebook
        </Button>
      </SectionTitle>

      <SectionContent className="ebooks">
        {loading ? (
          <CircularProgress size={50} />
        ) : (
          <>
            {ebooks.map(ebook => {
              return (
                <EbookItem
                  key={ebook.id}
                  ebook={ebook}
                  findCard={findCard}
                  moveCard={onMove}
                  onDrop={onDrop}
                  refetch={refetch}
                  setRefetch={setRefetch}
                />
              );
            })}
          </>
        )}
      </SectionContent>
      {isNewEbookOpen && promotion && (
        <NewEbook
          open={isNewEbookOpen}

          onClose={handleCloseModal}
          promotionId={promotion.idPromocao}
          newEbookInPromotion
        />
      )}

      {isSelectEbookOpen && (
        <SelectEbook
          open={isSelectEbookOpen}
          onClose={handleCloseModalSelectEbook}
          promotionId={promotion.idPromocao}
        />
      )}

    </Section>
  );
}
