/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@rocketseat/unform';
import { isEmpty } from 'lodash';
import { MdSearch } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import {
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';

import { BsQuestionCircleFill } from 'react-icons/bs';
import { Content } from '~/styles/global';

import api from '~/services/api';
import history from '~/services/history';
import SessionStorage from '~/helpers/SessionStorage';
import NoRecord from '~/components/NoRecord';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import PersonList from '../List';
import { TextField } from './styles';
import Tip from './tip';

const schema = Yup.object().shape({
  search: Yup.string().required(
    'Digite algo no campo de pesquisa, tipo: número do CPF, nome, TID...'
  ),
});

export default function PersonSearch() {
  const [persons, setPersons] = useState({});
  const [loading, setLoading] = useState();
  const [lastsSearchs, setLastsSearchs] = useState([]);
  const [catchSearch, setCatchSearch] = useState({ search: undefined });

  const redirectToPerson = person => {
    if (person && person.cpf) {
      history.push(`/person/${person.cpf}`);
    }
  };
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    setLastsSearchs(SessionStorage.get('lastsSearchs') || []);
  }, []);

  useEffect(() => {
    if (persons && persons.cpf && !loading) {
      redirectToPerson(persons);
    }
  }, [persons, loading]);

  const getPersons = useCallback(
    async ({ search }) => {
      if (!search) return;
      setLoading(true);

      const requestUrl = `/persons?q=${encodeURIComponent(
        // eslint-disable-line
        search
      )}&page=${customPaginator.pagination.page || 1}&limit=${customPaginator
        .pagination.limit || 10}`;

      try {
        if (hasPermission(['read_persons', 'support_reset_password'])) {
          const { data } = (await api.get(requestUrl)) || {};

          setPersons(data);
          // set paginator.
          if (data) {
            const { limit, page, total } = data;
            setPagination({
              ...{
                limit,
                page,
                pages: Math.ceil(total / limit),
                totals: total,
              },
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [
      customPaginator.pagination.page,
      customPaginator.pagination.limit,
      setPagination,
    ]
  );

  function handleSubmit({ search }) {
    const date = new Date();
    SessionStorage.set('lastsSearchs', [...lastsSearchs, { search, date }]);
    setLastsSearchs([...lastsSearchs, { search, date: date.toISOString() }]);
    setCatchSearch({ search });
  }

  const handleClickSearchList = search => {
    document.getElementById('search').value = search;
    setCatchSearch({ search });
  };

  const handleKeyUp = e => {
    if (e.target.value === '') {
      setPersons({});
    }
  };

  useEffect(() => {
    getPersons({
      search: catchSearch.search,
    });
  }, [getPersons, catchSearch]);

  function renderLastSearchs() {
    return lastsSearchs && lastsSearchs.length ? (
      <Content>
        <table>
          <thead>
            <tr>
              <th colSpan="2">Últimas Pesquisas</th>
            </tr>
            <tr style={{ textAlign: 'left' }}>
              <th>Data</th>
              <th>Pesquisa</th>
            </tr>
          </thead>
          <tbody>
            {lastsSearchs
              .sort((a, b) => (a.date > b.date ? -1 : 1))
              .map(l => (
                <tr key={Math.random()}>
                  <td style={{ width: 200 }}>
                    {format(parseISO(l.date), 'dd/MM/yyyy HH:mm:ss')}
                  </td>
                  <td>
                    <Button
                      onMouseEnter={e => e.target.focus()}
                      style={{ justifyContent: 'flex-start' }}
                      variant="text"
                      onClick={() => handleClickSearchList(l.search)}
                    >
                      {l.search}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Content>
    ) : (
      ''
    );
  }

  function renderPersonTable() {
    return (
      <PersonList
        data={persons}
        paginator={() => (
          <CustomPaginator
            {...{ loading, setPagination, ...customPaginator }}
          />
        )}
      />
    );
  }

  function renderPersonResult() {
    if (!isEmpty(persons) && !isEmpty(persons.docs)) {
      return renderPersonTable();
    }

    return persons && persons.total === 0 ? <NoRecord /> : null;
  }

  return (
    <>
      <Content>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Form schema={schema} onSubmit={handleSubmit}>
              <TextField
                autoFocus
                name="search"
                id="search"
                label="Pesquisar Pessoa"
                placeholder="Pesquisar..."
                fullWidth
                autoComplete="off"
                autoCapitalize="off"
                variant="outlined"
                helperText={
                  <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    component="span"
                  >
                    Pesquise por CPF, ID, Nome, Nome Social, TID, Celular,
                    Protocolo do correio, e-mail e Tecle enter para
                    buscar.&nbsp;
                    <span
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      style={{ fontSize: 12, textDecoration: 'underline' }}
                    >
                      Sugestões
                      <BsQuestionCircleFill
                        style={{ marginLeft: 4, marginTop: 4 }}
                        color="gray"
                      />
                    </span>
                  </Typography>
                }
                disabled={loading}
                onKeyUp={handleKeyUp}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search"
                        type="submit"
                        onSubmit={() => handleSubmit()}
                      >
                        {loading ? <CircularProgress /> : <MdSearch />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Form>
          </Grid>
          <Grid item md={12} />
        </Grid>
      </Content>
      {renderPersonResult()}
      {renderLastSearchs()}
      <Tip
        open={open}
        handlePopoverClose={handlePopoverClose}
        anchorEl={anchorEl}
      />
    </>
  );
}
