import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Copyright } from '~/components';
import { Container, ContentContainer } from './styles';
import AppBar from './AppBar';
import Sidebar from './Sidebar';

const DRAWER_WIDTH = 240;

export default function LayoutWrapper({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  function handleToggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }

  return (
    <>
      <Container>
        <AppBar
          drawerWidth={DRAWER_WIDTH}
          expanded={!sidebarOpen}
          onToggle={() => handleToggleSidebar()}
        />

        <Sidebar
          width={DRAWER_WIDTH}
          open={sidebarOpen}
          onHide={() => handleToggleSidebar()}
        />

        <ContentContainer width={DRAWER_WIDTH} expanded={!sidebarOpen}>
          {/* <ToolBar /> */}
          {children}
        </ContentContainer>
      </Container>
      <Copyright />
    </>
  );
}

LayoutWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
