import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

function EmptyState({ onReset }) {
  return (
    <Container>
      <h4>Nenhum resultado encontrado</h4>
      {onReset && <Button onClick={onReset}>Limpar filtros</Button>}
    </Container>
  );
}

EmptyState.propTypes = {
  onReset: PropTypes.func,
};

EmptyState.defaultProps = {
  onReset: undefined,
};

export default EmptyState;
