import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  CircularProgress,
  Checkbox,
  Grid,
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import { MdSearch } from 'react-icons/md';
import * as Yup from 'yup';
import { Table, Product, TextField } from './styles';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';
import { Content } from '~/styles/global';
import { hasPermission } from '~/components/AccessControl';
import RegionDialog from '../Dialog';

const schema = Yup.object().shape({
  search: Yup.string().required(
    'Digite algo no campo de pesquisa, tipo: número do CPF, nome, TID...'
  ),
});

export default function List() {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const getProducts = async ({ search }) => {
    setLoading(true);
    const q = search.replace(/[^\w\s]/gi, '');

    try {
      if (hasPermission('read_products')) {
        const { data } =
          (await api.get(`/purchase-regions/${q}/products`)) || {};
        setResult(data);
      }
    } finally {
      setLoading(false);
    }
  };

  function handleSubmit({ search }) {
    getProducts({ search });
  }

  const handleKeyUp = e => {
    if (e.target.value === '') {
      setResult(null);
    }
  };

  function addDefaultSrc(ev) {
    ev.target.src = 'https://ui-avatars.com/api/';
  }

  function renderResult() {
    if (result && result.docs && result.docs.length > 0) {
      return (
        <Box style={{ backgroundColor: 'white' }}>
          <Table>
            <thead>
              <tr>
                <th>Titulo</th>
                <th>Inclusão</th>
                <th>Status do Range</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {result.docs.map(item => (
                <tr key={item.idProduto}>
                  <Product>
                    <img
                      src={item.icone}
                      alt={item.descricao}
                      onError={addDefaultSrc}
                    />
                    {item.titulo}
                  </Product>
                  <td>{format(parseISO(item.dataInsert), 'dd/MM/yyyy')}</td>
                  <td>
                    <Checkbox readOnly checked={item.status} color="primary" />
                  </td>
                  <td>
                    <RegionDialog product={item} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      );
    }
    if (result)
      return (
        <NoRecord message="Nenhum Produto disponível para sua pesquisa." />
      );
    return '';
  }

  return (
    <>
      <Content>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Form schema={schema} onSubmit={handleSubmit}>
              <TextField
                autoFocus
                name="search"
                label="Pesquisar"
                placeholder="Digite Nome,CEP ou DDD..."
                fullWidth
                autoComplete="off"
                autoCapitalize="off"
                variant="outlined"
                helperText="Pesquise por Nome ou CEP ou DDD e Tecle enter para buscar."
                disabled={loading}
                onKeyUp={handleKeyUp}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search"
                        type="submit"
                        onSubmit={handleSubmit}
                      >
                        {loading ? <CircularProgress /> : <MdSearch />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Form>
          </Grid>
        </Grid>
      </Content>
      {renderResult()}
    </>
  );
}
