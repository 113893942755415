import styled from 'styled-components';

export const Container = styled.div`
  background: #fafafa;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(204, 204, 204, 0.25);
  border-radius: 8px;
  padding: 16px;
`;
