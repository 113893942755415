/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import api from '~/services/api';
import { Content } from '~/styles/global';
import { AccessControl, UnauthorizedGhost } from '~/components';
import { decimalFormat } from '~/helpers/number';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import FormDialog from '../Dialog';
import { Table } from './styles';
// import { AccessControl } from '~/components';

export default function AwardList({ product }) {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [awards, setAwards] = useState([]);
  const [award, setAward] = useState([]);
  const [codigo, setCodigo] = useState(false);

  const { setPagination, ...customPaginator } = useCustomPaginator();
  useEffect(() => {
    const { cod } = product;
    if (cod === 'instantaneo') {
      setCodigo(true);
    } else {
      setCodigo(false);
    }
  }, [product]);

  const getAwards = useCallback(async () => {
    try {
      setLoading(true);
      if (hasPermission('read_sweepstakes_awards') && product) {
        const { data } =
          (await api.get(
            `/sweepstake/awards?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}&product=${product.id}`
          )) || {};
        if (data) {
          if (data.docs) {
            data.docs.map(a => {
              if (a.imagem) a.imagem += `?v=${new Date().getTime()}`;
              return a;
            });
          }
          setAwards(data);
        }
        // set paginator.
        if (data) {
          const { limit, page, pages, total } = data;
          setPagination({
            ...{ limit, page, pages, totals: total },
          });
        }
      } else {
        setAwards({});
      }
    } finally {
      setLoading(false);
    }
  }, [
    product,
    setPagination,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  const handleCloseModal = _award => {
    setAward(_award);
    if (_award) {
      setAwards({
        ...awards,
        docs: [_award, ...awards.docs.filter(a => a.id !== _award.id)],
      });
    }
    setOpenModal(false);
  };

  const handleClickAward = _award => {
    setOpenModal(false);
    setAward({ ..._award, valor: decimalFormat(_award.valor) });
    setOpenModal(true);
  };

  const renderAwards = () => {
    return (
      awards &&
      awards.docs &&
      awards.docs.map(item => (
        <tr key={item.id} onClick={() => handleClickAward(item)}>
          <td>
            <img src={item.imagem} alt={item.descricao} width={60} />
          </td>
          {codigo && (
            <td>
              {item.imagemPremio && (
                <img src={item.imagemPremio} alt={item.descricao} width={60} />
              )}
            </td>
          )}
          <td>{item.descricao}</td>
          {(product.cod === 'instantaneo' ||
            product.cod === 'diadasorte' ||
            product.cod === 'sabadaodasorte') && (
            <td align="right">{item.numeroPlano}</td>
          )}
          {(product.cod === 'instantaneo' ||
            product.cod === 'diadasorte' ||
            product.cod === 'sabadaodasorte') && (
            <td align="right">{item.numeroSerie}</td>
          )}
          <td align="right">{decimalFormat(item.valor)}</td>

          {/* <td align="center">{item.deleted ? 'Sim' : 'Não'}</td> */}
        </tr>
      ))
    );
  };

  useEffect(() => {
    getAwards();
  }, [product, getAwards]);

  return (
    <AccessControl
      can="read_sweepstakes_award"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} md={12} align="right">
            <AccessControl can="create_sweepstakes_award">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickAward({ produto: product })}
              >
                Novo
              </Button>
            </AccessControl>
          </Grid>
          <Grid item xs={12}>
            <Content>
              <Table>
                <thead>
                  <tr>
                    <th>Produto</th>
                    {codigo && <th>Prêmio</th>}
                    <th>Descrição</th>

                    {(product.cod === 'instantaneo' ||
                      product.cod === 'diadasorte' ||
                      product.cod === 'sabadaodasorte') && (
                      <th style={{ width: '10%' }}>Número Plano</th>
                    )}

                    {(product.cod === 'instantaneo' ||
                      product.cod === 'diadasorte' ||
                      product.cod === 'sabadaodasorte') && (
                      <th style={{ width: '10%' }}>Número Serie</th>
                    )}

                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>{renderAwards()}</tbody>
              </Table>
              <CustomPaginator
                {...{ loading, setPagination, ...customPaginator }}
              />
              {loading && <CircularProgress size={24} />}
            </Content>
          </Grid>
        </Grid>
        {openModal && (
          <FormDialog
            award={award}
            opened
            handleCloseModal={handleCloseModal}
            product={product}
          />
        )}
      </>
    </AccessControl>
  );
}

AwardList.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
