import styled from 'styled-components';

export const Roles = styled.span`
  padding: 6px;
  background-color: ${({ backgrounded }) => backgrounded};
  border-radius: 20px;
`;

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 8px;
`;

export const LabelRules = styled.span`
  font-size: 16px;
  font-family: 'Roboto';
  color: ${({ enable }) => (enable ? 'green' : 'rgba(0, 0, 0, 0.6)')};
  font-weight: 500;
`;
