import React from 'react';
import { CircularProgress } from '@material-ui/core';

import CustomPaginator from '~/components/CustomPaginator';
import BannerFilterBar from '../FilterBar';
import BannerItem from '../BannerItem';
import AddBannerButton from '../AddBannerButton';
import { useBannersHome } from '../context';
import { GridContainer } from './styles';
import { useBannersHomePermission } from '../context-permissions';

const BannersHomeList = () => {
  const {
    banners,
    loading,
    colSize,
    setPagination,
    customPaginator,
  } = useBannersHome();
  const { canCreate, canRead } = useBannersHomePermission();

  return (
    <>
      {canRead && <BannerFilterBar />}

      {/* When loading. */}
      {loading && <CircularProgress />}

      {/* After loading. */}
      {!loading && (
        <GridContainer size={colSize}>
          {canCreate && <AddBannerButton />}
          {canRead &&
            banners.map(banner => (
              <BannerItem key={banner.idBannerHome} data={banner} />
            ))}
        </GridContainer>
      )}

      <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
    </>
  );
};

export default BannersHomeList;
