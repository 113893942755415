import styled, { css } from 'styled-components';
import { DialogContent } from '@material-ui/core';

export const Container = styled.div``;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 20px;
  padding: 0px;
  margin: 0px 0px 20px 0px;
`;

export const TitleMgTop = styled.h1`
  font-weight: 300;
  font-size: 20px;
  padding: 0px;
  margin: 10px 0px 20px 0px;
`;

export const SectionTitle = styled.h1`
  font-weight: 500;
  font-size: 16px;
  padding: 0px;
  margin: 20px 0px 7px 0px;

  > small {
    font-size: 11px;
    color: #999;
    font-weight: normal !important;
  }
`;

export const Content = styled(DialogContent)`
  && {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-gap: 20px;
    padding: 20px;

    > section:first-child {
      padding: 20px 0px;
    }
  }
`;

export const Alert = styled.div`
  width: 100%;
  padding: 10px 8px;
  border-radius: 4px;
  font-size: 14px;
  vertical-align: middle;
  background: lightyellow;
  border: 1px solid lightgoldenrodyellow;

  ${({ failure }) =>
    !!failure &&
    css`
      background: #e65e5e;
      color: #ffffff;
    `}

  ${({ success }) =>
    !!success &&
    css`
      background: #67c971;
      color: #ffffff;
    `}
`;

export const SimpleTable = styled.section`
  display: flex;
  flex-direction: column;

  > header {
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      flex: 1;
      color: #222;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      padding: 5px 0px;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      padding: 3px 0px;
      text-align: center;
    }
  }
`;
