import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import api from '~/services/api';
import { Content } from '~/styles/global';
import { AccessControl } from '~/components';
import { hasPermission } from '~/components/AccessControl';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import { store } from '~/store';
import { isEmpty } from 'lodash';
import RoleForm from '../Form';
import { Table } from './styles';

export default function Roles() {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState();
  const [permissions, setPermissions] = useState();

  const { token } = store.getState().auth;

  const getRoles = async () => {
    try {
      setLoading(true);
      if (hasPermission('read_roles')) {
        const { data } = await api.get('/roles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!isEmpty(data)) {
          setRoles(data);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  // Get all permissions from api
  const getPermissions = async () => {
    try {
      setLoading(true);
      if (hasPermission('read_permissions')) {
        const { data } = await api.get('/permissions');
        if (data) setPermissions(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetRole = selectedRole => {
    setRole(selectedRole);
  };

  const handleNewClick = () => {
    setRole({ name: null, permissions: [] });
  };

  const renderRoles = () => {
    return roles.map(r => (
      <tr key={r.roleId} onClick={() => handleSetRole(r)}>
        <td>{r.roleAttributes.roleName}</td>
      </tr>
    ));
  };

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    getRoles();
  }, [role]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={11}>
          <Header>
            <h1 style={{ fontWeight: 400, fontSize: 24, marginBottom: 20 }}>
              Perfis
            </h1>
          </Header>
        </Grid>
        <Grid item xs={6} md={1} align="right">
          <AccessControl can="create_role">
            <Button
              type="submit"
              onClick={handleNewClick}
              variant="contained"
              color="primary"
            >
              Novo
            </Button>
          </AccessControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Content>
            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                </tr>
              </thead>
              <tbody>{renderRoles()}</tbody>
            </Table>
          </Content>
          {loading ? <CircularProgress /> : ''}
        </Grid>
        <Grid item xs={12} md={8}>
          {role && permissions ? (
            <RoleForm role={role} setRole={setRole} permissions={permissions} />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
}
