import { useState } from 'react';

import api from '~/services/api';
import history from '~/services/history';

export default function useFeedback({
  id,
  onFeedbackUpdated,
  onFeedbackPersonUpdated,
}) {
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleFeedbackUpdate({ titulo, descricao }) {
    setLoading(true);

    api
      .put(`/winners-feedback/${id}`, { titulo, descricao })
      .then(({ data }) => {
        // call onFeedbackUpdated
        if (onFeedbackUpdated && typeof onFeedbackUpdated === 'function') {
          onFeedbackUpdated(data);
        }

        // close editing mode
        setEditing(false);
      })
      .finally(() => setLoading(false));
  }

  function handleFeedbackStatusUpdate(status) {
    setLoading(true);

    api
      .put(`/winners-feedback/${id}`, { status })
      .then(({ data }) => {
        // call onFeedbackUpdated
        if (onFeedbackUpdated && typeof onFeedbackUpdated === 'function') {
          onFeedbackUpdated(data);
        }
      })
      .finally(() => setLoading(false));
  }

  function toggleEditingMode() {
    setEditing(!isEditing);
  }

  function onPersonUpdated(pessoa) {
    if (
      onFeedbackPersonUpdated &&
      typeof onFeedbackPersonUpdated === 'function'
    ) {
      onFeedbackPersonUpdated({ id, pessoa });
    }
  }

  function handlePersonFeedbackUpdate({ pessoa }) {
    const route =
      pessoa.provisorio === true
        ? `/person/${pessoa.id}/provisional`
        : `/person/${pessoa.cpf.replace(/[^0-9]/g, '')}`;

    if (pessoa.provisorio) {
      window.modalProvisionalPerson = {
        onPersonUpdated,
      };

      history.push(route, { modal: true, onSave: 'onPersonUpdated' });
    } else {
      window.open(route);
    }
  }

  return {
    isEditing,
    loading,
    handleFeedbackUpdate,
    handleFeedbackStatusUpdate,
    handlePersonFeedbackUpdate,
    toggleEditingMode,
  };
}
