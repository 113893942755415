import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div.MuiChip-root.statuses {
    /* font-size: 11px; */

    &.processing {
      color: #ffffff !important;
      background-color: #1d74cc;

      svg {
        color: #fff;
      }
    }

    &.new-releases {
      color: #222 !important;
      background-color: #f0f0f0;

      svg {
        color: #222;
      }
    }

    &.sent {
      color: #fff;
      background-color: #32ab5a;

      svg {
        color: #fff;
      }
    }

    &.not-sent {
      color: #fff;
      background-color: #b54343;

      svg {
        color: #ffffff;
      }
    }

    &.partial-sent {
      color: #fff;
      background-color: #e8a43f;

      svg {
        color: #fff;
      }
    }

    &.schedule {
      color: #fff;
      background-color: rgba(72, 111, 133);

      &:nth-child(even) {
        background-color: rgba(72, 111, 133, 0.8);
      }

      svg {
        color: #fff;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  > h1 {
    font-weight: normal;
    font-size: 22px;
    color: #222;
  }

  a {
    text-decoration: none;
  }
`;

export const FilterContainer = styled.section`
  padding: 15px;
  margin-bottom: 15px;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
`;

export const ButtonConsult = styled(Button)`
  background-color: #ffed00 !important;
  color: #000;
  margin-right: 5px !important;
`;
