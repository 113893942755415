import React, { useState, useEffect, useCallback } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import { useSelector } from 'react-redux';
import RaffleTabs from './tabs';

export default function Raffle({
  draft,
  updateDraft,
  setNextStep,
  isDoubleCheck,
  setStepsStatus,
}) {
  const { product } = useSelector(({ user }) => user);
  useEffect(() => {
    setNextStep(
      (!isDoubleCheck || draft.status === 'approved') &&
        draft?.data?.regulation &&
        draft?.data?.banners?.length &&
        draft?.data?.sweepstakes?.length &&
        draft?._id
    );
  }, [draft, setNextStep]);

  const [raffleTypes, setRaffleTypes] = useState();
  const [loading, setLoading] = useState();
  const [raffleTabsStatus, setRaffleTabsStatus] = useState({});

  const getRaffleTypes = useCallback(async () => {
    setLoading(true);
    const productId = draft?.data?.promotion?.idProduto ?? product.id;

    try {
      await api
        .get(`/promotions/config/${productId}?byProduct=true`)
        .then(({ data }) => {
          if (data) setRaffleTypes(data.config.tipoSorteio);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }

    return () => {
      setRaffleTypes([]);
    };
  }, []);

  useEffect(() => {
    if (raffleTypes) {
      const raffles = raffleTypes.reduce((acc, raffleType) => {
        acc[raffleType.id] = false;
        return acc;
      }, {});

      setRaffleTabsStatus(raffles);
    }
  }, [raffleTypes]);

  useEffect(() => {
    if (
      Object.keys(raffleTabsStatus).length > 0 &&
      Object.values(raffleTabsStatus).every(step => step === true)
    ) {
      setStepsStatus(prevStatus => ({ ...prevStatus, raffles: true }));
    }
  }, [raffleTabsStatus]);

  useEffect(() => {
    if (hasPermission('read_sweepstakes_types')) {
      getRaffleTypes();
    }
  }, [getRaffleTypes]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {loading && <CircularProgress />}
        {raffleTypes && (
          <RaffleTabs
            raffleTypes={raffleTypes}
            draft={draft}
            updateDraft={updateDraft}
            isDoubleCheck={isDoubleCheck}
            setRaffleTabsStatus={setRaffleTabsStatus}
          />
        )}
      </Grid>
    </Grid>
  );
}

Raffle.defaultProps = {
  isDoubleCheck: false,
  setStepsStatus: () => {},
};

Raffle.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  isDoubleCheck: PropTypes.bool,
  setStepsStatus: PropTypes.func,
};
