import React from 'react';

import FormDialog from './FormDialog';

const BannerForm = () => {
  return <FormDialog />;
};

export * from './context';
export default BannerForm;
