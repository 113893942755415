import { useCallback, useEffect, useState } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';

const initialPagination = {
  page: 1,
  pages: 0,
  limit: 10,
  totals: 0,
  rowsPerPageOptions: [5, 10, 15, 20, 30, 50],
};
export default function useCustomPaginator() {
  const [pagination, setLocalPagination] = useState(initialPagination);
  const { items } = usePagination({
    count: Math.ceil(pagination.totals / pagination.limit),
    page: pagination.page,
  });

  const onPageChange = useCallback(
    (_, currentPage) => {
      const page = currentPage + 1;
      setLocalPagination({ ...pagination, page });
    },
    [pagination]
  );

  const onLimitChange = useCallback(({ target }) => {
    setLocalPagination(oldPagination => ({
      ...oldPagination,
      limit: target.value,
      page: 1,
    }));
  }, []);

  const setPagination = useCallback(params => {
    setLocalPagination(oldParams => {
      const page =
        params.totals && oldParams.totals !== params.totals
          ? 1
          : params.page || oldParams.page;
      return { ...oldParams, ...params, page };
    });
  }, []);

  useEffect(() => {
    setLocalPagination(oldParams => ({
      ...oldParams,
      pages: Math.ceil(oldParams.totals / oldParams.limit),
    }));
  }, [pagination.limit, pagination.totals]);

  return {
    pagination,
    setPagination,
    onLimitChange,
    onPageChange,
    items,
  };
}
