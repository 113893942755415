import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import NO_IMAGE from '~/assets/no-banner.jpg';
import BALLOON_IMAGE from '~/assets/balloon.png';

import {
  PreloadContainer,
  BannerImagePreview,
  BallonImagePreview,
  ContainerBallon,
} from './styles';

const BannerImage = ({ src, title, isBallon, ...props }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (src) {
      const image = new Image();

      /* on image load. */
      image.onload = () => {
        setImageSrc(src);
      };

      /* on image error */
      image.onerror = () => {
        setImageSrc(NO_IMAGE);
      };

      /* preload with image src */
      image.src = src;
    }
  }, [src]);

  if (imageSrc === null)
    return (
      <PreloadContainer>
        <CircularProgress size={30} />
      </PreloadContainer>
    );

  return isBallon ? (
    <ContainerBallon>
      <img src={BALLOON_IMAGE} alt="ballon" />
      <BallonImagePreview {...props} src={imageSrc} alt="card" />
    </ContainerBallon>
  ) : (
    <BannerImagePreview {...props} alt={title} src={imageSrc} />
  );
};

BannerImage.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  isBallon: PropTypes.bool,
};

BannerImage.defaultProps = {
  src: null,
  isBallon: false,
};

export default React.memo(BannerImage);
