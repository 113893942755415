import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
export const Results = styled.span`
  font-weight: 300;
  margin-left: 10px;
`;
export const DivIcon = styled.div`
  display: flex;
  color: ${props => props.color};
  align-items: center;
  && span {
    margin-left: 4px;
  }
`;

export const Value = styled.div`
  font-size: 16px;
  font-weight: normal;
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;
export const Content = styled(Paper)`
  padding: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ColorColor = styled.div`
  width: 36px;
  /* height: 14px; */
  border-radius: 2px;
  background: ${props => props.color};
  padding-left: 40px;
`;

export const ColorSwatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const ColorPopover = styled.div`
  position: absolute;
  z-index: 2;
`;

export const ColorCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
