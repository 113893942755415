import styled from 'styled-components';

import { Autocomplete } from '@material-ui/lab';

export const AutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-input {
    height: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const Thumbnail = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Nome = styled.p`
  font-weight: 500;
`;

export const Autor = styled.p`
  color: rgba(0, 0, 0, 0.87);
`;
