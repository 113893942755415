import styled from 'styled-components';
import { Card } from '../../components';

export const Container = styled(Card)`
  display: flex;
  flex-direction: row;
  z-index: 20;
  position: relative;
`;

export const LeftContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-gap: 8px 32px;
  flex: 1;

  .card-label-content.content-wrapper {
    white-space: nowrap;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 16px;
  }

  button.collapse-button {
    border: 0px;
    background-color: transparent;
    padding: 0px;
    font-size: 32px;
  }
`;
