import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { debounce as debounceFn } from 'lodash';
import { isValid, format, parse } from 'date-fns';
import { useLocation } from 'react-router-dom';

// -- services
import qs from '~/services/qs';

// -- components
import { ProductAutocomplete, IDatePicker } from '~/components';

export default function PromotionsFilter({ filters, handleFilters }) {
  const { search } = useLocation();
  const { product, refDate, gift, statusVigente } = qs.parse(search);

  const debounce = debounceFn((...args) => handleFilters(...args), 400);

  return (
    <Grid container spacing={2}>
      {filters.includes('product') && (
        <Grid item xs={12} md={4}>
          <ProductAutocomplete
            value={product}
            onChange={({ target }) => debounce('product', target.value || null)}
          />
        </Grid>
      )}
      {filters.includes('refDate') && (
        <Grid item xs={12} md={4}>
          <IDatePicker
            label="Data da Promoção"
            onChange={value =>
              debounce(
                'refDate',
                value && isValid(value)
                  ? format(value, 'yyyy-MM-dd')
                  : undefined
              )
            }
            value={
              refDate
                ? parse(refDate, 'yyyy-MM-dd', Date.now()).toISOString()
                : null
            }
          />
        </Grid>
      )}
      {filters.includes('gift') && (
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '1rem',
          }}
        >
          <span style={{ height: '30px' }}>Tipo de promoção:</span>
          <RadioGroup
            row
            defaultValue="true"
            value={gift}
            onChange={({ target }) => debounce('gift', target.value)}
          >
            <FormControlLabel
              label="Títulos"
              value="true"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="Outros"
              value="false"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Grid>
      )}
      {filters.includes('statusVigente') && (
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '1rem',
          }}
        >
          <span style={{ height: '30px' }}>Status vigente:</span>
          <RadioGroup
            row
            defaultValue="true"
            value={statusVigente}
            onChange={({ target }) => debounce('statusVigente', target.value)}
          >
            <FormControlLabel
              label="Processado"
              value="Processado"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="Processando"
              value="Processando"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="Erro"
              value="Erro"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Grid>
      )}
    </Grid>
  );
}

PromotionsFilter.defaultProps = {
  filters: ['product'],
  handleFilters: () => {},
};

PromotionsFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  handleFilters: PropTypes.func,
};
