import { useState, useEffect } from 'react';
import { applySpec, prop } from 'ramda';
import { keys, has, isFunction } from 'lodash';

import api from '~/services/api';

const personSpec = applySpec({
  id: prop('idGanhadorProvisorio'),
  nome: prop('nome'),
  logradouro: prop('logradouro'),
  cidade: prop('cidade'),
  bairro: prop('bairro'),
  estado: prop('estado'),
  avatar: prop('urlImageProfile'),
  provisorio: () => true,
});

export default function useProvisionalPerson({
  id,
  onSave,
  autoClose,
  redirectToBack,
}) {
  const [person, setPerson] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const [avatar, setAvatar] = useState({
    preview: person.avatar,
  });

  useEffect(() => {
    setLoading(true);

    api
      .get(`/persons/${id}/provisional`)
      .then(({ data }) => setPerson(personSpec(data)))
      .finally(() => setLoading(false));
  }, [id]);

  // update person avatar when load new person
  useEffect(() => {
    setAvatar({
      preview:
        person.avatar ||
        `https://ui-avatars.com/api/?size=150&name=${person.nome}`,
    });
  }, [person]);

  // to avoid memory leaks
  useEffect(() => {
    URL.revokeObjectURL(avatar);
  }, [avatar]);

  function handleUpdatePerson(data) {
    setLoading(true);
    setUpdating(true);
    const payload = { ...data, avatar };
    const formData = new FormData();

    keys(payload).forEach(key => {
      const field = payload[key];
      formData.append(key, field);
    });

    api
      .put(`/persons/${id}/provisional`, formData)
      .then(({ data: res }) => {
        const spec = personSpec(res);

        // update state
        setPerson(spec);

        // callback
        if (onSave && has(window, `modalProvisionalPerson.${onSave}`)) {
          const fn = window.modalProvisionalPerson[onSave];
          if (isFunction(fn)) fn(spec);
        }

        // autoClose
        if (autoClose === true && typeof redirectToBack === 'function') {
          redirectToBack();
        }
      })
      .finally(() => {
        setLoading(false);
        setUpdating(false);
      });
  }

  function handleUpdateAvatar(file) {
    setAvatar(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  }

  return {
    person,
    avatar,
    loading,
    handleUpdatePerson,
    handleUpdateAvatar,
    isUpdating,
  };
}
