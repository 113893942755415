import React from 'react';

import { Icon } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField } from 'unform-material-ui';
import PropTypes from 'prop-types';
import { Button } from './style';

export default function DialogResetPassword({
  person,
  setSendType,
  setReasonReset,
  sendType,
}) {
  return (
    <>
      <DialogContentText>
        Selecione a opção de reset desejada pelo cliente:
      </DialogContentText>
      <div
        style={{
          justifyContent: 'space-evenly',
          display: 'flex',
          padding: 10,
        }}
      >
        {person.celular && person.ddd && (
          <Button
            onClick={() => setSendType('sms')}
            type="button"
            variant="contained"
            color="primary"
          >
            <Icon>sms</Icon>
            &nbsp;SMS
          </Button>
        )}
        {person.email && (
          <Button
            onClick={() => setSendType('email')}
            type="button"
            variant="contained"
            color="primary"
          >
            <Icon>email</Icon>
            &nbsp;Email
          </Button>
        )}
        {(!person.celular || !person.email) && !person.ddd && (
          <DialogContentText style={{ color: 'red' }}>
            Este usuário não possui email ou telefone para receber nova senha.
          </DialogContentText>
        )}
      </div>
      {sendType === 'sms' && (
        <>
          <DialogContentText>
            SMS: ({person.ddd}) {person.celular}
          </DialogContentText>
          <DialogContentText>Justifique a alteração:</DialogContentText>
        </>
      )}
      {sendType === 'email' && (
        <>
          <DialogContentText>EMAIL: {person.email} </DialogContentText>
          <DialogContentText>Justifique a alteração:</DialogContentText>
        </>
      )}
      <TextField
        onChange={reasonValue => setReasonReset(reasonValue.target.value)}
        margin="dense"
        id="reason"
        name="reason"
        label="Motivo"
        fullWidth
        multiline
        rowsMax="4"
      />
    </>
  );
}

DialogResetPassword.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setSendType: PropTypes.bool.isRequired,
  setReasonReset: PropTypes.func.isRequired,
  sendType: PropTypes.string.isRequired,
};
