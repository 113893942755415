import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import useAPIBannersHome from './useAPIBannersHome';

export const BannersHomeContext = createContext({});

export const BannersHomeProvider = ({ children }) => {
  const [colSize, setColSize] = useState(6);
  const apiValues = useAPIBannersHome();

  return (
    <BannersHomeContext.Provider value={{ ...apiValues, colSize, setColSize }}>
      {children}
    </BannersHomeContext.Provider>
  );
};

BannersHomeProvider.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line
};

export const useBannersHome = () => {
  const context = useContext(BannersHomeContext);
  return context;
};
