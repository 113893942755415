import { useState, useEffect, useCallback } from 'react';
import { applySpec, prop, map, pipe, isEmpty } from 'ramda';

import api from '~/services/api';

const promotionCodeSpecs = applySpec({
  idCodigoPromocional: prop('idCodigoPromocional'),
  acao: prop('acao'),
  codigoPromocional: prop('codigoPromocional'),
  descricao: prop('descricao'),
  dataInicioVigencia: prop('dataInicioVigencia'),
  dataFimVigencia: prop('dataFimVigencia'),
  quantidadeCriada: prop('quantidadeCriada'),
  usos: prop('usos'),
  limiteValidacao: prop('limiteValidacao'),
  mensagemRetorno: prop('mensagemRetorno'),
  status: prop('status'),
  mensagemRegraValidacao: prop('mensagemRegraValidacao'),
  tipo: prop('tipo'),
  dataInsert: prop('dataInsert'),
  validacao: prop('validacao'),
});

const getPromotionCodes = pipe(prop('docs'), map(promotionCodeSpecs));

const paginationSpec = applySpec({
  totals: prop('total'),
  limit: prop('limit'),
  page: prop('page'),
  pages: prop('pages'),
});

export default function usePromotionCodeList({ setPagination, ...custom }) {
  const [promotionCodes, setPromotionCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const timeId = setTimeout(() => {
      setLoading(true);

      const params = {
        ...filters,
        page: custom.pagination.page,
        limit: custom.pagination.limit,
        q: /^\d{3}.\d{3}.\d{3}-\d{2}/g.test(filters.q)
          ? String(filters.q).replace(/D+/g, '')
          : filters.q,
      };
      api
        .get(`/promotion-codes`, { params })
        .then(({ data = {} } = {}) => {
          if (!isEmpty(data)) {
            setPromotionCodes(getPromotionCodes(data));
            setPagination(paginationSpec(data));
            if (data.docs.length) {
              document.querySelector('main').scroll(0, 0);
              window.scroll(0, 0);
            }
          }
        })
        .finally(() => setLoading(false));
    }, 120);

    return () => clearTimeout(timeId);
  }, [custom.pagination.page, custom.pagination.limit, filters, setPagination]);

  const sendFilters = async atributeFilters => {
    setFilters(atributeFilters);
  };

  const handleFilters = useCallback(
    (field, value) => {
      custom.setPagination({ page: 1 });
      setFilters({ ...filters, [field]: value });
    },
    [filters, custom]
  );

  return {
    promotionCodes,
    filters,
    loading,
    setLoading,
    handleFilters,
    sendFilters,
  };
}
