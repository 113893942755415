import React from 'react';
import PropTypes from 'prop-types';

export default function LayoutWrapper({ children }) {
  return <>{children}</>;
}

LayoutWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
