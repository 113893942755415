import React, { useState } from 'react';
import {
  Icon,
  Switch,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MdEdit } from 'react-icons/md';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import {
  Container,
  LeftContainer,
  RightContainer,
  SectionTitle,
  ContainerDropDown,
} from './styles';
import ChangeStatusDialog from './ChangeStatusDialog';
import ChangeAnswerDialog from './ChangeAnswerDialog';

const FAQ = ({
  faq: faqData,
  faqs,
  container,
  idProduto,
  onUpdate,
  submitFilter,
  loading: isLoading,
  setLoading: setMainLoading,
  preSubmitFilter,
  posContainerSubmit,
  customPage,
  customLimit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [loadingContent, setLoadingContent] = useState(false);
  const [marked, setMarked] = useState(false);

  const order = get(faqData, 'ordem');
  const question = get(faqData, 'faq.pergunta');
  const answer = get(faqData, 'faq.resposta');
  const produtos = get(faqData, 'faq.produtos', []);

  const handleSubmitProducts = async () => {
    setCollapsed(value => !value);
  };

  const handleChangeIndex = fromIndex => {
    if (order === fromIndex + 1) return;

    setMainLoading(true);
    setLoadingContent(true);

    if (hasPermission('order_faq')) {
      api
        .put(`faqs/reorder/${faqData.idFaqProduto}`, {
          idProduto,
          position: fromIndex + customLimit * customPage - customLimit,
        })
        .then(({ data }) => {
          if (data) {
            if (typeof onUpdate === 'function') onUpdate();
          }
        })
        .finally(() => {
          setMainLoading(false);
          setLoadingContent(false);
        });
    }
  };

  const getDialogStatusMessage = (checked = false) => {
    const hasProductFilter = !!idProduto;
    setMarked(checked);
    if (checked && hasProductFilter) {
      return 'Ao clicar em confirmar, você irá adicionar a FAQ somente no produto selecionado. Deseja executar esta ação?';
    }

    if (checked && !hasProductFilter) {
      return 'Ao clicar em confirmar, você irá adicionar a FAQ em todos os produtos inclusos. Deseja executar esta ação?';
    }

    if (!checked && hasProductFilter) {
      return 'Ao clicar em confirmar, você irá retirar a FAQ do produto selecionado. Deseja executar esta ação?';
    }

    return 'Ao clicar em confirmar, você irá retirar a FAQ de todos os produtos inclusos. Deseja executar esta ação?';
  };

  return (
    <>
      {isLoading && loadingContent && (
        <CircularProgress color="white" size={34} style={{ marginLeft: 900 }} />
      )}

      <Container
        className="screening-content"
        loading={false}
        status={faqData.faq.status}
      >
        <LeftContainer status={faqData.faq.status}>
          <SectionTitle status={faqData.faq.status}>
            {submitFilter && posContainerSubmit !== 0 && preSubmitFilter && (
              <Select
                value={order}
                onChange={e => handleChangeIndex(e.target.value)}
              >
                {faqs.map((_, Index) => (
                  <MenuItem value={Index + 1}>{Index + 1}</MenuItem>
                ))}
              </Select>
            )}

            {question}
          </SectionTitle>
          <p>{get(faqData, 'faq.resposta', '').substr(0, 80)}...</p>
        </LeftContainer>

        <RightContainer>
          <button
            className="popup-button"
            type="button"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <MdEdit />
          </button>

          {posContainerSubmit === 1 ? (
            <Switch
              color="primary"
              checked={faqData.faq.status === 1}
              onChange={(_, checked) => {
                setStatusMessage(getDialogStatusMessage(checked));
              }}
            />
          ) : (
            ''
          )}

          <button
            className="collapse-button"
            type="button"
            onClick={() => handleSubmitProducts()}
          >
            <Icon>
              {collapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          </button>
        </RightContainer>

        <ChangeStatusDialog
          checked={marked ? 1 : 0}
          message={statusMessage}
          faq={faqData}
          onCancel={() => setStatusMessage('')}
          onConfirm={() => {
            if (idProduto && container && marked === false) {
              if (hasPermission('toggle_status_faq')) {
                api.delete(`/faqs/${faqData.idFaqProduto}`).then(() => {
                  if (typeof onUpdate === 'function') onUpdate();
                });
              }
            } else if (!idProduto) {
              if (hasPermission('toggle_status_faq')) {
                api
                  .put(`/faqs/${faqData.faq.idFaq}/status/${marked ? 1 : 0}`)
                  .then(() => {
                    if (typeof onUpdate === 'function') onUpdate();
                  });
              }
            }

            setStatusMessage('');
          }}
        />

        <ChangeAnswerDialog
          isOpen={isEditing}
          onUpdate={onUpdate}
          faq={faqData}
          product={produtos.map(produto => produto.id)}
          onClose={() => setIsEditing(false)}
          onSave={() => {
            setIsEditing(false);
          }}
          answer={get(faqData, 'faq.resposta')}
          question={get(faqData, 'faq.pergunta')}
        />
      </Container>

      {collapsed && (
        <ContainerDropDown>
          {answer && answer.length > 1800 ? answer.substr(0, 1800) : answer}

          <h5>Disponível em: </h5>
          <Grid container style={{ marginLeft: 100 }}>
            {produtos.map(produto => (
              <Grid item xs={2} style={{ marginRight: 10 }} key={produto.id}>
                <p style={{ marginRight: 30 }}>{produto.nome}</p>
              </Grid>
            ))}
          </Grid>
        </ContainerDropDown>
      )}
    </>
  );
};

FAQ.propTypes = {
  posContainerSubmit: PropTypes.string.isRequired,
  preSubmitFilter: PropTypes.bool.isRequired,
  idProduto: PropTypes.number.isRequired,
  container: PropTypes.number.isRequired,
  customPage: PropTypes.number.isRequired,
  customLimit: PropTypes.number.isRequired,
  faq: PropTypes.number.isRequired,
  faqs: PropTypes.arrayOf().isRequired,
  onUpdate: PropTypes.func.isRequired,
  submitFilter: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.bool.isRequired,
};

export default FAQ;
