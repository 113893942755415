import styled, { css } from 'styled-components';

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;

  && p:hover,
  :hover {
    color: #3f51b5;
    border-color: #3f51b5;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const UploadMessage = styled.p`
  display: flex;
  color: #999;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;
