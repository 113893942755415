import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid } from '@material-ui/core';
import { FaCalendar } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';

// -- components
import {
  GridCustom,
  Product,
  PromotionContainer,
  PromotionHeader,
} from './styles';

export default function Promotion({ data: promocao, ...rest }) {
  // -- dataSorteioPrincipal
  function getDataSorteioPrincipal() {
    const data = promocao.dataSorteioPrincipal;
    return data && format(parseISO(data), 'dd/MM/yyyy HH:mm');
  }

  return (
    <PromotionContainer theme={promocao.corPromocao} {...rest}>
      <GridCustom container>
        {/* header */}
        <PromotionHeader theme={promocao.corPromocao}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <FaCalendar size={20} />
              <span>{getDataSorteioPrincipal()}</span>
            </Grid>
            <Grid item xs={6} md={6} align="right">
              {promocao.categoria && <p>CHANCE {promocao.categoria}</p>}
            </Grid>
          </Grid>
        </PromotionHeader>
        {/* body */}
        <Grid className="body" item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Product image={promocao.produto.icone} />
            </Grid>
            <Grid item xs={12} md={9}>
              <h2>{promocao.produto.titulo}</h2>
              <h4>{promocao.tituloPromocao}</h4>
            </Grid>
          </Grid>
        </Grid>
        {/* body */}
        <Grid container className="footer" item xs={12} md={12}>
          <Grid
            container
            item
            xs={12}
            md={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={6}
              md={6}
              style={{
                textTransform: 'uppercase',
                textAlign: 'end',
                fontWeight: 'bold',
              }}
            >
              {promocao.statusText}
            </Grid>
            <Grid
              container
              item
              xs={6}
              md={6}
              style={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <Grid
                item
                style={{
                  textTransform: 'uppercase',
                }}
              >
                <Icon>
                  {promocao.statusVigente === 'Processado'
                    ? 'done'
                    : 'schedule'}
                </Icon>
              </Grid>
              <Grid
                item
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'right',
                }}
              >
                {' '}
                {promocao.statusVigente || 'UNDEFINED'} (
                {promocao.meioEntregaCupom || 'PF'})
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridCustom>
    </PromotionContainer>
  );
}

Promotion.propTypes = {
  data: PropTypes.shape({
    idPromocao: PropTypes.number,
    categoria: PropTypes.string,
    statusText: PropTypes.string,
    produto: PropTypes.shape({
      titulo: PropTypes.string,
      icone: PropTypes.string,
    }),
    dataSorteioPrincipal: PropTypes.string,
    tituloPromocao: PropTypes.string,
    corPromocao: PropTypes.shape({
      background: PropTypes.string,
      color: PropTypes.string,
    }),
    statusVigente: PropTypes.string,
    meioEntregaCupom: PropTypes.string,
  }).isRequired,
};
