import React from 'react';
import { ModalSwitch, ModalRoute } from 'react-router-modal-gallery';

import Login from '~/pages/Login';
import PersonSearch from '~/pages/Backoffice/Person/Search';
import ProductList from '~/pages/Backoffice/Product/List';
import PersonView from '~/pages/Backoffice/Person/View';
import Roles from '~/pages/Backoffice/admin/Roles/List';
import UserList from '~/pages/Backoffice/admin/Users/List';
import NotFoundPage from '~/pages/NotFoundPage';
import PromotionList from '~/pages/Backoffice/Promotion/List';
import PromotionView from '~/pages/Backoffice/Promotion/View';
import ScreeningList from '~/pages/Backoffice/Screening/List';
import FeedbackList from '~/pages/Backoffice/Feedbacks/List';
import ProvisionalPersonForm from '~/pages/Backoffice/Person/Provisional/Form';
import FeedbackForm from '~/pages/Backoffice/Feedbacks/Form';
import PushNotificationCreate from '~/pages/Backoffice/PushNotification/Create';
import ActivitiesList from '~/pages/Backoffice/Activities';
import PromotionConfigMain from '~/pages/Backoffice/Promotion/Config/main';
import AwardsList from '~/pages/Backoffice/Awards/List';
import PromotionForm from '~/pages/Backoffice/Promotion/Draft';
import PromotionDraftList from '~/pages/Backoffice/Promotion/Draft/List';
import BannersHomePage from '~/pages/Backoffice/BannersHome';
import CgSusepList from '~/pages/Backoffice/CgSusep/List';
import CgSusepForm from '~/pages/Backoffice/CgSusep/Form';
import Contestation from '~/pages/Backoffice/Contestation';
import ContestationCreate from '~/pages/Backoffice/Contestation/Create';
import Faq from '~/pages/Backoffice/Faq/index';
import ScreeningsPage from '~/pages/Backoffice/Screenings';
import PushNotification from '~/pages/Backoffice/PushNotification';
import Financial from '~/pages/Backoffice/Financial/index';
import PromotionCodeList from '~/pages/Backoffice/PromotionCode/List';
import PromotionCodeForm from '~/pages/Backoffice/PromotionCode/Form';
import Route from './Route';

export default function Routes() {
  return (
    <ModalSwitch
      renderModal={() => (
        <>
          <ModalRoute
            defaultParentPath="/person/search"
            path="/person/:id/provisional"
            component={ProvisionalPersonForm}
          />
          <ModalRoute
            defaultParentPath="/"
            path="/new-feedback"
            component={FeedbackForm}
          />
        </>
      )}
    >
      {/* modal routes */}
      <ModalRoute
        defaultParentPath="/person/search"
        path="/person/:id/provisional"
        component={ProvisionalPersonForm}
      />

      <ModalRoute
        defaultParentPath="/"
        path="/new-feedback"
        component={FeedbackForm}
      />

      {/* authenticated routes */}
      <Route path="/" exact component={PersonSearch} isPrivate />
      <Route path="/person/search" exact component={PersonSearch} isPrivate />

      <Route
        path="/person/:searchParam"
        exact
        component={PersonView}
        isPrivate
      />

      <Route path="/products" component={ProductList} isPrivate />
      <Route path="/roles" component={Roles} isPrivate />
      <Route path="/users" component={UserList} isPrivate />

      <Route
        path="/promotions/:context"
        exact
        component={PromotionList}
        isPrivate
      />

      <Route
        path="/promotions/:context/:id"
        component={PromotionView}
        isPrivate
      />

      <Route path="/screening" exact component={ScreeningList} isPrivate />
      <Route path="/screening/:id" component={ScreeningList} isPrivate />
      <Route path="/feedbacks" component={FeedbackList} isPrivate />

      <Route
        exact
        isPrivate
        path="/push-notifications"
        component={PushNotification}
      />

      <Route
        path="/push-notifications/create"
        component={PushNotificationCreate}
        isPrivate
      />

      <Route
        isPrivate
        path="/promotion-config"
        exact
        component={PromotionConfigMain}
      />

      <Route exact isPrivate path="/activities" component={ActivitiesList} />

      <Route path="/awards" component={AwardsList} isPrivate />

      <Route
        path="/promotion/drafts"
        exact
        component={PromotionDraftList}
        isPrivate
      />
      <Route
        path="/promotion/draft"
        exact
        component={PromotionForm}
        isPrivate
      />
      <Route path="/promotion/draft/:id" component={PromotionForm} isPrivate />
      <Route path="/banners-home" component={BannersHomePage} isPrivate />

      <Route path="/cgs-susep" component={CgSusepList} isPrivate />
      <Route path="/cg-susep" exact component={CgSusepForm} isPrivate />
      <Route path="/cg-susep/:id" component={CgSusepForm} isPrivate />

      <Route path="/constestation" exact component={Contestation} isPrivate />
      <Route path="/faq" exact component={Faq} isPrivate />
      <Route
        path="/constestation/create"
        component={ContestationCreate}
        isPrivate
      />

      <Route path="/financial" component={Financial} isPrivate />

      <Route
        path="/promotion-codes/form/:id"
        component={PromotionCodeForm}
        isPrivate
      />
      <Route
        path="/promotion-codes/form"
        component={PromotionCodeForm}
        isPrivate
      />
      <Route path="/promotion-codes" component={PromotionCodeList} isPrivate />

      {/* Screenings */}
      <Route isPrivate path="/screenings" component={ScreeningsPage} />

      {/* unauthenticated routes */}
      <Route path="/login" component={Login} />

      <Route path="*" component={NotFoundPage} isPrivate />
    </ModalSwitch>
  );
}
