import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { head } from 'lodash';

// -- services
// import api from '~/services/api';

// -- components
import { PhotoContainer, PhotoImage } from './styles';

export default function AwardPhoto({ data: award, onUpdated }) {
  const [menu, setMenu] = useState(null);
  const [imagemUrl, setImagemUrl] = useState(award);
  const [loading, setLoading] = useState(false);

  // -- dropzone
  const onDrop = useCallback(
    files => {
      if (files.length) {
        // -- loading
        setLoading(true);

        const formdata = new FormData();
        const imagem = head(files);

        // FileReader support
        if (FileReader && imagem) {
          const fr = new FileReader();
          fr.onload = () => {
            setImagemUrl(fr.result);
          };
          fr.readAsDataURL(imagem);
        }

        // -- append data
        formdata.append('imagem', imagem);

        if (onUpdated && typeof onUpdated === 'function') {
          onUpdated(imagem);
        }
        setLoading(false);
      }
    },
    [onUpdated]
  );

  const {
    open: openFileDialog,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
  });
  const handleMenuClick = target => {
    if (award) {
      setMenu(target);
    } else {
      setMenu(null);
      openFileDialog();
    }
  };
  return (
    <PhotoContainer>
      <PhotoImage
        onClick={e => handleMenuClick(e.currentTarget)}
        url={imagemUrl}
      >
        {loading && <CircularProgress />}
        {!imagemUrl && !loading && !isDragActive && (
          <p>Clique para selecionar uma imagem</p>
        )}
      </PhotoImage>

      <div
        {...getRootProps()}
        style={{ width: 0, height: 0, opacity: 0, display: 'none' }}
      >
        <input {...getInputProps()} />
      </div>

      <Menu
        keepMounted
        anchorEl={menu}
        open={!!menu}
        onClose={() => setMenu(null)}
      >
        <MenuItem onClick={() => window.open(award)}>Abrir</MenuItem>
        <MenuItem
          onClick={() => {
            setMenu(null);
            openFileDialog();
          }}
        >
          Trocar
        </MenuItem>
      </Menu>
    </PhotoContainer>
  );
}

AwardPhoto.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onUpdated: PropTypes.func.isRequired,
};
