import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { first, compact } from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import { Checkbox } from 'unform-material-ui';

export function DialogConfirm({ open, toggleDialog, handleConfirm }) {
  const [error, setError] = useState('');

  const schema = Yup.object().shape({
    options: Yup.array().min(1, 'Selecione pelo menos um processamento'),
  });

  const renderError = () => <p style={{ color: 'red' }}>{error}</p>;

  const handleConfirmar = async ({ options }) => {
    try {
      const sendCapitalizer = options[0] || false;
      const sendPayment = options[1] || false;

      await schema.validate(
        { options: compact(options) },
        {
          abortEarly: false,
        }
      );
      toggleDialog(false);
      handleConfirm({ sendCapitalizer, sendPayment });
    } catch (err) {
      setError(first(err.errors));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <Form onSubmit={handleConfirmar}>
          <DialogTitle id="form-dialog-title">
            Marcar o modo de envio
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Checkbox name="options.0" value={1} />
                </ListItemIcon>
                <ListItemText primary="Enviar Capitalizadora" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Checkbox name="options.1" value={1} />
                </ListItemIcon>
                <ListItemText primary="Enviar Pagamento e Push" />
              </ListItem>
            </List>
            {error && renderError()}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleDialog(!open)} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

DialogConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};
