/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import useProducts from '~/components/ProductList/useProducts';
import PropTypes from 'prop-types';
import { Avatar, Chip, CircularProgress } from '@material-ui/core';

export default function ProductsToUsers({ user }) {
  const { products, loading } = useProducts();

  const selectedProducts = useMemo(
    () =>
      products.filter(product =>
        user?.attributes?.productPermissions?.includes(product.id)
      ),
    [products]
  );

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : !user?.attributes?.productPermissions ? (
        <span>Nenhum produto liberado.</span>
      ) : (
        selectedProducts?.map(product => {
          return (
            <>
              <Chip
                key={product.id}
                label={product.titulo}
                avatar={<Avatar src={product.imagem} />}
                style={{ backgroundColor: 'transparent' }}
              />
            </>
          );
        })
      )}
    </>
  );
}

ProductsToUsers.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
    createdTimestamp: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    clientRoles: PropTypes.oneOfType([PropTypes.array]),
    attributes: PropTypes.shape({
      productPermissions: PropTypes.oneOfType([PropTypes.array]),
      qrCodeSecretKey: PropTypes.string,
      dateQrcodeRead: PropTypes.string,
      allowApiUsage: PropTypes.bool,
    }),
  }).isRequired,
};
