import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { MdAdd, MdOutlineImage } from 'react-icons/md';
import useEbooks from './useEbook';
import { Container, Thumbnail, Nome, Autor, AutoComplete } from './styles';

export default function EbookAutoComplete({
  onChange,
  onClickAdd,
  refetch,
  isDoubleCheck,
}) {
  const [value, setValue] = useState(null);
  const { ebooks, loading } = useEbooks({ refetch });
  const rendered = useRef(false);

  useEffect(() => {
    // call on change
    if (
      !!value &&
      onChange &&
      typeof onChange === 'function' &&
      rendered.current
    ) {
      onChange({ target: { value: value && value.id } });
    }

    // empty value
    if (!value && rendered.current) {
      onChange({ target: { value: null } });
    }

    // -- set rendered
    if (!rendered.current) rendered.current = true;
  }, [value]); // eslint-disable-line

  return (
    <AutoComplete
      style={{ height: '40px !important' }}
      fullWidth
      options={ebooks}
      value={value}
      loading={loading}
      onChange={(_, selectValue) => setValue(selectValue)}
      getOptionLabel={a => a.nome}
      renderOption={option => (
        <Container>
          <Thumbnail>
            {option.url ? (
              <img src={option.url} alt="thumbnail" width="40px" />
            ) : (
              <MdOutlineImage
                style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                size={20}
              />
            )}
          </Thumbnail>
          <div style={{ display: 'flex' }}>
            <Nome>{option.nome}</Nome> &nbsp;<Autor>({option.autor})</Autor>
          </div>
        </Container>
      )}
      renderInput={params => (
        <TextField
          variant="outlined"
          {...params}
          label="Selecione o ebook"
          InputProps={{
            ...params.InputProps,
            style: {
              ...params.InputProps.style,
              paddingRight: 'inherit',
            },
            endAdornment: !isDoubleCheck && (
              <InputAdornment position="end">
                <Button
                  style={{ marginRight: '1rem' }}
                  onClick={onClickAdd}
                  color="primary"
                  variant="outlined"
                  startIcon={<MdAdd />}
                >
                  Novo
                </Button>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

EbookAutoComplete.defaultProps = {
  isDoubleCheck: false,
};

EbookAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  refetch: PropTypes.bool.isRequired,
  isDoubleCheck: PropTypes.bool,
};
