import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import PropTypes from 'prop-types';

import api from '~/services/api';
import { DropContainer, UploadMessage } from './styles';
import Detail from '../Detail';

export default function Attachment({
  type,
  promotion,
  screening,
  anexo,
  getAttachments,
  disabled,
}) {
  const [file, setFile] = useState();

  const processUpload = fu => {
    const data = new FormData();

    data.append(String(fu.type).replace('-', '_'), fu.file);
    // data.append('fieldname', uploadedFile.type);

    api
      .post(
        `/promotions/${promotion}/immediate-screening/${screening}/attachments`,
        data,
        {
          onUploadProgress: e => {
            const progress = parseInt(
              Math.round((e.loaded * 100) / e.total),
              10
            );

            setFile({ ...fu, progress });
          },
        }
      )
      .then(() => {
        setFile({ ...fu, uploaded: true });
        getAttachments({ idTriagem: screening });
      })
      .catch(() => {
        setFile({ ...fu, error: true });
      });
  };

  const onDrop = useCallback(
    acceptedFiles => {
      const fu = acceptedFiles.map(f =>
        Object.assign(
          anexo,
          {
            file: f,
            url: URL.createObjectURL(f),
            new: true,
            type: type.type,
          },
          f
        )
      )[0];
      setFile(fu);
      processUpload(fu);
    },
    // TODO:
    // eslint-disable-next-line
    [anexo, type]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, .pdf',
    onDrop,
    disabled,
  });

  useEffect(() => {
    setFile(anexo);
  }, [anexo]);

  return (
    (type && (
      <>
        <h4>{type.name}</h4>
        <DropContainer {...getRootProps()} disabled={disabled}>
          <input {...getInputProps()} />
          <UploadMessage>Arraste e solte o arquivo aqui</UploadMessage>
        </DropContainer>
        {file && <Detail file={file} />}
      </>
    )) ||
    ''
  );
}

Attachment.propTypes = {
  promotion: PropTypes.number.isRequired,
  screening: PropTypes.number.isRequired,
  type: PropTypes.oneOfType([PropTypes.object]).isRequired,
  anexo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getAttachments: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
