import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
  CircularProgress,
  MenuItem,
  Menu,
  Typography,
} from '@material-ui/core';
import {
  MdVisibility,
  MdVisibilityOff,
  MdMenu,
  MdSearch,
  MdSend,
} from 'react-icons/md';

import { BiBlock } from 'react-icons/bi';
import PaymentIcon from 'react-payment-icons';
import { compareAsc, format, parseISO } from 'date-fns';
import Toast from '~/components/Toast';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';
import { hasPermission } from '~/components/AccessControl';
import formatCPF from '@brazilian-utils/format-cpf';
import {
  Table,
  Chip,
  DadosCartao,
  ContainerEyeIcon,
  ContainerIcons,
  NotFoundCpfText,
} from './styles';
import DialogReason from '../Dialog';
import DialogReasonSituation from '../DialogSituation';

export default function List({ person }) {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingBlocking, setLoadingBlocking] = useState(false);
  const [loadingIdentifierIcons, setLoadingIdentifierIcons] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSituation, setOpenDialogSituation] = useState(false);
  const [cardSelected, setCardSelected] = useState();
  const [action, setAction] = useState();
  const [elMenu, setElMenu] = useState(null);
  const [cardToken, setCardToken] = useState({});
  const [cardAvailable, setCardAvailable] = useState('');
  const [count, setCount] = useState(0);

  const [cardSelectedToken, setCardSelectedToken] = useState();

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleToggleAtivo = card => {
    if (hasPermission('toggle_activate_payment_method')) {
      setCardSelected(card);
      toggleDialog();
      setAction('delete');
    }
  };
  function handleOpenMenu(event) {
    setElMenu(event.currentTarget);
  }

  function handleCloseMenu() {
    setElMenu(null);
  }

  const renderModalInfo = () => {
    return (
      <>
        {count === 3 &&
          toast.warn(
            <Toast message="Aguarde enquanto a requisição é concluída." warn />
          )}
      </>
    );
  };

  const renderValidade = validade => {
    if (!validade && validade !== '') return '';
    const date = new Date();
    const yearMonthCard = validade.split('/');
    const today = new Date(date.getFullYear(), date.getMonth(), 1);
    const valid = new Date(yearMonthCard[1], yearMonthCard[0] - 1, 1);
    const diff = compareAsc(today, valid);
    return diff <= 0 ? '' : <p style={{ color: 'red' }}>Cartão vencido</p>;
  };

  const loadCards = async () => {
    setLoading(true);

    try {
      if (hasPermission('read_payment_methods')) {
        const { data } =
          (await api.get(`/persons/${person.idCliente}/payment-methods`)) || {};
        if (data) setCards(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const addCardToBlackList = async formReason => {
    setCount(count + 1);
    if (count > 2) {
      setCount(0);
    }
    setLoadingBlocking(true);
    try {
      await api.post(`/persons/${person.idCliente}/card/blacklist/`, {
        cardToken,
        formReason,
      });
    } finally {
      if (cardSelected.situacao === 'disponivel') {
        setCardSelected((cardSelected.situacao = 'bloqueado'));
        setCardAvailable('bloqueado');
      }
      setLoadingBlocking(false);
    }
  };

  const getDetailsCardTokens = async token => {
    const tokenSelectedCard = token || cardToken;

    setLoadingSearch(true);

    try {
      const { data } = await api.get(
        `/persons/${person.idCliente}/card/${tokenSelectedCard}`
      );

      if (data) {
        const cardData = await cards.docs.find(
          c => c.cardToken === tokenSelectedCard
        );

        cardData.isVisible = true;

        cardData.nomeCompletoCartao = data.holder;
        cardData.cpfCadastradoCartao = data.identification;
        cardData.validadeCartao =
          data.expirationMonth && data.expirationYear
            ? `${data.expirationMonth}/${data.expirationYear}`
            : '';
      }
    } finally {
      setLoadingIdentifierIcons(false);
      setLoadingSearch(false);
    }
  };

  const hiddeCardsInformations = async tokenSelectedCard => {
    try {
      const cardData = await cards.docs.find(
        c => c.cardToken === tokenSelectedCard
      );
      cardData.isVisible = false;
    } finally {
      setLoadingIdentifierIcons(false);
    }
  };
  const removeCardFromBlackList = async formReason => {
    setCount(count + 1);
    if (count >= 3) {
      setCount(0);
    }
    setLoadingBlocking(true);
    try {
      await api.post(
        `/persons/${person.idCliente}/card/blacklist/${cardToken}`,
        {
          formReason,
        }
      );
    } finally {
      if (cardSelected.situacao === 'bloqueado') {
        setCardSelected((cardSelected.situacao = 'disponivel'));
        setCardAvailable('disponivel');
      }
      setLoadingBlocking(false);
    }
  };

  useEffect(() => {
    if (person && !openDialog) loadCards();

    // TODO:
    // eslint-disable-next-line
  }, [person, openDialog]);

  const renderColor = color => {
    switch (color) {
      case 'disponivel':
        return <Typography style={{ color: 'blue' }}>Disponível</Typography>;
      case 'bloqueado':
        return <Typography style={{ color: '#f22915' }}>Bloqueado</Typography>;
      case 'cancelado':
        return <Typography style={{ color: '#f22915' }}>Cancelado</Typography>;
      case 'pendente':
        return <Typography>Pendente</Typography>;
      case 'aguardando':
        return <Typography>Aguardando</Typography>;
      case 'expirado':
        return <Typography style={{ color: '#f22915' }}>Expirado</Typography>;
      default:
    }
    return '';
  };

  const renderOptionsMenu = () => {
    if (cardAvailable === 'disponivel') {
      return loadingBlocking ? (
        <div style={{ marginLeft: 15 }}>
          <CircularProgress size={25} />
        </div>
      ) : (
        <MenuItem
          onClick={() => {
            setOpenDialogSituation(true);
          }}
        >
          <BiBlock size={25} />
          &nbsp;Bloquear Cartão
        </MenuItem>
      );
    }
    if (cardAvailable === 'bloqueado') {
      return loadingBlocking ? (
        <div style={{ marginLeft: 15 }}>
          <CircularProgress size={25} />
        </div>
      ) : (
        <MenuItem
          onClick={() => {
            setOpenDialogSituation(true);
          }}
        >
          <MdSend size={25} />
          &nbsp;Desbloquear Cartão
        </MenuItem>
      );
    }
    return <></>;
  };

  const renderMenu = () => {
    return (
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={elMenu}
        open={Boolean(elMenu)}
        onClose={() => handleCloseMenu}
      >
        {loadingSearch ? (
          <div style={{ marginLeft: 15, marginRight: 15 }}>
            <CircularProgress size={25} />
          </div>
        ) : (
          <MenuItem
            onClick={() => {
              getDetailsCardTokens();
            }}
          >
            <MdSearch size={25} />
            &nbsp;Buscar informações
          </MenuItem>
        )}
        {renderOptionsMenu()}
      </Menu>
    );
  };

  const handleChangeStates = card => {
    setLoadingIdentifierIcons(true);
    setCardSelectedToken(card.cardToken);
  };

  if (cards && cards.docs && cards.docs.length > 0) {
    return (
      <>
        {renderModalInfo()}
        {renderMenu()}

        {loading ? <CircularProgress size={24} /> : null}
        <Table>
          <thead>
            <tr>
              <th>Cartão</th>
              <th>Nome do titular</th>
              <th>Validade</th>
              <th>Data do cadastro</th>
              <th>Situação</th>
              <th>Status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {cards.docs.map(card => (
              <tr key={card.idFormaDePagamento}>
                <td>
                  <DadosCartao>
                    <PaymentIcon
                      id={
                        String(card.bandeiraCartao).toLowerCase() !==
                        'hipercard'
                          ? String(card.bandeiraCartao).toLowerCase()
                          : 'generic'
                      }
                      style={{ marginRight: 10, width: 50 }}
                      className="payment-icon"
                    />
                    <div>
                      <p>
                        Cartão de{' '}
                        {card.tipoCartao === 1 ? ' Débito' : ' Crédito'}
                      </p>
                      <p>{card.numeroCartao}</p>
                    </div>
                  </DadosCartao>
                </td>

                <td>
                  {loadingIdentifierIcons &&
                  card.cardToken === cardSelectedToken ? (
                    <div style={{ marginLeft: 24, marginRight: 24 }}>
                      <CircularProgress size={24} />
                    </div>
                  ) : (
                    <>
                      {card.isVisible ? (
                        <div>
                          {card.nomeCompletoCartao}
                          <br />
                          <ContainerIcons>
                            {card.cpfCadastradoCartao ? (
                              <div>{formatCPF(card.cpfCadastradoCartao)}</div>
                            ) : (
                              <NotFoundCpfText>
                                CPF NÃO VINCULADO
                              </NotFoundCpfText>
                            )}
                            <MdVisibilityOff
                              style={{ marginLeft: 4 }}
                              size={16}
                              onClick={() => {
                                hiddeCardsInformations(card.cardToken);
                                handleChangeStates(card);
                              }}
                            />
                          </ContainerIcons>
                        </div>
                      ) : (
                        <ContainerEyeIcon>
                          <MdVisibility
                            size={32}
                            onClick={() => {
                              getDetailsCardTokens(card.cardToken);

                              handleChangeStates(card);
                            }}
                          />
                        </ContainerEyeIcon>
                      )}
                    </>
                  )}
                </td>

                <td>
                  {card.validadeCartao}
                  {renderValidade(card.validadeCartao)}
                </td>

                <td>{format(parseISO(card.dataInsert), 'dd/MM/yyyy')}</td>
                <td>{renderColor(card.situacao)}</td>
                <td>
                  <Chip
                    label={card.status ? 'Ativo' : 'Excluído'}
                    disabled={card.situacao === 'cancelado'}
                    onDelete={() => handleToggleAtivo(card)}
                    color={card.status ? 'primary' : 'secondary'}
                  />
                </td>

                <td>
                  <MdMenu
                    size={30}
                    onClick={event => {
                      setCount(0);
                      setCardSelected(card);
                      setCardAvailable(card.situacao);
                      setCardToken(card.cardToken);
                      handleOpenMenu(event);
                      setCardSelectedToken(card.cardToken);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Dialog para Justificar quarentena */}
        {cardSelected ? (
          <DialogReason
            open={openDialog}
            toggleDialog={toggleDialog}
            person={person}
            card={cardSelected}
            action={action}
          />
        ) : (
          <br />
        )}
        {openDialogSituation ? (
          <DialogReasonSituation
            open={openDialogSituation}
            addCard={addCardToBlackList}
            removeCard={removeCardFromBlackList}
            onClose={() => {
              setOpenDialogSituation(false);
            }}
            loading={loadingBlocking}
            card={cardSelected}
            action={action}
          />
        ) : (
          <br />
        )}
      </>
    );
  }
  return <NoRecord message="Nenhum meio de pagamento cadastrado" />;
}

List.defaultProps = {
  person: null,
};

List.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};
