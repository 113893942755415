import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import useRaffleTypes from './useRaffleTypes';

function RaffleTypesAutocomplete({
  id,
  onChange,
  getOption,
  valueKey,
  label,
  value: defaultValue,
}) {
  const { raffleTypes, loading } = useRaffleTypes();
  const [value, setValue] = useState(null);
  const rendered = useRef(false);

  useEffect(() => {
    // call on change
    if (
      !!value &&
      onChange &&
      typeof onChange === 'function' &&
      rendered.current
    ) {
      onChange({ target: { value: value && value[valueKey] } });
    }

    // empty value
    if (!value && rendered.current) {
      onChange({ target: { value: null } });
    }

    // -- set rendered
    if (!rendered.current) rendered.current = true;
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (defaultValue) {
      const stringValue = String(defaultValue);
      const selected =
        raffleTypes.find(p => String(p[valueKey]) === stringValue) || null;
      if (selected !== undefined) setValue(selected);
    }
  }, [raffleTypes, defaultValue, valueKey]);

  useEffect(() => {
    if (defaultValue) {
      const stringValue = String(defaultValue);
      const selected =
        raffleTypes.find(p => String(p[valueKey]) === stringValue) || null;
      if (selected !== undefined) setValue(selected);
    }
  }, [raffleTypes, defaultValue, valueKey]);

  return (
    <Autocomplete
      options={raffleTypes}
      id={id}
      loading={loading}
      getOptionLabel={getOption}
      value={value}
      onChange={(_, selectValue) => setValue(selectValue)}
      renderInput={params => (
        <TextField
          variant="outlined"
          margin="normal"
          {...params}
          label={label}
        />
      )}
    />
  );
}

RaffleTypesAutocomplete.defaultProps = {
  onChange: () => {},
  getOption: opt => opt.titulo,
  valueKey: 'id',
  value: undefined,
  label: 'Selecione o Tipo do Sorteio',
  id: 'raffleType',
};

RaffleTypesAutocomplete.propTypes = {
  onChange: PropTypes.func,
  getOption: PropTypes.func,
  valueKey: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  id: PropTypes.string,
};

export default memo(RaffleTypesAutocomplete);
