import styled from 'styled-components';
import { Grid, Paper as PP, TableRow as TR, Button } from '@material-ui/core';
import NoImage from '~/assets/no-image.png';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  tbody td {
    text-align: left;
    padding: 8px;
  }
`;
export const TableRow = styled(TR)`
  td {
    font-size: 18px;
  }
`;

export const ButtonChargeBack = styled(Button)`
  background-color: ${props =>
    props.disabled ? '#f4f4f4' : '#dd5a66'} !important;
  color: ${props => (props.disabled ? '#a8a8a3' : '#fff')} !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
`;

export const Dezena = styled.span`
  padding: 10px;
  border-radius: 100px;
  background-color: white;
  color: #616161;
  font-family: monospace;
  font-weight: 800;
`;

export const Product = styled.div`
  background-image: url(${props => (props.image ? props.image : NoImage)});
  background-size: contain;
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5f5f5;
`;

export const Paper = styled(PP)`
  background: #d8d8d8 !important;
  padding: 10px;
  width: 230px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;

export const GridCustom = styled(Grid)`
  border: solid 1px #dadada;
  border-radius: 4px;
  color: #616161;

  div.title {
    background-color: #f5f5f5;
    padding: 10px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    svg {
      margin-right: 5px;
    }
  }

  div.body {
    padding: 10px;
  }

  div.footer {
    font-size: 12px;
    background-color: #fafafa;
    padding: 10px;
  }

  button.titulo {
    font-size: 30px;
    border: none;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
