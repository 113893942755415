import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 10px;

  span {
    margin-left: 10px;
  }
`;
