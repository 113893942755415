import React from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import { Title, Content } from './styles';

function Detail({ prop, onClose }) {
  const {
    tids,
    tidsErrors,
    status: statusJob,
    sendDate,
    countBlockUser,
    createdBy,
    error,
  } = prop;

  const status = value => {
    if (value === 'done') return 'Completo';
    if (value === 'error') return 'Não completou';
    return 'Pendente';
  };

  const totalAmount = new Intl.NumberFormat('pt-br', {
    currency: 'BRL',
    style: 'currency',
  }).format(prop.totalAmount || 0);

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <Content style={{ flexDirection: 'column' }}>
        <Title>Detalhamento de Tids Contestadas</Title>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                Total de Tids: {tids.length || 0}
              </TableCell>
              <TableCell style={{ color: '#000' }}>
                Tids OK:
                {(tids.length || 0) - ((tidsErrors && tidsErrors.length) || 0)}
              </TableCell>
              <TableCell style={{ color: '#000' }}>
                Tids com avisos:{(tidsErrors && tidsErrors.length) || 0}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ color: '#000' }}>
                Contas Bloqueadas:
              </TableCell>
              <TableCell colSpan={2} style={{ color: '#000' }}>
                {countBlockUser || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                Valor das transações:
              </TableCell>
              <TableCell colSpan={2} style={{ color: '#000' }}>
                {totalAmount || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>Criado por:</TableCell>
              <TableCell colSpan={2} style={{ color: '#000' }}>
                {createdBy ? createdBy.name : 'Não informado'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>Enviado às:</TableCell>
              <TableCell colSpan={2} style={{ color: '#000' }}>
                {format(parseISO(sendDate), 'dd/MM/yyyy HH:mm')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>Status do Job:</TableCell>
              <TableCell colSpan={2} style={{ color: '#000' }}>
                {status(statusJob)}
              </TableCell>
            </TableRow>
            {statusJob === 'error' && !!error && (
              <TableRow>
                <TableCell style={{ color: 'red', fontWeight: 'bold' }}>
                  Error:
                </TableCell>
                <TableCell
                  colSpan={2}
                  style={{ color: 'red', fontWeight: 'bold' }}
                >
                  {error}
                </TableCell>
              </TableRow>
            )}
            {tidsErrors && tidsErrors.length > 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={3} style={{ fontWeight: 'bold' }}>
                    Lista de avisos das Tids
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tid</TableCell>
                  <TableCell colSpan={2}>Aviso</TableCell>
                </TableRow>
              </>
            )}
            {tidsErrors &&
              tidsErrors.map(t => (
                <TableRow>
                  <TableCell style={{ color: '#000' }}>{t.tid}</TableCell>
                  <TableCell colSpan={2} style={{ color: '#000' }}>
                    {t.error}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Content>
    </Dialog>
  );
}

export default Detail;

Detail.defaultProps = {
  onClose: () => {},
  prop: {},
};

Detail.propTypes = {
  onClose: PropTypes.func,
  prop: PropTypes.shape(),
};
