import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { ProductAutocomplete } from '~/components';
import { FeedbackFilterContainer } from './styles';

export default function FeedbackFilters({ sendFilters }) {
  const [cpfNome, setCpfNome] = useState(null);
  const [producFilter, setProductFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);

  const ref = useRef(null);
  const submitFilters = async () => {
    sendFilters({ q: cpfNome, product: producFilter, status: statusFilter });
  };

  const options = [
    { label: 'Ativo', value: 'ativado' },
    { label: 'Desativado', value: 'desativado' },
  ];
  const clearAll = () => {
    const clearAutocomplete = ref.current.getElementsByClassName(
      'MuiAutocomplete-clearIndicator'
    )[0];

    setCpfNome('');
    setProductFilter([]);
    setStatusFilter([]);
    sendFilters({ q: '', product: '', status: '' });

    if (clearAutocomplete) {
      clearAutocomplete.click();
      document.getElementById('status').blur();
    }
  };
  return (
    <FeedbackFilterContainer>
      <TextField
        id="cpfNome"
        value={cpfNome}
        onChange={e => setCpfNome(e.target.value)}
        label="Procurar por CPF ou Nome"
      />

      <ProductAutocomplete
        id="productField"
        value={producFilter}
        onChange={e => setProductFilter(e.target.value)}
      />

      <Autocomplete
        id="status"
        ref={ref}
        value={
          typeof statusFilter === 'object' ? statusFilter.value : statusFilter
        }
        options={options}
        getOptionLabel={opt => opt.label}
        onChange={(_, opt) => {
          if (opt && opt.value) {
            setStatusFilter(opt.value);
          }
        }}
        renderInput={params => (
          <TextField {...params} label="Filtrar por status" />
        )}
      />
      <div>
        <Button variant="text" color="secondary" onClick={clearAll}>
          Limpar filtros
        </Button>
        <Button
          style={{ width: 100, marginLeft: 20 }}
          color="primary"
          variant="contained"
          onClick={submitFilters}
        >
          Filtrar
        </Button>
      </div>
    </FeedbackFilterContainer>
  );
}

FeedbackFilters.propTypes = {
  sendFilters: PropTypes.func.isRequired,
};
