import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';
import { useSelector } from 'react-redux';
import { ConfigsProvider } from '~/contexts/useConfig';
import Draft from './draft';

const DraftContainer = props => {
  const product = useSelector(({ user }) => user.product);

  return (
    <ConfigsProvider codigo={product.cod}>
      <ConfirmProvider>
        <Draft {...props} />
      </ConfirmProvider>
    </ConfigsProvider>
  );
};

export default DraftContainer;
