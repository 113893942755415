import { isValid } from 'date-fns';
import React, { useEffect } from 'react';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { TextField as MUITextField } from '@material-ui/core';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { pickAll } from 'ramda';
import { has } from 'lodash';

function getMask(dateFormat) {
  return dateFormat.replace(/\w/gi, '_');
}

function IDateTimePicker({
  clearCalendar,
  inputRef,
  onChange,
  value: defaultValue = null,
  format = 'dd/MM/yyyy HH:mm',
  mask,
  ...rest
}) {
  const [selectedDate, setSelectedDate] = React.useState(defaultValue);

  const handleDateChange = date => {
    setSelectedDate(date);

    if (typeof onChange === 'function') onChange(date);
  };
  const onClearCalendar = () => {
    setSelectedDate(null);
  };

  useEffect(() => {
    onClearCalendar();
  }, [clearCalendar]);

  useUpdateEffect(() => {
    if (isValid(defaultValue)) {
      setSelectedDate(defaultValue);
    }
  }, [defaultValue]);

  const { inputVariant, ...inputProps } = pickAll(
    [
      'id',
      'label',
      'style',
      'className',
      'inputVariant',
      'InputLabelProps',
      'InputProps',
      'inputProps',
      'fullWidth',
      'margin',
      'name',
    ],
    rest
  );

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ptBR}>
      <DateTimePicker
        {...rest}
        allowSameDateSelection
        renderInput={props => {
          return (
            <MUITextField
              {...inputProps}
              {...props}
              name={inputProps.name}
              id={inputProps.id || undefined}
              inputRef={refValue => {
                if (props.inputRef && typeof props.inputRef === 'function')
                  props.inputRef(refValue);

                if (props.inputRef && has(props, 'inputRef.current')) {
                  props.inputRef.current = refValue;
                } else {
                  props.inputRef = refValue;
                }

                if (inputRef) inputRef.current = refValue;
              }}
              InputLabelProps={{
                shrink: !!selectedDate,
                ...inputProps.InputLabelProps,
              }}
              variant={inputVariant}
              helperText={undefined}
            />
          );
        }}
        mask={mask || getMask(format)}
        inputFormat={format}
        value={selectedDate}
        onChange={handleDateChange}
        ref={inputRef}
      />
    </LocalizationProvider>
  );
}

IDateTimePicker.defaultProps = {
  value: null,
  id: '',
  format: 'dd/MM/yyyy HH:mm',
  onChange: () => {},
  mask: null,
  clearCalendar: null,
};

IDateTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.any, // eslint-disable-line
  format: PropTypes.string,
  mask: PropTypes.string,
  clearCalendar: PropTypes.bool,
};

export default IDateTimePicker;
