import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import ProductList from '~/components/ProductList';

function ProductDrawer({ opened, toggleDrawer, handleSelectProduct }) {
  return (
    <>
      <Drawer anchor="right" open={opened} onClose={toggleDrawer(false)}>
        <ProductList limited handleSelectProduct={handleSelectProduct} />
      </Drawer>
    </>
  );
}

ProductDrawer.defaultProps = {
  opened: false,
};

ProductDrawer.propTypes = {
  opened: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  handleSelectProduct: PropTypes.func.isRequired,
};

export default memo(ProductDrawer);
