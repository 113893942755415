import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  margin-bottom: 20px;
`;

export const TitleTag = styled.h1`
  font-weight: 10;
  font-family: 'arial';
  font-size: 14px;
`;

export const ContentFlexSpace = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.main`
  flex-grow: 1;
  padding: 24px;
  min-height: 100%;
  margin-top: 56px;
  margin-left: ${({ width }) => width}px;

  ${({ expanded }) =>
    expanded &&
    css`
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
      margin-left: 70px !important;
    `};

  @media (min-width: 0px) and (orientation: landscape) {
    margin-top: 48px;
  }

  @media (min-width: 600px) {
    margin-top: 64px;
  }
`;

export const ToolBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
`;
