import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import api from '~/services/api';
import { FaTrash } from 'react-icons/fa';

export default function DeleteDialog({ promotionCode }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeletePromotionCode = async () => {
    try {
      setLoading(true);
      await api.delete(`/promotion-codes/${promotionCode.idCodigoPromocional}`);
    } finally {
      setLoading(false);
      handleClose();
      window.location.reload();
    }
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        <FaTrash size={16} style={{ marginRight: 15 }} /> Excluir
      </MenuItem>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>Você tem certeza?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={handleDeletePromotionCode}
          >
            {!loading ? 'Confirmar' : <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  promotionCode: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
