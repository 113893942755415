import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
  CircularProgress,
  Grid,
  withStyles,
  StepConnector,
} from '@material-ui/core';
import {
  MdArrowBack,
  MdArrowForward,
  MdFlag,
  MdWarning,
  MdCheck,
  MdDelete,
} from 'react-icons/md';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import { setParams, setProduct } from '~/store/modules/user/actions';
import { NoRecord } from '~/components';
import { Content } from '~/styles/global';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import { useConfigsContext } from '~/contexts/useConfig';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import PropTypes from 'prop-types';
import Raffle from './Raffle';
import ToastAction from './ToastAction';
import PromotionForm from './Promotion';
import Regulation from './Regulation';
import Banner from './Banner';
import Ebook from './Ebook';
import useDraft from './useDraft';
import { Status } from './styles';
import Log from './Log';
import Review from './Review';
import DoubleCheck from './DoubleCheck';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3f51b5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3f51b5',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export default function Draft({ match }) {
  const config = useConfigsContext();
  const dispatch = useDispatch();
  const product = useSelector(({ user }) => user.product);
  const products = useSelector(({ user }) => user.products);
  const params = useSelector(({ user }) => user.params);
  const draft = useSelector(({ user }) => user.draft);
  const userProfile = useSelector(({ user }) => user.profile);

  const confirm = useConfirm();
  const CAN_DELETE = hasPermission('delete_promotion_draft', { toast: false });

  const [nextStep, setNextStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const {
    loadingDraft,
    updateDraft,
    setId,
    deleteDraft,
    deletingDraft,
  } = useDraft();

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState();
  const [search, setSearch] = useState();
  const [noDraft, setNoDraft] = useState(false);
  const draftStatus = useMemo(() => get(draft, 'status', null), [draft]);

  const getDescription = useCallback(status => {
    return status === 'draft'
      ? 'Confirma o envio dessa promoção para Aprovação ?'
      : 'Deseja realmente Aprovar essa promoção ?';
  }, []);

  const handleClickOpenConfirm = useCallback(async () => {
    try {
      const handleChangeStatus = () => {
        setLoading(true);
        if (
          config.steps.conferencia &&
          userProfile?._id === draft?.doubleCheckRequestedBy?._id
        ) {
          setLoading(false);
          toast.error(
            <Toast
              message="Você não pode confirmar uma promoção que você pediu para conferir."
              error
            />
          );
          return;
        }
        if (
          config.steps.conferencia &&
          userProfile?._id === draft?.doubleCheckedBy?._id
        ) {
          setLoading(false);
          toast.error(
            <Toast
              message="Você não pode confirmar uma promoção que você conferiu."
              error
            />
          );
          return;
        }
        const verb = draftStatus === 'draft' ? 'put' : 'post';
        if (
          (hasPermission('create_promotion_draft') && verb === 'post') ||
          (hasPermission('update_promotion_draft') && verb === 'put')
        ) {
          api[verb](
            `/promotions/drafts/${draft._id}/${
              draftStatus === 'draft' ? 'pending' : 'approve'
            }`,
            { config }
          )
            .then(async res => {
              if (res.status === 204) {
                updateDraft({
                  ...draft,
                  status: draftStatus === 'draft' ? 'pending' : 'approved',
                });
                return;
              }

              if (res.data.codeError === 1) {
                const { title, currentDate, lastDate } = res.data;
                const getContent = () => (
                  <p>
                    <b>Ultima Data de Vigência</b>: {lastDate} <br />
                    <b>Início da Data de Vigência deste rascunho</b>:{' '}
                    {currentDate}
                  </p>
                );
                await confirm({
                  title,
                  content: getContent(),
                  dialogProps: {
                    disableBackdropClick: true,
                    fullWidth: true,
                    maxWidth: 'sm',
                  },
                  confirmationText: 'SIM',
                  confirmationButtonProps: { color: 'primary' },
                  cancellationText: 'NÃO',
                  cancellationButtonProps: { color: 'secondary' },
                });
                setLoading(true);

                const {
                  status,
                } = await api.put(
                  `/promotions/drafts/${draft._id}/pending?ignore=1`,
                  { config }
                );
                if (status === 204) {
                  updateDraft({
                    ...draft,
                    status: draftStatus === 'draft' ? 'pending' : 'approved',
                  });
                }
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }
      };

      await confirm({
        title: 'Atenção',
        description: getDescription(draftStatus),
        dialogProps: {
          disableBackdropClick: true,
          fullWidth: true,
          maxWidth: 'sm',
        },
        confirmationText: 'SIM',
        confirmationButtonProps: { color: 'primary' },
        cancellationText: 'NÃO',
        cancellationButtonProps: { color: 'secondary' },
      });
      handleChangeStatus();
    } catch (err) {} // eslint-disable-line
  }, [
    confirm,
    draft,
    updateDraft,
    draftStatus,
    config,
    getDescription,
    userProfile,
  ]);

  const requestDoubleCheck = useCallback(async () => {
    setLoading(true);
    try {
      const { data, status } = await api.post(
        `/promotions/drafts/${draft._id}/request-double-check`
      );

      if (data && status === 200) {
        const d = { ...data };
        d.doubleCheckRequestedBy = { _id: userProfile?._id };
        updateDraft(d);
        return toast.success(
          <Toast message="Rascunho enviado para conferência." success />
        );
      }
      return toast.error(
        <Toast
          message="Ocorreu um erro ao enviar o rascunho para conferência."
          error
        />
      );
    } catch (error) {
      return toast.error(
        <Toast
          message="Ocorreu um erro ao enviar o rascunho para conferência."
          error
        />
      );
    } finally {
      setLoading(false);
    }
  }, [draft, updateDraft, setLoading]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setParams({ ...params, useFilterByProduct: true }));
    }, 200);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      updateDraft({});
      dispatch(setParams({ ...params, useFilterByProduct: false }));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setId(match.params.id);
  }, [match, setId]);

  useEffect(() => {
    if (
      draft &&
      draftStatus === 'pending' &&
      config?.steps?.conferencia &&
      draft.doubleCheck &&
      userProfile?._id !== draft?.doubleCheckRequestedBy?._id &&
      userProfile?._id !== draft?.doubleCheckedBy?._id
    ) {
      setNextStep(true);
    }

    if (
      config?.steps?.conferencia === false &&
      activeStep === steps?.length - 1 &&
      draftStatus === 'pending'
    ) {
      setNextStep(draft._id);
    }

    if (search?.review && draft && draftStatus !== 'draft') {
      setActiveStep(steps?.length - 1);
      setNextStep(draft._id);
    }
  }, [
    search,
    draft,
    draftStatus,
    steps,
    userProfile,
    activeStep,
    config?.steps,
  ]);

  useEffect(() => {
    setNoDraft(
      draft &&
        Object.keys(draft).length > 0 &&
        draft?.data?.promotion?.idProduto !== product.id
    );
  }, [product, draft]);

  useEffect(() => {
    const step = new URLSearchParams(location.search).get('step');
    const hash = new URLSearchParams(location.search).get('hash');
    const review =
      String(step)
        .trim()
        .toLowerCase() === 'review';

    if (hash) {
      let userProds = [];
      if (userProfile.productPermissions === -1) {
        userProds = products;
      } else {
        userProds = products.filter(p =>
          userProfile.productPermissions.includes(p.id)
        );
      }
      const hashProduct = userProds.find(p => p.hash === hash);
      if (hashProduct) {
        dispatch(setProduct(hashProduct));
      }
    }
    setSearch({ hash, review });
  }, [location, products, dispatch, userProfile.productPermissions]);

  const isStepFailed = _step => {
    if (!draft?._id || !draft?.data?.promotion) {
      return false;
    }

    switch (_step) {
      case 'promocao':
        return (
          !draft.data.promotion.tituloPromocao ||
          !draft.data.promotion.dataReferencia ||
          (!config.fields.promotions.categoria
            ? false
            : !draft.data.promotion.categoria) ||
          !draft.data.promotion.dataSorteioPrincipal ||
          !draft.data.promotion.valorProduto
        );
      case 'regulamento':
        return !draft.data.regulation;
      case 'banners':
        return !draft?.data?.banners?.length;
      case 'sorteios':
        return !draft?.data?.sweepstakes?.length;
      case 'ebook':
        return !draft?.data?.ebooks?.length;
      default:
        return false;
    }
  };

  const handleNext = () => {
    const acaoBrindeIndex = steps?.findIndex(
      step => step.step === 'acaoBrinde'
    );

    const ebooksIndex = steps?.findIndex(step => step.step === 'ebook');

    if (activeStep === steps?.length - 1) {
      handleClickOpenConfirm();
    } else if (nextStep) {
      if (
        config.steps.conferencia &&
        draftStatus !== 'approved' &&
        draftStatus !== 'double-check' &&
        (activeStep === acaoBrindeIndex || activeStep === ebooksIndex)
      ) {
        requestDoubleCheck();
      } else if (
        config.steps.conferencia &&
        draftStatus !== 'approved' &&
        draftStatus === 'double-check' &&
        draft?.doubleCheckRequestedBy?._id === userProfile?._id &&
        (activeStep === acaoBrindeIndex || activeStep === ebooksIndex)
      ) {
        toast.warn(<Toast message="Rascunho em conferência." warn />);
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Render Steps
  useEffect(() => {
    const allSteps = [
      {
        step: 'promocao',
        labelProps: {},
        stepProps: {
          disabled: draftStatus === 'deleted',
        },
        content: product && config?.fields && (
          <PromotionForm
            product={product}
            config={config}
            draft={draft || {}}
            updateDraft={updateDraft}
            setNextStep={setNextStep}
          />
        ),
        label: 'Promoção',
      },
      {
        step: 'regulamento',
        labelProps: {},
        stepProps: {
          disabled: draftStatus === 'deleted',
        },
        content: draft?.data && product && (
          <Regulation
            draft={draft}
            updateDraft={updateDraft}
            setNextStep={setNextStep}
          />
        ),
        label: 'Regulamento',
      },
      {
        step: 'banners',
        labelProps: {},
        stepProps: {
          disabled: draftStatus === 'deleted',
        },
        content: draft?.data && product && (
          <Banner
            draft={draft}
            updateDraft={updateDraft}
            setNextStep={setNextStep}
          />
        ),
        label: 'Banners',
      },
      {
        step: 'sorteios',
        labelProps: {},
        stepProps: {
          disabled: draftStatus === 'deleted',
        },
        content: draft?.data && product && (
          <Raffle
            draft={draft}
            updateDraft={updateDraft}
            setNextStep={setNextStep}
          />
        ),
        label: 'Sorteios',
      },
      {
        step: 'acaoBrinde',
        labelProps: {},
        stepProps: {
          disabled: draftStatus === 'deleted',
        },
        content: draft?.data && product && (
          <ToastAction
            draft={draft}
            updateDraft={updateDraft}
            setNextStep={setNextStep}
            product={product}
          />
        ),
        label: 'Ação Brinde',
      },
      {
        step: 'ebook',
        labelProps: {},
        stepProps: { disabled: draftStatus === 'deleted' },
        content: draft?.data && product && (
          <Ebook
            draft={draft}
            updateDraft={updateDraft}
            setNextStep={setNextStep}
          />
        ),
        label: 'Ebook',
      },
      {
        step: 'conferencia',
        labelProps: {},
        stepProps: {},
        content: draft?.data && product && (
          <DoubleCheck
            setNextStep={setNextStep}
            draft={draft}
            product={product}
            updateDraft={updateDraft}
            config={config}
          />
        ),
        label: 'Conferência',
      },
      {
        step: 'revisao',
        labelProps: {},
        stepProps: {},
        content: draft?.data && product && (
          <Review draft={draft} product={product} />
        ),
        label: 'Revisão',
      },
    ];

    if (config?.steps) {
      const stps = allSteps.filter(step => config.steps[step.step]);

      setSteps(stps);
    }
  }, [product, draft, setNextStep, updateDraft, draftStatus, config]);

  useEffect(() => {
    const doubleCheckIndex = steps?.findIndex(
      step => step.step === 'conferencia'
    );
    const userId = userProfile?._id;
    const doubleCheckRequestById = draft?.doubleCheckRequestedBy?._id;
    const doubleCheckedById = draft?.doubleCheckedBy?._id;

    if (draftStatus === 'deleted') {
      setActiveStep(4);
      return;
    }

    if (draftStatus === 'pending') {
      if (doubleCheckedById === userId) {
        setActiveStep(doubleCheckIndex);
      } else if (doubleCheckRequestById === userId) {
        setActiveStep(0);
      } else {
        setActiveStep(steps?.length - 1);
      }
    } else if (
      draftStatus === 'double-check' &&
      doubleCheckRequestById !== userId
    ) {
      setActiveStep(doubleCheckIndex);
    }
  }, [
    draftStatus,
    steps,
    draft?.doubleCheckRequestedBy?._id,
    draft?.doubleCheckedBy?._id,
    userProfile?._id,
  ]);

  const getStatus = status => {
    switch (status) {
      case 'draft':
        return (
          <Status
            backgroundColor="#FF5722"
            color="white"
            className="animated flash infinite slower"
          >
            <MdFlag size={18} />
            &nbsp;Rascunho
          </Status>
        );
      case 'pending':
        return (
          <Status
            backgroundColor="#FFEB3B"
            color="black"
            className="animated flash infinite slowest"
          >
            <MdWarning size={18} />
            &nbsp;Pendente
          </Status>
        );
      case 'double-check':
        return (
          <Status
            backgroundColor="#FFEB3B"
            color="black"
            className="animated flash infinite slowest"
          >
            <MdWarning size={18} />
            &nbsp;Conferencia Pendente
          </Status>
        );
      case 'approved':
        return (
          <Status
            backgroundColor="#4CAF50"
            color="white"
            className="animated bounceIn"
          >
            <MdCheck size={18} />
            &nbsp;Aprovado
          </Status>
        );
      case 'deleted':
        return (
          <Status
            backgroundColor="#FF0000"
            color="white"
            className="animated bounceIn"
          >
            <MdDelete size={18} />
            &nbsp;Excluído
          </Status>
        );
      default:
        return '';
    }
  };

  const getFinalizarLabel = () => {
    if (loading || !config) return <CircularProgress size={25} />;

    switch (draftStatus) {
      case 'double-check':
        return 'Enviar para Aprovação';
      case 'draft':
        if (config.steps.conferencia) {
          return 'Enviar para Conferência';
        }
        return 'Enviar para Aprovação';
      case 'pending':
        return 'Aprovar';
      case 'approved':
        return 'Aprovado';
      default:
        return 'Cancelar';
    }
  };
  const handleDelete = React.useCallback(async () => {
    try {
      await confirm({
        title: 'Tem certeza que deseja excluir esta promoção?',
        description:
          'Você está prestes a exluir uma promoção, isto inclui todos recursos relacionados a ela, como: Banners, Sorteios e Regulamento. Você deseja continuar?',
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        cancellationButtonProps: { color: 'secondary' },
        confirmationText: 'Sim, continuar!',
        confirmationButtonProps: { color: 'primary' },
      });
      deleteDraft(draft._id);
    } catch (err) {} // eslint-disable-line
  }, [draft, confirm, deleteDraft]);

  const getNextButtonLabel = useCallback(() => {
    if (activeStep === steps?.length - 1) {
      return getFinalizarLabel();
    }

    const conferenciaIndex = steps?.findIndex(
      step => step.step === 'conferencia'
    );

    if (activeStep === conferenciaIndex - 1 && draftStatus !== 'approved') {
      return 'Enviar para conferência';
    }

    if (activeStep === conferenciaIndex && draftStatus !== 'approved') {
      return 'Enviar para aprovação';
    }
    return 'Próximo';
  }, [activeStep, getFinalizarLabel, steps]);

  const renderMain = () => {
    const isDoubleCheckRequestedByUser =
      draft?.doubleCheckRequestedBy?._id === userProfile?._id;

    const isDoubleCheckedByUser =
      draft?.doubleCheckedBy?._id === userProfile?._id;

    return product?.id && steps ? (
      <>
        <Header>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <h1 style={{ fontWeight: 400, fontSize: 26 }}>
                Cadastro de Promoção
              </h1>
            </Grid>
            <Grid item xs={6} align="right">
              {draft && getStatus(draftStatus)}
              <Typography
                variant="body2"
                color="primary"
                className="animated fadeIn delay-2s"
              >
                {draft?.data?.promotion?.tituloPromocao}
              </Typography>
            </Grid>
          </Grid>
        </Header>

        {loadingDraft || !config ? (
          <CircularProgress />
        ) : (
          <div>
            <Stepper activeStep={activeStep} connector={<QontoConnector />}>
              {steps?.map(step => {
                if (isStepFailed(step.step)) {
                  step.labelProps.optional = (
                    <Typography variant="caption" color="error">
                      Etapa incompleta
                    </Typography>
                  );
                  step.labelProps.error = true;
                }
                return (
                  <Step key={step.label} {...step.stepProps}>
                    <StepLabel {...step.labelProps}>
                      <h2>{step.label}</h2>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {/* Tela final de review */}
              {activeStep === steps?.length ? (
                <div>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset}>Salvar</Button>
                </div>
              ) : (
                <div style={{ marginTop: 10 }}>
                  {/* Botoes */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {draftStatus !== 'deleted' && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            activeStep === 0 ||
                            (config.steps.conferencia &&
                              draftStatus === 'double-check' &&
                              !isDoubleCheckRequestedByUser &&
                              !isDoubleCheckedByUser) ||
                            (config.steps.conferencia &&
                              draftStatus === 'pending' &&
                              (!isDoubleCheckRequestedByUser ||
                                !isDoubleCheckedByUser))
                          }
                          onClick={handleBack}
                        >
                          <MdArrowBack size={22} />
                          Voltar
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={6} align="right">
                      {CAN_DELETE &&
                        draftStatus === 'approved' &&
                        activeStep === steps?.length - 1 && (
                          <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginRight: 10 }}
                            disabled={deletingDraft}
                            onClick={handleDelete}
                          >
                            {deletingDraft ? (
                              <CircularProgress size={20} />
                            ) : (
                              <MdDelete />
                            )}
                            Excluir
                          </Button>
                        )}
                      {draftStatus !== 'deleted' && (
                        <Button
                          variant="contained"
                          color={
                            activeStep === steps?.length - 1
                              ? 'secondary'
                              : 'primary'
                          }
                          onClick={handleNext}
                          disabled={
                            loading ||
                            !nextStep ||
                            (activeStep === steps?.length - 1 &&
                              draftStatus === 'approved') ||
                            (activeStep === steps?.length - 1 &&
                              draftStatus === 'draft' &&
                              !hasPermission('pending_promotion_draft', {
                                toast: false,
                              })) ||
                            (activeStep === steps?.length - 1 &&
                              draftStatus === 'pending' &&
                              !hasPermission('approve_promotion_draft', {
                                toast: false,
                              })) ||
                            (config.steps.conferencia &&
                              activeStep === steps?.length - 1 &&
                              draftStatus === 'double-check' &&
                              !hasPermission('double_check_promotion_draft', {
                                toast: false,
                              }))
                          }
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <>
                              {getNextButtonLabel()}
                              <MdArrowForward size={22} />
                            </>
                          )}
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  {/* step content */}
                  {steps?.[activeStep] && (
                    <div style={{ marginTop: 10 }}>
                      <DndProvider backend={Backend}>
                        {steps[activeStep].content}
                      </DndProvider>
                    </div>
                  )}
                </div>
              )}
            </div>
            <br />
            {draft?._id && activeStep === 0 && (
              <Content>
                <Log draft={draft} />
              </Content>
            )}
          </div>
        )}
      </>
    ) : (
      <NoRecord message="Favor Selecionar o produto" />
    );
  };

  return noDraft ? <NoRecord /> : renderMain();
}

Draft.defaultProps = {
  draft: {},
  match: {},
};

Draft.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};
