import styled from 'styled-components';

export const Row = styled.div`
  margin: 10px 0px;
`;

export const Label = styled.p`
  font-weight: 600;
  font-size: 14px;
`;

export const InputText = styled.input`
  border: none;
  font-size: 1rem;
  width: 100%;
`;
