import produce from 'immer';

const INITIAL_STATE = {
  profile: {},
  permissions: [],
  roles: [],
  product: {},
  params: {},
  draft: null,
};

export default function user(state = INITIAL_STATE, { type, payload }) {
  return produce(state, draftState => {
    switch (type) {
      case '@auth/SIGN_IN_SUCCESS': {
        const { permissions, roles, ...userData } = payload.user;

        draftState.profile = userData;
        draftState.permissions = permissions;
        draftState.roles = roles;
        draftState.product = {};
        draftState.products = [];
        draftState.params = {};
        draftState.draft = null;
        break;
      }

      case '@auth/SIGN_OUT': {
        draftState.profile = {};
        draftState.product = {};
        draftState.products = [];
        draftState.params = {};
        draftState.draft = null;
        break;
      }

      case '@user/SET_PRODUCT': {
        draftState.product = payload.product;
        break;
      }

      case '@user/SET_PRODUCTS': {
        draftState.products = payload.products;
        break;
      }

      case '@user/SET_PARAMS': {
        draftState.params = payload.params;
        break;
      }

      case '@user/SET_DRAFT': {
        draftState.draft = payload.draft;
        break;
      }

      default:
    }
  });
}
