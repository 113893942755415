import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { MdClear } from 'react-icons/md';

export default function PushCreateImageUpload({ onChange, src }) {
  const EMPTY_TEXT = 'Nenhuma imagem selecionada';
  const [filename, setFilename] = useState(EMPTY_TEXT);

  const onDrop = useCallback(
    ([file]) => {
      setFilename(file.name);
      onChange(file);
    },
    [onChange]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function clearInput() {
    setFilename(EMPTY_TEXT);
    onChange(null);
  }
  const hasFilled = useMemo(() => {
    return src || filename !== EMPTY_TEXT;
  }, [src, filename]);

  const fileLabel = useMemo(() => {
    if (filename !== EMPTY_TEXT) {
      return filename;
    }
    return src;
  }, [src, filename]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        type="button"
        color="primary"
        variant="contained"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        Escolher Imagem
      </Button>
      <span style={{ marginLeft: 10, flex: 1, textAlign: 'left' }}>
        {fileLabel}
      </span>
      {hasFilled && (
        <MdClear size={22} style={{ cursor: 'pointer' }} onClick={clearInput} />
      )}
    </div>
  );
}

PushCreateImageUpload.defaultProps = {
  onChange: () => {},
  src: '',
};

PushCreateImageUpload.propTypes = {
  onChange: PropTypes.func,
  src: PropTypes.string,
};
