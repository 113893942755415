import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled(Button)`
  && {
    text-transform: unset !important;

    .MuiButton-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      font-size: 14px;
    }

    .MuiIcon-root,
    .MuiCircularProgress-root {
      margin-bottom: 4px;
    }
  }
`;
