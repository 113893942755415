import styled from 'styled-components';
import { Input } from '@rocketseat/unform';
import {
  Button as ButtonBase,
  ExpansionPanelDetails,
  Typography,
  Grid,
} from '@material-ui/core';

import { UnformTextareaAutosize } from '~/components';

export const DetailsContainer = styled(ExpansionPanelDetails)`
  background-color: rgb(248, 248, 248);
`;

export const DetailsContent = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
`;

export const FeedbackAvatar = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    width: 150px;
    height: 150px;
    border-radius: 90px;
  }
`;

export const FeedbackAvatarImage = styled.figure`
  max-width: 100%;
  max-height: auto;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  background-image: url("${({ url }) => url}");
  box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.20);
  border: 3px solid #fff;
  cursor: pointer;
`;

export const FeedbackDetails = styled.div`
  flex: 1;

  h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

export const FeedbackActions = styled.section`
  width: 100%;
  margin-top: 20px;
`;

export const Button = styled(ButtonBase)`
  svg {
    margin-right: 7px;
  }
`;

export const FeedbackFilterContainer = styled.section`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  margin-bottom: 15px;
`;

export const FeedbackAuthor = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StatusBall = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  background-color: ${({ status }) =>
    status === 'ativado' ? '#007bff' : '#dc3545'};
`;

export const FeedbackFiltersLegend = styled.section`
  display: flex;
  align-items: center;
  margin: 10px 0px;

  > h4 {
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 15px;
  }

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: flex;

    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;

      ${StatusBall} {
        margin-right: 10px;
      }
    }

    li + li {
      margin-left: 25px;
    }
  }
`;

export const FeedbackFormInput = styled(Input)`
  border: 0px solid transparent;
  font-size: 20px;
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.04;
  letter-spacing: 0em;
  font-weight: 400;
`;

export const FeedbackFormTextarea = styled(UnformTextareaAutosize)`
  border: 0px solid transparent;
  background-color: transparent;
  width: 100%;
  resize: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.00938em;
`;

export const FeedbackHeaderContainer = styled(Grid).attrs({
  spacing: 2,
  container: true,
})`
  && {
    padding: 0 24px 0 24px;
    margin-top: 10px !important;
    background-color: #3f51b5;
    width: 100%;
    margin-left: 0px;

    p.MuiTypography-root {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      color: #ffffff;
    }
  }
`;
