import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ProductAutocomplete } from '~/components';

function FaqsFilters({ updateFilters }) {
  const [idProduto, setIdProduto] = useState();
  const [contain, setContain] = useState(1);
  const [status, setStatus] = useState();
  const [search, setSearch] = useState();
  const handleSubmitFilter = async () => {
    updateFilters({ idProduto, contain, status, search });
  };

  return (
    <>
      <Grid md={3}>
        <TextField
          name
          label="Pesquisa"
          margin="normal"
          variant="outlined"
          id=""
          onChange={event => setSearch(event.target.value)}
        />
      </Grid>
      <Grid md={2}>
        <Autocomplete
          disableClearable
          options={[
            { value: 1, label: 'Contém o produto' },
            { value: 0, label: 'Não contém o produto' },
          ]}
          onChange={(_, value) => {
            if (value) {
              setContain(value.value);
            } else {
              setContain(null);
            }
          }}
          defaultValue={{ value: 1, label: 'Contém o produto' }}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              variant="outlined"
              margin="normal"
              {...params}
              label={null}
            />
          )}
        />
      </Grid>

      <Grid md={2}>
        <ProductAutocomplete
          onChange={event => {
            if (event.target.value !== null) {
              setIdProduto(event.target.value);
            } else {
              setIdProduto(null);
            }
          }}
          variant="outlined"
          margin="normal"
          label="Produto"
        />
      </Grid>
      <Grid md={1}>
        <Autocomplete
          options={[
            { value: 1, label: 'Ativo' },
            { value: 0, label: 'Não Ativo' },
          ]}
          onChange={(_, value) => {
            if (value) {
              setStatus(value.value);
            } else {
              setStatus(null);
            }
          }}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              variant="outlined"
              margin="normal"
              {...params}
              label="Situação"
            />
          )}
        />
      </Grid>
      <Grid style={{ padding: 10, marginLeft: -10 }}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmitFilter}
        >
          Filtrar
        </Button>
      </Grid>
    </>
  );
}

FaqsFilters.propTypes = {
  updateFilters: PropTypes.func.isRequired,
};
export default FaqsFilters;
