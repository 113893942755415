import React, { useState, useMemo, memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import api from '~/services/api';
import { compareArrays } from '~/helpers/compareArray';
import Raffle from '../Raffle';
import ToastAction from '../ToastAction';
import DoubleCheckPromotionForm from './DoubleCheckPromotionForm';
import TabPanel from '../../Config/main/tabPanel';
import {
  CreatedByContainer,
  DoubleCheckedByButton,
  DoubleCheckedByContainer,
  Container,
} from './styles';
import Ebook from '../Ebook';

const DoubleCheck = ({ setNextStep, product, draft, updateDraft, config }) => {
  const isEbook = product?.cod === 'ebook';
  const [stepsStatus, setStepsStatus] = useState({
    promotion: false,
    ...(isEbook ? { ebook: false } : { raffles: false, actionGifts: false }),
  });
  const [actionGiftsList, setActionGiftsList] = useState([]);
  const [doubleCheckEbooks, setDoubleCheckEbooks] = useState([]);
  const [allStepsDone, setAllStepsDone] = useState(false);
  const [value, setValue] = useState('promocao');

  const [loading, setLoading] = useState(false);

  const userEmail = useSelector(({ user }) => user.profile.email);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const validateActionGifts = useCallback(async () => {
    try {
      setLoading(true);

      const { data: updatedDraft } = await api.get(
        `/promotions/drafts/${draft._id}`
      );

      if (updatedDraft) {
        updateDraft(updatedDraft);
      }

      const keys = { id: 'idAcao', fields: ['idBrinde'] };

      const parsedActionGiftsList = actionGiftsList?.map(item => ({
        idAcao: +item.idAcao,
        idBrinde: +item.idBrinde,
      }));

      const parsedDraftList = updatedDraft?.data?.promotion?.brindes?.map(
        item => ({
          idAcao: +item.idAcao,
          idBrinde: +item.idBrinde,
        })
      );

      if (!compareArrays(parsedActionGiftsList, parsedDraftList, keys)) {
        throw new Error(
          'Falha ao confirmar dados de ação brinde, tente novamente.'
        );
      }
    } catch (error) {
      throw new Error(
        'Falha ao confirmar dados de ação brinde, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }, [draft, updateDraft, actionGiftsList]);

  const validateEbooks = useCallback(async () => {
    try {
      setLoading(true);
      const { data: updatedDraft } = await api.get(
        `/promotions/drafts/${draft._id}`
      );

      if (updatedDraft) {
        updateDraft(updatedDraft);
      }

      const draftEbooks = updatedDraft.data.ebooks;
      const fields = Object.keys(draftEbooks[0]);

      if (
        !compareArrays(draftEbooks, doubleCheckEbooks, { id: '_id', fields })
      ) {
        throw new Error('Falha ao confirmar dados de ebooks, tente novamente.');
      }
    } catch (error) {
      throw new Error('Falha ao confirmar dados de ebooks, tente novamente.');
    } finally {
      setLoading(false);
    }
  }, [doubleCheckEbooks, draft._id, updateDraft]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);

    if (!allStepsDone) {
      toast.warn(
        <Toast message="Por favor, confirme todos os passos antes de continuar." />
      );
      return;
    }

    try {
      setLoading(true);
      if (stepsStatus.actionGifts) {
        await validateActionGifts();
      }
      if (isEbook) {
        await validateEbooks();
      }
      const { data } = await api.post(
        `/promotions/drafts/${draft._id}/confirm-double-check`
      );
      updateDraft(data);
      setNextStep(true);
      toast.success(<Toast message="Conferência realizada com sucesso." />);
    } catch (error) {
      setAllStepsDone(false);
      setStepsStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        Object.keys(newStatus).forEach(key => {
          newStatus[key] = false;
        });
        return newStatus;
      });

      toast.error(
        <Toast message="Falha ao realizar a conferência, confira todos os passos novamente." />
      );
    } finally {
      setLoading(false);
    }
  }, [
    allStepsDone,
    setNextStep,
    draft._id,
    isEbook,
    stepsStatus.actionGifts,
    updateDraft,
    validateActionGifts,
    validateEbooks,
  ]);

  useEffect(() => {
    if (Object.values(stepsStatus).every(step => step === true)) {
      setAllStepsDone(true);
    }
  }, [stepsStatus]);

  const renderPromotionForm = useMemo(
    () => (
      <DoubleCheckPromotionForm
        product={product}
        config={config}
        draft={draft || {}}
        setNextStep={setNextStep}
        setStepsStatus={setStepsStatus}
      />
    ),
    [product, config, draft, setNextStep]
  );

  const renderRaffle = useMemo(
    () => (
      <Raffle
        draft={draft}
        updateDraft={updateDraft}
        setNextStep={setNextStep}
        isDoubleCheck
        setStepsStatus={setStepsStatus}
      />
    ),
    [draft, setNextStep, updateDraft]
  );

  const renderToastAction = useMemo(
    () => (
      <ToastAction
        draft={draft}
        updateDraft={updateDraft}
        setNextStep={setNextStep}
        product={product}
        isDoubleCheck
        setStepsStatus={setStepsStatus}
        setActionGiftsList={setActionGiftsList}
      />
    ),
    [draft, product, setNextStep, updateDraft]
  );

  const renderEbook = useMemo(
    () => (
      <Ebook
        draft={draft}
        updateDraft={updateDraft}
        setNextStep={setNextStep}
        isDoubleCheck
        setStepsStatus={setStepsStatus}
        doubleCheckEbooks={doubleCheckEbooks}
        setDoubleCheckEbooks={setDoubleCheckEbooks}
      />
    ),
    [draft, setNextStep, updateDraft, doubleCheckEbooks]
  );

  return (
    <>
      <Paper elevation={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="PROMOÇÃO"
            value="promocao"
            disabled={stepsStatus.promotion}
          />
          {isEbook ? (
            <Tab label="EBOOK" value="ebook" disabled={stepsStatus.ebook} />
          ) : (
            [
              <Tab
                label="SORTEIOS"
                value="sorteios"
                disabled={stepsStatus.raffles}
                key="sorteios"
              />,
              <Tab
                label="AÇÃO BRINDE"
                value="acaoBrinde"
                disabled={stepsStatus.actionGifts}
                key="acaoBrinde"
              />,
            ]
          )}
        </Tabs>

        <TabPanel value={value} index="promocao">
          {renderPromotionForm}
        </TabPanel>

        {isEbook ? (
          <TabPanel value={value} index="ebook">
            {renderEbook}
          </TabPanel>
        ) : (
          <>
            <TabPanel value={value} index="sorteios">
              {renderRaffle}
            </TabPanel>
            <TabPanel value={value} index="acaoBrinde">
              {renderToastAction}
            </TabPanel>
          </>
        )}
      </Paper>
      <Container>
        <CreatedByContainer elevation={1}>
          <TextField
            id="createdBy"
            label="Enviado para conferência por"
            variant="outlined"
            margin="normal"
            fullWidth
            value={draft?.doubleCheckRequestedBy?.email}
          />
        </CreatedByContainer>
        <DoubleCheckedByContainer elevation={1}>
          <TextField
            id="double-checked-by"
            label="Conferido por"
            variant="outlined"
            margin="normal"
            fullWidth
            style={{ marginRight: 8 }}
            value={userEmail}
          />
          <DoubleCheckedByButton
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!allStepsDone || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Confirmar'}
          </DoubleCheckedByButton>
        </DoubleCheckedByContainer>
      </Container>
    </>
  );
};

DoubleCheck.propTypes = {
  setNextStep: PropTypes.func.isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  config: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(DoubleCheck);
