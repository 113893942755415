import styled from 'styled-components';
import { Chip as MTChip } from '@material-ui/core';

export const DadosCartao = styled.div`
  display: flex;
`;

export const Chip = styled(MTChip)`
  background-color: ${props =>
    props.color === 'default' ? 'green' : 'none'} !important;
  color: ${props => (props.color === 'default' ? 'white' : 'none')} !important;

  & svg:hover,
  svg:active {
    color: #fff;
  }

  & svg {
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #3f51b520;
  }
  thead {
    background-color: #3f51b5;
    color: white;
  }
`;

export const ButtonGroup = styled.div`
  padding: 10px 0;
  opacity: 0.5;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerEyeIcon = styled.div`
  margin: 0 24px;
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const NotFoundCpfText = styled.p`
  font-size: 16px;
`;
