import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { check } from 'acler';
import { toast } from 'react-toastify';
import { defaults, isEmpty } from 'lodash';

import { store } from '~/store';
import Toast from '../Toast';

export default function AccessControl({
  children,
  can,
  is,
  renderNoAccess,
  displayError,
}) {
  const { permissions, roles } = useSelector(({ user }) => user);

  function userCan(expression) {
    return typeof expression === 'string'
      ? permissions.includes(expression)
      : !isEmpty(
          expression.filter(permission => permissions.includes(permission))
        );
  }

  function userIs(expression) {
    return check(expression, role => roles.includes(role));
  }

  if ((is && userIs(is)) || (can && userCan(can))) {
    return children;
  }

  if (displayError) return renderNoAccess();
  return null;
}

export function hasPermission(expression, options = {}) {
  options = defaults(options, {
    toast: true,
  });

  const { permissions } = store.getState().user;
  const has =
    typeof expression === 'string'
      ? permissions.includes(expression)
      : !isEmpty(
          expression.filter(permission => permissions.includes(permission))
        );

  if (!has && options.toast === true) {
    toast.error(<Toast error message="Acesso Negado." />);
  }

  return has;
}

function NoAccess() {
  useEffect(() => {
    toast.error(<Toast error message="Acesso Negado" />);
  }, []);
  return null;
}

AccessControl.defaultProps = {
  is: null,
  can: null,
  displayError: false,
  renderNoAccess: () => <NoAccess />,
};

AccessControl.propTypes = {
  is: PropTypes.string,
  can: PropTypes.oneOfType([PropTypes.any]),
  displayError: PropTypes.bool,
  renderNoAccess: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
