import React from 'react';
import PropTypes from 'prop-types';

import { Grid, ExpansionPanel } from '@material-ui/core';

import { UnauthorizedGhost } from '~/components';
import AccessControl from '~/components/AccessControl';

import { format, parseISO } from 'date-fns';
import Copy from '~/pages/Backoffice/Person/View/Dialog/copy';
import { InputText } from '~/pages/Backoffice/Person/View/Dialog/styles';
import { MdMenu } from 'react-icons/md';
import {
  DetailsSummary,
  DetailsContainer,
  DetailsContent,
  PromotionCodeDetails,
  ActionsColumn,
} from './styles';

import PromotionCodeContent from './promotionCodeContent';

export default function PromotionCodes({
  promotionCode,
  renderQuantity,
  renderStatus,
  renderRestrictions,
  renderOptionsMenu,
  handleClickMenu,
}) {
  return (
    <ExpansionPanel>
      <DetailsSummary>
        <Grid container>
          <Grid item xs={12} md={2}>
            <InputText
              value={promotionCode.codigoPromocional}
              disabled
              name="code"
              id={`code-${promotionCode.codigoPromocional}`}
            />
            <Copy element={`code-${promotionCode.codigoPromocional}`} />
          </Grid>

          <Grid item xs={12} md={2}>
            {promotionCode.acao.nome}
          </Grid>

          <Grid item xs={12} md={2}>
            {format(parseISO(promotionCode.dataInsert), 'dd/MM/yyyy HH:mm')}
          </Grid>

          <Grid item xs={12} md={1}>
            {renderStatus(promotionCode)}
          </Grid>

          <Grid item xs={12} md={1}>
            {renderQuantity(promotionCode)}
          </Grid>

          <Grid item xs={12} md={2}>
            <p>
              De{' '}
              {format(
                parseISO(promotionCode.dataInicioVigencia),
                'dd/MM/yyyy HH:mm'
              )}
            </p>
            <p>
              Até{' '}
              {format(
                parseISO(promotionCode.dataFimVigencia),
                'dd/MM/yyyy HH:mm'
              )}
            </p>
          </Grid>

          <Grid item xs={12} md={1}>
            {renderRestrictions(promotionCode)}
          </Grid>

          <ActionsColumn item xs={12} md={1}>
            <MdMenu
              size={30}
              onClick={event => handleClickMenu(event, promotionCode)}
            />
            {renderOptionsMenu()}
          </ActionsColumn>
        </Grid>
      </DetailsSummary>

      <DetailsContainer>
        <AccessControl
          can="read_promotion_codes"
          displayError
          renderNoAccess={UnauthorizedGhost}
        >
          <DetailsContent>
            <PromotionCodeDetails>
              <PromotionCodeContent {...promotionCode} />
            </PromotionCodeDetails>
          </DetailsContent>
        </AccessControl>
      </DetailsContainer>
    </ExpansionPanel>
  );
}

PromotionCodes.propTypes = {
  promotionCode: PropTypes.oneOfType([PropTypes.object]).isRequired,
  renderQuantity: PropTypes.func.isRequired,
  renderType: PropTypes.func.isRequired,
  renderStatus: PropTypes.func.isRequired,
  renderRestrictions: PropTypes.func.isRequired,
  renderOptionsMenu: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
};
