import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import '~/config/ReactotronConfig';

import Routes from '~/routes';
import history from '~/services/history';
import { store, persistor } from '~/store';

import GlobalStyles from '~/styles/global';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyles />
        <Router history={history}>
          <Routes />
        </Router>
      </PersistGate>
      <ToastContainer autoClose={4000} position="bottom-right" />
    </Provider>
  );
}

export default App;
