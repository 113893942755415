import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import api from '~/services/api';

export default function DeleteDialog({
  setProductRange,
  product,
  idProdutoCompraRegiao,
  children,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleDeleteProductRegion = async () => {
    try {
      setLoading(true);
      await api.delete(`/purchase-regions/${idProdutoCompraRegiao}`);
      setProductRange(oldProductRange =>
        oldProductRange.filter(
          item => idProdutoCompraRegiao !== item.idProdutoCompraRegiao
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button {...props} onClick={handleClickOpen}>
        {children}
      </Button>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>Você tem certeza?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={handleDeleteProductRegion}
          >
            {!loading ? 'Confirmar' : <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  setProductRange: PropTypes.oneOfType([PropTypes.object]).isRequired,
  idProdutoCompraRegiao: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
