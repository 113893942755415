import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { toNumber } from 'lodash';
import { ImSearch } from 'react-icons/im';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function CustomPaginator({ loading, ...custom }) {
  const [pageValue, setPageValue] = useState(custom.pagination.page);

  const handlePage = () => {
    let page = toNumber(pageValue) || custom.pagination.page;
    page = page <= 0 ? 1 : page;
    if (page > custom.pagination.pages) {
      page = custom.pagination.pages;
    }
    setPageValue(page);
    custom.setPagination({ page });
  };

  const handleKeyUp = e => {
    const key = e.which || e.keyCode;
    if (key === 13) {
      handlePage();
    }
  };

  const handleChange = value => {
    setPageValue(value);
  };

  useEffect(() => {
    setPageValue(custom.pagination.page);
  }, [custom.pagination.page]);
  return custom ? (
    <Container
      container
      justifyContent="space-between"
      alignItems="flex-end"
      spacing={2}
      style={{ marginTop: 10 }}
    >
      <Grid item>
        Total de Registros: {custom.pagination.totals.toLocaleString()}
      </Grid>
      <Grid item>
        <Container
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <div>Total por página: </div>
          </Grid>
          <Grid item>
            <Select
              onChange={custom.onLimitChange}
              value={custom.pagination.limit}
            >
              {custom.pagination.rowsPerPageOptions.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              {custom.items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = (
                    <Button
                      key={`${index + Math.random}`}
                      disabled
                      style={{
                        backgroundColor: '#FFF',
                      }}
                    >
                      ...
                    </Button>
                  );
                } else if (type === 'page') {
                  children = (
                    <Button
                      key={`${index + Math.random}`}
                      type="button"
                      style={{
                        fontWeight: selected ? 'bold' : undefined,
                        backgroundColor: selected ? '#eee' : '#FFFFFF',
                      }}
                      {...item}
                      onClick={() => custom.setPagination({ page })}
                      disabled={loading}
                    >
                      {page}
                    </Button>
                  );
                } else {
                  children = (
                    <Button
                      key={`${index + Math.random}`}
                      {...item}
                      onClick={() => custom.setPagination({ page })}
                      style={{ backgroundColor: 'white' }}
                      disabled={
                        loading ||
                        (custom.pagination.page >= custom.pagination.pages &&
                          type === 'next') ||
                        (custom.pagination.page <= 1 && type === 'previous')
                      }
                    >
                      {type === 'next' ? (
                        <BsChevronRight size={20} />
                      ) : (
                        <BsChevronLeft size={20} />
                      )}
                    </Button>
                  );
                }

                return children;
              })}
            </ButtonGroup>
          </Grid>
          <Grid item>
            <TextField
              label="Ir p/ Página"
              value={pageValue}
              onChange={e => handleChange(e.target.value)}
              onKeyUp={handleKeyUp}
              style={{ maxWidth: 100 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Ir para página"
                      onClick={handlePage}
                    >
                      <ImSearch size={20} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Container>
      </Grid>
    </Container>
  ) : (
    <div />
  );
}

CustomPaginator.propTypes = {
  loading: PropTypes.bool,
};

CustomPaginator.defaultProps = {
  loading: false,
};
