import React from 'react';
import { Divider, IconButton, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';

import { AccessControl } from '~/components';
import { Container, Header } from './styles';
import SidebarMenuItem from '../SidebarMenuItem';

import menuItems from './items';

export default function Sidebar({ width, open, onHide }) {
  return (
    <Container width={width} open={open}>
      <Header>
        <h2>Menu</h2>
        <IconButton onClick={onHide}>
          <Icon>chevron_left</Icon>
        </IconButton>
      </Header>

      <Divider />

      {menuItems.map(menu =>
        menu.divider ? (
          <Divider key={Math.random()} />
        ) : (
          <AccessControl
            key={menu.router}
            can={menu.permission}
            displayError={false}
          >
            <SidebarMenuItem item={menu} />
          </AccessControl>
        )
      )}
    </Container>
  );
}

Sidebar.defaultProps = {
  onHide: () => {},
  open: false,
};

Sidebar.propTypes = {
  width: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onHide: PropTypes.func,
};
