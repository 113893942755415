import styled from 'styled-components';

export const NoImage = styled.div`
  width: 160px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 2rem;
`;
