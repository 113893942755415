import { useState, useEffect, useCallback } from 'react';
import { applySpec, prop } from 'ramda';

import api from '~/services/api';

// -- data
const paginationSpec = applySpec({
  totals: prop('total'),
  limit: prop('limit'),
  page: prop('page'),
  pages: prop('pages'),
});

const initialPagination = {
  page: 1,
  limit: 30,
  totals: 0,
};

export default function usePromotions({
  context,
  defaultFilters = {},
  setPagination,
  ...custom
}) {
  const [promotions, setPromotions] = useState([]);
  // const [pagination, setPagination] = useState(initialPagination);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setLoading(true);
      setPromotions([]);

      // -- create query params
      const params = {
        context,
        page: custom.pagination.page,
        limit: custom.pagination.limit,
        ...filters,
      };

      api
        .get('/promotions', { params })
        .then(res => {
          if (res && res.data) {
            setPromotions(res.data.docs);
            setPagination(paginationSpec(res.data));
          }
        })
        .finally(() => setLoading(false));
    }, 120);

    return () => clearTimeout(timeId);
  }, [
    context,
    filters,
    custom.pagination.page,
    custom.pagination.limit,
    setPagination,
  ]);

  // -- handle to update filters
  const handleFilters = useCallback(
    (field, value) => {
      setPagination({ ...custom.pagination, page: 1 });
      setFilters({ ...filters, [field]: value });
    },
    [filters, custom.pagination, setPagination]
  );

  // -- reset filters
  function resetFilters() {
    setFilters({});
    custom.setPagination(initialPagination);
  }

  // -- update filters value
  const updateFilters = useCallback(
    value => {
      setFilters(value);
      setPagination(initialPagination);
    },
    [setPagination]
  );

  // -- handle to update pagination

  return {
    promotions,
    loading,
    filters,
    handleFilters,
    resetFilters,
    updateFilters,
  };
}
