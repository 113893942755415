export function setProduct(product) {
  return {
    type: '@user/SET_PRODUCT',
    payload: { product },
  };
}

export function setParams(params) {
  return {
    type: '@user/SET_PARAMS',
    payload: { params },
  };
}

export function setDraft(draft) {
  return {
    type: '@user/SET_DRAFT',
    payload: { draft },
  };
}

export function setProducts(products) {
  return {
    type: '@user/SET_PRODUCTS',
    payload: { products },
  };
}
