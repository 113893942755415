import React, { useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { Typography, CircularProgress, Button, Icon } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { DropContainer, DropZone } from './styles';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';

export default function Upload({ promotionId, onUpload }) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    rejectedFiles,
  } = useDropzone({
    accept: 'image/*',
    multiple: false,
  });
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [file, setFile] = useState();

  const errorFiles = rejectedFiles.map(_file => {
    setTimeout(() => {
      setImage(null);
    }, 100);
    return (
      <div>
        <Typography variant="h5" style={{ color: 'red' }}>
          Aquivo {_file.name} inválido
        </Typography>
      </div>
    );
  });

  useMemo(
    () =>
      acceptedFiles.map(_file => {
        const reader = new FileReader();
        reader.onabort = () => 'file reading was aborted';
        reader.onerror = () => 'file reading has failed';
        reader.onload = e => {
          const binaryStr = e.target.result;
          setImage(binaryStr);
          setFile(_file);
        };
        reader.readAsDataURL(_file);
        return '';
      }),
    [acceptedFiles, setImage]
  );

  const handleUpload = () => {
    if (hasPermission('update_promotion')) {
      const formdata = new FormData();

      setLoading(true);
      // -- append data
      formdata.append('imagemCapa', file);
      api
        .put(`/promotions/${promotionId}/imagemcapa`, formdata)
        .then(() => {
          onUpload(image);
          setImage(null);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <DropContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {image ? (
              <img src={image} width="100%" alt="Imagem Upload" />
            ) : (
              <div>
                <p>Solte o arquivo aqui, ou clique para selecionar</p>
                <em>(Somente imagens serão aceitas)</em>
              </div>
            )}
          </DropZone>
        )}
      </DropContainer>
      {errorFiles}
      {image && (
        <Button
          disabled={loading}
          onClick={handleUpload}
          style={{ width: '100%' }}
          variant="contained"
          color="primary"
        >
          <Icon>cloud_upload</Icon>&nbsp;Enviar
        </Button>
      )}
    </>
  );
}

Upload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  promotionId: PropTypes.string.isRequired,
  imagePromotion: PropTypes.string.isRequired,
};
