import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const ContentPromoCad = styled(Paper)`
  padding: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ColorColor = styled.div`
  width: 36px;
  /* height: 14px; */
  border-radius: 2px;
  background: ${props => props.color};
  padding-left: 40px;
`;

export const ColorSwatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const ColorPopover = styled.div`
  position: absolute;
  z-index: 2;
`;

export const ColorCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
