import React, { useRef } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Button } from '@material-ui/core';
import { Form } from '@unform/web';
import { TextField } from '@unform/material-ui';
import toFormdata from 'object-to-formdata';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';

import UploadField from '~/components/unform/v2/UploadField';
import DataPicker from '~/components/unform/v2/DatePicker';

import { Container, SectionTitle, Row, Col } from './styles';

function Create() {
  const formRef = useRef(null);
  const history = useHistory();

  async function handleSubmit(data, { reset }) {
    try {
      const schema = Yup.object().shape({
        file: Yup.mixed().test(
          'is-file',
          'Você deve anexar um arquivo',
          file => {
            return file instanceof File;
          }
        ),
        sendDate: Yup.date().typeError('O campo data é obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { file, message, sendDate } = data;

      const dados = toFormdata({ file, message, sendDate });
      await api.post('/contestation', dados);
      reset();
      history.push('/constestation');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <SectionTitle>
            <MdKeyboardArrowRight />
            Processamento de TIDs
          </SectionTitle>

          <Row cols={2}>
            <Col>
              <DataPicker
                label="Selecione uma data"
                name="sendDate"
                // onChange={date => setSendDate(date)}
              />
            </Col>
            <Col>
              <UploadField name="file" />
            </Col>
          </Row>
          <Row>
            <TextField name="message" placeholder="Observações..." multiline />
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default Create;
