import React, { useMemo, useState, useCallback } from 'react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { get, toNumber } from 'lodash';
import { isValid, parseISO, format, isAfter, isBefore } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';

import BannerImage from './BannerImage';
import LoadingPlaceholder from './LoadingPlaceholder';

import {
  Container,
  Switch,
  IconButton,
  StatusManagement,
  ValidityPreview,
} from './styles';
import { useBannersHome } from '../context';
import { useBannerForm } from '../Form';
import { useBannersHomePermission } from '../context-permissions';

const dateWithFormat = (date, formatPattern, defaultValue) => {
  if (isValid(parseISO(date))) return format(parseISO(date), formatPattern);

  return defaultValue;
};

const isWithinValidity = (startDate, endDate) => {
  if (!isValid(startDate) || !isValid(endDate)) return false;

  const curr = new Date();
  return isBefore(curr, endDate) && isAfter(curr, startDate);
};

const getDate = stringDate =>
  isValid(parseISO(stringDate)) ? parseISO(stringDate) : null;

const BannerItem = React.forwardRef(
  ({ data = {}, placeholderOnly = false, children, ...props }, ref) => {
    const { updateBanner, deleteBanner } = useBannersHome();
    const confirm = useConfirm();
    const { editBanner } = useBannerForm();
    const { canDelete, canUpdate } = useBannersHomePermission();

    const banner = useMemo(
      () => ({
        id: get(data, 'idBannerHome', null),
        url: get(data, 'url', ''),
        active: toNumber(get(data, 'status', 0)) === 1,
        product: get(data, 'produto.nome', '-'),
        dateStart: get(data, 'dataInicio', null),
        dateEnd: get(data, 'dataFim', null),
        ordem: get(data, 'ordem', null),
        urlPath: get(data, 'urlPath', null),
      }),
      [data]
    );

    const validity = useMemo(
      () => ({
        start: getDate(banner.dateStart),
        end: getDate(banner.dateEnd),
      }),
      [banner.dateStart, banner.dateEnd]
    );

    const [isActive, setIsActive] = useState(banner.active);
    const [loading, setLoading] = useState(false);

    const semiTransparent =
      (isActive && isWithinValidity(validity.start, validity.end)) === false &&
      !loading;

    const handleToggleStatus = useCallback(async () => {
      setIsActive(!isActive);
      setLoading(true);
      const res = await updateBanner(banner.id, { status: isActive ? 0 : 1 });
      const updatedStatus = get(res, 'status', 0);
      setIsActive(updatedStatus === 1);
      setLoading(false);
    }, [isActive, banner.id, updateBanner]);

    const handleDeleteBanner = useCallback(async () => {
      try {
        setLoading(true);
        await confirm({
          title: 'Tem certeza que deseja excluir o banner?',
          description:
            'Após a confirmação, todos os dados do banner serão excluído incluindo imagens.',
          dialogProps: { fullWidth: true, maxWidth: 'xs' },
          cancellationText: 'Não, cancelar!',
          confirmationText: 'Sim, continuar!',
        });
        await deleteBanner(banner.id);
      } catch (err) {
        // eslint-disable-line
      } finally {
        setLoading(false);
      } // eslint-disable-line
    }, [banner.id, deleteBanner, confirm]);

    const handleEditBanner = useCallback(() => {
      editBanner(data);
    }, [data, editBanner]);

    useUpdateEffect(() => {
      setIsActive(banner.active);
    }, [banner.active]);

    /**
     * Placeholder only.
     *
     * This show only box with dashed border.
     */
    if (placeholderOnly)
      return (
        <Container transparent dashed {...props} ref={ref}>
          {children}
        </Container>
      );

    return (
      <Container
        semiTransparent={semiTransparent}
        withAnimation
        {...props}
        ref={ref}
      >
        <LoadingPlaceholder loading={loading} />
        <BannerImage src={banner.url} title={banner.product} />

        <header>
          <div className="product-name" title={banner.product}>
            <span>{banner.product}</span>
          </div>
          <div className="actions">
            {canUpdate && (
              <IconButton onClick={handleEditBanner}>
                <FaPencilAlt />
              </IconButton>
            )}

            {canDelete && (
              <IconButton onClick={handleDeleteBanner}>
                <FaTrash />
              </IconButton>
            )}
          </div>
        </header>

        {canUpdate && (
          <StatusManagement>
            <span>{isActive ? 'Ativo' : 'Inativo'}</span>
            <Switch
              color="primary"
              checked={isActive}
              onChange={handleToggleStatus}
            />
          </StatusManagement>
        )}

        <ValidityPreview>
          <span>Vigência</span>
          <div className="range">
            <p>
              {dateWithFormat(banner.dateStart, 'dd/MM/yyyy HH:mm:ss', '-')}
            </p>
            <p>{dateWithFormat(banner.dateEnd, 'dd/MM/yyyy HH:mm:ss', '-')}</p>
          </div>
        </ValidityPreview>
        <StatusManagement>
          <span>Ordem</span>
          <span>{banner.ordem}</span>
        </StatusManagement>
        <br />
        <StatusManagement>
          <span>URL/Deeplink</span>
          {banner.urlPath && (
            <a
              target="__blank"
              style={{ position: 'relative', zIndex: 3 }}
              href={banner.urlPath}
            >
              Clique aqui
            </a>
          )}
        </StatusManagement>
      </Container>
    );
  }
);

BannerItem.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
  }),
  placeholderOnly: PropTypes.bool,
  children: PropTypes.node,
};

BannerItem.defaultProps = {
  placeholderOnly: false,
  data: {},
  children: null,
};

export default React.memo(BannerItem);
