/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  CircularProgress,
  Button,
  Table,
  TableBody,
  IconButton,
  Icon,
} from '@material-ui/core';
import { TextField } from 'unform-material-ui';
import { format } from 'date-fns';
import api from '~/services/api';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import { AccessControl } from '~/components';
import { hasPermission } from '~/components/AccessControl';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import { store } from '~/store';
import FilterPermissionsDialog from '../FilterPermissions';
import {
  STableHead,
  STableRow,
  MoreRoles,
  Roles,
  FiltersForm,
  FiltersContainer,
} from './styles';
import ViewUser from '../ViewUser';
import DialogNewUser from '../NewUser';
import { backgroundColorRole } from '../components/backgroundColorRole';

export default function UserList() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [dialogViewUser, setDialogViewUser] = useState(false);
  const [dialogFilter, setDialogFilter] = useState(false);
  const [user, setUser] = useState();
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [refetchUsers, setRefetchUsers] = useState(false);
  const [filterPermissions, setFilterPermissions] = useState([]);
  const [filters, setFilters] = useState([]);
  const refDialogOpen = useRef(true);
  const formRef = useRef(null);
  const { token } = store.getState().auth;

  const { setPagination, ...customPaginator } = useCustomPaginator();

  const handleCloseDialogFilter = () => {
    setDialogFilter(false);
  };

  const getUsers = useCallback(async () => {
    handleCloseDialogFilter();
    setUsers([]);
    const permissions = [];
    if (filterPermissions.length) {
      filterPermissions.forEach(role => {
        permissions.push(role._id);
      });
    }

    try {
      setLoading(true);
      if (hasPermission('read_users')) {
        const { data } = await api.get('/users', {
          params: {
            ...filters,
            permissions: filterPermissions,
            page: customPaginator.pagination.page,
            limit: customPaginator.pagination.limit,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (data) {
          setUsers(data.users);
          const { total } = data;
          setPagination({
            ...{
              limit: customPaginator.pagination.limit,
              page: customPaginator.pagination.page,
              pages: Math.ceil(total / customPaginator.pagination.limit),
              totals: total,
            },
          });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [
    filters,
    filterPermissions,
    setPagination,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  const handleSetFilters = values => {
    setFilters(values);
  };

  const handleCloseDialogForm = () => {
    setOpenDialogForm(false);
    setUser('');
    refDialogOpen.current = true;
  };

  const handleCloseModalViewUser = () => {
    setUser('');
    setDialogViewUser(false);
  };

  const renderUsers = () => {
    return users.map(userInfo => {
      return (
        <STableRow
          style={{ backgroundColor: 'white' }}
          key={userInfo.id}
          onClick={() => {
            setUser(userInfo);
            if (refDialogOpen.current) {
              setDialogViewUser(true);
            }
          }}
        >
          <td>{`${userInfo.firstName} ${userInfo.lastName}`}</td>
          <td>{userInfo.email}</td>
          <td>
            {format(new Date(userInfo.createdTimestamp), 'dd/MM/yyyy HH:mm')}
          </td>
          <td>
            <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
              {userInfo.clientRoles.map((role, index) => {
                if (index <= 4) {
                  return (
                    <Roles
                      key={role.id}
                      backgrounded={backgroundColorRole(role.name)}
                    >
                      {role.name}
                    </Roles>
                  );
                }
                return <></>;
              })}
              {userInfo.clientRoles.length > 5 && (
                <MoreRoles>+{userInfo.clientRoles.length - 5}</MoreRoles>
              )}
            </div>
          </td>
          <td align="center">{!userInfo.enabled ? 'Sim' : 'Não'}</td>
          <td
            style={{
              paddingRight: '10px',
              color: '#7986CB',
              fontSize: '1.5rem',
            }}
          >
            <IconButton
              color="inherit"
              onClick={() => {
                refDialogOpen.current = false;
                setUser(userInfo);
                setOpenDialogForm(true);
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
          </td>
        </STableRow>
      );
    });
  };

  useEffect(() => {
    getUsers();
  }, [
    refetchUsers,
    filters,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={11}>
          <Header>
            <h1 style={{ fontWeight: 400, fontSize: 24, marginBottom: 20 }}>
              Usuários
            </h1>
          </Header>
        </Grid>
        <Grid item xs={12}>
          <FiltersContainer>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FiltersForm
                  onSubmit={handleSetFilters}
                  ref={formRef}
                  autoComplete="off"
                  autoCapitalize="off"
                  noValidate
                >
                  <TextField
                    name="name_or_email"
                    id="name_or_email"
                    variant="outlined"
                    label="Buscar valor"
                    placeholder="Primeiro nome ou email"
                    style={{ maxWidth: 340 }}
                  />
                  <div>
                    <Button type="submit" variant="contained" color="primary">
                      Aplicar
                    </Button>
                  </div>
                </FiltersForm>
              </Grid>
              <Grid item xs={5} align="right">
                <AccessControl can="create_user">
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={() => setOpenDialogForm(true)}
                      variant="contained"
                      color="primary"
                    >
                      Novo usuário
                    </Button>
                  </div>
                </AccessControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => setDialogFilter(true)}
                  variant="contained"
                  color="primary"
                >
                  Filtrar permissões
                </Button>
                <FilterPermissionsDialog
                  open={dialogFilter}
                  onClose={handleCloseDialogFilter}
                  setPermissions={setFilterPermissions}
                  permissions={filterPermissions}
                  handleFilterPermissions={getUsers}
                />
              </Grid>
            </Grid>
          </FiltersContainer>
          <Table style={{ width: '100%' }}>
            <STableHead>
              <STableRow>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Inclusão</th>
                <th>Perfil</th>
                <th align="center">Excluído</th>
                <th />
              </STableRow>
            </STableHead>
            <TableBody>{renderUsers()}</TableBody>
          </Table>
          {loading ? <CircularProgress /> : ''}
        </Grid>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
      </Grid>
      {dialogViewUser && (
        <ViewUser
          open={dialogViewUser}
          onClose={handleCloseModalViewUser}
          user={user}
          userRoles={backgroundColorRole}
          setRefetch={setRefetchUsers}
          refetchState={refetchUsers}
        />
      )}
      {openDialogForm && (
        <DialogNewUser
          user={user}
          open={openDialogForm}
          onClose={handleCloseDialogForm}
          setRefetch={setRefetchUsers}
          refetchState={refetchUsers}
        />
      )}
    </>
  );
}
