import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Select, InputLabel } from '@material-ui/core';
import { debounce as debounceFn } from 'lodash';
import { isValid, format, parse } from 'date-fns';
import { useLocation } from 'react-router-dom';

// -- services
import qs from '~/services/qs';

// -- components
import { IDatePicker } from '~/components';

export default function PromotionsFilter({ filters, handleFilters }) {
  const { search } = useLocation();
  const { dataReferencia } = qs.parse(search);
  const { status } = qs.parse(search);
  const selectRef = useRef();
  const debounce = debounceFn((...args) => handleFilters(...args), 400);

  return (
    <Grid container spacing={2}>
      {filters.includes('dataReferencia') && (
        <Grid item xs={12} md={4}>
          <IDatePicker
            label="Data Referência"
            onChange={value =>
              debounce(
                'dataReferencia',
                value && isValid(value)
                  ? format(value, 'yyyy-MM-dd')
                  : undefined
              )
            }
            value={
              dataReferencia
                ? parse(dataReferencia, 'yyyy-MM-dd', Date.now()).toISOString()
                : null
            }
          />
        </Grid>
      )}
      {filters.includes('status') && (
        <Grid item xs={12} md={4}>
          <InputLabel id="Status">Status</InputLabel>
          <Select
            labelId="Status"
            label="Status"
            variant="standard"
            margin="none"
            fullWidth
            ref={selectRef}
            defaultValue={status || ''}
            onChange={value => debounce('status', value.target.value)}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="draft">Rascunho</MenuItem>
            <MenuItem value="pending">Pendente</MenuItem>
            <MenuItem value="double-check">Aguardando Conferência</MenuItem>
            <MenuItem value="approved">Aprovado</MenuItem>
          </Select>
        </Grid>
      )}
    </Grid>
  );
}

PromotionsFilter.defaultProps = {
  filters: ['produto'],
  handleFilters: () => {},
};

PromotionsFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  handleFilters: PropTypes.func,
};
