import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { CircularProgress, Chip } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { get } from 'lodash';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import { Table } from './styles';

export default function Log({ person }) {
  const ACTIONS = [
    {
      value: 'resend_email_purchase',
      name: 'Reenvio de email de compra',
      color: 'primary',
    },
    {
      value: 'resend_email_coupon',
      name: 'Reenvio de email de cupom',
      color: 'primary',
    },
    { value: 'tag_person', name: 'Marcar Pessoa', color: 'secondary' },
    { value: 'deregister_person', name: 'Excluir Pessoa', color: 'secondary' },
    { value: 'unblock_person', name: 'Desbloquear Pessoa', color: 'secondary' },
    { value: 'block_person', name: 'Bloquear Pessoa', color: 'primary' },
    { value: 'update_person', name: 'Atualizar Pessoa', color: 'primary' },

    { value: 'unblock_card', name: 'Desbloquear cartão', color: 'secondary' },
    { value: 'block_card', name: 'Bloquear cartão', color: 'primary' },
    {
      value: 'confirm_transaction_pix',
      name: 'Confirmar Pagamento',
      color: 'primary',
    },
    {
      value: 'update_person_image_profile',
      name: 'Atualizar Foto de Perfil',
      color: 'primary',
    },
    {
      value: 'deactivate_payment_method',
      name: 'Desativar Cartão',
      color: 'secondary',
    },
    {
      value: 'activate_payment_method',
      name: 'Ativar Cartão',
      color: 'primary',
    },
    {
      value: 'create_payment_method',
      name: 'Inserir Cartão',
      color: 'primary',
    },
    {
      value: 'quarantine_payment_method',
      name: 'Colocar cartão em quarentena',
      color: 'primary',
    },
    {
      value: 'remove_quarantine_payment_method',
      name: 'Retirar cartão da quarentena',
      color: 'secondary',
    },
    {
      value: 'refund_purchase',
      name: 'Cancelar/Estornar Compra',
      color: 'secondary',
    },
    {
      value: 'transaction_contested',
      name: 'Contestação de tids',
      color: 'secondary',
    },
    {
      value: 'reprocess_purchase',
      name: 'Reprocessar compra',
      color: 'primary',
    },
    {
      value: 'reset_person_password',
      name: 'Reset de Senha',
      color: 'primary',
    },
    {
      value: 'push_sending',
      name: 'Envio de Push',
      color: 'secondary',
    },
    {
      value: 'refund_bank_slip',
      name: 'Estorno Boleto',
      color: 'secondary',
    },
    {
      value: 'chargeback_pix_wallet',
      name: 'Estorno de Pix tipo Saldo',
      color: 'secondary',
    },
    {
      value: 'person_attendance',
      name: 'Ocorrência',
      color: 'primary',
    },
    {
      value: 'cancel_coupons',
      name: 'Estorno de Cupom',
      color: 'default',
      style: { backgroundColor: '#FFEB3B', color: '#000000' },
    },
    {
      value: 'promo_code',
      name: 'Promo',
      color: 'primary',
    },
    {
      value: 'call_contact',
      name: 'Contato',
      color: 'primary',
    },
  ];

  const [result, setResult] = useState([]);

  const [loading, setLoading] = useState(false);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const getLogs = async () => {
    setLoading(true);
    try {
      if (hasPermission('read_logs')) {
        const { data } =
          (await api.get(
            `/persons/${person.idCliente}/logs?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`
          )) || {};

        if (data) {
          setResult(data);
          const { limit, page, pages, total } = data;
          setPagination({
            ...{
              limit,
              page,
              pages,
              totals: total,
            },
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  function renderAction(item) {
    if (item.action) {
      const act = ACTIONS.find(a => a.value === item.action.name) || {};
      return (
        <Chip
          size="small"
          label={act.name || 'não definido'}
          color={act.color || undefined}
          style={act.style || null}
        />
      );
    }
    return '';
  }

  function renderResource(item) {
    const modifiedIn = get(item, 'action.modifiedIn.0', null);

    if (modifiedIn) {
      const resourceName = get(modifiedIn, 'resource', '');
      const {
        bandeiraCartao: cardBrand,
        numeroCartao: cardNumber,
        nomeCompletoCartao: cardHolder,
      } = get(modifiedIn, 'entities.0', {}) || {};

      switch (resourceName) {
        case 'PaymentMethod':
        case 'QuarantinePaymentMethod':
          return (
            <>
              <p>{`${cardBrand} ${cardNumber}`}</p>
              <p>{cardHolder}</p>
            </>
          );

        default:
          return '';
      }
    }

    return '';
  }

  useEffect(() => {
    if (person) getLogs();
    // TODO:
    // eslint-disable-next-line
  }, [
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  if (loading && (!result || !result.docs)) {
    return <CircularProgress size={24} />;
  }
  if (result && result.docs && result.docs.length > 0) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Usuário</th>
              <th>Ação</th>
              <th>Motivo / Descrição</th>
              <th>Dados Afetados</th>
            </tr>
          </thead>
          <tbody>
            {result.docs.map(item => (
              // eslint-disable-next-line no-underscore-dangle
              <tr key={item._id}>
                <td>
                  {format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                </td>
                <td>
                  {item?.user?.name}
                  <p>{item?.user?.email}</p>
                </td>
                <td>
                  {renderAction(item)}
                  {renderResource(item)}
                </td>
                <td>{item.action.reason}</td>
                <td>
                  <ReactJson
                    displayObjectSize={false}
                    displayDataTypes={false}
                    enableClipboard={false}
                    collapsed={false}
                    sortKeys
                    defaultValue=""
                    src={item.action.data || {}}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}
      </>
    );
  }
  return <NoRecord />;
}

Log.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};

Log.defaultProps = {
  person: null,
};
