import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  h4 {
    margin: 24px 0px;
    font-size: 32px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
  }

  .MuiIcon-root {
    font-size: 48px;
  }
`;
