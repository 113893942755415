/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { MdDelete } from 'react-icons/md';
import Img from 'react-image';
import Skeleton from '@material-ui/lab/Skeleton';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';

const style = {
  border: '1px dashed gray',
  padding: '1rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};
const Card = ({ url, draft, onDelete }) => {
  // const opacity = isDragging ? 0 : 1;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [clickDelete, setClickDelete] = useState(0);
  const canDeleteBanner = useMemo(
    () => hasPermission('delete_promotion_draft_banner', { toast: false }),
    []
  );

  const handleDeleteBanner = () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      setTimeout(() => setClickDelete(0), 3000);
    } else if (hasPermission('delete_promotion_draft_banner')) {
      setLoadingDelete(true);

      api
        .delete(`/promotions/drafts/${draft._id}/imagemcapa`)
        .then(res => {
          if (res) onDelete(res.data);
        })
        .finally(() => setLoadingDelete(false));
    }
  };

  return (
    <div style={{ ...style }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <h2>1</h2>
        </Grid>
        <Grid item xs={9}>
          <Img
            width={100}
            src={url}
            alt="Banner Home"
            loader={<Skeleton variant="rect" width={100} height={80} />}
          />
        </Grid>
        <Grid item xs={2} align="right">
          {canDeleteBanner && draft.status !== 'approved' && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleDeleteBanner}
            >
              {loadingDelete ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : (
                <>
                  <MdDelete />
                  &nbsp;{clickDelete ? 'Confirma ?' : 'Excluir'}
                </>
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Card;
