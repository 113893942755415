import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { FaCheck } from 'react-icons/fa';
import DialogTitle from '@material-ui/core/DialogTitle';
import { debounce as debounceFn } from 'lodash';

import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TableContainer from '@material-ui/core/TableContainer';

import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import { useBannersHome } from '../context';

import { FilterContainer } from '../../PushNotification/List/styles';

export default function DialogRoutesApp({ open, toggleDialog, onUseRoute }) {
  const { bannersMobileRoutes } = useBannersHome();
  const [data, setData] = useState();

  useEffect(() => {
    if (bannersMobileRoutes) {
      setData(bannersMobileRoutes);
    }
  }, [bannersMobileRoutes]);

  const debounce = debounceFn((...args) => {
    const { application } = args[0];

    if (!application || application === 'all') {
      setData(bannersMobileRoutes);
      return;
    }

    setData(bannersMobileRoutes.filter(e => e.aplicacao === application));
  });

  const handleCopy = (textToCopy, application) => {
    onUseRoute(textToCopy);
    toggleDialog(false);

    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.success(
        <Toast
          message={`Rota da aplicação ${application} utilizada com sucesso!`}
        />
      );
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Lista de deeplinks</DialogTitle>
        <TableContainer component={Paper} color="primary">
          <FilterContainer>
            <Autocomplete
              size="medium"
              options={[
                { value: 'all', label: 'Todas' },
                { value: 'WL', label: 'WL' },
                { value: 'ADS', label: 'ADS' },
              ]}
              getOptionLabel={opt => opt.label}
              onChange={(_, opt) =>
                debounce({
                  application: (opt && opt.value) || undefined,
                })
              }
              renderInput={params => (
                <TextField {...params} label="Aplicação" />
              )}
            />
          </FilterContainer>

          <Table size="small">
            <TableHead style={{ backgroundColor: '#3f51b5' }}>
              <TableRow>
                <TableCell style={{ color: '#fff' }}>Referência</TableCell>
                <TableCell style={{ color: '#fff' }}>Rota</TableCell>
                <TableCell style={{ color: '#fff' }}>Aplicação</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data &&
                data.map(dados => (
                  <TableRow key={dados.id}>
                    <TableCell>{dados.tela}</TableCell>
                    <TableCell>
                      {dados.rota}{' '}
                      <FaCheck
                        size={15}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        color="#3f51b5"
                        title="Utilizar rota"
                        onClick={() => handleCopy(dados.rota, dados.aplicacao)}
                      />
                    </TableCell>
                    <TableCell align="center">{dados.aplicacao}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DialogActions>
          <Button onClick={() => toggleDialog(false)} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogRoutesApp.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  onUseRoute: PropTypes.func.isRequired,
};
