import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import FormDialog from './formDialog';
import { Table } from '../List/styles';
import DeleteDialog from './deleteDialog';

export default function RegionDialog({ product }) {
  const [open, setOpen] = React.useState(false);
  const [productRange, setProductRange] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingIdDelete, setIdLoadingDelete] = React.useState(null);

  const { setPagination, ...customPaginator } = useCustomPaginator();

  const handleClickOpen = async () => {
    setOpen(true);
    // eslint-disable-next-line no-use-before-define
    // await loadingProduct();
  };

  const loadingProduct = useCallback(async () => {
    try {
      if (product) {
        setProductRange([]);
        setLoading(true);
        const params = {
          page: customPaginator.pagination.page,
          limit: customPaginator.pagination.limit,
        };

        await api
          .get(`/purchase-regions/products/${product.idProduto}`, { params })
          .then(res => {
            if (res && res.data) {
              setProductRange(res.data.data);
              const { limit, page, totals } = res.data;
              setPagination({
                ...{ limit, page, pages: totals / limit, totals },
              });
            }
          });
        setLoading(false);
      }
    } catch (erro) {
      setProductRange([]);
      setLoading(false);
    }
  }, [
    product,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
  ]);

  useEffect(() => {
    if (open) {
      loadingProduct();
    }
  }, [
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    open,
    loadingProduct,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Região de Compra
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item justifyContent="flex-start" xs={11}>
                <strong>{product.descricao}</strong>
              </Grid>
              <Grid
                item
                style={{ justifyContent: 'flex-end', display: 'flex' }}
                xs={1}
              >
                <FormDialog
                  title="Novo"
                  variant="contained"
                  product={product}
                  color="primary"
                  onEndEvent={() => loadingProduct()}
                >
                  Novo
                </FormDialog>
              </Grid>
            </Grid>
            <Table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>DDD</th>
                  <th>CEP Range Inicio</th>
                  <th>CEP Range Fim</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td
                      colSpan="5"
                      align="center"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <CircularProgress />
                    </td>
                  </tr>
                )}

                {productRange.length > 0 &&
                  productRange.map(item => (
                    <tr key={item.idProdutoCompraRegiao}>
                      <td>{item.idProdutoCompraRegiao}</td>
                      <td>{item.ddd}</td>
                      <td>{item.cepRangeInicio}</td>
                      <td>{item.cepRangeFim}</td>
                      <td>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <FormDialog
                            variant="contained"
                            color="primary"
                            size="small"
                            title="Editar"
                            rangeProduct={{
                              ...item,
                              ddd: item.ddd === 127 ? null : item.ddd,
                            }}
                            product={product}
                            style={{
                              padding: 12,
                              marginRight: 2,
                              minWidth: 0,
                            }}
                            onEndEvent={() => loadingProduct()}
                          >
                            <FaPencilAlt />
                            &nbsp;Editar
                          </FormDialog>

                          <DeleteDialog
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ padding: 12, minWidth: 0 }}
                            setIdLoadingDelete={setIdLoadingDelete}
                            setProductRange={setProductRange}
                            product={product}
                            idProdutoCompraRegiao={item.idProdutoCompraRegiao}
                          >
                            {loadingIdDelete === item.idProdutoCompraRegiao ? (
                              <CircularProgress size={12} />
                            ) : (
                              <FaTrash />
                            )}
                            &nbsp;Excluir
                          </DeleteDialog>
                        </Grid>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5">
                    <CustomPaginator
                      {...{ setPagination, ...customPaginator }}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RegionDialog.defaultProps = {
  product: null,
};

RegionDialog.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]),
};
