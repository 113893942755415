import React from 'react';
import styled from 'styled-components';
import { Ghost } from 'react-kawaii';

const Container = styled.section`
  display: flex;
  width: 100%;
  height: 60%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
`;

const Message = styled.h3`
  color: #bbb;
  font-size: 24px;
  margin-top: 20px;
  text-align: center;
`;

export default function NoAccess() {
  return (
    <Container>
      <Ghost mood="shocked" color="#c7c7c7" />
      <Message>Buuhh... Você não pode entrar aqui.</Message>
    </Container>
  );
}
