import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumberTicket } from '~/helpers/number';
import { Paper, Dezena } from '../styles';

export function CardCoupon({ item }) {
  const [open, setOpen] = useState(null);

  const renderPopper = titulo => {
    return (
      <Paper>
        {titulo.dezenas.map(dezena => (
          <Dezena key={dezena}>{dezena}</Dezena>
        ))}
      </Paper>
    );
  };
  return (
    <div>
      <h2>{item.promocao.produto.titulo}</h2>
      <p>{item.promocao.tituloPromocao}</p>
      <p>
        {item.titulos.length > 1 ? 'Números da Sorte: ' : 'Número da Sorte: '}
        {item.titulos.map(titulo => {
          titulo.show = false;

          return (
            <span key={titulo.numTitulo + Math.random()}>
              <button
                type="button"
                onClick={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                className="titulo"
              >
                {formatNumberTicket(titulo.numTitulo)}
              </button>
              {open && renderPopper(titulo)}
              &nbsp;
            </span>
          );
        })}
      </p>
    </div>
  );
}

CardCoupon.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
