/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { TextField, Select, Checkbox } from '@unform/material-ui';
import * as Yup from 'yup';
import {
  debounce as debounceFn,
  get,
  isEmpty,
  last,
  set,
  toNumber,
} from 'lodash';
import { format, set as setDate } from 'date-fns';

import { FaLink, FaFileAlt } from 'react-icons/fa';

import { Grid, MenuItem, CircularProgress, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { toNumber as toNumberHelper } from '~/helpers/number';

import DatePicker from '~/components/unform/v2/DatePicker';
import DateTimePicker from '~/components/unform/v2/DateTimePicker';
import InstitutionsAutocomplete from '~/components/unform/v2/InstitutionsAutocomplete';
import CgsSusepAutocomplete from '~/components/unform/v2/CgsSusepAutocomplete';
import { TextMaskTime, TextMaskNumber } from '~/components/masks';
import { getPromotionColors } from '~/helpers/color';
import TextMaskCurrency from '~/components/masks/TextMaskCurrency';
import api from '~/services/api';
import PropTypes from 'prop-types';
import { usePromotionAllPermission } from './context-permissions';
import PromotionActionGiftForm from './Form';
import { usePromotionContext } from '../../context';
import {
  Section,
  SectionTitle,
  SectionContent,
  ContainerPromoCad,
} from './styles';
import UploadImageCapaPromoVirgente from '../../../Draft/Banner/uploadCapaPromoVirgente';
import CardImagemCapa from './CardImagemCapa';

import PromotionActionsGiftsList from './List';

const schema = Yup.object().shape({
  dataReferencia: Yup.date()
    .typeError('Data de referência inválida.')
    .required('Informa a data de referência'),
  dataVigenciaInicio: Yup.date()
    .typeError('Data de inicio de vigência inválida.')
    .required('Informe a data inicial da vigência.'),
  dataVigenciaFim: Yup.date()
    .typeError('Data de fim de vigência inválida.')
    .required('Informe a data final da vigência.'),
  // eslint-disable-next-line func-names
  categoria: Yup.mixed().when('$produto', function(produto) {
    if (produto && produto !== 'instantaneo') {
      return this.required('Informe a categoria.');
    }
    return this.nullable().optional();
  }),
  pulo: Yup.string().nullable(),
  idInstituicao: Yup.number()
    .required('Instituição inválida.')
    .typeError('Instituição inválida.')
    .when('$load', {
      is: true,
      then: self => self.nullable(),
      otherwise: self => self,
    }),
  idCgSusep: Yup.number().nullable(),
  tituloPromocao: Yup.string()
    .typeError('Titulo da promoção inválido.')
    .required('Informe o titulo corretamente.'),
  valorProduto: Yup.number()
    .transform(function parseAmount(value, originalValue) {
      if (this.isType(value)) return value;
      return toNumber(originalValue.replace(',', '.').replace(/[^0-9.]/gi, ''));
    })
    .typeError('Valor do titulo inválido.')
    .positive('Defina o valor corretamente.')
    .required('Informe o valor do produto.'),
  extracao: Yup.number()
    .typeError('Extração inválida.')
    .positive('Defina corretamente o valor da extração.')
    .required('Informe o valor da extração.'),
  horaSorteio: Yup.string()
    .typeError('Informe a hora do sorteio corretamente.')
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
    .required('Hora do sorteio inválida.'),
  status: Yup.number()
    .oneOf([0, 1, 2], 'Status inválido')
    .typeError('Status inválido.'),
  dataSorteioPrincipal: Yup.date(),
  numeroExtra: Yup.boolean().required('Numero extra inválido.'),
  config: Yup.object({
    multiProduto: Yup.object({
      qtdMinimaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdMaximaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      botoesQtd: Yup.array().of(Yup.number().nullable()),
    }).nullable(),
    isDouble: Yup.boolean(),
    quantidadeCuponsInicio: Yup.date()
      .typeError('Informe a data de inicio para a ação em dobro.')
      .when('isDouble', function(isDouble) {
        if (isDouble) {
          return Yup.date()
            .typeError('Informe a data de inicio para a ação em dobro.')
            .required('Informe a data de inicio para a ação em dobro.');
        }
        return this.nullable().optional();
      }),
    quantidadeCuponsFim: Yup.date()
      .typeError('Informe a data fim para a ação em dobro.')
      .when(['isDouble', 'quantidadeCuponsInicio'], function(
        isDouble,
        quantidadeCuponsInicio
      ) {
        if (isDouble) {
          return Yup.date()
            .min(
              quantidadeCuponsInicio,
              'A data fim deve ser maior que a data de início.'
            )
            .typeError('Informe a data fim para a ação em dobro.')
            .required('Informe a data fim para a ação em dobro.');
        }
        return this.nullable().optional();
      }),
  }).nullable(),
  qtdSugerida1: Yup.string()
    .nullable()
    .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
  qtdSugerida2: Yup.string()
    .nullable()
    .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
});

const validateSchema = data => {
  try {
    schema.validateSync(data, { abortEarly: false, skipUnkown: true });
    return {};
  } catch (err) {
    return get(err, 'inner', []).reduce((obj, error) => {
      Object.assign(obj, {
        [error.path]: error.message,
      });
      return obj;
    }, {});
  }
};

export function PromotionDataForm({ config }) {
  const formRef = useRef();
  const { can } = usePromotionAllPermission();

  const [list, setList] = useState([]);
  const [disablePuloInput, setDisablePuloInput] = useState(true);

  const [loading, setLoading] = useState(false);
  const [isDouble, setIsDouble] = useState(false);

  const {
    promotion,
    saving,
    savePromotion,
    changeRegulation,
    updatingRegulation,
  } = usePromotionContext();

  useEffect(() => {
    if (!isEmpty(promotion) && !!formRef.current) {
      try {
        const context = { load: true, produto: promotion.produto.codigo };
        const castedValue = schema.cast(promotion, {
          context,
        });
        const { valorProduto, config: promotionConfig, ...other } = castedValue;
        const castPrice = valorProduto
          .toPrecision(5)
          .toString()
          .replace('.', ',');
        const horaSorteio = format(castedValue.dataSorteioPrincipal, 'HH:mm');
        const corPromocaoIndex = get(promotion, 'corPromocao.index', '');
        const corPromocao = get(promotion, 'corPromocao', '');

        if (promotionConfig.quantidadeCupons) {
          setIsDouble(true);
        }

        formRef.current.setData({
          ...other,
          valorProduto: castPrice,
          horaSorteio,
          corPromocao: corPromocaoIndex || corPromocao,
          config: promotionConfig,
          isDouble: promotionConfig.quantidadeCupons,
          qtdSugerida1:
            promotionConfig &&
            promotionConfig.multiProduto &&
            promotionConfig.multiProduto.botoesQtd &&
            promotionConfig.multiProduto.botoesQtd.length &&
            promotionConfig.multiProduto.botoesQtd[0],
          qtdSugerida2:
            promotionConfig &&
            promotionConfig.multiProduto &&
            promotionConfig.multiProduto.botoesQtd &&
            promotionConfig.multiProduto.botoesQtd.length &&
            promotionConfig.multiProduto.botoesQtd[1],
        });
      } catch (err) {
        console.warn('Application Error: %s', err.message); // eslint-disable-line
        const message = (
          <p style={{ color: '#333', fontWeight: 'bold' }}>
            Desculpe, ocorreu um erro ao tentar carregar todos dados da
            promoção. Por favor entre em contato com o suporte!
          </p>
        );
        toast.warn(message, { autoClose: false });
      }
    }
  }, [promotion, config]);

  const validateForm = useCallback(
    data => {
      const errors = validateSchema(data);
      formRef.current.setErrors(errors);
      return { hasError: !!Object.keys(errors).length };
    },
    [formRef]
  );

  const handleSubmit = useCallback(
    data => {
      const { hasError } = validateForm(data);
      if (hasError) return;

      const formData = schema.cast(data, {
        context: { produto: promotion.produto.codigo },
        skipUnkown: true,
      });
      const [hours, minutes] = formData.horaSorteio.split(':');

      formData.pulo = toNumberHelper(formData.pulo) || null;
      formData.numTituloFim = toNumberHelper(formData.numTituloFim) || null;
      formData.numTituloInicio =
        toNumberHelper(formData.numTituloInicio) || null;
      formData.config = {
        multiProduto: {
          qtdMinimaCupons: data.config.multiProduto.qtdMinimaCupons || null,
          qtdMaximaCupons: data.config.multiProduto.qtdMaximaCupons || null,
          botoesQtd: [data.qtdSugerida1 || null, data.qtdSugerida2 || null],
        },
        quantidadeCupons: parseInt(data.config.quantidadeCupons, 10) || null,
        quantidadeCuponsInicio: data.config.quantidadeCuponsInicio || null,
        quantidadeCuponsFim: data.config.quantidadeCuponsFim || null,
      };

      set(
        formData,
        'dataSorteioPrincipal',
        setDate(formData.dataReferencia, {
          hours,
          minutes,
        })
      );

      savePromotion(formData);
    },
    [validateForm, savePromotion, promotion]
  );
  const [imagemCapa, setImagemCapa] = useState(promotion.imagemCapa || null);

  const onUploadCapa = useCallback(imageCapa => {
    setImagemCapa(imageCapa);
  }, []);

  const handleOnDeleteCapa = useCallback(isDelete => {
    if (isDelete) {
      setImagemCapa(null);
    }
  }, []);

  const debounce = useCallback(debounceFn, []);
  const regulation = useMemo(() => {
    const url = get(promotion, 'regulamento.url', '');
    return { name: last(url.split('/')), url };
  }, [promotion]);

  const loadingData = useCallback(async () => {
    const brindes = await api.get(`/promotions/action/${promotion.idPromocao}`);
    // // eslint-disable-next-line react/prop-types
    if (brindes.data.rows) {
      // eslint-disable-next-line react/prop-types
      setList(brindes.data.rows);
    }

    let scrambledMatrixCode = 'Desabilitado';

    if (promotion.meioEntregaCupom === 'CS') {
      const couponServicePromo = await api.get(
        `/promotions/${promotion.idPromocao}/couponservice`
      );

      if (couponServicePromo.data?.scrambledMatrix?.code) {
        scrambledMatrixCode = couponServicePromo.data?.scrambledMatrix?.code;
      }
    }

    const currentData = formRef.current.getData();
    formRef.current.setData({
      ...currentData,
      scrambledMatrix: scrambledMatrixCode,
    });

    setLoading(false);
  }, [promotion.idPromocao]);

  useEffect(() => {
    if (!isEmpty(promotion) && !!formRef.current) {
      loadingData();
    }
  }, [promotion, loadingData, config]);

  useEffect(() => {
    setImagemCapa(promotion.imagemCapa);
  }, [promotion.imagemCapa]);
  if (!can('update_promotion')) return null;

  return (
    <Section>
      <SectionTitle>
        <FaLink size="18" />
        <h3>Informações da Promoção</h3>
      </SectionTitle>

      <SectionContent loading={loading || saving}>
        {loading ||
          saving ||
          (isEmpty(config) && <CircularProgress size={50} />)}
        {!isEmpty(config) && (
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            id="promotion"
            initialData={{ categoria: '' }}
            onBlur={debounce(() => {
              if (formRef.current) {
                const formData = formRef.current.getData();
                validateForm(formData);
              }
            }, 100)}
          >
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <DatePicker
                  disabled={!config.fields.promotions.dataReferencia}
                  name="dataReferencia"
                  label="Data de Referência"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled={!config.fields.promotions.tituloPromocao}
                  name="tituloPromocao"
                  label="Titulo"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <InstitutionsAutocomplete
                  disabled={!config.fields.promotions.instituicao}
                  name="idInstituicao"
                  margin="none"
                />
              </Grid>
              <Grid item xs={3}>
                <CgsSusepAutocomplete
                  disabled={!config.fields.promotions.cgSusep}
                  name="idCgSusep"
                  margin="none"
                  produto={
                    promotion && promotion.produto && promotion.produto.id
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={!config.fields.promotions.horarioSorteio}
                  name="horaSorteio"
                  label="Horário do Sorteio"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskTime,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Select
                  disabled={!config.fields.promotions.categoria}
                  name="categoria"
                  label="Categoria"
                  defaultValue=""
                  variant="outlined"
                  onChange={({ target: { value } }) => {
                    setDisablePuloInput(
                      !(value === 'dupla' || value === 'tripla')
                    );
                  }}
                >
                  {promotion.produto &&
                  promotion.produto.codigo === 'instantaneo' ? (
                    <MenuItem value="simples">Simples</MenuItem>
                  ) : (
                    [
                      <MenuItem key="simples" value="simples">
                        Simples
                      </MenuItem>,
                      <MenuItem key="dupla" value="dupla">
                        Dupla
                      </MenuItem>,
                      <MenuItem key="tripla" value="tripla">
                        Tripla
                      </MenuItem>,
                    ]
                  )}
                </Select>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="pulo"
                  label="Pulo"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  disabled={disablePuloInput || !config.fields.promotions.pulo}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={!config.fields.promotions.tituloInicial}
                  name="numTituloInicio"
                  label="Título Inicial"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={!config.fields.promotions.tituloFinal}
                  name="numTituloFim"
                  label="Título Final"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <DateTimePicker
                  disabled={!config.fields.promotions.inicioVigencia}
                  name="dataVigenciaInicio"
                  label="Início da Vigência"
                />
              </Grid>
              <Grid item xs={2}>
                <DateTimePicker
                  disabled={!config.fields.promotions.fimVigencia}
                  name="dataVigenciaFim"
                  label="Fim da Vigência"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={!config.fields.promotions.extracao}
                  name="extracao"
                  label="Extração"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={!config.fields.promotions.valor}
                  name="valorProduto"
                  label="Valor do titulo"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCurrency,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <Select
                  disabled={!config.fields.promotions.status}
                  name="status"
                  label="Status"
                  variant="outlined"
                >
                  <MenuItem value={0}>0 - Encerrado</MenuItem>
                  <MenuItem value={1}>1 - Ativado</MenuItem>
                  <MenuItem value={2}>2 - Apuração</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={2}>
                <Select
                  disabled={!config.fields.promotions.corPromocao}
                  name="corPromocao"
                  label="Cor Promoção"
                  variant="outlined"
                >
                  {getPromotionColors().map(color => (
                    <MenuItem key={color.value} value={color.value}>
                      {color.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={2} style={{ display: 'flex' }}>
                <Select
                  disabled={!config.fields.promotions.numeroExtra}
                  name="numeroExtra"
                  label="Número extra?"
                  variant="outlined"
                >
                  <MenuItem value>SIM</MenuItem>
                  <MenuItem value={false}>NÃO</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="config.multiProduto.qtdMinimaCupons"
                  label="Quantidade mínima"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="config.multiProduto.qtdMaximaCupons"
                  label="Quantidade máxima"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="qtdSugerida1"
                  label="Quantidade sugerida 1"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  name="qtdSugerida2"
                  label="Quantidade sugerida 2"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled="true"
                  name="scrambledMatrix"
                  label="Matriz embaralhada"
                  variant="outlined"
                />
              </Grid>

              {can('update_promotion_regulation') && (
                <Grid
                  item
                  xs={3}
                  justify-content="center"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span>Regulamento</span>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <FaFileAlt size={22} style={{ marginRight: 5 }} />
                    <a
                      href={regulation.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ flex: 1 }}
                    >
                      {`...${regulation.name.substr(-20)}`}
                    </a>
                    <label htmlFor="regulation_file">
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="regulation_file"
                        name="regulation_file"
                        onChange={evt => {
                          const file = evt.target.files[0] || null;
                          if (file) {
                            changeRegulation(file, formRef.current.getData());
                            document.getElementById('regulation_file').value =
                              '';
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{ marginLeft: 5 }}
                        component="span"
                        disabled={updatingRegulation}
                      >
                        {!updatingRegulation ? (
                          'Trocar'
                        ) : (
                          <CircularProgress size={20} />
                        )}
                      </Button>
                    </label>
                  </div>
                </Grid>
              )}

              {promotion.instantanea && (
                <>
                  <Grid item xs={2}>
                    <Checkbox
                      color="primary"
                      size="medium"
                      name="config.isDouble"
                      label="Ação em dobro"
                      checked={isDouble}
                      onChange={(_, checked) => {
                        setIsDouble(checked);
                      }}
                    />
                    Ação em dobro
                  </Grid>
                  {isDouble && (
                    <>
                      <Grid item xs={2}>
                        <Select
                          disabled={!isDouble}
                          name="config.quantidadeCupons"
                          label="Quantidade de cupons"
                          defaultValue={promotion.config.quantidadeCupons || 2}
                          variant="outlined"
                        >
                          <MenuItem key="2" value="2">
                            2
                          </MenuItem>
                          <MenuItem key="3" value="3">
                            3
                          </MenuItem>
                          <MenuItem key="4" value="4">
                            4
                          </MenuItem>
                          <MenuItem key="5" value="5">
                            5
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={2}>
                        <DateTimePicker
                          disabled={!isDouble}
                          name="config.quantidadeCuponsInicio"
                          label="Início da Vigência"
                          defaultValue={promotion.config.quantidadeCuponsInicio}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <DateTimePicker
                          disabled={!isDouble}
                          name="config.quantidadeCuponsFim"
                          label="Fim da Vigência"
                          defaultValue={promotion.config.quantidadeCuponsFim}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Form>
        )}
      </SectionContent>
      <ContainerPromoCad>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h3 style={{ marginLeft: 10, marginBottom: 20, marginTop: 20 }}>
              CAPA PARA PROMOÇÃO
            </h3>
            <hr style={{ margin: 10 }} />
            <UploadImageCapaPromoVirgente
              imagePromotion={promotion.imagemCapa}
              promotionId={promotion.idPromocao}
              onUpload={onUploadCapa}
            />
          </Grid>
          {imagemCapa && (
            <>
              <Grid item xs={12} md={8} style={{ marginTop: 47 }}>
                <hr style={{ margin: 10 }} />
                <div>
                  <CardImagemCapa
                    promotionId={promotion.idPromocao}
                    onDelete={handleOnDeleteCapa}
                    url={imagemCapa}
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>

        {promotion && list.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PromotionActionGiftForm
                promotion={promotion}
                loadingData={loadingData}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PromotionActionsGiftsList
                list={list}
                loadingData={loadingData}
              />
            </Grid>
          </Grid>
        )}
      </ContainerPromoCad>
    </Section>
  );
}

PromotionDataForm.propTypes = {
  config: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
