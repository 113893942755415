/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { FaCog } from 'react-icons/fa';

import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector, useDispatch } from 'react-redux';
import { Content, Title } from '~/styles/global';
import AwardList from '~/pages/Backoffice/Awards/List';
import { NoRecord } from '~/components';
import { setParams } from '~/store/modules/user/actions';
import PromotionConfigForm from '../Form';
import { Container } from './PromotionActionsGifts/styles';
import TabPanel from './tabPanel';
import PromotionActionsGifts from './PromotionActionsGifts';
// sign of the function compoment
export default function PromotionConfigMain() {
  const useStyles = makeStyles(_theme => ({
    root: {
      backgroundColor: _theme.palette.background.paper,
      width: '100%',
    },
  }));
  const product = useSelector(({ user }) => user.product);
  const params = useSelector(({ user }) => user.params);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setParams({ ...params, useFilterByProduct: true }));
    }, 200);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => dispatch(setParams({ ...params, useFilterByProduct: false }));
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const renderTabs = () => {
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default" elevation={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="Abas de configuração da promoção"
          >
            <Tab
              label="Configuração Padrão"
              // icon={<FaCog size={20} />}
              {...a11yProps(0)}
            />
            <Tab
              label="Prêmios"
              // icon={<FaBoxes size={20} />}
              {...a11yProps(1)}
            />
            <Tab
              label="Ação Brinde"
              // icon={<FaCog size={20} />}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PromotionConfigForm product={product} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <AwardList product={product} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <PromotionActionsGifts product={product} />
          </TabPanel>
        </SwipeableViews>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Container>
            <FaCog size="25" color="gray" />
            <Title className="title">&nbsp;Configuração da promoção</Title>
          </Container>
        </Grid>
        <Grid item xs={12} md={12}>
          {product && renderTabs()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Content>
      {product && product.id ? (
        renderMain()
      ) : (
        <NoRecord message="Selecione um produto primeiro." />
      )}
    </Content>
  );
}
