import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import api from '~/services/api';
import PromotionActionGiftForm from './Form';
import PromotionActionsGiftsList from './List';

export default function PromotionActionsGifts({ product }) {
  const [list, setList] = useState([]);
  const loadingData = useCallback(async () => {
    const brindes = await api.get(
      `/promotions/config/actiongifts/${product.id}`
    );
    setList(brindes.data);
  }, [product.id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PromotionActionGiftForm product={product} loadingData={loadingData} />
      </Grid>

      <Grid item xs={12} md={6}>
        <PromotionActionsGiftsList
          product={product}
          list={list}
          loadingData={loadingData}
        />
      </Grid>
    </Grid>
  );
}

PromotionActionsGifts.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
