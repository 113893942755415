import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { TextField, Select } from '@unform/material-ui';
import {
  InputAdornment,
  TextField as MUITextField,
  Button,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  set,
  defaultsDeep,
  cloneDeep,
  has,
  get,
  toNumber,
  isEmpty,
  isString,
  unset,
  isNil,
  isNaN,
} from 'lodash';
import { mergeDeepLeft } from 'ramda';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { MdSend } from 'react-icons/md';
import { addMinutes, isValid, getMinutes, format, isBefore } from 'date-fns';
import createFormData from 'object-to-formdata';
import { useConfirm } from 'material-ui-confirm';

import {
  AccessControl,
  UnauthorizedGhost,
  PushPreview,
  ProductAutocomplete,
  EmojiPopover,
} from '~/components';
import {
  Container,
  FormContainer,
  Row,
  Col,
  SectionTitle,
  ApplicationTable,
  ApplicationItem,
} from './styles';
import ApplicationItemDate from './applicationItemDate';
import PushCreateImageUpload from './imageUpload';
import usePush from './usePush';
import history from '~/services/history';

function getClonedPush() {
  try {
    const push = JSON.parse(window.localStorage.getItem('clonedPush'));
    window.localStorage.removeItem('clonedPush');
    return push;
  } catch (error) {
    return null;
  }
}

export default function PushNotificationCreate() {
  const { applications: applicationList, creating, createPush } = usePush();
  const clonedPush = getClonedPush();

  const formRef = useRef(null);

  const [formdata, setFormdata] = useState({
    title: '',
    body: '',
    image: null,
    clonedImage: null,
  });

  const [deeplink, setDeeplink] = useState(() => {
    const initialState = '';
    const { deepLink: clonedDeepLink } = get(clonedPush, 'data', {});
    if (clonedDeepLink) {
      return clonedDeepLink;
    }
    return initialState;
  });

  const [redirectLink, setRedirectLink] = useState(() => {
    const initialState = '';
    const { redirectLink: clonedRedirectLink } = get(clonedPush, 'data', {});
    if (clonedRedirectLink) {
      return clonedRedirectLink;
    }
    return initialState;
  });

  const [applications, setApplications] = useState([]);
  const [sendDate, setSendDate] = useState(new Date());
  const [dateInterval, setDateInterval] = useState(5);
  const [topics, setTopics] = useState([]);
  const confirm = useConfirm();

  useEffect(() => {
    if (clonedPush) {
      const { title, body, buttonText, product, image } = clonedPush.data;
      formRef.current.setData({
        title,
        body,
        buttonText,
        product,
        deeplink,
        redirectLink,
      });
      setFormdata(oldState => ({
        ...oldState,
        title,
        body,
        buttonText,
        product,
        clonedImage: image,
        deeplink,
        redirectLink,
      }));
    }
  }, [redirectLink, deeplink, clonedPush]);

  const getSendDate = useCallback(
    ({ appSendDate }) => {
      if (!isValid(appSendDate)) {
        return isValid(sendDate) ? sendDate : '';
      }
      return appSendDate;
    },
    [sendDate]
  );

  const getSendDateWithInterval = useCallback(
    ({ appSendDate, idx = 0 }) => {
      const date = getSendDate({ appSendDate });
      const interval = toNumber(dateInterval) >= 0 ? Math.abs(dateInterval) : 0;
      return addMinutes(date, idx * interval);
    },
    [getSendDate, dateInterval]
  );

  const getAppSendDate = useCallback(
    ({ appSendDate, appId }) => {
      const newIdx = applications.findIndex(app => app.id === appId);

      return appSendDate
        ? getSendDate({ appSendDate })
        : getSendDateWithInterval({ appSendDate, idx: newIdx });
    },
    [applications, getSendDate, getSendDateWithInterval]
  );

  const submitDisabled = useMemo(() => {
    const hasContent = !isEmpty(formdata.title) && !isEmpty(formdata.body);
    const hasApps = !isEmpty(applications) && applications.length > 0;
    const hasSendDate = isValid(sendDate);

    if (
      !!deeplink &&
      deeplink === 'url' &&
      !/^https?:\/\/.+/gi.test(redirectLink)
    ) {
      return true;
    }

    const appDates = applications.map(app => {
      const appDate = getAppSendDate({
        appSendDate: app.sendDate,
        appId: app.id,
      });
      const isValidAppDate = isValid(appDate);
      return isValidAppDate ? format(appDate, 'yyyyMMddHHmm') : '';
    });
    const hasInvalidAppDates = applications.some(app => {
      const appDate = getAppSendDate({
        appSendDate: app.sendDate,
        appId: app.id,
      });
      const isValidAppDate = isValid(appDate);
      const currAppDate = isValidAppDate ? format(appDate, 'yyyyMMddHHmm') : '';
      return (
        appDates.filter(findDate => findDate === currAppDate).length > 1 ||
        isBefore(appDate, sendDate)
      );
    });

    return (
      hasInvalidAppDates ||
      !hasContent ||
      !hasApps ||
      !hasSendDate ||
      dateInterval < 2 ||
      !topics ||
      !topics.length
    );
  }, [
    formdata,
    sendDate,
    applications,
    dateInterval,
    deeplink,
    redirectLink,
    getAppSendDate,
    topics,
  ]);

  function updateValue(field) {
    return evt => {
      const object = cloneDeep(formdata);

      if (has(evt, 'target.value')) {
        set(object, field, evt.target.value);
      } else {
        set(object, field, evt);
      }
      setFormdata(value => defaultsDeep(object, value));
    };
  }

  function insertEmoji(value, handler) {
    return ({ native: emoji }) => {
      if (typeof handler === 'function') {
        handler(value + emoji);
      }
    };
  }

  function updateApplicationDate({ date, appId }) {
    const apps = [...applications];
    const appIdx = apps.findIndex(app => app.id === appId);

    if (isValid(date)) {
      set(apps, `${appIdx}.sendDate`, date);
    } else if (isEmpty(date)) {
      unset(apps, `${appIdx}.sendDate`);
    }

    setApplications(apps);
  }

  async function handleSubmit({ interval, ...data }) {
    /**
     * Display confirm popup before continue.
     */
    try {
      const messageWithoutFilters = (
        <>
          <strong>ATENÇÃO!!!</strong>
          <p>
            Esta notificação será enviada para todos dispositivos inscritos nos
            tópicos
          </p>
          <strong>{topics.map(({ label }) => label).join(', ')}</strong>
          <p>
            Por favor confira atentamente todas informações antes de enviar.
          </p>
          <strong>ESTA AÇÃO É IRREVERSÍVEL!</strong>
        </>
      );

      await confirm({
        title: 'Confirme esta ação',
        description: messageWithoutFilters,
        confirmationText: 'OK, está tudo certo.',
        confirmationButtonProps: { color: 'primary' },
        cancellationText: 'Cancelar',
        cancellationButtonProps: { color: 'secondary' },
      });
    } catch (err) {
      return;
    }

    const formPayload = mergeDeepLeft(data, {
      ...formdata,
      image: formdata.clonedImage || formdata.image,
      sendDate,
      applications: applications.map((app, idx) => ({
        ...app,
        sendDate:
          app.sendDate || addMinutes(sendDate, idx * Math.abs(dateInterval)),
      })),
    });

    set(
      formPayload,
      'topics',
      topics.map(({ value }) => value)
    );

    if (dateInterval && !isNaN(toNumber(dateInterval))) {
      set(formPayload, 'applicationInterval', toNumber(dateInterval));
    }

    // remove unecessary fields
    const unsetFields = [
      'deepLink',
      'buttonText',
      'product',
      'redirectLink',
      'image',
    ];

    unsetFields.forEach(field => {
      const value = get(formPayload, field, null);

      if (isNil(value) || (isString(value) && isEmpty(value))) {
        unset(formPayload, field);
      }
    });

    // formdata
    const pushdata = createFormData(formPayload, {
      indices: true,
    });

    createPush(pushdata).then(() => {
      history.push('/push-notifications');
    });
  }

  const topicOptions = useMemo(
    () => [
      {
        value: 'ios',
        label: 'IOS',
      },
      {
        value: 'android',
        label: 'Android',
      },
      {
        value: 'bought',
        label: 'Usuário que já compraram na promoção atual',
      },
      {
        value: 'notBought',
        label: 'Usuário que NÃO compraram na promoção atual',
      },
      {
        value: 'neverBought',
        label: 'Usuários que nunca compraram na plataforma',
      },
      {
        value: 'pendingBankslip',
        label: 'Usuários com boletos pendentes não vencidos',
      },
      {
        value: 'genderF',
        label: 'Usuários de gênero FEMININO',
      },
      {
        value: 'genderM',
        label: 'Usuários de gênero MASCULINO',
      },
      {
        value: 'genderO',
        label: 'Usuários de gênero OUTROS',
      },
      {
        value: 'withoutPaymentMethod',
        label: 'Usuários sem forma de pagamento',
      },
      {
        value: 'age18to29',
        label: 'Usuários com idade de 18 a 29 anos',
      },
      {
        value: 'age30to45',
        label: 'Usuários com idade de 30 a 45 anos',
      },
      {
        value: 'age46to55',
        label: 'Usuários com idade de 46 a 55 anos',
      },
      {
        value: 'age56over',
        label: 'Usuários com idade acima de 56 anos',
      },
      {
        value: 'ideaHomol',
        label: 'Ambiente de testes (ideaHomol)',
      },
      {
        value: 'everybody',
        label: 'TODOS USUÁRIOS',
      },
    ],
    []
  );

  const shouldDisableAppDateTime = useCallback(
    appId => {
      const currentApp = applications.find(app => appId === app.id);
      const { sendDate: currentAppDate } = currentApp;
      const currentDate = getAppSendDate({
        appSendDate: currentAppDate,
        appId,
      });
      const disabledDates = applications
        .filter(app => appId !== app.id)
        .map(app => {
          return getAppSendDate({ appSendDate: app.sendDate, appId: app.id });
        });

      // callback to `shouldDisableTime` DateTimePicker
      return (currentTime, clockType) => {
        if (clockType === 'minutes') {
          return disabledDates.some(disabledDate => {
            const disabledDayWithHours = format(disabledDate, 'yyyyMMddHH');
            const dayWithHours = format(currentDate, 'yyyyMMddHH');
            const disabledMinutes = getMinutes(disabledDate);
            return (
              disabledDayWithHours === dayWithHours &&
              disabledMinutes === currentTime
            );
          });
        }
        return false;
      };
    },
    [applications, getAppSendDate]
  );

  return (
    <AccessControl
      can="create_push"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <Container>
        <FormContainer ref={formRef} onSubmit={handleSubmit} id="push-form">
          <SectionTitle>Configurações do Push</SectionTitle>

          <Row cols={2}>
            <Col>
              <TextField
                required
                label="Titulo da notificação..."
                name="title"
                onChange={updateValue('title')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmojiPopover
                        onPick={insertEmoji(
                          formdata.title,
                          updateValue('title')
                        )}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                required
                label="Conteúdo da mensagem..."
                name="body"
                multiline
                onChange={updateValue('body')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmojiPopover
                        onPick={insertEmoji(formdata.body, updateValue('body'))}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>

            <Col>
              <Row>
                <Col>
                  <Select
                    label="Deeplink / Tela"
                    name="deepLink"
                    value={deeplink}
                    onClose={({ target }) => {
                      const value = target.getAttribute('data-value');
                      setDeeplink(value);
                    }}
                  >
                    {[
                      {
                        value: '',
                        label: '— SELECIONE —',
                      },
                      {
                        value: 'promocaoVigente',
                        label: 'Abre cupom da promoção vigente',
                      },
                      {
                        value: 'promocaoAnterior',
                        label: 'Abre cupom da promoção anterior',
                      },

                      {
                        value: 'resultadoPromocaoAnterior',
                        label: 'Resultado da Promoção Anterior',
                      },
                      { value: 'promo', label: 'Conta > Código Promocional' },

                      { value: 'meusDados', label: 'Conta > Meu Dados' },
                      { value: 'validar', label: 'Tela Leitura de QR Code' },
                      { value: 'url', label: 'Redirecionar para URL' },
                      { value: 'checkout', label: 'Abre tela de compra' },
                    ].map(({ value, label }) => (
                      <MenuItem value={value}>{label}</MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row cols={2}>
                <Col>
                  <TextField name="buttonText" label="Texto do Botão" />
                </Col>

                <Col>
                  <ProductAutocomplete
                    label="Produto"
                    valueKey="cod"
                    onChange={updateValue('product')}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextField
                    name="redirectLink"
                    onChange={({ target }) => setRedirectLink(target.value)}
                    label="Link para redirecionamento"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <PushCreateImageUpload
                    src={formdata.clonedImage}
                    onChange={evt => {
                      updateValue('image')(evt);
                      setFormdata(oldState => ({
                        ...oldState,
                        clonedImage: null,
                      }));
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <SectionTitle>Selecione o tópico</SectionTitle>

          <Row>
            <Col>
              <Autocomplete
                multiple
                disableCloseOnSelect
                size="small"
                options={topicOptions}
                getOptionLabel={opt => opt.label}
                onChange={(_, value) => setTopics(value)}
                value={topics}
                renderInput={params => (
                  <MUITextField {...params} label="Tópicos" />
                )}
              />
            </Col>
          </Row>

          <SectionTitle>Aplicações</SectionTitle>

          <Row cols={2}>
            <Col>
              <Autocomplete
                multiple
                disableCloseOnSelect
                size="small"
                options={applicationList}
                getOptionLabel={opt => opt.title}
                onChange={(_, value) => {
                  setApplications([...value]);
                }}
                value={applications}
                renderInput={params => (
                  <MUITextField {...params} label="Selecione as aplicações *" />
                )}
              />
            </Col>

            <Col>
              <Row cols={2}>
                <Col>
                  <LocalizationProvider dateAdapter={DateFnsAdapter}>
                    <DateTimePicker
                      ampm={false}
                      inputFormat="dd/MM/yyyy HH:mm"
                      value={sendDate}
                      onChange={date => setSendDate(date)}
                      hideTabs
                      renderInput={props => (
                        <MUITextField
                          {...props}
                          label="Data de início"
                          helperText={undefined}
                        />
                      )}
                      showToolbar={false}
                      disablePast
                    />
                  </LocalizationProvider>
                </Col>

                <Col>
                  <MUITextField
                    type="number"
                    inputProps={{ min: '2' }}
                    name="interval"
                    label="Intervalo (em minutos)"
                    value={dateInterval}
                    onChange={({ target }) => setDateInterval(target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <ApplicationTable>
            {applications.map(({ id, title, sendDate: appSendDate }, idx) => (
              <ApplicationItem key={id}>
                <header>
                  <span className="name">
                    <MdSend />
                    {title}
                  </span>
                </header>

                <ul className="items">
                  <li>
                    {/* <MdAccessTime /> */}
                    <ApplicationItemDate
                      date={getAppSendDate({ appSendDate, idx, appId: id })}
                      minDateTime={sendDate}
                      minutesStep={Math.abs(toNumber(dateInterval))}
                      shouldDisableTime={shouldDisableAppDateTime(id)}
                      onChange={date => {
                        updateApplicationDate({ appId: id, date });
                      }}
                    />
                  </li>
                </ul>
              </ApplicationItem>
            ))}
          </ApplicationTable>

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={submitDisabled || creating}
          >
            {creating ? 'Salvando...' : 'Salvar'}
          </Button>
        </FormContainer>

        <section className="push-preview">
          <PushPreview
            title={formdata.title || 'Titulo da notificação'}
            body={formdata.body || 'Conteúdo da notificação'}
          />
        </section>
      </Container>
    </AccessControl>
  );
}
