import styled from 'styled-components';
import { UnFormDatePicker } from '~/components';

export const Container = styled.header`
  margin-bottom: 30px;
  display: flex;
`;

export const FilterSection = styled.section`
  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 15px;
  }

  & > h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #888888;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const FilterSectionContent = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 10px;
  }
`;

export const DateTimePicker = styled(UnFormDatePicker)`
  & + & {
    margin-left: 15px;
  }
`;
