/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
// -- services
import history from '~/services/history';
import qs from '~/services/qs';

// -- components
import { NoRecord, AccessControl, UnauthorizedGhost } from '~/components';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import PromotionsFilter from './filter';
import Promotion from './promotion';

// -- hooks
import usePromotions from './usePromotions';

export default function PromotionsList() {
  const { context } = useParams();
  const { search } = useLocation();
  const filters = useMemo(() => qs.parse(search), [search]);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const { promotions, loading, updateFilters, resetFilters } = usePromotions({
    context,
    defaultFilters: filters,
    setPagination,
    ...customPaginator,
  });

  // -- filter from query string
  useEffect(() => {
    updateFilters(filters);
  }, [filters]); // eslint-disable-line

  // -- reset filters when context update
  useEffect(() => {
    if (context && !filters) {
      resetFilters();
    }
  }, [context]); // eslint-disable-line

  // -- navigate to promotion item
  function navigateToPromotion(id) {
    if (context === 'immediate') {
      history.push(`/screening/${id}`, {
        promocao: promotions.find(p => p.idPromocao === id),
      });
    } else {
      history.push(`/promotions/${context}/${id}`);
    }
  }

  // -- get filters type
  function getFilters() {
    if (context === 'immediate') {
      return [];
    }
    return ['product', 'refDate', 'gift', 'statusVigente'];
  }

  // modify query string
  function handleQueryString(field, value) {
    history.push({
      pathname: `/promotions/${context}`,
      search: qs.build({ ...filters, [field]: value }),
    });
  }

  const getPageTitle = useCallback(() => {
    switch (context) {
      case 'immediate':
        return 'Triagem';
      case 'results':
        return 'Resultados';
      default:
        return 'Promoções Vigentes';
    }
  }, [context]);

  return (
    <AccessControl
      can={['read_promotions', 'read_screening_promotions']}
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <>
        <Header>
          <h1 style={{ fontWeight: 400, fontSize: 26 }}>{getPageTitle()}</h1>
        </Header>
        <PromotionsFilter
          handleFilters={handleQueryString}
          filters={getFilters()}
        />

        {/* loading */}
        {loading && (
          <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}

        {/* no record */}
        {!loading && !promotions.length && <NoRecord />}

        {/* promotions */}
        {!loading && !!promotions.length && (
          <Grid container spacing={4} alignItems="flex-start">
            {promotions.map(({ idPromocao, ...promotion }) => (
              <Promotion
                key={String(idPromocao)}
                data={{ ...promotion, idPromocao }}
                onClick={() => navigateToPromotion(idPromocao)}
              />
            ))}
          </Grid>
        )}

        <Grid align="right">
          <CustomPaginator
            {...{ loading, setPagination, ...customPaginator }}
          />
        </Grid>
      </>
    </AccessControl>
  );
}
