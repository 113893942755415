import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import Feedback from './feedback';
import FeedbackHeader from './feedbackHeader';
import FeedbackFilter from './filter';
import { NoRecord, AccessControl, UnauthorizedGhost } from '~/components';
import { StatusBall, FeedbackFiltersLegend } from './styles';

import useFeedbackList from './useFeedbackList';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';

export default function Feedbacks() {
  const { ...customPagination } = useCustomPaginator();
  const {
    feedbacks,
    loading,
    onFeedbackUpdated,
    onFeedbackPersonUpdated,
    sendFilters,
    handleFilters,
  } = useFeedbackList(customPagination);

  return (
    <AccessControl
      can="read_winners_feedback"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <div>
        <Header>
          <h1 style={{ fontWeight: 400, fontSize: 26, marginBottom: 20 }}>
            Feedbacks
          </h1>
        </Header>
        {/* Filters */}
        <FeedbackFilter
          sendFilters={sendFilters}
          handleFilters={handleFilters}
        />

        {/* Filters Legends */}
        <FeedbackFiltersLegend>
          <h4>Legenda</h4>

          <ul>
            <li>
              <StatusBall status="ativado" /> Ativo
            </li>
            <li>
              <StatusBall status="desativado" /> Inativo
            </li>
          </ul>
        </FeedbackFiltersLegend>

        {/* NoRecord for Feedbacks */}
        {!feedbacks.length && !loading && (
          <NoRecord message="Nenhum feedback encontrado" />
        )}

        {/* Loading state */}
        {loading && (
          <Typography align="center" variantMapping={{ body1: 'div' }}>
            <CircularProgress />
          </Typography>
        )}

        {!!feedbacks.length && <FeedbackHeader />}

        {/* Feedback list */}
        {feedbacks.map(feedback => (
          <Feedback
            key={String(feedback.id)}
            {...feedback}
            onFeedbackUpdated={onFeedbackUpdated}
            onFeedbackPersonUpdated={onFeedbackPersonUpdated}
          />
        ))}

        {/* <CustomPaginator {...customPagination} /> */}
        <CustomPaginator {...{ loading, ...customPagination }} />
      </div>
    </AccessControl>
  );
}
