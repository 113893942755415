/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import api from '~/services/api';
import isEmpty from 'lodash/isEmpty';
import { CircularProgress } from '@material-ui/core';
import { NoRecord } from '~/components';
import { Table } from './styles';
import BodyTable from './components/bodyTable';

export default function OwnershipHistory({ person }) {
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState({});

  const getOwnershipHistory = useCallback(async () => {
    if (!person) return;
    setLoading(true);

    try {
      const { data } = await api.get(
        `/persons/${person.idCliente}/ownership-exchange/history?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`
      );
      if (!isEmpty(data)) {
        setHistory(data);

        const { limit, page, total } = data;
        setPagination({
          ...{ limit, page, pages: Math.ceil(total / limit), totals: total },
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    setPagination,
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  useEffect(() => {
    getOwnershipHistory();
  }, [getOwnershipHistory]);

  if (loading && (!history || !history.docs)) {
    return <CircularProgress />;
  }

  if (history && history.docs && history.docs.length > 0 && person) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Nº do Título</th>
              <th>Produto</th>
              <th>Promoção</th>
              <th>Data da mudança</th>
              <th>Origem</th>
              <th>Destino</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {history.docs.map(data => (
              <BodyTable data={data} personIdCliente={person.idCliente} />
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
      </>
    );
  }
  return <NoRecord />;
}

OwnershipHistory.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};

OwnershipHistory.defaultProps = {
  person: null,
};
