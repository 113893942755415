import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Grid,
  Avatar,
  DialogContent,
  IconButton,
  Icon,
} from '@material-ui/core';
import { format } from 'date-fns';
import PopoverUserActions from '../Popover';
import { Title, Subtitle } from './styles';
import { Roles } from '../List/styles';
import Products from '../Products';

export default function ViewUser({
  open,
  onClose,
  user,
  userRoles,
  setRefetch,
  refetchState,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          {!user ? (
            ''
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={1}>
                <Avatar>DK</Avatar>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
                item
                xs={9}
              >
                <Title>
                  {user.firstName} {user.lastName}
                </Title>
                <Subtitle>{user.email}</Subtitle>
              </Grid>
              <Grid
                item
                xs={2}
                align="right"
                style={{ paddingRight: 0, paddingTop: 0 }}
              >
                <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
                  <Icon>more_vert</Icon>
                </IconButton>
                <PopoverUserActions
                  open={openPopover}
                  onClose={handleClosePopover}
                  anchorEl={anchorEl}
                  user={user}
                  setRefetch={setRefetch}
                  refetchState={refetchState}
                  closeModal={onClose}
                />
              </Grid>
              <Grid
                style={{ display: 'flex', flexDirection: 'column' }}
                item
                xs={12}
              >
                <Title>
                  Data de inclusão:
                  <Subtitle>
                    {' '}
                    {format(new Date(user.createdTimestamp), 'dd/MM/yyyy')}
                  </Subtitle>
                </Title>
              </Grid>
              <Grid item xs={12}>
                <Title>Excluído:</Title>
                <Subtitle> {!user.enabled ? 'Sim' : 'Não'}</Subtitle>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                <Title>Permissões:</Title>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  {user?.clientRoles?.map(role => (
                    <Roles key={role.id} backgrounded={userRoles(role.name)}>
                      {role.name}
                    </Roles>
                  ))}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                <Title>Produtos:</Title>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  <Products user={user} />
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

ViewUser.defaultProps = {
  onClose: () => {},
  userRoles: () => {},
};

ViewUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
    createdTimestamp: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    clientRoles: PropTypes.oneOfType([PropTypes.array]),
    attributes: PropTypes.shape({
      productPermissions: PropTypes.oneOfType([PropTypes.array]),
    }),
  }).isRequired,
  userRoles: PropTypes.func,
  setRefetch: PropTypes.func.isRequired,
  refetchState: PropTypes.bool.isRequired,
};
