import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/no-cycle
import { store } from '~/store';

// import history from './history';
import { signOut } from '~/store/modules/auth/actions';
import Toast from '~/components/Toast';

export const baseURL = process.env.REACT_APP_BASE_URL;
const api = axios.create({
  baseURL,
});

// INTERCEPTOR
api.interceptors.response.use(
  response => {
    switch (response.config.method) {
      case 'post':
      case 'put':
      case 'delete':
        if (
          response.data.codeError !== 1 &&
          response.config.url !== '/auth' &&
          !response.config.url.includes('/bank-slips/') &&
          !response.config.url.includes('/screenings/sendMail') &&
          !response.config.url.includes('/cgs-susep/upload') &&
          !response.config.url.includes(
            '/two-factor-authentication/get-qrcode'
          ) &&
          !response.config.url.includes(
            '/two-factor-authentication/validate-pin'
          ) &&
          !response.config.url.includes('double-check')
        ) {
          toast.success(<Toast message="OK" />);
        }
        break;
      default:
        break;
    }
    return response;
  },
  error => {
    if (!error.response || !error.response.data) {
      toast.error(
        <Toast
          message={
            error.message ||
            'Algo inesperado aconteceu, tente novamente em instantes.'
          }
          error
        />
      );
      return error;
    }

    if (error.response.config.url.includes('double-check')) {
      throw new Error(error);
    }

    let message = `<h4>${error.response.data.error}</h4>`;
    switch (error.response.status) {
      case 400:
      case 404:
        if (error.response.data.messages) {
          error.response.data.messages.forEach(m => {
            message += `<br />${m}`;
          });
        }

        toast.warn(<Toast message={message} warn />);
        throw error;
      case 401:
        if (error.response.config.url === `/auth`) {
          toast.error(<Toast message="Dados de acesso incorretos." error />);
          return error;
        }
        if (error.response.config.url === `/auth/pre-two-factor`) {
          toast.error(<Toast message="Dados de acesso incorretos." error />);
          return error;
        }
        toast.error(
          <Toast message="Sessão Expirada. Faça login novamente." error />
        );
        store.dispatch(signOut());
        throw error;

      case 403:
        toast.error(<Toast message="Acesso Negado" error />);
        throw error;
      case 500:
        toast.error(
          <Toast
            message={
              error.response.data.error ||
              'Algo inexperado aconteceu, tente novamente em instantes.'
            }
            error
          />
        );
        throw error;
      default:
        toast.error(<Toast message={error.response.data.error} error />);
        // eslint-disable-next-line consistent-return
        throw error;
    }
  }
);

export default api;
