import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AccessControl } from '~/components';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import api from '~/services/api';
import { compareArrays } from '~/helpers/compareArray';
import { Container } from './styles';
import PromotionActionGiftForm from './Form';
import PromotionActionsGiftsList from './List';

function ToastAction({
  draft,
  updateDraft,
  product,
  isDoubleCheck,
  setStepsStatus,
  setActionGiftsList,
}) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadingData = useCallback(async () => {
    if (draft.data.promotion.brindes && !isDoubleCheck) {
      setList(draft.data.promotion.brindes);
    }
  }, [draft.data.promotion.brindes, isDoubleCheck, setList]);

  const getDoubleCheckData = useCallback(async () => {
    setLoading(true);
    try {
      const { data: doubleCheckedData } = await api.get(
        `/promotions/drafts/${draft._id}/get-double-check-data`
      );

      setList(
        Array.isArray(doubleCheckedData?.actionGifts)
          ? doubleCheckedData.actionGifts
          : []
      );
    } catch (e) {
      setList([]);
    } finally {
      setLoading(false);
    }
  }, [draft._id, setList, setLoading, isDoubleCheck]);

  useEffect(() => {
    loadingData();
  }, [draft.data.promotion.brindes, loadingData]);

  useEffect(() => {
    if (isDoubleCheck) {
      getDoubleCheckData();
    }
  }, [isDoubleCheck, getDoubleCheckData]);

  const handleDoubleCheck = useCallback(async () => {
    try {
      setLoading(true);

      const { data: updatedDraft } = await api.get(
        `/promotions/drafts/${draft._id}`
      );

      if (updatedDraft) {
        updateDraft(updatedDraft);
      }

      const keys = { id: 'idAcao', fields: ['idBrinde'] };

      const parsedList = list?.map(item => ({
        idAcao: +item.idAcao,
        idBrinde: +item.idBrinde,
      }));

      const parsedDraftList = updatedDraft?.data?.promotion?.brindes?.map(
        item => ({
          idAcao: +item.idAcao,
          idBrinde: +item.idBrinde,
        })
      );

      if (!compareArrays(parsedList, parsedDraftList, keys)) {
        setStepsStatus(prevStatus => ({ ...prevStatus, actionGifts: false }));
        toast.error(
          <Toast message="Dados divergentes, verifique novamente." />
        );
        return false;
      }

      toast.success(
        <Toast message="Os dados foram confirmados com sucesso." />
      );
      setStepsStatus(prevStatus => ({ ...prevStatus, actionGifts: true }));
      setActionGiftsList(parsedList);

      return true;
    } catch (error) {
      setStepsStatus(prevStatus => ({ ...prevStatus, actionGifts: false }));
      toast.error(<Toast message="Erro ao confirmar os dados." />);
      return false;
    } finally {
      setLoading(false);
    }
  }, [list, draft, updateDraft]);

  return (
    <Container>
      <Grid container spacing={2}>
        {draft.status !== 'approved' && (
          <Grid item xs={12} md={6}>
            <PromotionActionGiftForm
              draft={draft}
              loadingData={loadingData}
              updateDraft={updateDraft}
              product={product}
              isDoubleCheck={isDoubleCheck}
              setList={setList}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <PromotionActionsGiftsList
            draft={draft}
            updateDraft={updateDraft}
            list={list}
            loadingData={loadingData}
            isDoubleCheck={isDoubleCheck}
            setList={setList}
          />
        </Grid>
      </Grid>

      {isDoubleCheck && (
        <Box align="right" style={{ marginTop: 20 }}>
          {draft.status !== 'approved' && (
            <AccessControl can={['double_check_promotion_draft']}>
              <Button
                variant="contained"
                disabled={loading}
                color="primary"
                onClick={handleDoubleCheck || draft.status === 'approved'}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Conferir
                  </>
                )}
              </Button>
            </AccessControl>
          )}
        </Box>
      )}
    </Container>
  );
}

ToastAction.defaultProps = {
  draft: {},
  product: {},
  updateDraft: () => {},
  isDoubleCheck: false,
  setStepsStatus: () => {},
  setActionGiftsList: () => {},
};

ToastAction.propTypes = {
  draft: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.shape({
      promotion: PropTypes.shape({
        brindes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  updateDraft: PropTypes.func,
  isDoubleCheck: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setStepsStatus: PropTypes.func,
  setActionGiftsList: PropTypes.func,
};

export default ToastAction;
