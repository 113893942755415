import styled from 'styled-components';

export const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: ${props => (!props.state ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: all 0.6s;
`;
