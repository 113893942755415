import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Collapse } from '@material-ui/core';
import { ExpandIcon } from '~/components';
import { format } from 'date-fns';
import { SubTitle, Title } from './styles';
import Steps from './steps';

export default function BodyTable({ data, personIdCliente }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandInfos = () => {
    setExpanded(!expanded);
  };

  function formatNumber(number) {
    return number
      .toString()
      .padStart(7, '0')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return (
    <>
      <tr key={data.id}>
        <td>{formatNumber(data.titulo.titulo.numTitulo)}</td>
        <td>{data.titulo.promocao.produto.nome}</td>
        <td>
          {format(new Date(data.titulo.promocao.dataReferencia), 'dd/MM/yyyy')}
        </td>
        <td>
          <div>
            <p>{format(new Date(data.dataInsert), 'dd/MM/yyyy')}</p>
            <SubTitle>{format(new Date(data.dataInsert), 'hh:mm')}</SubTitle>
          </div>
        </td>
        <td>
          <div>
            <p>{data.pessoaOrigem.nomeCompleto}</p>
            <SubTitle>ID APCAP: {data.idPessoaOrigem}</SubTitle>
          </div>
        </td>
        <td>
          <div>
            <p>{data.pessoaDestino.nomeCompleto}</p>
            <SubTitle>ID APCAP: {data.idPessoaDestino}</SubTitle>
          </div>
        </td>
        <td>
          <ExpandIcon func={handleExpandInfos} state={expanded} />
        </td>
      </tr>
      <tr>
        {expanded && (
          <td colSpan={7}>
            <Collapse
              style={{ display: 'flex' }}
              in={expanded}
              timeout={1200}
              unmountOnExit
            >
              <Title>Data da compra</Title>
              <SubTitle>
                {format(new Date(data.titulo.compra.dataCompra), 'dd/MM/yyyy')}{' '}
                - {format(new Date(data.titulo.compra.dataCompra), 'hh:mm')}
              </SubTitle>
              <Title>Histórico de movimentações</Title>
              <Steps data={data} personIdCliente={personIdCliente} />
            </Collapse>
          </td>
        )}
      </tr>
    </>
  );
}

BodyTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  personIdCliente: PropTypes.number.isRequired,
};
