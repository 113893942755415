import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const ScreeningItemContext = createContext({});

export const ScreeningContextProvider = ({ children, data }) => (
  <ScreeningItemContext.Provider value={data}>
    {children}
  </ScreeningItemContext.Provider>
);

export const useScreening = () => {
  const context = useContext(ScreeningItemContext);
  if (!context)
    console.error(
      'ScreeningContext its not available outside <Screening /> component.'
    ); // eslint-disable-line
  return context;
};

ScreeningContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({}).isRequired,
};
