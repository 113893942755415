import { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { pick } from 'ramda';
import { setParams } from '~/store/modules/user/actions';
import api from '~/services/api';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import { useBannersHomePermission } from './context-permissions';

const replaceBanner = (arr = [], bannerId, newBanner = {}) => {
  const copyArr = [...arr];
  const bannerIdx = copyArr.findIndex(({ idBannerHome }) => {
    return bannerId === idBannerHome;
  });
  if (bannerIdx !== -1) copyArr.splice(bannerIdx, 1, newBanner);
  return copyArr;
};

export default function useQueryBannersHome() {
  const [banners, setBanners] = useState([]);
  const [bannersMobileRoutes, setBannersMobileRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [filters, setFilters] = useState({});
  const params = useSelector(({ user }) => user.params);

  const { product } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {
    canCreate,
    canRead,
    canDelete,
    canUpdate,
  } = useBannersHomePermission();

  const { setPagination, ...customPaginator } = useCustomPaginator();
  useEffect(() => {
    if (canRead || canCreate || canUpdate) {
      setTimeout(() => {
        dispatch(setParams({ ...params, useFilterByProduct: true }));
      }, 200);
    }
    // eslint-disable-next-line
  }, [dispatch, canRead, canCreate, canUpdate]);

  useEffect(() => {
    return () => dispatch(setParams({ ...params, useFilterByProduct: false }));
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchBanners = useCallback(async () => {
    if (!canRead) return;
    try {
      setLoading(true);
      const res = await api.get('/banners-home', {
        params: {
          ...filters,
          page: customPaginator.pagination.page,
          limit: customPaginator.pagination.limit,
          produto: product.id ? product.id : undefined,
        },
      });
      const paginationProps = pick(
        ['page', 'limit', 'total'],
        get(res, 'data', {})
      );

      setPagination({
        ...{
          limit: customPaginator.pagination.limit,
          page: customPaginator.pagination.page,
          pages: paginationProps.total / customPaginator.pagination.limit,
          totals: paginationProps.total,
        },
      });
      setBanners(get(res, 'data.docs', []));
    } finally {
      setLoading(false);
    }
  }, [
    canRead,
    product.id,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
    filters,
  ]);

  const fetchBannersMobileRoutes = useCallback(async () => {
    if (!canRead) return;
    try {
      setLoading(true);
      const res = await api.get('/banners-home/mobile-routes', {
        params: {
          ...filters,
        },
      });
      setBannersMobileRoutes(get(res, 'data.docs', []));
    } finally {
      setLoading(false);
    }
  }, [canRead, filters]);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  useEffect(() => {
    fetchBannersMobileRoutes();
  }, [fetchBannersMobileRoutes]);

  const deleteBanner = useCallback(
    async bannerId => {
      if (!canDelete) return;
      try {
        const copyBanners = [...banners];
        const idx = copyBanners.findIndex(({ idBannerHome }) => {
          return idBannerHome === bannerId;
        });

        await api.delete(`/banners-home/${bannerId}`);

        if (idx !== -1) {
          copyBanners.splice(idx, 1);
          setBanners(copyBanners);
        }
      } catch (err) {
        console.error(err); // eslint-disable-line
      }
    },
    [banners, canDelete]
  );

  const sendFilters = async atributeFilters => {
    setFilters(atributeFilters);
  };

  const addNewBanner = useCallback(
    async bannerData => {
      if (!canCreate) return;
      try {
        setCreating(true);
        const res = await api.post('/banners-home', bannerData);
        const newBanner = get(res, 'data', {});
        if (newBanner.idBannerHome)
          setBanners(oldBanners => [newBanner, ...oldBanners]);
      } finally {
        setCreating(false);
      }
    },
    [canCreate]
  );

  const updateBanner = useCallback(
    async (bannerId, data) => {
      if (!canUpdate) return null;
      try {
        setUpdating(true);
        const res = await api.put(`/banners-home/${bannerId}`, data);
        setBanners(oldBanners => {
          return replaceBanner(oldBanners, bannerId, get(res, 'data', {}));
        });
        return get(res, 'data', {});
      } finally {
        setUpdating(false);
      }
    },
    [canUpdate]
  );

  return {
    banners,
    bannersMobileRoutes,
    loading,
    creating,
    updating,
    deleteBanner,
    addNewBanner,
    updateBanner,
    setPagination,
    customPaginator,
    fetchBanners,
    fetchBannersMobileRoutes,
    setDataInicial,
    setDataFinal,
    dataInicial,
    dataFinal,
    sendFilters,
  };
}
