import React, { useCallback, useMemo } from 'react';
import { useModalGallery } from 'react-router-modal-gallery';
import { useParams, useLocation } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { MdEdit, MdSave } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import { TextField, Select } from 'unform-material-ui';
import { useDropzone } from 'react-dropzone';

// -- data
import brazilStates from '~/helpers/brazilStates';

// -- components
import { hasPermission } from '~/components/AccessControl';

import useProvisionalPerson from './useProvisionalPerson';
import { Container, Title, Content, Footer, Avatar } from './styles';

export default function Provisional() {
  const { isModal, redirectToBack } = useModalGallery();
  const { id } = useParams();
  const { state } = useLocation();

  const {
    person,
    avatar,
    loading,
    handleUpdatePerson,
    handleUpdateAvatar,
  } = useProvisionalPerson({
    id,
    onSave: state.onSave || undefined,
    autoClose: state.autoClose || undefined,
    redirectToBack,
  });

  const onDrop = useCallback(([file]) => handleUpdateAvatar(file), [
    handleUpdateAvatar,
  ]);

  const canEdit = useMemo(
    () => hasPermission('update_person', { toast: false }),
    []
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function normalizeState() {
    if (person.estado) {
      return String(person.estado).toUpperCase();
    }
    return null;
  }

  return (
    <Container open={isModal} onClose={redirectToBack}>
      <Form
        onSubmit={(...args) => canEdit && handleUpdatePerson(...args)}
        initialData={{ ...person, estado: normalizeState() }}
      >
        <Title>
          <MdEdit /> Informações Pessoais
        </Title>

        <Content>
          {loading && !person.id && (
            <Typography align="center">
              <CircularProgress />
            </Typography>
          )}

          {(!loading || person.id) && (
            <>
              <Avatar {...getRootProps()} url={avatar.preview}>
                <input {...getInputProps()} />
              </Avatar>

              <TextField name="nome" label="Nome" />
              <TextField name="logradouro" label="Logradouro" />
              <TextField name="bairro" label="Bairro" />
              <TextField name="cidade" label="Cidade" />

              <Select
                options={brazilStates.map(({ sigla: value, nome: label }) => ({
                  value,
                  label,
                }))}
                name="estado"
                inputProps={{ maxlength: 2 }}
                label="Estado"
              />
            </>
          )}
        </Content>

        <Footer>
          <Button
            onClick={redirectToBack}
            variant="text"
            color="secondary"
            disabled={loading}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={loading || !canEdit}
            type="submit"
          >
            {loading && person.id ? (
              <span>Salvando...</span>
            ) : (
              <>
                <MdSave /> Salvar
              </>
            )}
          </Button>
        </Footer>
      </Form>
    </Container>
  );
}
