import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { MdKeyboardArrowDown } from 'react-icons/md';

export const Product = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    height: 30px;
    padding-right: 10px;
    max-width: 36px;
  }
`;

export const ErrorTip = styled.div`
  display: ${props => (props.check ? 'none' : 'block')};
  font-size: 10px;
  color: red;
  padding: 4px;
`;

export const Presenteado = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  p {
    text-align: left;
    padding-left: 5px;
    font-size: 13px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  tbody td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #3f51b520;
  }
  thead {
    background-color: #3f51b5;
    color: white;
  }

  button.messageCielo {
    border: none;
    background: transparent;
    color: #3f51b5;
    outline: none;
    vertical-align: super;
    margin-left: 12px;
  }
`;

export const TransactionContainer = styled.div`
  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  h3 + strong {
    font-weight: normal;
    margin-top: 3px;
    font-size: 15px;
  }

  strong + h3 {
    margin-top: 20px;
  }

  section.estorno {
    margin-top: 20px;

    h3,
    strong {
      color: #ff0000;
    }
  }
`;

export const Alert = styled.div`
  padding: 6px 16px;
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
`;

export const StatusBullet = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #222;
  display: inline-flex;
  align-items: center;
  margin-top: 5px;

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-left: 5px;
    display: block;

    /* css para "Não Finalizada" */
    ${({ status }) =>
      String(status) === '0' &&
      css`
        background-color: #fe0100;
      `}

    /* css para não "Pago" */
    ${({ status }) =>
      String(status) === '2' &&
      css`
        background-color: #308b58;
      `}

    /* css para "Não Autorizada" */
    ${({ status }) =>
      String(status) === '3' &&
      css`
        background-color: #818085;
      `}

    /* css para "Cancelada" */
    ${({ status }) =>
      String(status) === '10' &&
      css`
        background-color: #ff953f;
      `}

    /* css para "Estornada" */
    ${({ status }) =>
      String(status) === '11' &&
      css`
        background-color: #a74400;
      `}
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

export const ButtonShowMultiProducts = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  right: 8px;
`;

export const IconArrow = styled(MdKeyboardArrowDown)`
  transition: transform 0.3s ease-in-out;
  ${props =>
    props.rotate &&
    css`
      transform: rotate(180deg);
      animation: ${rotate} 0.6s ease-in-out;
    `}
`;

export const TooltipContainer = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ tooltip: className, tooltipArrow: className }}
  />
))`
  && {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    padding: 10px;
    color: #333333;

    h2 {
      margin: -10px -10px 10px -10px;
      font-size: 16px;
      font-weight: 500;
      background-color: #e2e2e2;
      padding: 8px;
    }

    ul {
      margin: inherit;
      padding: 0px;
      list-style-type: disc !important;
      font-size: 14px;

      li {
        margin-bottom: 4px;
      }
    }

    .MuiTooltip-arrow {
      color: #c4c4c4;
    }
  }
`;
