import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MdForum, MdReceiptLong } from 'react-icons/md';
import {
  FaMap,
  FaHourglassEnd,
  FaBullhorn,
  FaBell,
  FaCog,
  FaCalendarAlt,
  FaImages,
  FaCalendarCheck,
  FaUsersSlash,
} from 'react-icons/fa';
import { IoMdPaper } from 'react-icons/io';

import history from '~/services/history';

export default function SidebarMenuItem({ item }) {
  const renderIcon = itemMenu => {
    switch (itemMenu.icon) {
      case 'FaCog':
        return <FaCog size="22" />;
      case 'FaMap':
        return <FaMap size="22" />;
      case 'FaHourglassEnd':
        return <FaHourglassEnd size="22" />;
      case 'FaBullhorn':
        return <FaBullhorn size="22" />;
      case 'FaBell':
        return <FaBell size="22" />;
      case 'MdForum':
        return <MdForum size="22" />;
      case 'FaCalendarAlt':
        return <FaCalendarAlt size="22" />;
      case 'FaCalendarCheck':
        return <FaCalendarCheck size="22" />;
      case 'FaImages':
        return <FaImages size="22" />;
      case 'IoMdPaper':
        return <IoMdPaper size="22" />;
      case 'MdReceiptLong':
        return <MdReceiptLong size="22" />;
      case 'FaUsersSlash':
        return <FaUsersSlash size="22" />;
      default:
        return <Icon size="25">{itemMenu.icon}</Icon>;
    }
  };

  return (
    <ListItem
      button
      onClick={() => history.push(item.router)}
      title={item.title}
    >
      <ListItemIcon>{renderIcon(item)}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
}

SidebarMenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    router: PropTypes.string.isRequired,
    divider: PropTypes.bool,
  }).isRequired,
};
