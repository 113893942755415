import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { PromotionCodesHeaderContainer } from './styles';

// eslint-disable-next-line react/prop-types
const Column = ({ label, width, ...rest }) => (
  <Grid {...rest} style={{ width, ...rest.style }}>
    <Typography>{label}</Typography>
  </Grid>
);

export default function PromotionCodesHeader() {
  return (
    <PromotionCodesHeaderContainer>
      <Column item xs={12} md={2} label="Código" />
      <Column item xs={12} md={2} label="Ação brinde" />
      <Column item xs={12} md={2} label="Criado em" />
      <Column item xs={12} md={1} label="Status" />
      <Column item xs={12} md={1} label="Quantidade" />
      <Column item xs={12} md={2} label="Validade" />
      <Column item xs={12} md={2} label="Condições" />
    </PromotionCodesHeaderContainer>
  );
}
