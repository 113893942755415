import React, { useState, useCallback, useEffect } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { toNumber } from 'lodash';

import io from 'socket.io-client';
import { MdCheck, MdClose } from 'react-icons/md';
import { VscRunAll } from 'react-icons/vsc';
import api, { baseURL } from '~/services/api';
import { ProgressBar } from '~/components';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import SessionStorage from '~/helpers/SessionStorage';
import { hasPermission } from '~/components/AccessControl';
import { Header, EmptyState, ConfirmPrompt } from './components';
import Screening from './ScreeningItem';
import { Centered } from './styles';
import { DialogConfirm } from './components/DialogConfirm';

const INITIAL_FILTERS = {
  search: '',
  edition: '',
  status: '',
  period: '',
  firstDate: '',
  lastDate: '',
  raffleType: null,
  raffle: '',
};

function ScreeningsPage() {
  const [screenings, setScreenings] = useState([]);
  const [maxValueBalance, setMaxValueBalance] = useState();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(true);
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const [showProcessAll, setshowProcessAll] = useState(false);
  const [status, setStatus] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totals, setTotals] = useState({ total: 0, done: 0 });

  useEffect(() => {
    if (totals.total > 0) setProgress((totals.done / totals.total / 2) * 100);
  }, [totals]);

  useEffect(() => {
    const storagedFilters = SessionStorage.get('triageFilters');
    setshowProcessAll(
      !!storagedFilters &&
        hasPermission('process_all_screenings', { toast: false })
    );
    setFilters({ ...INITIAL_FILTERS, ...(storagedFilters || {}) });
    setStarting(false);
    return () => SessionStorage.set('triageFilters', null);
  }, []);

  const clearFilter = () => {
    setFilters(INITIAL_FILTERS);
    setshowProcessAll(false);
    SessionStorage.set('triageFilters', INITIAL_FILTERS);
  };

  const handleFilters = data => {
    setPagination({ page: 1 });
    setFilters(data);
  };

  const loadScreenings = useCallback(async () => {
    if (starting) return;
    setLoading(true);
    try {
      const params = Object.keys(filters).length ? filters : {};
      const response = await api.get('/screenings', {
        params: {
          ...params,
          page: customPaginator.pagination.page,
          limit: customPaginator.pagination.limit,
        },
      });

      if (response && response.data && response.data.docs) {
        setMaxValueBalance(response.data.maxValueBalance);
        setScreenings(response.data.docs);
        const { limit, page, pages, total } = response.data;
        setPagination({
          ...{ limit, page: toNumber(page), pages, totals: total },
        });
      }
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [
    filters,
    starting,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
  ]);

  const handleProcessAll = useCallback(
    async ({ sendCapitalizer, sendPayment }) => {
      const socket = io(baseURL);
      socket.connect();
      socket.on('triage/updated', update => {
        setStatus(oldStatus => [...oldStatus, update]);
        if (update.item) {
          setTotals(prevTotals => ({
            ...prevTotals,
            done: prevTotals.done + 1,
          }));
        }
        if (update.total) {
          setTotals(prevTotals => ({ ...prevTotals, total: update.total }));
        }
      });

      try {
        setLoading(true);
        const params = Object.keys(filters).length ? filters : {};

        await api.get(`/screenings/processAll`, {
          params: {
            ...params,
            ...(sendCapitalizer
              ? { envioCapitalizadora: sendCapitalizer }
              : {}),
            ...(sendPayment ? { envioPagamento: sendPayment } : {}),
          },
        });
      } finally {
        socket.close();
        setLoading(false);
        setshowProcessAll(false);
        loadScreenings();
      }
    },
    [filters, loadScreenings]
  );

  const handleEndProcess = () => {
    setStatus([]);
  };

  useEffect(() => {
    loadScreenings();
  }, [loadScreenings]);

  return (
    <ConfirmPrompt>
      <Header
        clearFilter={clearFilter}
        filters={filters}
        setFilters={handleFilters}
      />

      <DialogConfirm
        open={openDialog}
        toggleDialog={e => setOpenDialog(e)}
        handleConfirm={handleProcessAll}
      />
      {showProcessAll && customPaginator.pagination.totals > 1 && (
        <Grid
          container
          spacing={2}
          className="animated bounceInRight delay-1s"
          style={{ marginBottom: 4 }}
        >
          <Grid item align="right" xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={!loading ? 'animated pulse slower infinite' : ''}
              onClick={() => setOpenDialog(!openDialog)}
              disabled={loading}
              startIcon={<VscRunAll />}
            >
              Enviar todos para Capitalizadora e Pagar o prêmio
            </Button>
          </Grid>
        </Grid>
      )}
      {loading && status.length === 0 && (
        <Centered>
          <CircularProgress />
        </Centered>
      )}
      {status.length > 0 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={4} align="center">
            <ProgressBar value={progress} />
          </Grid>
        </Grid>
      )}
      {status.length > 0 &&
        status.map(s => (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            key={s.key}
            alignItems="center"
          >
            <Grid item xs={4} align="left">
              <Typography variant={s.item ? 'body1' : 'h6'}>
                {s.status}
              </Typography>
              {s.info && (
                <Typography
                  variant="p"
                  color="secondary"
                  align="center"
                  style={{ marginLeft: 20 }}
                >
                  {s.info}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} align="right">
              <Typography variant="h6">{s.value}</Typography>
            </Grid>
            <Grid item xs={1} align="right">
              {s.error === true && <MdClose size={30} color="red" />}
              {s.error === false && <MdCheck size={30} color="green" />}
            </Grid>
          </Grid>
        ))}
      {status.length > 0 && !loading && (
        <Centered>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEndProcess}
          >
            Fechar
          </Button>
        </Centered>
      )}
      {status.length === 0 && (
        <>
          {screenings.map(screening => (
            <Screening
              key={String(screening.id)}
              data={screening}
              maxValueBalance={maxValueBalance}
            />
          ))}
          {!loading && !screenings.length && <EmptyState />}

          <CustomPaginator
            {...{ loading, setPagination, ...customPaginator }}
          />
          {loading && screenings.length > 4 && (
            <Centered>
              <CircularProgress />
            </Centered>
          )}
        </>
      )}
    </ConfirmPrompt>
  );
}

export default ScreeningsPage;
