import React, { useEffect, useRef } from 'react';
import {
  DatePicker as BaseDatePicker,
  LocalizationProvider,
} from '@material-ui/pickers';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import PropTypes from 'prop-types';

import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';

export default function DatePicker({ name, ...rest }) {
  const refValue = useRef(null);
  const { registerField, fieldName, error } = useField(name);
  const render = useForceUpdate();

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        clearValue() {
          refValue.current = null;
          render();
        },
        setValue(_, value) {
          refValue.current = value;
          render();
        },
        getValue() {
          return refValue.current;
        },
      });
    }
  }, [registerField, fieldName, render]);

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <BaseDatePicker
        inputFormat="dd/MM/yyyy"
        mask="__/__/____"
        allowSameDateSelection
        renderInput={props => (
          <TextField
            {...props}
            error={!!error || props.error}
            helperText={error || props.helperText}
            variant="outlined"
          />
        )}
        {...rest}
        value={refValue.current}
        onChange={value => {
          refValue.current = value;
          render();
        }}
      />
    </LocalizationProvider>
  );
}

DatePicker.defaultProps = {
  error: null,
  helperText: null,
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  helperText: PropTypes.string,
};
