import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 230px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  padding-left: 9px;
  padding-bottom: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Label = styled.span`
  color: ${props => props.color};
  line-height: 20px;
  font-weight: ${props => props.color && '600'};
`;

export const List = styled.div`
  display: flex;
  gap: 15px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -40px;

  h1 {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const Chart = styled.div`
  width: 90px;
  height: 90px;
`;
