import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';
import { get } from 'lodash';
import { ConfirmProvider } from 'material-ui-confirm';
import * as Yup from 'yup';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';

import { formatNumberTicket } from '~/helpers/number';
import ScreeningHeader from './ScreeningHeader';
import ScreeningContent from './ScreeningContent';

import { ScreeningContextProvider } from './context';
import { Container } from './styles';

const parseDate = dateString =>
  format(parseISO(dateString), "dd/MM/yyyy HH':'mm");

const parseAmount = amount =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount);

const parseNumTitulo = numTitulo => formatNumberTicket(numTitulo);

const DEFAULT_ADDRESS = {
  logradouro: '',
  numero: '',
  bairro: '',
  cidade: '',
  estado: '',
  complemento: '',
  cep: '',
};

const DEFAULT_DADOSCLIENTE = {
  renda: '',
  profissao: '',
  orgaoEmissor: '',
  tipoDocumento: '',
  numeroDocumento: '',
  dataEmissaoDocumento: '',
};

const schema = Yup.object().shape({
  logradouro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  estado: Yup.string().required('Campo obrigatório'),
  // complemento: Yup.string().required('Campo obrigatório'),
  cidade: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
});

function ScreeningItem({ data = {}, maxValueBalance }) {
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [screening, setScreening] = useState(data);
  const [address, setAddress] = useState(data.endereco || DEFAULT_ADDRESS);
  const [dadosCliente, setDadosCliente] = useState(
    data.dadosCliente || DEFAULT_DADOSCLIENTE
  );
  const [notes, setNotes] = useState(data.notas || '');
  const [rgPessoa, setRgPessoa] = useState(data.rgPessoa || '');
  const filesRef = useRef({});
  const [logs, setLogs] = useState([]);
  const [addressErrors, setAddressErrors] = useState({});
  const [dadosClienteErrors, setDadosClienteErrors] = useState({});
  const [ticketNumber, setTicketNumber] = useState();
  const [capitalizerNumber, setCapitalizerNumber] = useState();
  const [loadingTicketNumber, setLoadingTicketNumber] = useState();

  const fetchLogs = useCallback(
    async (options = { force: false }) => {
      const force = options.force || false;
      if (force === false && (!!logs.length || !hasPermission('read_logs')))
        return;
      try {
        setLoading(true);
        const response = await api.get(`/screenings/${screening.id}/logs`, {
          params: { limit: 10 },
        });
        setLogs(response.data.docs);
      } finally {
        setLoading(false);
      }
    },
    [logs, screening.id]
  );

  const fetchNumber = useCallback(async () => {
    try {
      setLoadingTicketNumber(true);
      const resp = await api.get(`/screenings/show/ticket/${screening.id}`);

      setTicketNumber(resp.data.numTitulo);
      setCapitalizerNumber(resp.data.numCaptalizadora);
    } finally {
      setLoadingTicketNumber(false);
    }
  }, [screening.id]);

  const handleCollapsed = useCallback(async () => {
    setCollapsed(value => {
      if (!value === false) fetchLogs();
      return !value;
    });

    if (collapsed && !screening.ganhador.numTitulo) {
      fetchNumber();
    }
  }, [fetchLogs, fetchNumber, collapsed, screening.ganhador.numTitulo]);

  const addFile = useCallback((field, file) => {
    filesRef.current[field] = file;
  }, []);

  const addFilesToFormadata = useCallback(formdata => {
    if (!Object.keys(filesRef.current).length) return;
    Object.entries(filesRef.current).forEach(([field, file]) => {
      formdata.append(field, file);
    });
  }, []);

  const addAddressToFormadata = useCallback(
    formdata => {
      if (!Object.keys(address).length) return;
      Object.entries(address).forEach(([field, file]) => {
        formdata.append(`endereco[${field}]`, file);
      });
    },
    [address]
  );

  const addDadosClienteToFormadata = useCallback(
    formdata => {
      if (!Object.keys(dadosCliente).length) return;
      Object.entries(dadosCliente).forEach(([field, file]) => {
        formdata.append(`dadosCliente[${field}]`, file);
      });
    },
    [dadosCliente]
  );

  const updateAddress = useCallback((field, value) => {
    setAddress(oldAddress => ({
      ...oldAddress,
      [field]: value,
    }));
  }, []);

  const updateDadosCliente = useCallback((field, value) => {
    setDadosCliente(oldDadosCliente => ({
      ...oldDadosCliente,
      [field]: value,
    }));
  }, []);

  const updateNotes = useCallback(newNotes => {
    setNotes(newNotes);
  }, []);

  const update = useCallback((newPayload, target) => {
    if (target === 'rgPessoa') {
      setRgPessoa(newPayload);
    } else {
      setScreening(oldScreening => ({ ...oldScreening, ...newPayload }));
    }
  }, []);

  const save = useCallback(
    async (extraData = {}) => {
      if (!hasPermission('update_screening')) return;

      try {
        // validation
        setAddressErrors({});
        setDadosClienteErrors({});

        if (screening.sorteio.tipo.codigo === 'premiofisico') {
          await schema.validate(address, {
            abortEarly: false,
          });
        }

        setLoading(true);
        const formdata = new FormData();
        addFilesToFormadata(formdata);
        addAddressToFormadata(formdata);
        addDadosClienteToFormadata(formdata);
        formdata.append('notas', notes);
        formdata.append('rgPessoa', rgPessoa || '');
        if (extraData && !!Object.keys(extraData).length) {
          Object.entries(extraData).forEach(([field, extraDataValue]) => {
            formdata.append(field, extraDataValue);
          });
        }
        const { data: newScreening } = await api.put(
          `/screenings/${data.id}`,
          formdata
        );
        fetchLogs({ force: true });
        setScreening(newScreening);
        setAddress(newScreening.endereco || DEFAULT_ADDRESS);
        setDadosCliente(newScreening.dadosCliente || DEFAULT_DADOSCLIENTE);
        setRgPessoa(newScreening.rgPessoa || '');
        setNotes(newScreening.notas || '');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};

          error.inner.forEach(err => {
            errorMessages[err.path] = err.message;
          });
          setAddressErrors(errorMessages);
          setDadosClienteErrors(errorMessages);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      addFilesToFormadata,
      addAddressToFormadata,
      addDadosClienteToFormadata,
      address,
      notes,
      rgPessoa,
      fetchLogs,
      data.id,
      screening.sorteio.tipo.codigo,
    ]
  );

  const changeStatus = useCallback(newStatus => save({ status: newStatus }), [
    save,
  ]);

  useEffect(() => {
    if (screening.endereco) {
      setAddress(oldAddress => ({ ...oldAddress, ...screening.endereco }));
    }
  }, [screening]);

  useEffect(() => {
    if (screening.dadosCliente) {
      setDadosCliente(oldDadosCliente => ({
        ...oldDadosCliente,
        ...screening.dadosCliente,
      }));
    }
  }, [screening]);

  const dataHasChanges = useMemo(() => {
    const rgPessoaHasChanges = rgPessoa !== (screening.rgPessoa || '');
    const notesHasChanges = notes !== (screening.notas || '');
    const addressHasChanges = Object.entries(address).some(
      ([key, addressValue]) => {
        const originalAddressValue = get(screening, `endereco.${key}`, '');
        return originalAddressValue !== addressValue;
      }
    );
    const dadosClienteHasChanges = Object.entries(dadosCliente).some(
      ([key, dadosClienteValue]) => {
        const originalDadosClienteValue = get(
          screening,
          `dadosCliente.${key}`,
          ''
        );
        return originalDadosClienteValue !== dadosClienteValue;
      }
    );
    return (
      rgPessoaHasChanges ||
      addressHasChanges ||
      notesHasChanges ||
      dadosClienteHasChanges
    );
  }, [rgPessoa, address, dadosCliente, screening, notes]);

  return (
    <ScreeningContextProvider
      data={{
        maxValueBalance,
        ...screening,
        rgPessoa,
        parseDate,
        parseAmount,
        changeStatus,
        loading,
        parseNumTitulo,
        addFile,
        save,
        updateAddress,
        updateDadosCliente,
        address,
        dadosCliente,
        updateNotes,
        notes,
        update,
        dataHasChanges,
        logs,
        addressErrors,
        dadosClienteErrors,
        ticketNumber,
        capitalizerNumber,
        loadingTicketNumber,
      }}
    >
      <ConfirmProvider>
        <Container>
          <ScreeningHeader opened={!collapsed} onCollapse={handleCollapsed} />
          {!collapsed && <ScreeningContent />}
        </Container>
      </ConfirmProvider>
    </ScreeningContextProvider>
  );
}

ScreeningItem.propTypes = {
  data: PropTypes.shape().isRequired,
  maxValueBalance: PropTypes.number.isRequired,
};

export default React.memo(ScreeningItem);
