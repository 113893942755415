import React, { useMemo, useCallback, useState, useRef } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useDrop, useDrag } from 'react-dnd';

import { get, isNaN, toNumber } from 'lodash';
import { CircularProgress, IconButton } from '@material-ui/core';
import { FaTrash, FaEdit, FaUsers, FaBars } from 'react-icons/fa';
import BannerImage from '~/pages/Backoffice/BannersHome/BannerItem/BannerImage';
import api from '~/services/api';
import { SweepstakeItemContainer } from './styles';
import { useSweepstakeForm } from './SweepstakeFormProvider';
import { usePromotionContext } from '../../context';
import { usePromotionAllPermission } from './context-permissions';

const formatPrice = number => {
  let n = number;
  if (isNaN(toNumber(number))) n = 0;
  return n.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

// eslint-disable-next-line
export default function SweepstakeItem({ data }) {
  const confirm = useConfirm();
  const { editSweepstake } = useSweepstakeForm();
  const { can } = usePromotionAllPermission();

  const {
    promotion,
    replacePromotion,
    movePromotionSweepstake,
  } = usePromotionContext();
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const ref = useRef();

  const sweepstake = useMemo(
    () => ({
      id: get(data, 'idSorteio', null),
      image: get(data, 'imagem.urlImagem', null),
      description: get(data, 'descricao', ''),
      sugestedValue: get(data, 'valorSugerido', ''),
      winners: get(data, 'ganhadores', []),
      type: get(data, 'tipo.codigo'),
      typeId: get(data, 'tipo.idTipoSorteio'),
      index: get(data, 'index', 0),
      order: get(data, 'ordem'),
    }),
    [data]
  );

  const handleDelete = useCallback(async () => {
    try {
      await confirm({
        title: 'Tem certeza que deseja excluir o sorteio?',
        description:
          'Após a confirmação, todos os dados do sorteio serão excluídos.',
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        confirmationText: 'Sim, continuar!',
      });
      setDeleting(true);
      try {
        const promotionId = promotion.idPromocao;
        const { id } = sweepstake;

        await api.delete(`/promotions/${promotionId}/sweepstakes/${id}`);
        const allSweepstakes = get(promotion, 'sorteios', []).filter(s => {
          return s.idSorteio !== id;
        });
        replacePromotion({ ...promotion, sorteios: allSweepstakes });
      } catch (err) {
        // nothing...
      } finally {
        setDeleting(false);
      }
    } catch (err) {
      // nothing...
    }
  }, [confirm, sweepstake, promotion, replacePromotion]);

  const handleReorder = useCallback(
    async ({ sweepstake: moveSweepstake }) => {
      try {
        const order = get(moveSweepstake, 'index', 0) + 1;
        const id = get(moveSweepstake, 'id', null);
        const promotionId = get(promotion, 'idPromocao');
        const url = `/promotions/${promotionId}/sweepstakes/${id}`;

        if (!id) return;

        const {
          typeId: type,
          description,
          image,
          price: amount,
          order: originalOrder,
          sugestedValue,
        } = moveSweepstake;

        if (originalOrder === order) return;

        setUpdating(true);
        await api.put(url, {
          type,
          description,
          image,
          amount,
          order,
          sugestedValue,
        });
      } catch (err) {
        // nothing...
      } finally {
        setUpdating(false);
      }
    },
    [promotion]
  );

  const [{ isDragging }, drag] = useDrag({
    item: { type: sweepstake.type, ...sweepstake },
    canDrag: () => can('update_promotion_sweepstakes'),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: sweepstake.type,
    canDrop: () => can('update_promotion_sweepstakes'),
    drop(item) {
      handleReorder({
        sweepstake: item,
      });
    },
    hover(item) {
      const index = get(data, 'index', 0);

      if (!ref.current || item.index === index) return;

      const sweepstakeItem = get(promotion, 'sorteios', []).find(s => {
        return s.idSorteio === item.id;
      });

      // move on the array only...
      movePromotionSweepstake({
        sweepstake: sweepstakeItem,
        oldIndex: item.index,
        newIndex: index,
      });
      item.index = index;
    },
  });

  drag(drop(ref));

  return (
    <SweepstakeItemContainer
      ref={ref}
      isDragging={isDragging}
      loading={deleting || updating}
    >
      {(deleting || updating) && <CircularProgress size={60} />}
      <header>
        <BannerImage src={sweepstake.image} title={sweepstake.description} />
        <div className="actions">
          {can('update_promotion_sweepstakes') && (
            <IconButton size="small" onClick={() => editSweepstake(data)}>
              <FaEdit size={15} />
            </IconButton>
          )}
          {can('remove_promotion_sweepstakes') && (
            <IconButton size="small" onClick={handleDelete}>
              <FaTrash size={15} />
            </IconButton>
          )}
        </div>
        <div className="winners">
          <FaUsers size={15} />
          <span>
            {sweepstake &&
              sweepstake.winners &&
              sweepstake.winners.length &&
              sweepstake.winners[0].numeroGanhadores}
          </span>

          <FaBars size={15} style={{ marginLeft: 10 }} />
          <span>{sweepstake.index + 1}</span>
        </div>
        <span className="price">{formatPrice(sweepstake.sugestedValue)}</span>
      </header>
      <p>{sweepstake.description}</p>
    </SweepstakeItemContainer>
  );
}
