import React from 'react';
import PropTypes from 'prop-types';
import FaqItem from './FaqItem';

function FaqContent({
  faqs = [],
  containerCheck,
  idProduto,
  handleParamsSubmit,
  submitFilter,
  loading,
  setLoading,
  preSubmitFilter,
  posContainerSubmit,
  customPage,
  customLimit,
}) {
  return faqs.map(faq => {
    return (
      <FaqItem
        posContainerSubmit={posContainerSubmit}
        key={faq.idFaqProduto}
        faq={faq}
        setLoading={setLoading}
        container={containerCheck}
        idProduto={idProduto}
        preSubmitFilter={preSubmitFilter}
        faqs={faqs}
        onUpdate={handleParamsSubmit}
        submitFilter={submitFilter}
        loading={loading}
        customPage={customPage}
        customLimit={customLimit}
      />
    );
  });
}
FaqContent.propTypes = {
  filters: PropTypes.shape.isRequired,
  faqs: PropTypes.arrayOf(PropTypes.oneOfType([])).isRequired,
  handleDrag: PropTypes.func.isRequired,
};

export default FaqContent;
