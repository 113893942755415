import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy, get, countBy } from 'lodash';
import { FaEye, FaEyeSlash, FaLink, FaPlusCircle } from 'react-icons/fa';
import { RiRefreshLine } from 'react-icons/ri';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import api from '~/services/api';

import {
  Button,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import Dialog from './Dialog';
import {
  Section,
  SectionTitle,
  SectionContent,
  SweepstakeFilterContainer,
  ButtonFilter,
} from './styles';
import { usePromotionContext } from '../../context';
import SweepstakeItem from './SweepstakeItem';
import { useSweepstakeForm } from './SweepstakeFormProvider';
import { usePromotionAllPermission } from './context-permissions';

export default function PromotionSweepstakes() {
  const {
    sweepstakes,
    promotion,
    loading,
    sweepstakeTypeFiltered,
    toggleSweepstakeTypeFilter,
    setSweepstakeTypeFiltered,
  } = usePromotionContext();
  const {
    addNewSweepstake,
    resetPromotionCache,
    closePromotionCache,
  } = useSweepstakeForm();
  const { can } = usePromotionAllPermission();

  const [isCleanCache, setIsCleanCache] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sweepstakeTypes = useMemo(() => {
    const allSweepstakes = get(promotion, 'sorteios', []).map(sweepstake => {
      const label = get(sweepstake, 'tipo.descricao', '');
      const code = get(sweepstake, 'tipo.codigo', '');
      return { label, code };
    });
    return uniqBy(allSweepstakes, 'code');
  }, [promotion]);

  const countAllBy = useMemo(
    () => countBy(get(promotion, 'sorteios', []), 'tipo.codigo'),
    [promotion]
  );

  useEffect(() => {
    setSweepstakeTypeFiltered(sweepstakeTypes.map(t => t.code));
  }, [sweepstakeTypes, setSweepstakeTypeFiltered]);

  const typeIsFiltered = useCallback(
    type => sweepstakeTypeFiltered.includes(type),
    [sweepstakeTypeFiltered]
  );
  const toggleFilter = useCallback(type => toggleSweepstakeTypeFilter(type), [
    toggleSweepstakeTypeFilter,
  ]);

  const cleanSweepstakeCache = useCallback(async () => {
    try {
      setIsLoading(true);
      await api.post(`/promotions/clean-cache/${promotion.idPromocao}`);
      setIsLoading(false);
      closePromotionCache();
    } catch {
      setIsLoading(false);
    }
  }, [promotion]);

  useEffect(() => {
    let shouldStopMapping = false;

    sweepstakeTypes.some(item => {
      if (shouldStopMapping) return true;

      switch (item.code) {
        case 'premiosaldocarteira':
        case 'premiofisico':
        case 'premiosorteiodiario':
        case 'premiosorteiosemanal':
        case 'premiosorteiomensal':
          setIsCleanCache(true);
          shouldStopMapping = true;
          return true;
        default:
          setIsCleanCache(false);
      }
      return false;
    });
  }, [sweepstakeTypes]);

  if (!can('read_promotion_sweepstakes')) return null;
  return (
    <>
      <Dialog
        isConfirmModal
        onOk={cleanSweepstakeCache}
        loading={isLoading}
        okText="Confirmar"
        loadingText="Limpando"
      >
        <DialogTitle id="form-dialog-title">Deseja limpar o cache?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esta ação renovará as informações dos sorteios dessa promoção. Caso
            alguma nova informação tenha sido editada ou cadastrada nos sorteios
            abaixo, limpar o cache fará com que essas informações sejam
            refletidas imediatamente.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Section>
        <SectionTitle>
          <FaLink size={18} />
          <h3>Sorteios</h3>

          {can('create_promotion_sweepstakes') && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<FaPlusCircle size={18} />}
              onClick={() => addNewSweepstake()}
            >
              Novo sorteio
            </Button>
          )}
          {can('reset_sweepstakes_cache') && isCleanCache && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<RiRefreshLine size={18} />}
              onClick={() => resetPromotionCache()}
            >
              Limpar cache
            </Button>
          )}
        </SectionTitle>

        <SweepstakeFilterContainer>
          {sweepstakeTypes.map(({ label, code }) => (
            <ButtonFilter
              type="button"
              key={code}
              onClick={() => {
                toggleFilter(code);
              }}
              active={typeIsFiltered(code)}
            >
              {typeIsFiltered(code) ? <FaEye /> : <FaEyeSlash />}
              {`${label} (${countAllBy[code]})`}
            </ButtonFilter>
          ))}
        </SweepstakeFilterContainer>

        <SectionContent className="sweepstakes">
          {loading && <CircularProgress size={50} />}
          <DndProvider backend={HTML5Backend}>
            {sweepstakes
              .map((item, originalIndex) => {
                return { ...item, index: originalIndex };
              })
              .filter(sweepstake => {
                const type = get(sweepstake, 'tipo.codigo', '');
                return typeIsFiltered(type);
              })
              .map(sweepstake => (
                <SweepstakeItem key={sweepstake.idSorteio} data={sweepstake} />
              ))}
          </DndProvider>
        </SectionContent>
      </Section>
    </>
  );
}
