import React, { useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { MdCheckCircle, MdError, MdLink, MdPictureAsPdf } from 'react-icons/md';
import PropTypes from 'prop-types';

import { hasPermission } from '~/components/AccessControl';
import { Container, FileInfo, Preview } from './styles';

const types = {
  selfie: 'Selfie',
  document: 'Documento',
  'proof-residence': 'Comprovante de Residência',
  others: 'Outros',
};

const Detail = ({ file }) => {
  const canRead = useMemo(
    () => hasPermission('read_screening_attachments', { toast: false }),
    []
  );

  return (
    <Container>
      {canRead && file && (
        <li key={file.type}>
          <FileInfo>
            {String(file.url)
              .toLowerCase()
              .indexOf('.pdf') >= 0 ? (
              <MdPictureAsPdf size={36} style={{ marginRight: 10 }} />
            ) : (
              <Preview src={file.url} disabled={!canRead} />
            )}
            <div>
              <i>{types[file.type]}</i>
            </div>
          </FileInfo>

          <div>
            {!file.uploaded && !file.error && file.new && (
              <CircularProgress
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#7159c1' },
                }}
                strokeWidth={10}
                percentage={file.progress}
              />
            )}

            {file.url && !file.new && (
              <a
                href={canRead ? file.url : '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
              </a>
            )}

            {file.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
            {file.error && <MdError size={24} color="#e57878" />}
          </div>
        </li>
      )}
    </Container>
  );
};
export default Detail;

Detail.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
