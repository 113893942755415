import React, { memo } from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const getColor = _prod => {
  return { backgroundColor: _prod.color };
};

const initials = value => {
  const init = value.split(' ');
  if (init.length >= 2) {
    return (init[0].substr(0, 1) + init[1].substr(0, 1)).toUpperCase();
  }
  return value.substr(0, 2).toUpperCase();
};
function ProductListItem({ handleClick, product, selected }) {
  const renderStyle = _prod => {
    return selected && _prod.id === selected.id
      ? { backgroundColor: 'rgba(96, 125, 139, 0.42)' }
      : null;
  };

  return (
    <ListItem
      button
      onClick={() => handleClick(product)}
      style={renderStyle(product)}
    >
      <ListItemAvatar>
        <Avatar
          alt={initials(product.titulo)}
          src={product.imagem}
          style={getColor(product)}
        />
      </ListItemAvatar>
      <ListItemText id={product.id} primary={product.titulo} />
    </ListItem>
  );
}

ProductListItem.defaultProps = {
  selected: undefined,
};

ProductListItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selected: PropTypes.oneOfType([PropTypes.object]),
};

export default memo(ProductListItem);
