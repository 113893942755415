import React from 'react';
import { Icon, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

export const statusDescriptionMap = new Map([
  ['aguardando', 'Ganhador foi contemplado'],
  ['pendente', 'Aguardando Contato'],
  ['agendado', 'Aguardando documentos'],
  ['processando', 'Pendente retorno da capemisa'],
  ['enviado', 'Aguardando entrega do prêmio'],
  ['entregue', 'Pagamento realizado / Prêmio despachado'],
  ['finalizado', 'Ciclo concluído e informações enviadas'],
  ['cancelado', 'Premiação Cancelada'],
]);

function TooltipStatusDescription({ status }) {
  const description = statusDescriptionMap.get(String(status).toLowerCase());
  return (
    <Tooltip title={description}>
      <Icon style={{ fontSize: 16, marginLeft: 4, cursor: 'pointer' }}>
        info
      </Icon>
    </Tooltip>
  );
}

TooltipStatusDescription.propTypes = {
  status: PropTypes.string.isRequired,
};

export default TooltipStatusDescription;
