import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const FormError = styled.span`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 3px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

export const Content = styled(Box)`
  padding: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
