import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';

import CheckboxInput from '~/components/unform/v2/CheckboxInput';
import api from '~/services/api';
import ApplicationsAutoComplete from '~/components/unform/v2/ApplicationsAutoComplete';

import { Row } from './styles';

function NotificationModal({ person, onClose }) {
  const formRef = useRef(null);

  const defaultsOptInsChannels = [
    { id: 'sms', value: false, label: 'SMS' },
    { id: 'email', value: false, label: 'E-mail' },
    { id: 'whatsapp', value: false, label: 'Whatsapp' },
    { id: 'push', value: false, label: 'Push' },
  ];

  const [loading, setLoading] = useState(false);
  const [recordsNotFound, setRecordsNotFound] = useState(false);
  const [applications, setApplications] = useState([]);
  const [optInsUser, setOptInsUser] = useState(defaultsOptInsChannels);

  const schema = Yup.object().shape({
    application: Yup.string().required('O campo de seleção é obrigatório.'),
    optIns: Yup.array().of(Yup.string()),
  });

  const getApplications = useCallback(async () => {
    try {
      setLoading(true);
      const { data } =
        (await api.get(`/persons/${person.idCliente}/applications`)) || {};

      if (data?.docs) setApplications(data.docs);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSelectApplication = useCallback(async applicationData => {
    if (applicationData) {
      formRef.current.setErrors({});

      setLoading(true);
      setRecordsNotFound(false);

      const { id: applicationId } = applicationData;

      api
        .get(`/persons/notifications/${person.idCliente}/${applicationId}`)
        .then(resp => {
          const optIns = Object.keys(resp.data).map(key => {
            let label;
            switch (key) {
              case 'sms':
                label = 'SMS';
                break;
              case 'email':
                label = 'E-mail';
                break;
              default:
                label = key.charAt(0).toUpperCase() + key.slice(1);
            }

            return { id: key, value: resp.data[key], label };
          });

          setOptInsUser(optIns);
        })
        .catch(err => {
          if (err.response.data?.code === 1) {
            setRecordsNotFound(true);
            setOptInsUser(defaultsOptInsChannels);
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const handleSubmit = async formData => {
    try {
      setLoading(true);

      formRef.current.setErrors({});
      await schema.validate(formData, {
        abortEarly: false,
      });

      const { optIns, application } = formData;

      const allOptIns = optInsUser.map(item => item.id);
      const convertedOptIns = allOptIns.reduce((obj, item) => {
        obj[item] = optIns.includes(item);
        return obj;
      }, {});

      if (!recordsNotFound) {
        await api.put('/persons/notifications/profile-data', {
          identity: person.idCliente,
          applicationId: application.id,
          project: application.codigo,
          optIns: convertedOptIns,
        });
      }

      setLoading(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <>
      <Dialog open onClose={onClose} disableEnforceFocus>
        <Form
          onSubmit={handleSubmit}
          schema={schema}
          ref={formRef}
          noValidate
          autoComplete="off"
        >
          <DialogTitle>Notificações</DialogTitle>

          <DialogContent>
            {!applications && <CircularProgress size={24} />}
            <Row>
              <ApplicationsAutoComplete
                name="application"
                options={applications}
                label="Aplicações"
                onChange={handleSelectApplication}
              />
            </Row>

            <Row>
              <CheckboxInput name="optIns" options={optInsUser} />
            </Row>

            {recordsNotFound && (
              <DialogContentText style={{ color: 'red' }}>
                Esse usuário não fez o consentimento de opt-in
                <br />
                para a aplicação selecionada.
              </DialogContentText>
            )}
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={onClose}>
              Cancelar
            </Button>

            <Button
              type="submit"
              disabled={loading || recordsNotFound}
              color="primary"
            >
              {loading ? <CircularProgress size={24} /> : 'Salvar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

NotificationModal.defaultProps = {
  onClose: false,
  person: null,
};

NotificationModal.propTypes = {
  onClose: PropTypes.func,
  person: PropTypes.shape(),
};

export default NotificationModal;
