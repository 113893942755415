import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { Select, TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import {
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';

export default function DialogTag({ open, toggleDialog, person }) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  let schema = Yup.object().shape();
  schema = Yup.object().shape({
    tag: Yup.string().required('Campo obrigatório'),
    reason: Yup.string()
      .min(10, 'No mímino 10 caracteres')
      .required('Campo obrigatório'),
  });

  const handleConfirmar = async ({ reason, tag }) => {
    setLoading(true);
    try {
      if (hasPermission('tag_person')) {
        await api.put(`/persons/${person.idCliente}/tagging`, {
          tag,
          reason,
        });
        person.tag = tag;
      }

      if (
        (!person.bloqueado && checked && hasPermission('block_person')) ||
        (person.bloqueado && !checked && hasPermission('unblock_person'))
      ) {
        const { data } = await api.put(
          `/persons/${person.idCliente}/blocking`,
          {
            reason,
            blocked: !person.bloqueado,
          }
        );
        if (data && data.cpf) {
          person.bloqueado = !person.bloqueado;
        }
      }
    } finally {
      toggleDialog(person);
      setLoading(false);
    }
  };

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const handeSubmit = async values => {
    await handleConfirmar(values);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <Form schema={schema} onSubmit={handeSubmit}>
          <DialogTitle id="form-dialog-title">Marcar Cliente</DialogTitle>
          <DialogContent>
            <>
              <Select
                id="tag"
                name="tag"
                label="Escolha a Tag"
                options={[
                  {
                    value: 'OK',
                    label: 'Documentação confirmada',
                  },
                  { value: 'SUSPEITO', label: 'Suspeito' },
                  { value: 'FRAUDE', label: 'Fraude' },
                  { value: 'SEMTAG', label: 'Sem marcação' },
                ]}
              />
              <FormControlLabel
                label="Bloquear"
                control={<Checkbox checked={checked} onChange={handleChange} />}
              />

              <DialogContentText>
                Justifique o motivo para a marcação
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="reason"
                name="reason"
                label="Motivo"
                fullWidth
                multiline
                rowsMax="4"
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => toggleDialog()}
              color="secondary"
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

DialogTag.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
