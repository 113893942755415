import styled, { css } from 'styled-components';

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px auto;

  ${props =>
    props.column === 1 &&
    css`
      flex-direction: column;
    `}
`;
