import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import * as Yup from 'yup';

import AutoComplete from '~/components/unform/v2/CodePromotionAutoComplete';
import DateTimePicker from '~/components/unform/v2/DateTimePicker';
import Input from '~/components/unform/v2/InputText';
import api from '~/services/api';
import { ProductAutocomplete } from '~/components';
import Copy from './copy';

import { InputText, Row } from './styles';

function ModalPromo({ person, onClose }) {
  const [loading, setLoading] = useState(false);
  const [btnCopy, setBtnCopy] = useState(false);
  const [listActions, setlistActions] = useState([]);
  const [hashCode, sethashCode] = useState('');
  const formRef = useRef(null);
  const [isValidationEnabled, setIsValidationEnabled] = useState(false);
  const [productId, setProductId] = useState(null);
  const [customCode, setCustomCode] = useState(false);

  const handleListActions = useCallback(async () => {
    const result = await api.get(`/promotions/actions`);
    if (result.data.docs) {
      setlistActions(result.data.docs.rows);
    }
  }, [person]);

  useEffect(() => {
    handleListActions();
  }, [handleListActions]);

  useEffect(() => {
    if (formRef.current) formRef.current.setFieldValue('code', hashCode);
  }, [hashCode]);

  const setProduct = seletedProduct => {
    setProductId(seletedProduct);
    setIsValidationEnabled(seletedProduct);
  };

  const schema = Yup.object().shape(
    {
      action: Yup.string().required('O campo de seleção é obrigatório.'),
      endDate: Yup.date()
        .typeError('O campo data é obrigatório.')
        .min(new Date(), 'Selecione uma data futura'),
      message: Yup.string().required('O campo mensagem é obrigatório'),
      reason: Yup.string().required('O campo motivo é obrigatório'),
      uses: Yup.number().typeError(
        'O campo Nº de usos deve ser do tipo número'
      ),
      codigoCustomizado: Yup.boolean(),
      hash: Yup.string().when('codigoCustomizado', {
        is: true,
        then: Yup.string().required('O campo código promocional é obrigatório'),
      }),
      validationStart: Yup.mixed().when(
        'validationEnd',
        (validationEnd, validation) => {
          return validationEnd
            ? Yup.date()
                .required()
                .typeError('O campo data inicio é obrigatório')
            : validation;
        }
      ),
      validationEnd: Yup.mixed().when(
        'validationStart',
        (validationStart, validation) => {
          return validationStart
            ? Yup.date()
                .required()
                .min(
                  validationStart,
                  'A data final precisa ser maior que a inicial'
                )
                .typeError('O campo data fim é obrigatório')
            : validation;
        }
      ),
    },
    ['validationStart', 'validationEnd', 'codigoCustomizado']
  );

  const handleSubmit = async data => {
    try {
      setLoading(true);

      formRef.current.setErrors({});
      data.codigoCustomizado = customCode;

      await schema.validate(data, {
        abortEarly: false,
      });

      data.validationProductId = productId;

      if (!customCode) {
        data.hash = null;
      }

      const dataAction = {
        ...data,
        person,
      };

      const result = await api.post(`/promotion-codes`, dataAction);

      if (result.data) {
        setBtnCopy(true);
        const { codigoPromocional } = result.data;
        sethashCode(codigoPromocional);
      }

      setLoading(false);
      formRef.current.reset();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      setLoading(false);
    }
  };

  const alphaNumericValidate = e => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z]+/gi, '')
      : '';

    if (e.target.value !== value) {
      e.target.value = value;
    }
  };

  return (
    <>
      <Dialog open onClose={onClose} disableEnforceFocus>
        <Form
          onSubmit={handleSubmit}
          schema={schema}
          ref={formRef}
          noValidate
          autoComplete="off"
        >
          <DialogTitle>Gerar código promocional</DialogTitle>
          <DialogContent>
            {!listActions && <CircularProgress size={24} />}
            <Row>
              <AutoComplete
                name="action"
                options={listActions}
                label="Selecione..."
              />
            </Row>
            <Row>
              <Input
                margin="dense"
                name="message"
                label="Mensagem"
                variant="outlined"
                rows={4}
                fullWidth
                multiline
              />
            </Row>
            <Grid container spacing={1}>
              <Grid item md={4}>
                <Input
                  type="number"
                  label="Nº de usos"
                  variant="outlined"
                  name="uses"
                />
              </Grid>
              <Grid item md={8}>
                <DateTimePicker label="Válido até:" name="endDate" />
              </Grid>
            </Grid>
            <Row>
              <Input
                name="reason"
                label="Motivo"
                fullWidth
                multiline
                rowsMax="4"
              />
            </Row>
            <Row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="codigoCustomizado"
                    color="primary"
                    size="medium"
                    checked={customCode}
                    onClick={() => setCustomCode(!customCode)}
                  />
                }
                name="codigoCustomizado"
                label="Código promocional personalizado"
              />
              <Input
                name="hash"
                label="Código"
                fullWidth
                multiline
                rowsMax="4"
                variant="outlined"
                onChange={e => alphaNumericValidate(e)}
                inputProps={{
                  style: {
                    textTransform: 'uppercase',
                  },
                  maxLength: 20,
                }}
                containerStyle={{
                  maxHeight: customCode ? 120 : 0,
                  overflow: customCode ? 'initial' : 'hidden',
                  transition: 'all .25s ease-in',
                }}
              />
            </Row>

            <Row style={{ marginTop: 30 }}>
              <h4>Validação</h4>
            </Row>
            <Row>
              <ProductAutocomplete
                label="Produto"
                valueKey="id"
                onChange={evt => {
                  setProduct(evt.target.value);
                }}
              />
            </Row>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <DateTimePicker
                  disabled={!isValidationEnabled}
                  name="validationStart"
                  label="Início:"
                />
              </Grid>
              <Grid item md={6}>
                <DateTimePicker
                  disabled={!isValidationEnabled}
                  name="validationEnd"
                  label="Fim:"
                />
              </Grid>
            </Grid>
            {btnCopy && (
              <Row>
                <Grid container>
                  <Grid item xs={11} sm={11}>
                    <p style={{ fontSize: '10px' }}>Código</p>
                    <InputText
                      value={hashCode}
                      disabled
                      name="code"
                      id="code"
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <br />
                    <Copy />
                  </Grid>
                </Grid>
              </Row>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                onClose();
                setBtnCopy(true);
              }}
            >
              Fechar
            </Button>

            <Button type="submit" disabled={loading} color="primary">
              {loading ? <CircularProgress size={24} /> : 'Gerar código'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

ModalPromo.defaultProps = {
  onClose: false,
  person: null,
};

ModalPromo.propTypes = {
  onClose: PropTypes.func,
  person: PropTypes.shape(),
};

export default ModalPromo;
