/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import { MdDelete } from 'react-icons/md';
import { IoMdHourglass } from 'react-icons/io';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import SessionStorage from '~/helpers/SessionStorage';

// -- components
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';
import { SweepstakePaper } from './styles';
import { Dezena } from '../../Coupons/styles';

export default function SweepstakeDozens({ data: sorteio, promotion }) {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const hasDozens = has(sorteio, 'dezenas');
  const [clickDelete, setClickDelete] = useState(0);
  const canDeleteSweepstakeDozens = useMemo(
    () => hasPermission('delete_sweepstake_dozens', { toast: false }),
    []
  );

  function getDozens() {
    return sorteio.dezenas.map(dozen => (
      <Dezena style={{ margin: 4 }} key={dozen}>
        {dozen}
      </Dezena>
    ));
  }

  const handleDeleteSweepstakeDozens = () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      setTimeout(() => setClickDelete(0), 3000);
    } else {
      setLoadingDelete(true);

      api
        .delete(`/sweepstake-dozens/${sorteio.idSorteio}`)
        .then(res => {
          if (res && res.data) {
            setDeleted(true);
          }
        })
        .finally(() => setLoadingDelete(false));
    }
  };

  function handleTriagem() {
    SessionStorage.set('triageFilters', {
      // raffle: sorteio.idSorteio,
      raffleType: sorteio.tipo.codigo,
      edition: promotion.extracao,
      search: `sorteio:${sorteio.descricao}`,
    });
    window.open(`/screenings`);
  }

  if (!hasDozens || deleted) {
    return null;
  }

  return (
    <ExpansionPanel expanded>
      <ExpansionPanelSummary>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Typography>Dezenas Sorteadas</Typography>
          </Grid>
          <Grid item xs={4} align="right">
            {promotion.triagemHabilitada && (
              <Button
                disabled={loadingDelete}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleTriagem()}
              >
                <IoMdHourglass />
                Triagem
              </Button>
            )}
            &nbsp;
            {canDeleteSweepstakeDozens && (
              <Button
                disabled={loadingDelete}
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleDeleteSweepstakeDozens}
              >
                {loadingDelete ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    <MdDelete />
                    {clickDelete ? 'Confirma ?' : 'Excluir'}
                  </>
                )}
              </Button>
            )}
          </Grid>
        </Grid>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <SweepstakePaper>{getDozens()}</SweepstakePaper>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

SweepstakeDozens.propTypes = {
  data: PropTypes.shape({
    dezenas: PropTypes.oneOfType([PropTypes.array]),
  }).isRequired,
  promotion: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
