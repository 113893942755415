import React from 'react';
import { Dialog, DialogContentText, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { MarginButtons, ContainerPopUp } from './styles';

const noop = () => {};

function ChangeStatusDialog({
  message,
  onCancel = noop,

  onConfirm,
}) {
  if (!message) return null;

  return (
    <Dialog
      open={!!message}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') onCancel();
      }}
      maxWidth="xs"
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
    >
      <ContainerPopUp>
        <h2 style={{ marginBottom: 30, marginTop: 20 }}>Você tem certeza?</h2>
        <DialogContentText id="alert-dialog-description" align="left">
          {message}
        </DialogContentText>
        <Grid align="end">
          <MarginButtons>
            <Button size="normal" onClick={onCancel}>
              Cancelar
            </Button>
            <Button
              size="normal"
              color="primary"
              variant="contained"
              onClick={onConfirm}
            >
              Confirmar
            </Button>
          </MarginButtons>
        </Grid>
      </ContainerPopUp>
    </Dialog>
  );
}
ChangeStatusDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.bool.isRequired,
};

export default ChangeStatusDialog;
