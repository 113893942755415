import React from 'react';
import { FaTrash } from 'react-icons/fa';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function DialogConfirm({
  setStart,
  setFile,
  file,
  inputRef,
  open,
  onClose,
}) {
  const replaceLongString = fileName => {
    if (fileName.length > 30) {
      return `${fileName.substr(0, 30)}...`;
    }
    return fileName;
  };

  const handleDelete = () => {
    inputRef.current.value = '';
    setFile({});
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          inputRef.current.value = '';
          setFile({});
          onClose();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Deseja iniciar o serviço upload de arquivo?
        </DialogTitle>
        <DialogContent>
          {file ? (
            <DialogContentText style={{ color: 'blue' }}>
              Arquivo Inserido: {replaceLongString(file)}
              <IconButton
                style={{ marginLeft: 10 }}
                size="small"
                onClick={handleDelete}
              >
                <FaTrash />
              </IconButton>
            </DialogContentText>
          ) : (
            <DialogContentText>
              Insira o arquivo para iniciar o processamento.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              const clearRef = inputRef;
              clearRef.current.value = '';
              setFile({});
              onClose();
            }}
          >
            Cancelar
          </Button>
          {file ? (
            <Button
              color="primary"
              onClick={() => {
                setStart(true);
              }}
            >
              Iniciar upload
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              Anexar arquivo
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogConfirm.defaultProps = {
  inputRef: '',
  open: true,
  onClose: false,
  file: false,
  setFile: () => {},
  setStart: () => {},
};

DialogConfirm.propTypes = {
  inputRef: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.bool,
  file: PropTypes.bool,
  setFile: PropTypes.func,
  setStart: PropTypes.func,
};
