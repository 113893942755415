import React, {
  createContext,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import Prompt from './Prompt';

export const ConfirmPromptContext = createContext({});

export const useConfirmPrompt = () => {
  const context = useContext(ConfirmPromptContext);
  return context;
};

const ConfirmPromptProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const handlers = useRef({
    title: '',
    description: '',
    cancelText: 'Cancelar',
    okText: 'Ok',
  });

  const confirm = useCallback((newHandlers = {}) => {
    handlers.current = {
      ...handlers.current,
      ...newHandlers,
    };
    setVisible(true);
  }, []);

  return (
    <ConfirmPromptContext.Provider value={{ confirm }}>
      <Prompt
        visible={visible}
        title={handlers.current.title}
        description={handlers.current.description}
        cancelText={handlers.current.cancelText}
        okText={handlers.current.okText}
        onOk={() => {
          setVisible(false);
          if (typeof handlers.current.onOk === 'function')
            handlers.current.onOk();
        }}
        onCancel={() => {
          setVisible(false);
          if (typeof handlers.current.onCancel === 'function')
            handlers.current.onCancel();
        }}
      />
      {children}
    </ConfirmPromptContext.Provider>
  );
};

ConfirmPromptProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfirmPromptProvider;
