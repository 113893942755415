import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      margin-right: 8px;
    }
  }

  section.all-products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
  }

  div.product-item {
  }
`;
