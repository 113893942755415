import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { H2 } from './styles';

export default function NoRecord({ message }) {
  return (
    <>
      <H2>
        <FaExclamationTriangle size={50} />
        {message}
      </H2>
    </>
  );
}

NoRecord.propTypes = {
  message: PropTypes.string,
};

NoRecord.defaultProps = {
  message: 'Nenhum registro encontrado.',
};
