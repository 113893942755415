import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { CircularProgress, Popover } from '@material-ui/core';

import { toNumber } from 'lodash';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';

import { decimalFormat } from '~/helpers/number';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import { Table, Product, Presenteado } from './styles';

export default function List({ person }) {
  const [result, setResult] = useState({});

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getExtract = async () => {
    setLoading(true);
    try {
      const { data } =
        (await api.get(
          `/persons/${person.idCliente}/extract?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`
        )) || {};
      if (data) {
        setResult(data);
        const { limit, page, count } = data;
        setPagination({
          ...{
            limit: toNumber(limit),
            page: toNumber(page),
            pages: count / toNumber(limit),
            totals: count,
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (person) getExtract();
    // TODO:
    // eslint-disable-next-line
  }, [
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
  ]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading && (!result || !result.rows)) {
    return <CircularProgress align="center" size={24} />;
  }

  const renderColorValue = value => {
    switch (value) {
      case 'debito':
        return { color: 'red' };
      case 'credito':
        return { color: 'green' };
      default:
        return {};
    }
  };
  if (result && result.rows && result.rows.length > 0) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Data do Extrato</th>
              <th>Ação</th>
              <th>Descrição</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {result.rows.map(item => (
              <tr key={item.id}>
                <td>
                  {format(parseISO(item.dataExtrato), 'dd/MM/yyyy HH:mm')}
                </td>
                <td>
                  <Product>
                    <span>{item.metaDados.acao}</span>
                  </Product>
                  {item.metaDados.pessoa ? (
                    <Presenteado>
                      <p>
                        <b>Presenteado:</b>
                      </p>
                      <span>{item.metaDados.pessoa.nome}</span>
                    </Presenteado>
                  ) : (
                    ''
                  )}
                </td>
                <td>{item.metaDados.descricao}</td>
                {item.operacao === 'debito' ? (
                  <td style={renderColorValue(item.operacao)}>
                    -R$ {decimalFormat(item.metaDados.valor)}
                  </td>
                ) : (
                  <td style={renderColorValue(item.operacao)}>
                    +R$ {decimalFormat(item.metaDados.valor)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}
        {/* TRANSAÇÃO */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
      </>
    );
  }
  return <NoRecord />;
}

List.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};

List.defaultProps = {
  person: null,
};
