import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import { useConfirmPrompt } from '../ConfirmPrompt';
import { Container } from './styles';

const isImageRegex = /(image\/(jpg|jpeg|png|gif|bmp|svg)|\.(jpg|jpeg|png|gif|bmp|svg))/gi;
const isPdfRegex = /(application\/pdf|\.pdf)/gi;

const isImage = str => isImageRegex.test(str);
const isPdf = str => isPdfRegex.test(str);

function UploadArea({ label, value, onChange }) {
  const [source, setSource] = useState(value);
  const fileRef = useRef(null);
  const { confirm } = useConfirmPrompt();

  const onDrop = React.useCallback(
    files => {
      function addFile() {
        const file = files[0];

        const fileReader = new FileReader();
        fileReader.onload = () => setSource(fileReader.result);
        fileReader.readAsDataURL(file);

        fileRef.current = file;
        if (typeof onChange === 'function') onChange(file);
      }

      if (!source) addFile();
      else
        confirm({
          title: 'Você tem certeza?',
          description:
            'Você está prestes a alterar o anexo deste registro, você está realmente tentando troca-lo?',
          onOk: addFile,
          cancelText: 'Não',
          okText: 'Sim',
        });
    },
    [source, onChange, confirm]
  );

  const handleClear = React.useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      confirm({
        title: 'Você tem certeza?',
        description:
          'Você está removendo o anexo deste registro e esta ação não poderá ser revertida, você deseja realmente continuar?',
        onOk: () => {
          setSource('');
          fileRef.current = null;
          if (typeof onChange === 'function') onChange('');
        },
      });
    },
    [confirm, onChange]
  );

  const openFile = React.useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      if (fileRef.current) {
        const url = URL.createObjectURL(fileRef.current);
        window.open(url, '_blank');
      } else if (source && /^https?:\/\//gi.test(source)) {
        window.open(source, '_blank');
      }
    },
    [source]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const isPdfFile = isPdf(source) || isPdf(value);
  const isImageFile = isImage(source) || isImage(value);
  const backgroundImage = `url(${source})`;

  return (
    <Container {...getRootProps({ className: 'upload-area-wrapper' })}>
      <input {...getInputProps()} />

      {source && isImageFile && (
        <button
          type="button"
          className="picture-button"
          onClick={openFile}
          style={{ backgroundImage }}
        >
          &nbsp;
        </button>
      )}
      {source && isPdfFile && <Icon onClick={openFile}>picture_as_pdf</Icon>}
      {source && !isPdfFile && !isImageFile && (
        <Icon onClick={openFile}>file_copy</Icon>
      )}

      {!isDragActive && <p>{label}</p>}
      {isDragActive && <p>Solte o arquivo.</p>}

      {!source && <Icon>attach_file</Icon>}
      {source && <Icon onClick={handleClear}>clear</Icon>}
    </Container>
  );
}

UploadArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

UploadArea.defaultProps = {
  value: '',
  onChange: () => {},
};

export default React.memo(UploadArea);
