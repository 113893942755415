import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import { useConfirm } from 'material-ui-confirm';
import { FcApproval } from 'react-icons/fc';
import IconButton from '../IconButton';
import { Container } from './styles';
import api from '~/services/api';
import Dialog from './Dialog';
import { formatNumberTicket } from '~/helpers/number';

function SendEmail({ dataMail }) {
  const {
    end,
    pessoa,
    titulo,
    premio,
    hasChange,
    idTriagem,
    envioEmail,
    dataContemplado,
  } = dataMail;

  const [loading, setLoading] = useState(false);
  const [erros, setErros] = useState(false);
  const [msgErros, setMsgErros] = useState(false);
  const [changeIconeEmail, setChangeIconeEmail] = useState(false);
  const confirm = useConfirm();
  const renderCPF = cpf => {
    // retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, '');
    // realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const parseNumTitulo = numTitulo => formatNumberTicket(numTitulo);

  const schema = Yup.object().shape({
    raffleDate: Yup.string().required('O campo data é obrigatório.'),
    luckNumber: Yup.string().required('O campo número da sorte é obrigatório.'),
    name: Yup.string().required('O campo nome é obrigatório'),
    email: Yup.string().nullable(),
    address: Yup.string()
      .min(1, ' No mínimo 1 caracteres')
      .required('O campo endereço é obrigatório'),
    phone: Yup.string().required('O campo contato é obrigatório'),
    subject: Yup.string().required('O campo contato é obrigatório'),
  });

  const handleProcess = async data => {
    // send email
    setLoading(true);
    const result = await api
      .post('/screenings/sendMail', { ...data, idTriagem })
      .finally(() => {
        setLoading(false);
      });

    if (result.status === 204) {
      await api.put(`/screenings/${idTriagem}/update/sendMails`, {
        envioEmail: true,
      });
    }
    setChangeIconeEmail(true);
  };

  const handlePreConfirm = async data => {
    try {
      await confirm({
        title: 'Atenção!',
        description: `Deseja enviar o e-mail? `,
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        cancellationButtonProps: { color: 'secondary' },
        confirmationText: 'Sim, continuar!',
        confirmationButtonProps: { color: 'primary' },
      });
      await handleProcess(data);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleConfirm = async (data, verifySent) => {
    try {
      await confirm({
        title: 'Atenção!',
        description: `Você já enviou ${verifySent.data.envioEmail} emails. Deseja enviar novamente? `,
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        cancellationButtonProps: { color: 'secondary' },
        confirmationText: 'Sim, continuar!',
        confirmationButtonProps: { color: 'primary' },
      });

      await handleProcess(data);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const concactAddress = [
        end.logradouro,
        end.numero,
        end.complemento,
        end.cidade,
        end.estado,
        end.cep,
      ];

      const filterAddress = concactAddress
        // eslint-disable-next-line func-names
        .filter(function(el) {
          return el !== '';
        })
        .join(', ');

      const data = {
        subject: premio,
        raffleDate: format(parseISO(dataContemplado), 'dd/MM/yyyy'),
        luckNumber: parseNumTitulo(titulo),
        name: pessoa.nome,
        cpf: renderCPF(pessoa.cpf),
        email: pessoa.email,
        address: filterAddress,
        phone: `${pessoa.ddd}${pessoa.celular}`,
      };

      await schema.validate(data, {
        abortEarly: false,
      });

      const verifySent = await api.get(`/screenings/${idTriagem}`);

      if (verifySent.data.envioEmail !== null) {
        handleConfirm(data, verifySent);
      } else {
        handlePreConfirm(data);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = [];

        error.inner.forEach(err => {
          errorMessages.push(err.message);
        });

        setMsgErros(errorMessages[0]);
        setErros(true);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Container>
        <IconButton
          disabled={
            end.logradouro === '' ||
            end.bairro === '' ||
            end.cidade === '' ||
            end.estado === '' ||
            end.numero === '' ||
            end.cep === '' ||
            hasChange
          }
          loading={loading}
          iconName="mail"
          onClick={() => handleSubmit()}
        >
          {(envioEmail > 0 && envioEmail !== null) || changeIconeEmail ? (
            <FcApproval
              size={20}
              style={{ position: 'absolute', top: 5, left: 45 }}
            />
          ) : (
            ''
          )}
          <span>
            Envio
            <br />
            de Email
          </span>
        </IconButton>
      </Container>
      {erros && <Dialog open onClose={() => setErros(false)} msg={msgErros} />}
    </>
  );
}

SendEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataMail: PropTypes.object,
};

SendEmail.defaultProps = {
  dataMail: [],
};

export default SendEmail;
