import styled from 'styled-components';
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from '@material-ui/core';

export const DetailsSummary = styled(ExpansionPanelSummary)`
  div.MuiChip-root.type {
    &.nc {
      background-color: #f9a19a;
    }

    &.live {
      background-color: #8ac8f9;
    }

    &.marketing {
      background-color: #b0ddb2;
    }
  }

  input {
    width: 70%;
  }
`;

export const DetailsContainer = styled(ExpansionPanelDetails)`
  background-color: rgb(248, 248, 248);
`;

export const DetailsContent = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
`;

export const PromotionCodeDetails = styled.div`
  flex: 1;

  h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

export const PromotionCodesHeaderContainer = styled(Grid).attrs({
  spacing: 2,
  container: true,
})`
  && {
    padding: 0 24px 0 24px;
    margin-top: 10px !important;
    background-color: #3f51b5;
    width: 100%;
    margin-left: 0px;

    p.MuiTypography-root {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      color: #ffffff;
    }
  }
`;

export const ActionsColumn = styled(Grid)`
  text-align-last: center;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  background-color: white;
  padding-right: 15px;
`;
