import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { CircularProgress } from '@material-ui/core';

import { toNumber } from 'lodash';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';

import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import { Table } from './styles';

export default function List({ person }) {
  const [result, setResult] = useState({});

  const [loading, setLoading] = useState(false);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const getHistoricAddress = useCallback(async () => {
    setLoading(true);
    try {
      const { data } =
        (await api.get(
          `/persons/${person.idCliente}/historic-address?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`
        )) || {};
      if (data) {
        setResult(data);
        const { limit, page, count } = data;
        setPagination({
          ...{
            limit: toNumber(limit),
            page: toNumber(page),
            pages: Math.ceil(count / toNumber(limit)),
            totals: count,
          },
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    person,
    setPagination,
  ]);
  useEffect(() => {
    if (person) getHistoricAddress();
  }, [getHistoricAddress, person]);

  if (loading && (!result || !result.rows)) {
    return <CircularProgress align="center" size={24} />;
  }

  if (result && result.rows && result.rows.length > 0) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Data da Mudança</th>
              <th>DDD</th>
              <th>CEP</th>
              <th>Bairro</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Numero</th>
              <th>Logradouro</th>
              <th>Nome social</th>
            </tr>
          </thead>
          <tbody>
            {result.rows.map(item => (
              <tr key={item.id}>
                <td>{format(parseISO(item.dataInsert), 'dd/MM/yyyy HH:mm')}</td>
                <td>{item.ddd || '-'}</td>
                <td>
                  {item.cep
                    ? item.cep.replace(/(\d{3})(\d{2})(\d+)/, '$1$2-$3')
                    : '-'}
                </td>
                <td>{item.bairro || '-'}</td>
                <td>{item.cidade || '-'}</td>
                <td>{item.estado || '-'}</td>
                <td>{item.numero || '-'}</td>
                <td>{item.logradouro || '-'}</td>
                <td>{item.nomeSocial || '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}
      </>
    );
  }
  return <NoRecord />;
}

List.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};

List.defaultProps = {
  person: null,
};
