import styled from 'styled-components';

import TableSortLabelBase from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, yellow } from '@material-ui/core/colors';

export const TableSortLabel = styled(TableSortLabelBase)`
  && {
    color: white;

    svg {
      color: white !important;
    }
  }
`;

export const GreenButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

export const YellowButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
}))(Button);
