import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import IDatePicker from '~/components/IDatePicker';

export default function DatePicker({ name, value: defaultValueProp, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [ref, fieldName, registerField]);

  const props = {
    ...rest,
    inputRef: ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    value: defaultValueProp || defaultValue,
  };

  return <IDatePicker {...props} />;
}

DatePicker.defaultProps = {
  value: '',
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};
