import React from 'react';

import { CircularProgress, Button } from '@material-ui/core';
import { FaSave, FaChevronLeft } from 'react-icons/fa';

import history from '~/services/history';
import { useConfigsContext } from '~/contexts/useConfig';
import { isEmpty } from 'lodash';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  Container,
  Header,
  HeaderTitle,
  HeaderRightButtons,
  ContentContainer,
} from './styles';
import { usePromotionContext } from '../../context';
import { PromotionDataForm } from './promotion';
import PromotionSweepstakes from './sweepstakes';
import { SweepstakeFormProvider } from './SweepstakeFormProvider';
import SweepstakeFormDialog from './FormDialog';
import HistoryButton from './HistoryButton';
import { usePromotionAllPermission } from './context-permissions';
import PromotionEbooks from './ebooks';

export default function PromotionAllContext() {
  const { promotion, loading } = usePromotionContext();
  const { can } = usePromotionAllPermission();
  const config = useConfigsContext();

  return (
    <SweepstakeFormProvider>
      <Container>
        <Header>
          <HeaderTitle>
            <FaChevronLeft size="22" onClick={history.goBack} />
            {!!loading && <CircularProgress size={24} color="primary" />}
            {!!promotion.tituloPromocao && <h2>{promotion.tituloPromocao}</h2>}
          </HeaderTitle>

          <HeaderRightButtons>
            {can('read_promotion_logs') && <HistoryButton />}
            {can('update_promotion') && (
              <Button
                size="small"
                startIcon={<FaSave size="20" />}
                form="promotion"
                type="submit"
              >
                Salvar
              </Button>
            )}
          </HeaderRightButtons>
        </Header>

        <ContentContainer>
          <PromotionDataForm config={config} />
          {!isEmpty(config) && config.steps.sorteios && (
            <PromotionSweepstakes />
          )}
          {!isEmpty(config) && config.steps.ebook && (
            <DndProvider backend={HTML5Backend}>
              <PromotionEbooks />
            </DndProvider>
          )}
        </ContentContainer>
      </Container>
      <SweepstakeFormDialog />
    </SweepstakeFormProvider>
  );
}
