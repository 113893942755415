import React, { useState, useEffect, useCallback } from 'react';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toNumber } from 'lodash';
import api from '~/services/api';

import { NoRecord } from '~/components';
import DeleteButton from './deleteButton';

export default function PromotionActionsGiftsList({
  loadingData,
  list,
  draft,
  updateDraft,
  isDoubleCheck,
  setList,
}) {
  const [acaoList, setAcaoList] = useState([]);
  const [brindeList, setBrindeList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const [actiongift, setActiongift] = useState([]);
  const loadingConfig = useCallback(async () => {
    const brindes = await api.get(
      `/promotions/config/actiongifts/${draft.data.promotion.idProduto}`
    );
    setActiongift(brindes.data);
  }, [draft.data.promotion.idProduto]);

  const handleListAcao = useCallback(async () => {
    setLoadingList(true);
    const [brindes, acoes] = await Promise.all([
      api.get('/promotions/gifts'),
      api.get('/promotions/actions'),
    ]);
    if (brindes.data.docs.rows) {
      const bs = [];
      brindes.data.docs.rows.forEach(b => {
        bs[b.idBrinde] = b.nome;
      });
      setBrindeList(bs);
    }
    if (acoes.data.docs.rows) {
      const al = [];
      acoes.data.docs.rows.forEach(a => {
        al[a.idAcao] = a.nome;
      });
      setAcaoList(al);
    }
    await loadingData();
    setLoadingList(false);
  }, [loadingData]);

  useEffect(() => {
    handleListAcao();
    loadingConfig();
  }, [handleListAcao, loadingConfig]);

  const isDefault = ({ idAcao, idBrinde }) => {
    return actiongift.find(
      ag => ag.idBrinde === idBrinde && ag.idAcao === idAcao
    );
  };

  const renderList = (item, count) => {
    const { status } = draft;
    const { idAcao, idBrinde } = item;
    const canShowDeleteButton =
      isDoubleCheck ||
      (status !== 'approved' &&
        !isDefault({
          idAcao: toNumber(idAcao),
          idBrinde: toNumber(idBrinde),
        }));
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={`${item.idAcao}-${item.idBrinde}`}
      >
        <TableCell style={{ backgroundColor: '#F8F8FF' }}>
          {count + 1}
        </TableCell>
        <TableCell style={{ backgroundColor: '#F8F8FF' }}>
          {acaoList[item.idAcao] || 'Carregando ação...'}
        </TableCell>
        <TableCell style={{ backgroundColor: '#F8F8FF' }}>
          {brindeList[item.idBrinde] || 'Carregando brinde...'}
        </TableCell>
        <TableCell style={{ backgroundColor: '#F8F8FF' }}>
          {canShowDeleteButton && (
            <DeleteButton
              handleListAcao={handleListAcao}
              item={item}
              updateDraft={updateDraft}
              loadingData={loadingData}
              draft={draft._id}
              count={count}
              isDoubleCheck={isDoubleCheck}
              setList={setList}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <h3 style={{ marginLeft: 10, marginBottom: 20, marginTop: 40 }}>
        AÇÕES BRINDES CADASTRADAS
      </h3>
      <hr style={{ margin: 10 }} />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Nº</TableCell>
              <TableCell>Ação</TableCell>
              <TableCell>Brinde</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingList && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress size={20} style={{ color: 'black' }} />
                </TableCell>
              </TableRow>
            )}

            {!loadingList && list.length > 0 ? (
              list.map((item, count) => renderList(item, count))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {!loadingList && <NoRecord />}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

PromotionActionsGiftsList.defaultProps = {
  isDoubleCheck: false,
  setList: () => {},
};

PromotionActionsGiftsList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]).isRequired,
  loadingData: PropTypes.func.isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  isDoubleCheck: PropTypes.bool,
  setList: PropTypes.func,
};
