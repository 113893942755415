import { TextField as TF } from 'unform-material-ui';
import { Button as ButtonUI } from '@material-ui/core';
import styled from 'styled-components';

export const TextFieldLarge = styled(TF)`
  input {
    font-size: 50px;
    opacity: 0.8;
  }
`;

export const Button = styled(ButtonUI)`
  min-width: 106px !important;
  margin-left: 10px !important;
`;

export const BtnWhats = styled.button`
  background: #25d366;
  color: #fff;
  border: none;
  height: 55px;
  padding: 12px;
  margin-top: 16px;
  border-radius: 4px;
  margin-left: 5px;
  box-shadow: 1px 1px 1px 0px #ccc;

  &: hover {
    background: #136c34;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const Title = styled.h1`
  color: gray;
  font-weight: 100;
`;

export const Bloqueado = styled.div`
  color: white;
  background-color: red;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  padding: 10px;
  justify-content: space-between;
`;

export const Excluido = styled.div`
  color: white;
  background-color: black;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  padding: 10px;
  justify-content: space-between;
`;

export const SemCadastro = styled.div`
  color: white;
  background-color: gray;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  padding: 10px;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  display: block;
  margin-right: 20px;
`;

export const AvatarImage = styled.figure`
  max-width: 100%;
  max-height: auto;
  width: 90px;
  height: 90px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  background-image: url("${({ url }) => url}");
  box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.15);
  border: 3px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SizeInfo = styled.div`
  margin-top: 10px;
  margin-right: 0px;
  display: flex;
  justify-content: flex-end;

  div {
    text-align: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 1rem;
    font-weight: 400;
  }
`;
