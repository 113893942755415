import { TextField as TF } from 'unform-material-ui';
import styled from 'styled-components';

export const TextField = styled(TF)`
  input {
    transition: font-size 250ms;
    animation: ease-in-out;
    font-size: 50px;
  }
  input:focus {
    color: #3e51b5;
    font-size: 50px;
  }
`;

export const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-content: center;
`;

export const Button = styled.button`
  padding: 18.5px 14px;
  align-content: stretch;
  height: 96.38;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
