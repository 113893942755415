export const backgroundColorRole = role => {
  return (
    {
      admin_bko: '#FFB3B3',
      super_user: '#FFB3B3',
      atendente: '#CFF0D2',
      push: '#CFF0D2',
    }[role] || '#B9DAF9'
  );
};
