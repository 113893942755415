import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import api from '~/services/api';
import PropTypes from 'prop-types';

export const ConfigsContext = createContext({ config: {} });

export const ConfigsProvider = ({ children, codigo }) => {
  const [config, setConfig] = useState({});

  const getConfigs = useCallback(async () => {
    api.get(`/configs/?codigo=${codigo}`).then(res => {
      if (res.data) {
        setConfig(res.data[0]);
      }
    });
  }, [codigo]);

  useEffect(() => {
    if (typeof codigo === 'string') {
      getConfigs();
    }
  }, [codigo, getConfigs]);

  return (
    <ConfigsContext.Provider value={config}>{children}</ConfigsContext.Provider>
  );
};

ConfigsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  codigo: PropTypes.string.isRequired,
};

export const useConfigsContext = () => {
  const context = useContext(ConfigsContext);
  return context;
};
