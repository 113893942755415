import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import api from '~/services/api';
import { MdOutlineImage, MdCalendarToday } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { formatNumberTicket } from '~/helpers/number';
import { Container, LoadingContainer, Item, CloseText } from './styles';

export default function CouponsPurchaseDialog({ purchase, open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [shouldScroll, setShouldScroll] = useState(false);

  const getCoupons = async () => {
    const { idPessoa, idCompra } = purchase;

    try {
      setLoading(true);

      const { data } = await api.get(`/persons/${idPessoa}/coupons`, {
        params: {
          purchaseId: idCompra,
        },
      });

      if (data.docs.length > 0) {
        setCoupons(data.docs);
        setShouldScroll(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Ver Cupons ({coupons.length})</DialogTitle>

      {loading ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <DialogContent
          style={{
            display: 'flex',
            marginTop: 24,
            justifyContent: 'center',
            maxHeight: 641,
            overflowY: shouldScroll ? 'scroll' : 'hidden',
          }}
        >
          <Container>
            {coupons &&
              coupons.map(item => {
                return (
                  <Item>
                    <div
                      className="header"
                      style={{
                        backgroundColor:
                          item.promocao?.produto?.codigo !== 'ESTORNO'
                            ? '#80BCFF'
                            : '#FFBBB8',
                      }}
                    >
                      <p>
                        <MdCalendarToday size={20} /> Promoção{' '}
                        {item?.promocao?.dataSorteioPrincipal
                          ? format(
                              parseISO(item?.promocao?.dataSorteioPrincipal),
                              'dd/MM/yyyy HH:mm'
                            )
                          : null}
                      </p>
                    </div>

                    <div className="content">
                      {item.promocao.produto.icone ? (
                        <img
                          src={item.promocao?.produto?.icone}
                          alt="thumbnail"
                          width="96px"
                        />
                      ) : (
                        <MdOutlineImage
                          style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                          size={96}
                        />
                      )}

                      <div>
                        <h1>{item.promocao?.produto?.titulo}</h1>
                        <p>{item.promocao?.tituloPromocao}</p>
                      </div>
                    </div>

                    <div className="footer">
                      <p>Número da Sorte: </p>

                      <p style={{ fontWeight: 700, marginLeft: 4 }}>
                        {formatNumberTicket(item.titulos[0]?.numTitulo)}
                      </p>
                    </div>
                  </Item>
                );
              })}
          </Container>
        </DialogContent>
      )}

      <DialogActions onClick={onClose}>
        <CloseText>FECHAR</CloseText>
      </DialogActions>
    </Dialog>
  );
}

CouponsPurchaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  purchase: PropTypes.object.isRequired,
};
