import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default function TextMaskNumber(props) {
  const { inputRef, ...other } = props;
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    decimalLimit: 0,
    allowDecimal: false,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
  });

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
      placeholderChar="_"
      showMask={false}
    />
  );
}

TextMaskNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
