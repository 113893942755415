export const isYouTubeURL = url => {
  const youtubeDomainRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)(\/.*)?$/;
  return youtubeDomainRegex.test(url);
};

export const identifyLinkType = str => {
  // Verifica se é uma URL (http ou https) e não contém os deeplinks da adjust
  const urlPattern = /^(https?:\/\/(?![a-zA-Z0-9-]+\.adj\.st|app\.adjust\.com)[^\s]+)$/;

  if (urlPattern.test(str)) {
    return 'url';
  }

  // Verifica se é um Deeplink
  const deeplinkPattern = /^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//;
  if (deeplinkPattern.test(str)) {
    return 'deeplink';
  }

  // Verifica se é uma Rota (caminho relativo começando com '/')
  const routePattern = /^[A-Za-z]+$|^[A-Za-z]+\/[A-Za-z]+$/;
  if (routePattern.test(str)) {
    return 'route';
  }

  // Se não for nenhum dos tipos acima
  return 'unknown';
};
