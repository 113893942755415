import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import { FaCalendar, FaCalendarAlt } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { head } from 'lodash';
import { ConfirmProvider } from 'material-ui-confirm';
import { MdWarning } from 'react-icons/md';

// -- components
import { decimalFormat } from '~/helpers/number';
import { hasPermission } from '~/components/AccessControl';
import {
  GridCustom,
  Product,
  PromotionContainer,
  PromotionHeader,
} from './styles';
import PromotionMenu from './PromotionMenu';

const cores = ['#773386', '#ac0808', '#faa21d', '#8b5528', '#00a7b9'];

export default function Promotion({
  data: draft,
  product,
  onClick,
  onDelete,
  onClone,
  isDeleting,
  ...rest
}) {
  const isAvailableToDelete = React.useMemo(() => {
    return ['draft', 'pending', 'double-check', 'approved'].includes(
      draft.status
    );
  }, [draft]);
  const CAN_DELETE = hasPermission('delete_promotion_draft', { toast: false });

  function getDateTimeFormat(data) {
    return data && format(parseISO(data), 'dd/MM/yyyy HH:mm');
  }

  const getCor = cor => cores[cor];

  const getColorStatus = status => {
    const style = { textTransform: 'uppercase' };
    switch (status) {
      case 'double-check':
        style.backgroundColor = 'lightblue';
        style.color = 'black';
        break;
      case 'pending':
        style.backgroundColor = 'yellow';
        style.color = 'black';
        break;
      case 'draft':
        style.color = 'white';
        style.backgroundColor = 'gray';
        break;
      case 'approved':
        style.color = 'white';
        style.backgroundColor = 'green';
        break;
      case 'deleted':
        style.color = 'white';
        style.backgroundColor = 'red';
        break;

      default:
        break;
    }
    return style;
  };

  const getStatus = status => {
    switch (status) {
      case 'pending':
        return 'Pendente';
      case 'double-check':
        return 'Conferencia Pendente';
      case 'draft':
        return 'Rascunho';
      case 'approved':
        return 'Aprovado';
      case 'deleted':
        return 'Excluído';

      default:
        return status;
    }
  };

  const chance = value => {
    if (value === 2) return 'DUPLA';
    if (value === 3) return 'TRIPLA';
    return 'SIMPLES';
  };

  return (
    <ConfirmProvider>
      <PromotionContainer
        theme={getCor(draft.data.promotion.corPromocao)}
        {...rest}
      >
        <GridCustom container>
          {isDeleting === draft._id && (
            <div className="loading-container">
              <CircularProgress className="loading" />
            </div>
          )}
          {/* header */}
          <PromotionHeader theme={getCor(draft.data.promotion.corPromocao)}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} md={6}>
                <FaCalendar size={20} />
                <span>
                  {getDateTimeFormat(draft.data.promotion.dataSorteioPrincipal)}
                </span>
              </Grid>
              <Grid item xs>
                {!!draft.data.promotion.categoria && (
                  <p style={{ textAlign: 'right' }}>
                    CHANCE {chance(draft.data.promotion.categoria)}
                  </p>
                )}
              </Grid>
              {CAN_DELETE && isAvailableToDelete && (
                <Grid item xs={1}>
                  <PromotionMenu
                    data={draft}
                    onDelete={onDelete}
                    onClone={onClone}
                  />
                </Grid>
              )}
            </Grid>
          </PromotionHeader>
          {/* body */}
          <Grid className="body" item xs={12} md={12} onClick={onClick}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Product
                  image={
                    draft.data.banners &&
                    draft.data.banners.length &&
                    head(draft.data.banners).url
                  }
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container>
                  <Grid item xs={8}>
                    <h2>{product.titulo}</h2>
                  </Grid>
                  <Grid item xs={4}>
                    <h2>
                      {draft.data.promotion.valorProduto &&
                        `R$ ${decimalFormat(
                          draft.data.promotion.valorProduto
                        )}`}
                    </h2>
                  </Grid>
                </Grid>

                <h4>
                  {draft.data.promotion.tituloPromocao || (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      Sem Título&nbsp;
                      <MdWarning size={16} color="#ff0000" />
                    </span>
                  )}
                </h4>
                <p>
                  <FaCalendarAlt size={18} style={{ marginRight: 5 }} />
                  {getDateTimeFormat(
                    draft.data.promotion.dataVigenciaInicio
                  )}{' '}
                  até {getDateTimeFormat(draft.data.promotion.dataVigenciaFim)}
                </p>
              </Grid>
            </Grid>
          </Grid>
          {/* body */}
          <Grid className="footer" item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid
                item
                align="center"
                xs={12}
                md={12}
                style={getColorStatus(draft.status)}
              >
                {getStatus(draft.status)}
              </Grid>
            </Grid>
          </Grid>
        </GridCustom>
      </PromotionContainer>
    </ConfirmProvider>
  );
}

Promotion.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  isDeleting: PropTypes.bool,
};

Promotion.defaultProps = {
  onClone: () => {},
  onDelete: () => {},
  isDeleting: false,
};
