import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { FeedbackHeaderContainer } from './styles';

// eslint-disable-next-line react/prop-types
const Column = ({ label, width, ...rest }) => (
  <Grid {...rest} style={{ width, ...rest.style }}>
    <Typography>{label}</Typography>
  </Grid>
);

export default function FeedbackHeader() {
  return (
    <FeedbackHeaderContainer>
      <Column item width={120} label="Número" />
      <Column item xs={12} md={3} label="Nome" />
      <Column item xs={12} md={3} label="Titulo" />
      <Column item xs={12} md={3} label="Descrição" />
      <Column item width={170} label="Data" />
    </FeedbackHeaderContainer>
  );
}
