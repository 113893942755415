import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';
import PropTypes from 'prop-types';
import api from '~/services/api';

const ebookSpec = applySpec({
  id: prop('id'),
  nome: prop('nome'),
  autor: prop('autor'),
  url: prop('urlThumbnail'),
});

const getEbook = pipe(prop('docs'), map(ebookSpec));

export default function useEbook({ refetch }) {
  const [ebooks, setEbooks] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEbooks = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('/ebook');
      if (data) setEbooks(getEbook(data));
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEbooks();
  }, [refetch]);

  return { ebooks, loading };
}

useEbook.propTypes = {
  refetch: PropTypes.bool.isRequired,
};
