import React, { useCallback, useEffect, useState } from 'react';
import { Chip, Avatar, Checkbox } from '@material-ui/core';
import { MdDone, MdAdd } from 'react-icons/md';
import PropTypes from 'prop-types';
import useProducts from '~/components/ProductList/useProducts';
import { Container } from './styles';

const noop = () => {};

export default function Products({
  onCatchProducts,
  label = 'Produtos liberados',
  value = [],
  onChange = noop,
}) {
  const { products } = useProducts();
  const [selectedProducts, setSelectedProducts] = useState(value);

  const productIsSelected = productId => selectedProducts.includes(productId);
  const toggleSelectedProduct = useCallback(
    (isSelected, product) => {
      if (isSelected) {
        const unselectedProduct = selectedProducts.filter(productId => {
          return productId !== product.id;
        });
        setSelectedProducts(unselectedProduct);
        onChange(unselectedProduct, product);
      } else {
        const withNewSelectedProduct = [...selectedProducts, product.id];
        setSelectedProducts(withNewSelectedProduct);
        onChange(withNewSelectedProduct, product);
      }
    },
    [selectedProducts, onChange]
  );

  useEffect(() => {
    if (value.length > 0) {
      setSelectedProducts(value);
    }
  }, [value]);

  const totalProducts = products.length;
  const totalOfSelectedProducts = selectedProducts.length;
  const counterText = `${totalOfSelectedProducts} de ${totalProducts}`;
  const isIndeterminate =
    totalOfSelectedProducts > 0 && totalOfSelectedProducts < totalProducts;
  const isAllChecked = totalOfSelectedProducts === totalProducts;

  return (
    <Container>
      <header>
        <h2>
          {label} {counterText}
        </h2>
        <Checkbox
          color="primary"
          size="medium"
          indeterminate={isIndeterminate}
          checked={isAllChecked}
          onChange={(_, checked) => {
            if (checked) {
              const newArray = [];
              products.map(product => newArray.push(product.id));
              setSelectedProducts(newArray);
              onCatchProducts(newArray);
            } else {
              setSelectedProducts([]);
              onCatchProducts([]);
            }
          }}
        />
      </header>

      <section className="all-products">
        {products.map(product => {
          const isSelected = productIsSelected(product.id);
          return (
            <div className="product-item" key={product.id}>
              <Chip
                style={{ fontSize: 12 }}
                color={isSelected ? 'primary' : 'default'}
                onDelete={() => {
                  toggleSelectedProduct(isSelected, product);
                }}
                label={product.titulo}
                deleteIcon={isSelected ? <MdDone /> : <MdAdd />}
                avatar={<Avatar src={product.imagem} />}
              />
            </div>
          );
        })}
      </section>
    </Container>
  );
}

Products.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onCatchProducts: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
