import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FcApproval } from 'react-icons/fc';
import { useConfirm } from 'material-ui-confirm';
import IconButton from '../IconButton';
import { Container } from './styles';
import api from '~/services/api';
import { useScreening } from '../../ScreeningItem/context';

function SendPartner({ idTriagem, disabled, ...rest }) {
  const screening = useScreening();
  const [loading, setLoading] = useState(false);

  const confirm = useConfirm();
  const handleSubmit = useCallback(() => {
    setLoading(true);
    (async () => {
      try {
        await api.post(`/screenings/sendPartner`, { idTriagem });
        screening.update({ situacao: 'enviado', envioCapitalizadora: 1 });
      } finally {
        setLoading(false);
      }
    })();
  }, [idTriagem, screening]);

  const handleModal = async () => {
    try {
      await confirm({
        title: 'Atenção!',
        description: `Deseja enviar a capitalizadora? `,
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        cancellationButtonProps: { color: 'secondary' },
        confirmationText: 'Sim, continuar!',
        confirmationButtonProps: { color: 'primary' },
      });

      handleSubmit();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <IconButton
          loading={loading}
          iconName="near_me"
          onClick={() => handleModal()}
          disabled={
            screening.envioCapitalizadora === 1 ||
            screening.situacao !== 'pendente'
          }
          {...rest}
        >
          {screening.envioCapitalizadora === 1 && (
            <FcApproval
              size={20}
              style={{ position: 'absolute', top: -5, left: 65 }}
            />
          )}
          <span>
            Enviar
            <br />
            Capitalizadora
          </span>
        </IconButton>
      </Container>
    </>
  );
}

SendPartner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  idTriagem: PropTypes.number,
  disabled: PropTypes.bool,
};

SendPartner.defaultProps = {
  idTriagem: '',
  disabled: true,
};

export default SendPartner;
