import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FcApproval } from 'react-icons/fc';
import api from '~/services/api';
import IconButton from '../IconButton';
import { Container } from './styles';
import { useScreening } from '../../ScreeningItem/context';

function SendBalance({ params }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const screening = useScreening();
  const { idTriagem, amount, winners } = params;

  const handleSubmit = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.post(`/screenings/sendBalance`, {
          idTriagem,
          screening,
          amount,
          winners,
        });
        screening.update(data);
      } finally {
        setLoading(false);
      }
    })();
  }, [idTriagem, amount, winners, screening]);

  useEffect(() => {
    if (screening) {
      const screeningAtachment = [
        screening.urlComprovanteEnd,
        screening.urlDoc,
        screening.urlExtra,
        screening.urlNotaFiscal,
      ];
      const atachmentAccumulator = screeningAtachment.reduce(
        (accumulator, name) => {
          if (typeof name === 'string') {
            return accumulator + 1;
          }
          return accumulator;
        },
        0
      );
      if (
        (amount / winners >= screening.maxValueBalance &&
          (!screening.dadosCliente ||
            atachmentAccumulator <= 1 ||
            Object.values(screening.dadosCliente).some(
              el => el.trim() === ''
            ))) ||
        screening.envioPagamento === 1 ||
        screening.envioCapitalizadora !== 1
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [screening, amount, winners]);

  return (
    <>
      <Container>
        <IconButton
          loading={loading}
          iconName="attach_money"
          onClick={() => handleSubmit()}
          disabled={disabled}
        >
          {screening.envioPagamento === 1 && (
            <FcApproval
              size={20}
              style={{ position: 'absolute', top: -5, left: 65 }}
            />
          )}
          <span>
            Efetuar
            <br />
            Pagamento
          </span>
        </IconButton>
      </Container>
    </>
  );
}

SendBalance.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
};

SendBalance.defaultProps = {
  params: {},
};

export default SendBalance;
