import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { head } from 'lodash';

// -- services
import api from '~/services/api';

// -- components
import { AvatarContainer, AvatarImage } from './styles';

export default function PersonAvatar({ data: person, onUpdated, disabled }) {
  const [menu, setMenu] = useState(null);
  const [loading, setLoading] = useState(false);

  // -- dropzone
  const onDrop = useCallback(
    files => {
      if (files.length) {
        // -- loading
        setLoading(true);

        const formdata = new FormData();
        const avatar = head(files);

        // -- append data
        formdata.append('avatar', avatar);

        api
          // eslint-disable-next-line react/prop-types
          .post(`/persons/${person.idCliente}/image-profile`, formdata)
          .then(res => {
            if (res && res.data) {
              const newAvatar = `${res.data.urlImageProfile}?v=${Date.now()}`;

              // -- callback
              if (onUpdated && typeof onUpdated === 'function') {
                onUpdated(newAvatar);
              }
            }
          })
          .finally(() => setLoading(false));
      }
    },
    [person, onUpdated]
  );

  const { open: openFileDialog, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  const avatarUrl =
    person.urlImageProfile ||
    `https://ui-avatars.com/api/?size=150&name=${person.nomeCompleto}`;

  return (
    <AvatarContainer>
      <AvatarImage
        onClick={!disabled ? e => setMenu(e.currentTarget) : null}
        url={avatarUrl}
        // loading={loading}
      >
        {loading && <CircularProgress />}
      </AvatarImage>

      <div
        {...getRootProps()}
        style={{ width: 0, height: 0, opacity: 0, display: 'none' }}
      >
        <input {...getInputProps()} />
      </div>

      <Menu
        keepMounted
        anchorEl={menu}
        open={!!menu}
        onClose={() => setMenu(null)}
      >
        {person.urlImageProfile && (
          <MenuItem onClick={() => window.open(person.urlImageProfile)}>
            Abrir
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setMenu(null);
            openFileDialog();
          }}
        >
          Trocar
        </MenuItem>
      </Menu>
    </AvatarContainer>
  );
}

PersonAvatar.defaultProps = {
  disabled: false,
};

PersonAvatar.propTypes = {
  data: PropTypes.shape({
    nomeCompleto: PropTypes.string,
    urlImageProfile: PropTypes.string,
  }).isRequired,
  onUpdated: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
