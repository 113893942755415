/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Container,
  Button,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { MdCheck, MdClose, MdWarning } from 'react-icons/md';

import { ProgressBar } from '~/components';

export default function DialogCgSusep({ progress, onOpen, onClose, status }) {
  return (
    <Dialog
      scroll="body"
      open={onOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      style={{ padding: 10 }}
    >
      <Container>
        <Grid align="center">
          {status.length <= 0 && <CircularProgress style={{ marginTop: 30 }} />}
        </Grid>
      </Container>

      {status.length > 0 && (
        <Container style={{ padding: 30 }}>
          <ProgressBar value={progress} />
        </Container>
      )}
      <Container>
        {status.length > 0 &&
          status.map(s => (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              key={s.key}
              alignItems="center"
            >
              <Grid item xs={s.errorProcess ? 6 : 4} align="left">
                <Typography
                  color={s.errorProcess ? 'secondary' : 'inherit'}
                  variant={s.item ? 'body1' : 'h6'}
                >
                  {s.status}
                </Typography>
                {s.info && (
                  <Typography
                    variant="p"
                    color="secondary"
                    align="center"
                    style={{ marginLeft: 20 }}
                  >
                    {s.info}
                  </Typography>
                )}
              </Grid>
              {!s.errorProcess && (
                <>
                  <Grid item xs={1} align="right">
                    <Typography variant="h6">{s.value}</Typography>
                  </Grid>
                  <Grid item xs={1} align="right">
                    {s.error === true && s.item && (
                      <MdClose size={30} color="red" />
                    )}
                    {s.error === false && s.warning === true && s.item && (
                      <MdWarning size={30} color="#ffa500" />
                    )}

                    {s.error === false && !s.warning && s.item && (
                      <MdCheck size={30} color="green" />
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        <Grid align="center">
          <Button
            onClick={() => {
              onClose();
            }}
            style={{ margin: 40 }}
            variant="contained"
            color="primary"
          >
            Fechar processo
          </Button>
        </Grid>
      </Container>
    </Dialog>
  );
}

DialogCgSusep.propTypes = {
  onOpen: PropTypes.bool,
  progress: PropTypes.number,
  status: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func,
};

DialogCgSusep.defaultProps = {
  onOpen: false,
  progress: 0,
  onClose: () => {},
};
