import { useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { applySpec, prop } from 'ramda';

import api, { baseURL } from '~/services/api';

const paginationSpec = applySpec({
  totals: prop('total'),
  limit: prop('limit'),
  page: prop('page'),
  pages: prop('pages'),
});

export default function usePushs({ setPagination, ...custom }) {
  const [filters, setFilters] = useState({});

  const [pushs, setPushs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);

  /* load pushs fn */
  const loadPushs = params => api.get('/pushs', { params });

  /* load applications */
  useEffect(() => {
    Promise.all([
      api.get('/pushs/applications').then(res => {
        if (res && res.data) {
          const { docs } = res.data;
          setApplications(docs);
        }
      }),
    ]);
  }, [custom.pagination.page, custom.pagination.limit]);

  /* socket */
  useEffect(() => {
    const socket = io(baseURL);

    socket.on('pushs/updated', push => {
      if (push) {
        const newPushs = [...pushs];
        const idx = newPushs.findIndex(p => p._id === push._id);

        if (idx !== -1) {
          newPushs.splice(idx, 1, push);
          setPushs(newPushs);
        }
      }
    });

    return () => socket.close();
  }, [pushs]);

  /* filters and pagination */
  useEffect(() => {
    let timeId;

    /* pre fetch */
    if (applications.length) {
      timeId = setTimeout(() => {
        setPushs([]);
        setLoading(true);

        loadPushs({
          ...filters,
          page: custom.pagination.page,
          limit: custom.pagination.limit,
        })
          .then(res => {
            if (res.status === 200) {
              setPushs(res.data.docs);
              setPagination(paginationSpec(res.data));
            }
          })
          .finally(() => setLoading(false));
      }, 120);
    }

    return () => timeId && clearTimeout(timeId);
  }, [
    applications,
    custom.pagination.limit,
    custom.pagination.page,
    filters,
    setPagination,
  ]);

  const handleFilters = useCallback(
    data => {
      setPagination({ page: 1 });
      setFilters(oldFilters => ({ ...oldFilters, ...data }));
    },
    [setPagination]
  );

  const deletePushApplication = useCallback(
    ({ pushId, appId }) => {
      setLoading(true);

      api
        .delete(`/pushs/${pushId}`, {
          data: { applications: [appId] },
        })
        .then(res => {
          if (res.status === 204) {
            const newPushs = [...pushs];

            const push = newPushs.find(({ _id }) => pushId === _id);
            const pushIdx = newPushs.findIndex(({ _id }) => push._id === _id);

            const appIdx = newPushs[pushIdx].applications.findIndex(
              ({ id }) => id === appId
            );

            // remove app
            if (newPushs[pushIdx].applications.length > 1) {
              newPushs[pushIdx].applications.splice(appIdx, 1);
            } else {
              newPushs.splice(pushIdx, 1);
            }

            setPushs(newPushs);
          }
        })
        .finally(() => setLoading(false));
    },
    [pushs]
  );

  return {
    pushs,
    applications,
    loading,
    filters,
    handleFilters,
    deletePushApplication,
    // pagination,
    // handlePagination,
  };
}
