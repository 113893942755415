import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { FeedbackHeaderContainer } from '../../Feedbacks/List/styles';

// eslint-disable-next-line react/prop-types
const Column = ({ label, width, ...rest }) => (
  <Grid {...rest} style={{ width, ...rest.style }}>
    <Typography>{label}</Typography>
  </Grid>
);

export default function FeedbackHeader() {
  return (
    <FeedbackHeaderContainer>
      <Column item xs={6} md={1} label="Número" />
      <Column item xs={12} md={2} label="Data" />
      <Column item xs={12} md={4} label="Nome" />

      <Column
        item
        xs={12}
        md={3}
        label="Telefone"
        style={{ marginLeft: -20 }}
      />

      <Column
        item
        xs={12}
        md={2}
        label="Situação"
        style={{ marginLeft: -10 }}
      />
    </FeedbackHeaderContainer>
  );
}
