import { Button, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const BaseContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  width: 30%;
`;

export const DoubleCheckedByContainer = styled(BaseContainer)`
  margin-left: auto;
`;

export const CreatedByContainer = styled(BaseContainer)`
  margin-right: auto;
`;

export const DoubleCheckedByButton = styled(Button)`
  margin-top: 8px !important;
  height: 56px;
`;
