import styled from 'styled-components';
import BannerItem from '../BannerItem';

export const Container = styled(BannerItem).attrs({
  placeholderOnly: true,
})`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: none;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='3' stroke-dasharray='8%2c 10%2c 12' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;

  button {
    font-size: 48px;
  }

  span.label {
    font-size: 24px;
    margin-top: 8px;
    font-weight: 400;
  }

  button,
  span.label {
    color: #8e8e8e;
  }
`;
