import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { TextField, Checkbox as UnCheckbox } from 'unform-material-ui';
import { hasPermission } from '~/components/AccessControl';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import api from '~/services/api';

const schema = Yup.object().shape({
  yes: Yup.boolean(),
  no: Yup.boolean(),
  reason: Yup.string().when('no', function(no) {
    if (no) {
      return Yup.string()
        .min(10, 'No mínimo 10 caracteres')
        .required('Campo Obrigatório');
    }
    return this.nullable().optional();
  }),
});

export default function DialogCallContact({ open, toggleDialog, person }) {
  const [loading, setLoading] = useState(false);
  const [yesCallContactCheckbox, setYesCallContactCheckbox] = useState(false);
  const [noCallContactCheckbox, setNoCallContactCheckbox] = useState(false);

  const handleConfirm = async ({ reason, yes, no }) => {
    setLoading(true);
    try {
      if (hasPermission('create_call_contact')) {
        await api.put(`/persons/${person.idCliente}/callContact`, {
          reason,
          no,
          yes,
        });
        toggleDialog();
      }
    } finally {
      setLoading(false);
      setYesCallContactCheckbox(false);
      setNoCallContactCheckbox(false);
    }
  };

  const handleSubmit = async values => {
    await handleConfirm(values);
  };

  const handleYesCheck = () => {
    setYesCallContactCheckbox(document.getElementById('yes').checked);
  };

  const handleNoCheck = () => {
    setNoCallContactCheckbox(document.getElementById('no').checked);
  };

  return (
    <>
      <Dialog open={open} toggleDialog={toggleDialog}>
        <Form schema={schema} onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            O cliente foi contatado ?
          </DialogTitle>
          <DialogContent>
            <>
              <UnCheckbox
                name="yes"
                label="Sim"
                onClick={handleYesCheck}
                disabled={noCallContactCheckbox}
              />
              <UnCheckbox
                name="no"
                label="Não"
                onClick={handleNoCheck}
                disabled={yesCallContactCheckbox}
              />
              <TextField
                autoFocus
                margin="dense"
                id="reason"
                name="reason"
                label="Observação"
                fullWidth
                multiline
                maxRows="4"
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleDialog()} color="secondary">
              Cancelar
            </Button>
            <Button
              disabled={
                (!noCallContactCheckbox && !yesCallContactCheckbox) || loading
              }
              type="submit"
              color="primary"
            >
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

DialogCallContact.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
