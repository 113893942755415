import React, { useState, useRef } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';
import { UnauthorizedGhost, AccessControl } from '~/components';
import Card from './Card';
import ItemTypes from './ItemTypes';

import DialogRoutesApp from '../../../BannersHome/Form/AppRoutesDialog';
import { BannersHomeProvider } from '../../../BannersHome/context';
import { BannersHomePermissionProvider } from '../../../BannersHome/context-permissions';

const style = {
  width: '100%',
  textAling: 'center',
};

export default function Container({
  banners: cards,
  updateDraft,
  onMove,
  draft,
  onUpdate,
  onDelete,
}) {
  const nullRef = useRef();

  const [, drop] = useDrop({ accept: ItemTypes.CARD });
  const [loading, setLoading] = useState(false);
  const [isAppRoutesDialogOpen, setIsAppRoutesDialogOpen] = useState(false);
  const [cardIdDialog, setCardIdDialog] = useState();
  const [appRoute, setAppRoute] = useState({
    id: null,
    route: null,
  });

  const findCard = id => {
    const card = cards.filter(c => c.id === id)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };

  const updateOrder = () => {
    if (hasPermission('sort_promotion_draft_banners')) {
      setLoading(true);
      const data = cards.map(c => c.id);
      api
        .put(`/promotions/drafts/${draft._id}/reorder-banners`, data)
        .then(() => {
          onUpdate(cards);
        })
        .finally(() => setLoading(false));
    }
  };

  const onDrop = () => {
    if (draft.status !== 'approved') updateOrder();
  };

  return (
    <>
      {loading && <CircularProgress />}
      <div ref={loading ? nullRef : drop} style={style}>
        {cards &&
          cards.map(card => (
            <Card
              key={card.id}
              id={card.id}
              url={card.url}
              urlVideoDraft={card.urlPath}
              updateDraft={updateDraft}
              moveCard={onMove}
              findCard={findCard}
              loading={loading}
              appRoutesValue={appRoute}
              onOpenAppRoutes={e => {
                setCardIdDialog(e);
                setIsAppRoutesDialogOpen(true);
              }}
              onDrop={onDrop}
              onDelete={onDelete}
              draft={draft}
            />
          ))}

        <BannersHomePermissionProvider>
          <BannersHomeProvider>
            <AccessControl
              can="read_banners_home"
              renderNoAccess={() => <UnauthorizedGhost />}
              displayError
            >
              <DialogRoutesApp
                open={isAppRoutesDialogOpen}
                toggleDialog={() => setIsAppRoutesDialogOpen(false)}
                onUseRoute={e => setAppRoute({ id: cardIdDialog, route: e })}
              />
            </AccessControl>
          </BannersHomeProvider>
        </BannersHomePermissionProvider>
      </div>
    </>
  );
}

Container.propTypes = {
  banners: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onMove: PropTypes.func.isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateDraft: PropTypes.func.isRequired,
};
