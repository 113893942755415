import React, { useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSweepstakeForm } from './SweepstakeFormProvider';
import { ContainerButton } from './styles';

const BannerFormDialog = ({
  children,
  title,
  okText,
  cancelText,
  onOk,
  onCancel,
  onReturn,
  isReturn,
  formId,
  loadingText,
  loading,
  isConfirmModal,
}) => {
  const {
    formOpened,
    closeForm,
    modalCacheOpened,
    closePromotionCache,
  } = useSweepstakeForm();

  const handleCancel = useCallback(() => {
    if (loading === true) return;
    if (isConfirmModal) {
      closePromotionCache();
    } else {
      closeForm();
    }
    if (typeof onCancel === 'function') onCancel();
  }, [closeForm, onCancel, loading]);

  const handleOk = useCallback(() => {
    if (typeof onOk === 'function') onOk();
  }, [onOk]);

  const handleReturn = useCallback(() => {
    if (typeof onReturn === 'function') onReturn();
  }, [onReturn]);

  return (
    <Dialog
      open={isConfirmModal ? modalCacheOpened : formOpened}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions>
        <ContainerButton isReturn={isReturn}>
          {isReturn && (
            <Button
              onClick={handleReturn}
              type="submit"
              form={formId}
              color="primary"
              disabled={loading}
            >
              Voltar
            </Button>
          )}
          <div>
            <Button onClick={handleCancel} color="secondary" disabled={loading}>
              {cancelText}
            </Button>
            <Button
              onClick={handleOk}
              type="submit"
              form={formId}
              color="primary"
              disabled={loading}
            >
              {loading ? loadingText : okText}
            </Button>
          </div>
        </ContainerButton>
      </DialogActions>
    </Dialog>
  );
};

BannerFormDialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onReturn: PropTypes.func,
  isReturn: PropTypes.bool,
  formId: PropTypes.string,
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
  isConfirmModal: PropTypes.bool,
};

BannerFormDialog.defaultProps = {
  okText: 'Salvar',
  cancelText: 'Cancelar',
  loadingText: 'Salvando...',
  onOk: () => {},
  onCancel: () => {},
  onReturn: () => {},
  isReturn: false,
  formId: '',
  loading: false,
  isConfirmModal: false,
};

export default BannerFormDialog;
