import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const DialogFinancial = ({ open, close, title, children, disabled }) => {
  return (
    <Dialog onClose={close} open={open}>
      <DialogTitle>Gerar liquidação ({title})</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button disabled={disabled} variant="contained" color="primary">
          Gerar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogFinancial.defaultProps = {};

DialogFinancial.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};
