import styled, { css } from 'styled-components';
import { Drawer } from '@material-ui/core';

export const Container = styled(Drawer).attrs({
  variant: 'permanent',
})`
  white-space: nowrap;
  flex-shrink: 0;

  .MuiPaper-root {
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    /* position: relative; */
    white-space: nowrap;
    width: ${({ width }) => width}px;

    ${({ open }) =>
      !open &&
      css`
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        overflow-x: hidden;
        width: 57px;
      `}
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  min-height: 56px;

  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 48px;
  }

  @media (min-width: 600px) {
    min-height: 64px;
  }
`;
