import React, { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import AutocompleteForm from '@material-ui/lab/Autocomplete';

function AutoComplete({ name, onSelect, ...rest }) {
  const [value, setValue] = useState(null);
  const { fieldName, registerField } = useField(name);

  const { options } = rest;

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        getValue() {
          return value ? value.appId : null;
        },
        clearValue() {
          setValue(null);
        },
      });
    }
  }, [fieldName, registerField, value]);

  return (
    <AutocompleteForm
      value={value}
      options={options}
      getOptionLabel={option => option.descricao}
      onChange={(_, newValue) => {
        setValue(newValue);
        onSelect(newValue);
      }}
      renderInput={params => (
        <TextField {...params} label="Aplicações" variant="outlined" />
      )}
    />
  );
}

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};

AutoComplete.defaultProps = {
  onSelect: null,
};

export default AutoComplete;
