import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

function ConfirmPromptModal({
  title,
  visible = false,
  description,
  onCancel,
  onOk,
  okText = 'Continuar',
  cancelText = 'Cancelar',
}) {
  return (
    <Dialog
      open={visible}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button color="primary" onClick={onOk}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPromptModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  description: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
};

ConfirmPromptModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  onOk: () => {},
  cancelText: 'Cancelar',
  okText: 'Continuar',
};

export default ConfirmPromptModal;
