import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Row } from './styles';
import { TextError } from '../styles';

function CheckboxInput({ name, options }) {
  const [checkboxes, setCheckboxes] = useState([]);
  const inputRefs = useRef([]);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    setCheckboxes(options);
  }, [options]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: refs => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: refs => {
        setCheckboxes(prevCheckboxes =>
          prevCheckboxes.map(checkbox => ({ ...checkbox, value: false }))
        );
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        setCheckboxes(prevCheckboxes =>
          prevCheckboxes.map(checkbox => ({
            ...checkbox,
            value: values.includes(checkbox.id),
          }))
        );
        refs.forEach(ref => {
          ref.checked = values.includes(ref.value);
        });
      },
    });
  }, [fieldName, registerField]);

  const handleChange = index => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].value = !newCheckboxes[index].value;
    setCheckboxes(newCheckboxes);
  };

  return (
    <div className="checkbox-container">
      {checkboxes.map((option, index) => (
        <Row key={option.id}>
          <FormControlLabel
            control={
              <Checkbox
                checked={option.value}
                inputRef={ref => {
                  inputRefs.current[index] = ref;
                }}
                value={option.id}
                onChange={() => handleChange(index)}
                id={option.id}
              />
            }
            label={option.label}
          />
        </Row>
      ))}
      <Row>{error && <TextError>{error}</TextError>}</Row>
    </div>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CheckboxInput;
