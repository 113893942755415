import styled, { css } from 'styled-components';
import { Card } from '../../Screenings/components/index';

export const Container = styled.div`
  && {
    margin-top: 20px;
    height: 100px;
    width: 100%;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    transition: 400ms all;

    ${props => {
      return props.status === 1
        ? `background-color: #fff `
        : `background-color: #EDEDED;`;
    }}

    ${props => {
      return (
        props.loading === 1 &&
        css`
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 30;
            background-color: rgba(255, 255, 255, 0.8);
          }
        `
      );
    }}
  }
`;
export const ContainerDropDown = styled(Card)`
  && {
    margin-top: 0px;
    width: 100%;
    min-height: 200px;
    max-height: 100%;
    background-color: #f9f9f9;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: unset !important;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: 400ms all;
    ${props =>
      props.loading === 1 &&
      css`
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 30;
          background-color: rgba(255, 255, 255, 0.8);
        }
      `}
  }
  h5 {
    margin-top: 80px;
    margin-bottom: 10px;

    display: inline;
  }
`;

export const ContainerPopUp = styled.div`
  margin: 20px;
`;

export const LeftContainer = styled.div`
  flex: 1;

  ${props => {
    return props.status === 1 ? `  color: black` : `  color: #757575;`;
  }}

  .card-label-content {
    &.content-wrapper,
    &.label {
      white-space: nowrap;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .upload-area-wrapper + .upload-area-wrapper {
    margin-top: 8px;
  }
  button {
    margin: 0px;
    padding: 0px;
  }
  svg {
    height: 22.50312614440918px;
    width: 22.503124237060547px;
    border-radius: 0px;
  }

  button.collapse-button {
    border: 0px;
    background-color: transparent;
    padding: 0px;
    font-size: 32px;
  }
  button.popup-button {
    border: 0px;
    background-color: transparent;
    padding: 0px;
    font-size: 32px;
  }

  button + span,
  span + button,
  button + button {
    margin-left: 100px;
  }
`;

export const AlignTitles = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MarginButtons = styled.div`
  margin-top: 70px;
`;

export const DataGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px 32px;
`;

export const FormGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px 32px;

  .complemento,
  .notas {
    grid-column: 1 / 4;
  }

  .MuiTextField-root input {
    font-weight: normal;
    text-transform: initial !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 24px 0px;
  padding: 0px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  display: flex;

  ${props => {
    return props.status === 1 ? `  color: black` : `  color: #757575;`;
  }}

  span.MuiIcon-root {
    margin-left: -8px;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px 32px;

  .complemento,
  .notas {
    grid-column: 1 / 4;
  }

  .MuiTextField-root input {
    font-weight: normal;
    text-transform: initial !important;
  }
  background: gray;
`;

export const LogContainer = styled.div`
  .last-updated-by {
    font-size: 14px;
    text-decoration: underline;
    font-style: italic;
    text-align: right;
    width: 100%;
    display: block;
  }
`;
