import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { has, isEmpty } from 'lodash';
import { MdExpandMore } from 'react-icons/md';
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  CircularProgress,
  ExpansionPanel,
  Button,
  Grid,
} from '@material-ui/core';
import { applySpec, prop } from 'ramda';
import api from '~/services/api';
// -- components
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import SessionStorage from '~/helpers/SessionStorage';
import { IoMdHourglass } from 'react-icons/io';
import { WinnersContainer } from './styles';
import SweepstakeWinner from './winner';
import { usePromotionContext } from './context';

export default function SweepstakeDozens({ data: sorteio }) {
  const [totalGanhadores, setTotalGanhadores] = useState();
  const { promotion } = usePromotionContext();

  const [winnersList, setWinnersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { setPagination, ...customPaginator } = useCustomPaginator();
  const hasDozens = has(sorteio, 'dezenas');
  const { idSorteio, tipo } = sorteio;
  const handleListWinners = useCallback(async () => {
    setLoading(true);
    const paginationSpec = applySpec({
      totals: prop('total'),
      limit: prop('limit'),
      page: prop('page'),
      pages: prop('pages'),
    });
    await api
      .get(`/winners/sweepstake/${idSorteio}`, {
        params: {
          page: customPaginator.pagination.page,
          limit: customPaginator.pagination.limit,
          ...(tipo.codigo === 'girosorte' || tipo.codigo === 'girosimples'
            ? { idPromocao: promotion.idPromocao, codigo: tipo.codigo }
            : {}),
        },
      })
      .then(res => {
        setTotalGanhadores(res.data.total);

        if (res.data.docs) {
          setWinnersList(res.data.docs);
        }
        if (res && res.data) {
          setPagination(paginationSpec(res.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    idSorteio,
    customPaginator.pagination.limit,
    customPaginator.pagination.page,
    promotion.idPromocao,
    tipo.codigo,
    setPagination,
  ]);

  function getWinners(winners) {
    if (isEmpty(winners)) {
      return ' ';
    }

    return winners.map(
      ({
        ganhadorProvisorio,
        idGanhador,
        numeroContemplado,
        tituloPromocao,
        status: statusGanhador,
        provisorio,
      }) => (
        <SweepstakeWinner
          key={idGanhador}
          data={{
            ...ganhadorProvisorio,
            idGanhador,
            ...sorteio,
            numeroContemplado,
            tituloPromocao,
            statusGanhador,
            provisorio,
          }}
          updateWinnerList={handleListWinners}
        />
      )
    );
  }

  const handleTriagem = () => {
    SessionStorage.set('triageFilters', {
      // raffle: sorteio.idSorteio,
      raffleType: sorteio.tipo.codigo,
      edition: promotion.extracao,
      search: `sorteio:${sorteio.descricao}`,
    });
    window.open(`/screenings`);
  };

  useEffect(() => {
    handleListWinners();
  }, [handleListWinners]);

  return (
    <>
      {!hasDozens && promotion.triagemHabilitada && (
        <Grid item xs={12} align="right">
          <Button
            disabled={false}
            variant="contained"
            color="primary"
            size="small"
            align="right"
            onClick={() => handleTriagem()}
          >
            <IoMdHourglass />
            Triagem
          </Button>
        </Grid>
      )}

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<MdExpandMore />}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Typography>Contemplados ({totalGanhadores})</Typography>
          )}
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <WinnersContainer>
            {getWinners(winnersList)}
            <CustomPaginator
              {...{ loading, setPagination, ...customPaginator }}
            />
            {loading && <CircularProgress />}
          </WinnersContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

SweepstakeDozens.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
