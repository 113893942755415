import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { hasPermission } from '~/components/AccessControl';

export const BannersHomePermissionContext = createContext({
  canRead: false,
  canCreate: false,
  canUpdate: false,
  canDelete: false,
});

export const BannersHomePermissionProvider = ({ children }) => {
  const canRead = hasPermission('read_banners_home', { toast: false });
  const canCreate = hasPermission('create_banner_home', { toast: false });
  const canUpdate = hasPermission('update_banner_home', { toast: false });
  const canDelete = hasPermission('delete_banner_home', { toast: false });

  return (
    <BannersHomePermissionContext.Provider
      value={{ canRead, canCreate, canUpdate, canDelete }}
    >
      {children}
    </BannersHomePermissionContext.Provider>
  );
};

BannersHomePermissionProvider.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line
};

export const useBannersHomePermission = () => {
  const context = useContext(BannersHomePermissionContext);
  return context;
};
