import React, { useEffect, useCallback, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import FaqsFilters from './FaqsFilters';
import FaqContent from '../FaqContent/index';
import Products from '../FaqList/products';
import * as S from './styles';
import { TitleAndButton, AlignTitles } from './styles';

function FaqMain() {
  const [filters, setFilters] = useState({
    contain: undefined,
    search: undefined,
    status: undefined,
    idProduto: undefined,
  });
  const [open, setOpen] = useState(false);
  const [faqs, setFaqs] = useState({});
  const [pergunta, setPergunta] = useState();
  const [resposta, setResposta] = useState();
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isPageRefresh, setIsPageRefresh] = useState(false);
  const [submitFilter, setSubmitFilter] = useState(false);

  const [preSubmitFilter, setPreSubmitFilter] = useState(true);
  const [posContainerSubmit, setPosContainerSubmit] = useState(1);
  const { setPagination, ...customPaginator } = useCustomPaginator();
  const handleClickOpen = () => {
    setOpen(true);
    setProdutos([]);
  };

  const handleClose = () => {
    setOpen(false);
    setProdutos([]);
  };

  const handleSubmit = async () => {
    if (hasPermission('create_faq')) {
      setIsPageRefresh(true);
      setLoading(true);
      setLoadingPost(true);
      await api.post(`/faqs`, { pergunta, resposta, produtos }).finally(() => {
        setLoading(false);
        setIsPageRefresh(false);
        setLoadingPost(false);
        setPergunta('');
        setResposta('');
        handleClose();
      });
    }
  };

  const paramsSubmit = useCallback(async () => {
    setLoading(true);
    setSubmitFilter(false);
    setPreSubmitFilter(false);

    const params = {
      page: customPaginator.pagination.page,
      limit: filters.idProduto ? 9999 : customPaginator.pagination.limit,
      ...(filters.idProduto ? { id: filters.idProduto } : {}),
      ...(filters.contain || filters.contain === 0
        ? { contain: filters.contain }
        : {}),
      ...(filters.search ? { search: filters.search } : {}),
      ...(filters.status || filters.status === 0
        ? { status: filters.status }
        : {}),
    };
    const { data } = await api.get(`/faqs`, { params }).finally(() => {
      setLoading(false);
      setSubmitFilter(true);
      setPreSubmitFilter(!!filters.idProduto);
      setPosContainerSubmit(filters.contain);
    });

    if (data) {
      setFaqs(data);
      const { page, pages, total } = data;
      setPagination({
        ...{
          page,
          pages,
          totals: total,
        },
      });
    }
  }, [
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    filters,
    setPagination,
  ]);

  useEffect(() => {
    paramsSubmit();
  }, [paramsSubmit, isPageRefresh]);

  const updateFilters = useCallback(
    value => {
      setPagination({ page: 1 });
      setFilters(value);
    },
    [setPagination]
  );

  return (
    <>
      <S.Container>
        <TitleAndButton>
          <S.Title>FAQ (Perguntas Frequentes)</S.Title>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
          >
            + NOVA
          </Button>
        </TitleAndButton>
        <S.FilterContainer>
          <FaqsFilters updateFilters={updateFilters} />
        </S.FilterContainer>
      </S.Container>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        style={{ padding: 10 }}
      >
        <DialogTitle>
          <AlignTitles>
            <h5>Nova pergunta</h5>
          </AlignTitles>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Pergunta:</DialogContentText>
          <TextField
            id="outlined-basic"
            label={null}
            variant="outlined"
            placeholder="Digite sua pergunta"
            onChange={event => {
              setPergunta(event.target.value);
            }}
            value={pergunta}
          />
          <DialogContentText>Resposta:</DialogContentText>
          <TextField
            id="outlined-basic"
            label={null}
            multiline
            rows={6}
            variant="outlined"
            placeholder="Digite sua resposta"
            onChange={event => {
              setResposta(event.target.value);
            }}
            value={resposta}
          />
          <Grid md={12}>
            <Products
              onCatchProducts={value => {
                setProdutos(value);
              }}
              onChange={selectedProducts => {
                setProdutos(selectedProducts);
              }}
            />
          </Grid>
          <Grid align="end" style={{ padding: 20 }}>
            <Button size="normal" disabled={loadingPost} onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              size="normal"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={
                loadingPost || !pergunta || !resposta || produtos.length === 0
              }
            >
              {loadingPost ? (
                <CircularProgress color="white" size={24} />
              ) : (
                'Salvar'
              )}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      {loading ? (
        <CircularProgress color="white" style={{ marginLeft: 800 }} size={35} />
      ) : (
        <FaqContent
          posContainerSubmit={posContainerSubmit}
          faqs={faqs.docs}
          containerCheck={filters.contain}
          idProduto={filters.idProduto}
          preSubmitFilter={preSubmitFilter}
          handleParamsSubmit={paramsSubmit}
          customPage={customPaginator.pagination.page}
          customLimit={customPaginator.pagination.limit}
          submitFilter={submitFilter}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      <Grid>
        <Grid align="right">
          {!filters.idProduto && (
            <CustomPaginator
              {...{ loading, setPagination, ...customPaginator }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

FaqMain.propTypes = {
  onFilter: PropTypes.func,
};

FaqMain.defaultProps = {
  onFilter: () => {},
};

export default FaqMain;
