import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const ColorColor = styled.div`
  width: 36px;
  /* height: 14px; */
  border-radius: 2px;
  background: ${props => props.color};
  padding-left: 40px;
`;

export const ColorSwatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const ColorPopover = styled.div`
  position: absolute;
  z-index: 2;
`;

export const ColorCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const PhotoContainer = styled.div`
  display: block;
`;

export const PhotoImage = styled.figure`
  max-width: 100%;
  max-height: auto;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: top center;
  background-image: url("${({ url }) => url}");
  box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.15);
  border: 3px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px;
`;
