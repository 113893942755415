import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { Button } from '@material-ui/core';
import { FaCamera } from 'react-icons/fa';

import NO_IMAGE from '~/assets/no-image.png';
import NO_IMAGE_BALLOON from '~/assets/ballon-image-empty.png';

import BannerImage from '../BannerItem/BannerImage';
import { ImageFieldContainer } from './styles';
import { getImagePreview } from './helpers';

const BannerFormImageField = ({
  src = null,
  title = '',
  onChange,
  isBalloon,
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  useUpdateEffect(() => {
    setImageSrc(src);
  }, [src]);

  const onDrop = useCallback(
    async files => {
      const preview = await getImagePreview(files[0]);
      onChange(files[0]);
      setImageSrc(preview);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  return (
    <ImageFieldContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isBalloon ? (
        <BannerImage
          isBallon
          src={imageSrc || NO_IMAGE_BALLOON}
          title={title}
        />
      ) : (
        <BannerImage src={imageSrc || NO_IMAGE} title={title} />
      )}

      <Button type="button" fullWidth variant="outlined">
        <FaCamera style={{ marginRight: 8 }} /> Trocar
      </Button>
    </ImageFieldContainer>
  );
};

BannerFormImageField.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  isBalloon: PropTypes.bool,
};

BannerFormImageField.defaultProps = {
  src: null,
  title: '',
  onChange: () => {},
  isBalloon: false,
};

export default React.memo(BannerFormImageField);
