import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Grid } from '@material-ui/core';

export default function DialogConfirmUrl({ open, toggleDialog, onSubmit }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">🚨 ATENÇÃO 🚨</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong> Verifique e Teste os Links:</strong>
          </DialogContentText>
          <Grid container style={{ paddingLeft: 35 }}>
            <ul>
              <li>
                <DialogContentText>
                  Páginas Internas/Externas: Certifique-se de que estão
                  acessíveis.
                </DialogContentText>
              </li>
              <li>
                <DialogContentText>
                  Vídeos do YouTube/Lives: Assegure-se de que estão disponíveis.
                  Vídeos inválidos não serão reproduzidos no app.
                </DialogContentText>
              </li>
              <li>
                <DialogContentText>
                  {' '}
                  Deeplinks: Verifique se funcionam tanto para abrir o app para
                  direcionar para a loja.
                </DialogContentText>
              </li>
            </ul>
          </Grid>
          <DialogContentText>
            <strong>Importante:</strong>
          </DialogContentText>
          <Grid container style={{ paddingLeft: 35 }}>
            <ul>
              <li>
                <DialogContentText>
                  Deeplinks podem não funcionar em todos os dispositivos, tenha
                  cuidado ao utilizá-los.
                </DialogContentText>
              </li>
              <li>
                <DialogContentText>
                  Links temporários devem ser validados e conferidos antes da
                  ativação.
                </DialogContentText>
              </li>
            </ul>
          </Grid>
          <DialogContentText>
            ⚠️ Ao ignorar essas conferências, impactos negativos à imagem do
            produto e a qualidade do serviço poderão ocorrer, e essa
            responsabilidade é sua!
          </DialogContentText>
          <DialogContentText>
            Se tiver dúvidas, contate o seu gestor!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              toggleDialog(false);
              onSubmit();
            }}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogConfirmUrl.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
