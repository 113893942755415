import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import List from './List';
import Upload from './upload';
import UploadImageCapa from './uploadCapa';
import Card from './CardImageCapa';

export default function Banner({ draft, updateDraft, setNextStep }) {
  const [cards, setCards] = useState(
    (draft.data &&
      draft.data.banners &&
      draft.data.banners.sort((a, b) => a.order - b.order)) ||
      []
  );

  const [imgCapa, setImgCapa] = useState(
    draft.data.promotion.imagemCapa || null
  );

  useEffect(() => {
    setNextStep(
      ((cards && cards.length) || draft.status === 'draft') && draft._id
    );
  }, [cards, draft._id, draft.status, setNextStep]);

  const onUploadCapa = useCallback(
    imageCapa => {
      setImgCapa(imageCapa.data.promotion.imagemCapa);
      updateDraft(imageCapa);
    },
    [updateDraft]
  );

  const handleOnDelete = item => {
    setImgCapa(null);
    updateDraft(item);
  };

  const onUpload = useCallback(
    card => {
      setCards(_cards => [..._cards, card]);
      const d = { ...draft };
      d.data.banners = [...cards, card];
      updateDraft(d);
    },
    [setCards, cards, draft, updateDraft]
  );

  const onUpdate = useCallback(
    _cards => {
      const d = { ...draft };
      d.data.banners = _cards.map((c, index) => ({
        ...c,
        order: index + 1,
      }));
      updateDraft(d);
    },
    [draft, updateDraft]
  );

  const onDelete = useCallback(
    id => {
      setCards(draft.data.banners.filter(c => c.id !== id));
      const d = { ...draft };
      d.data.banners = draft.data.banners.filter(c => c.id !== id);
      updateDraft(d);
    },
    [draft, updateDraft]
  );

  const onMove = useCallback(
    (id, index) => {
      if (draft.status !== 'approved') {
        const cardIdx = cards.findIndex(currentCard => {
          return currentCard.id === id;
        });

        const card = cards[cardIdx] ? { ...cards[cardIdx] } : null;

        if (card) {
          setCards(() => {
            return update(cards, {
              $splice: [
                [cardIdx, 1],
                [index, 0, card],
              ],
            });
          });
        }
      }
    },
    [setCards, cards, draft.status]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <h3 style={{ marginLeft: 8, marginBottom: 10 }}>
            IMAGENS PARA BANNERS
          </h3>
          {draft.status !== 'approved' && (
            <Upload onUpload={onUpload} draftId={draft._id} />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {cards && (
            <div style={{ marginTop: 30 }}>
              <List
                banners={cards}
                onMove={onMove}
                draft={draft}
                updateDraft={updateDraft}
                onUpdate={onUpdate}
                onDelete={onDelete}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <hr style={{ marginTop: 30, marginBottom: 30 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <h3 style={{ marginLeft: 10, marginBottom: 10 }}>
            CAPA PARA PROMOÇÃO
          </h3>
          {draft.status !== 'approved' && (
            <UploadImageCapa onUpload={onUploadCapa} draftId={draft._id} />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {imgCapa && (
            <div style={{ marginTop: 30 }}>
              <Card draft={draft} onDelete={handleOnDelete} url={imgCapa} />
            </div>
          )}
        </Grid>
      </Grid>
      <hr style={{ marginTop: 30, marginBottom: 30 }} />
    </>
  );
}

Banner.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
};
