import {
  parse as parseDate,
  isValid,
  format as formatDate,
  parseISO,
} from 'date-fns';

/**
 * Check date is from brazil format.
 *
 * @param {String} date
 * @param {Boolean} returnFormat Return matched format.
 *
 * @return {Boolean|String} return the format if `returnFormat` is true.
 */
export function isBrazilDate(date, returnFormat = false) {
  const formats = [
    { format: 'dd/MM/yyyy HH:mm', pattern: /[0-9]{4}\s[0-9]{2}:[0-9]{2}$/i },
    {
      format: 'dd/MM/yyyy HH:mm:ss',
      pattern: /[0-9]{4}\s[0-9]{2}:[0-9]{2}:[0-9]{2}$/i,
    },
    { format: 'dd/MM/yyyy', pattern: /[0-9]{4}$/i },
    { format: 'MM/yy', pattern: /[0-9]{2}\/[0-9]{2}$/ },
  ];

  const dateValid = formats.filter(({ format, pattern }) => {
    const isValidDate = isValid(parseDate(date, format, Date.now()));
    const isValidFormat = pattern.test(String(date));

    return isValidDate && isValidFormat;
  });

  return returnFormat === true && !!dateValid.length
    ? dateValid[0]
    : !!dateValid.length;
}

/**
 * Check date is from international format.
 *
 * @param {String} date
 * @param {Boolean} returnFormat Return matched format.
 *
 * @return {Boolean|String} return the format if `returnFormat` is true.
 */
export function isInternationalDate(date, returnFormat = false) {
  const formats = [
    {
      format: 'yyyy-MM-dd HH:mm',
      pattern: /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/i,
    },
    { format: 'yyyy-MM-dd', pattern: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i },
  ];

  const dateValid = formats.filter(({ format, pattern }) => {
    const isValidDate = isValid(parseDate(date, format, Date.now()));
    const isValidFormat = pattern.test(String(date));

    return isValidDate && isValidFormat;
  });

  return returnFormat === true && !!dateValid.length
    ? dateValid[0]
    : !!dateValid.length;
}

/**
 * Parse date.
 *
 * @param {String} date
 */
export function parse(date, format = null) {
  let parsedDate;

  const { format: brazilFormat } = isBrazilDate(date, true);
  const { format: internationalFormat } = isInternationalDate(date, true);

  if (brazilFormat !== undefined) {
    parsedDate = parseDate(date, brazilFormat, Date.now());
  }

  if (internationalFormat !== undefined) {
    parsedDate = parseDate(date, internationalFormat, Date.now());
  }

  if (isValid(parseISO(date))) {
    parsedDate = parseISO(date);
  }

  if (parsedDate) {
    if (format) {
      return formatDate(parsedDate, format);
    }

    return parsedDate;
  }

  return new Date(NaN);
}

/**
 * Yup transformer
 *
 * @param {String} format
 *
 * @returns {Function}
 */
export function yupDateTransformer(format) {
  return function transform(value) {
    return this.isType(value) && value !== null ? parse(value, format) : value;
  };
}
