import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import formatCPF from '@brazilian-utils/format-cpf';
import { format, parseISO } from 'date-fns';
import { MdEdit } from 'react-icons/md';

import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
} from '@material-ui/core';

import { UnauthorizedGhost } from '~/components';
import AccessControl, { hasPermission } from '~/components/AccessControl';

import {
  DetailsContainer,
  DetailsContent,
  FeedbackAvatar,
  FeedbackAvatarImage,
  FeedbackDetails,
  FeedbackAuthor,
  StatusBall,
} from './styles';

import FeedbackForm from './feedbackForm';
import FeedbackContent from './feedbackContent';

import useFeedback from './useFeedback';

export default function Feedback({
  onFeedbackUpdated,
  onFeedbackPersonUpdated,
  onPersonEditClick,
  ...feedback
}) {
  // -- desctruturing
  const { id, status, titulo, descricao, data, pessoa } = feedback;
  const { nome, cpf, avatar, nomeSocial } = pessoa;

  // -- permissions
  const canEdit = useMemo(
    () => hasPermission('update_winner_feedback', { toast: false }),
    []
  );

  const canEditPerson = useMemo(
    () => hasPermission('update_person', { toast: false }),
    []
  );

  const {
    isEditing,
    toggleEditingMode,
    handleFeedbackUpdate,
    handleFeedbackStatusUpdate,
    handlePersonFeedbackUpdate,
    loading,
  } = useFeedback({ ...feedback, onFeedbackUpdated, onFeedbackPersonUpdated });

  const isApproved = status === 'ativado';
  const isDisapproved = status === 'desativado';

  const feedbackProps = {
    ...feedback,
    isApproved,
    isDisapproved,
    loading,
  };

  function getExcerpt(value, max = 30) {
    if (value) return `${String(value).substr(0, max)}...`;
    return '----';
  }

  function getAvatar() {
    const isAvatar = !isEmpty(avatar) && /^https?:\/\//g.test(avatar);
    return isAvatar
      ? avatar
      : `https://ui-avatars.com/api/?size=150&name=${nome}`;
  }

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <Grid container spacing={2}>
          <Grid item style={{ width: 120 }}>
            <h3>{String(id).padStart(7, '0')}</h3>
          </Grid>

          <Grid item xs={12} md={3}>
            <FeedbackAuthor>
              <StatusBall status={status} /> {nomeSocial || nome || '----'}
            </FeedbackAuthor>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography>{getExcerpt(titulo, 15)}</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography>{getExcerpt(descricao, 30)}</Typography>
          </Grid>

          <Grid item style={{ width: 170 }}>
            <Typography>
              {data && format(parseISO(data), 'dd/MM/yyyy HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>

      <DetailsContainer>
        <AccessControl
          can="read_winner_feedback"
          displayError
          renderNoAccess={UnauthorizedGhost}
        >
          <DetailsContent>
            <FeedbackAvatar>
              <FeedbackAvatarImage
                url={getAvatar()}
                onClick={() => window.open(getAvatar())}
              />
              {canEditPerson && cpf && (
                <Typography
                  onClick={() => handlePersonFeedbackUpdate(feedback)}
                >
                  {`CPF: ${formatCPF(cpf)}`} <MdEdit />
                </Typography>
              )}

              {canEditPerson && !cpf && (
                <Typography
                  onClick={() => handlePersonFeedbackUpdate(feedback)}
                >
                  Informações Pessoais <MdEdit />
                </Typography>
              )}
            </FeedbackAvatar>

            <FeedbackDetails>
              {/* Form to editing */}
              {canEdit && isEditing && (
                <FeedbackForm
                  {...feedbackProps}
                  onSave={handleFeedbackUpdate}
                  onCancel={toggleEditingMode}
                />
              )}

              {!isEditing && (
                <FeedbackContent
                  {...feedbackProps}
                  canEdit={canEdit}
                  onEdit={(...args) => canEdit && toggleEditingMode(...args)}
                  onApprove={() => handleFeedbackStatusUpdate(1)}
                  onDisapprove={() => handleFeedbackStatusUpdate(0)}
                />
              )}
            </FeedbackDetails>
          </DetailsContent>
        </AccessControl>
      </DetailsContainer>
    </ExpansionPanel>
  );
}

Feedback.defaultProps = {
  titulo: '',
  descricao: '',
  data: '',
  status: 'ativado',
  pessoa: {},
  onFeedbackUpdated: () => {},
  onFeedbackPersonUpdated: () => {},
  onPersonEditClick: () => {},
};

Feedback.propTypes = {
  id: PropTypes.number.isRequired,
  titulo: PropTypes.string,
  descricao: PropTypes.string,
  data: PropTypes.string,
  pessoa: PropTypes.shape({
    id: PropTypes.number,
    nome: PropTypes.string,
    cpf: PropTypes.string,
    avatar: PropTypes.string,
    provisorio: PropTypes.bool,
  }),
  status: PropTypes.oneOf(['ativado', 'desativado']),
  onFeedbackUpdated: PropTypes.func,
  onFeedbackPersonUpdated: PropTypes.func,
  onPersonEditClick: PropTypes.func,
};
