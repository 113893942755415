import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';

export const UploadFile = styled.input`
  width: 148px;

  &&::-webkit-file-upload-button {
    visibility: hidden;
  }
  &&::before {
    content: 'Selecione o arquivo';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  &&:hover::before {
    border-color: black;
  }
  &&:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

export const DropZone = styled.div`
  && {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
  && {
    :focus {
      border-color: #2196f3;
    }
`;

export const DropContainer = styled.section`
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const PhotoContainer = styled.div`
  display: block;
`;

export const PhotoImage = styled.figure`
  max-width: 100%;
  max-height: auto;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: top center;
  background-image: url("${({ url }) => url}");
  box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.15);
  border: 3px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewTab = styled(Tab)`
  && {
    .MuiTab-wrapper {
      align-items: flex-start;
    }
    span {
      text-align: left;
    }
  }
`;
