import styled from 'styled-components';
import { TableRow, TableHead } from '@material-ui/core';
import { Form } from '@rocketseat/unform';

export const STableHead = styled(TableHead)`
  background-color: #3f51b5;
  height: 48px;
  color: white;
`;

export const STableRow = styled(TableRow)`
  text-align: left;
  border: 1px solid #e4e4e4;

  td,
  th {
    width: auto;
    padding: 4px;
    font-size: 1rem;
    padding-left: 18px;
  }

  :hover {
    background-color: '#e4e4e4';
    cursor: pointer;
  }
`;

export const MoreRoles = styled.span`
  padding: 6px;
  background-color: #7986cb;
  color: white;
  border-radius: 20px;
`;

export const Roles = styled.span`
  padding: 6px;
  background-color: ${({ backgrounded }) => backgrounded};
  border-radius: 20px;
`;

export const FiltersForm = styled(Form)`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 15px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;
