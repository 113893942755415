import * as Yup from 'yup';
import { yupDateTransformer } from '~/helpers/date';
import { isValid as isValidDate } from 'date-fns';

export const promotionDraftFormSchema = Yup.object().shape({
  data: Yup.object().shape({
    promotion: Yup.object().shape({
      dataReferencia: Yup.mixed()
        .required('Campo obrigatório')
        .transform(yupDateTransformer())
        .test('is-date', 'Data Inválida', isValidDate),
      tituloPromocao: Yup.string().required('Campo obrigatório'),
      valorProduto: Yup.string().required('Campo obrigatório'),
      // eslint-disable-next-line func-names
      categoria: Yup.string().when('$produto', function(produto) {
        if (produto?.cod === 'instantaneo') {
          return this.nullable().optional();
        }
        return this.required('Campo obrigatório');
      }),
      numeroSerie: Yup.string()
        .nullable()
        .optional(),
      horarioSorteio: Yup.string()
        .required('Campo obrigatório')
        .matches(
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
          'Horário inválido'
        ),
      dataVigenciaInicio: Yup.mixed()
        .notRequired()
        .transform(yupDateTransformer()),
      dataVigenciaFim: Yup.mixed()
        .notRequired()
        .transform(yupDateTransformer()),
      idInstituicao: Yup.string().nullable(),
      idCgSusep: Yup.string()
        .notRequired()
        .nullable(),
      qtdMinimaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdMaximaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdSugerida1: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdSugerida2: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
    }),
    // eslint-disable-next-line func-names
    file: Yup.mixed().when('$produto', function(produto) {
      if (produto?.cod === 'instantaneo') {
        this.test('is-file', 'Você deve anexar um arquivo', file => {
          return file instanceof File;
        });
      }
    }),
  }),
});
