import styled from 'styled-components';

export const Container = styled.section`
  & + & {
    margin-top: 12px;
  }

  .screening-content {
    margin-top: -8px;
    position: relative;
    z-index: 10;
  }
`;
