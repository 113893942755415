import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  font-weight: normal;
`;

export const FilterContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 17% 10% 10% 15%;
  grid-gap: 12px;

  .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  min-height: 56px;

  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 48px;
  }

  @media (min-width: 600px) {
    min-height: 64px;
  }
`;
