import React, { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import AutocompleteForm from '@material-ui/lab/Autocomplete';
import { TextError } from '../styles';

function AutoComplete({ name, defaultValue, ...rest }) {
  const [value, setValue] = useState(defaultValue || '');
  const { fieldName, registerField, error } = useField(name);

  const { options } = rest;

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        getValue() {
          return value ? value.idProduto : '';
        },
        clearValue() {
          setValue('');
        },
      });
    }
  }, [fieldName, registerField, value]);

  return (
    <AutocompleteForm
      value={value}
      options={options}
      getOptionLabel={option => option.name || ''}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      disabled={rest.disabled}
      renderInput={params => (
        <div>
          <TextField
            {...params}
            error={!!error}
            label="Produto"
            variant="outlined"
          />
          {error && <TextError>{error}</TextError>}
        </div>
      )}
    />
  );
}

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.objectOf({}),
  onSelect: PropTypes.func,
};

AutoComplete.defaultProps = {
  onSelect: null,
  defaultValue: null,
};

export default AutoComplete;
