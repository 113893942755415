import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { Form } from '@unform/web';

export const Container = styled(Paper)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  padding: 30px;
  grid-gap: 30px;
`;

export const FormContainer = styled(Form)``;

export const Row = styled.div`
  display: grid;
  grid-gap: 20px;

  ${({ cols }) =>
    cols &&
    css`
      grid-template-columns: repeat(${cols}, 1fr);
    `}

  & + & {
    margin-top: 15px;
  }
`;

export const Col = styled.div`
  .MuiFormControl-root + .MuiFormControl-root {
    margin-top: 15px;
  }
`;

export const SectionTitle = styled.h1`
  margin: 0px;
  padding: 0px;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
  align-items: center;
  display: flex;

  &:not(:first-child) {
    margin-top: 52px;
  }
`;

export const ApplicationTable = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 20px;
`;

export const ApplicationItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #f2f2f2;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  header span.name {
    text-transform: uppercase;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  header svg {
    font-size: 18px;
    cursor: pointer;
  }

  ul.items {
    margin: 20px 0px 0px 0px;
    padding: 0px;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      font-style: italic;

      svg {
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #f2f2f2;

  span {
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  span svg {
    cursor: pointer;
    font-size: 22px;
  }

  span.delayUntil > svg {
    margin-left: 10px;
  }

  span.name > svg {
    margin-right: 10px;
  } */
`;
