import React from 'react';
import PropType from 'prop-types';

import { Container } from './styles';

function Card({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Card.propTypes = {
  children: PropType.node.isRequired,
};

export default Card;
