import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const ContainerInfos = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const Title = styled.div`
  display: flex;
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const Thumbnail = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
