const prefix = 'sac-apcap__';
export default {
  get: item => {
    return JSON.parse(sessionStorage.getItem(`${prefix}${item}`));
  },

  set: (item, value) => {
    sessionStorage.setItem(`${prefix}${item}`, JSON.stringify(value));
  },
};
