import React, { createContext, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

export const SweepstakeFormContext = createContext({
  data: {},
  addNewSweepstake: () => {},
  editSweepstake: () => {},
  closeForm: () => {},
  resetPromotionCache: () => {},
  formOpened: false,
});

export const SweepstakeFormProvider = ({ children }) => {
  const [formOpened, setFormOpened] = useState(false);
  const [modalCacheOpened, setModalCacheOpened] = useState(false);
  const [data, setData] = useState({});

  const addNewSweepstake = useCallback(additionalData => {
    setFormOpened(true);
    setData(additionalData);
  }, []);

  const editSweepstake = useCallback(sweepstakeData => {
    setData(oldData => ({ ...oldData, ...sweepstakeData }));
    setFormOpened(true);
  }, []);

  const closeForm = useCallback(() => {
    setFormOpened(false);
    setData({});
  }, []);

  const resetPromotionCache = useCallback(() => {
    setModalCacheOpened(true);
  }, []);

  const closePromotionCache = useCallback(() => {
    setModalCacheOpened(false);
  }, []);

  return (
    <SweepstakeFormContext.Provider
      value={{
        data,
        formOpened,
        modalCacheOpened,
        addNewSweepstake,
        editSweepstake,
        closeForm,
        closePromotionCache,
        resetPromotionCache,
      }}
    >
      {children}
    </SweepstakeFormContext.Provider>
  );
};

SweepstakeFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSweepstakeForm = () => {
  const context = useContext(SweepstakeFormContext);
  return context;
};
