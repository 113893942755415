import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  font-weight: normal;
`;

export const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AlignTitles = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 15px;

  .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
