import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { NoRecord, AccessControl, UnauthorizedGhost } from '~/components';
import history from '~/services/history';
import { Header } from '~/pages/_Layouts/Backoffice/Sidebar/styles';
import CustomPaginator from '~/components/CustomPaginator';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import {
  MdEditNote,
  MdPauseCircleFilled,
  MdPlayCircleFilled,
} from 'react-icons/md';
import { FiltersContainer } from '~/pages/Backoffice/PromotionCode/List/styles';
import { Link } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import DeleteDialog from '~/pages/Backoffice/PromotionCode/Dialog/deleteDialog';
import api from '~/services/api';
import PromotionCodes from './promotionCodes';

import usePromotionCodeList from './usePromotionCodeList';
import PromotionCodesHeader from './header';

function renderQuantity(item) {
  const { quantidadeCriada, limiteValidacao, usos } = item;
  return (
    <Typography>
      {limiteValidacao === null ? 'Ilimitado' : quantidadeCriada}
      <br />
      {usos} Usados
    </Typography>
  );
}

function renderStatus(item) {
  if (item.status === 'ativo') {
    return 'Ativo';
  }

  return 'Inativo';
}

function renderRestrictions(item) {
  if (!item.validacao) {
    return 'Nenhuma';
  }

  if (item.validacao.dataHoraInicio) {
    return 'Produto e validade';
  }

  return 'Produto';
}

export default function PromotionalCodes() {
  const { ...customPagination } = useCustomPaginator();
  const {
    promotionCodes,
    loading,
    setLoading,
    sendFilters,
  } = usePromotionCodeList(customPagination);
  const [selectedPromotionCode, setSelectedPromotionCode] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event, compra) => {
    setAnchorElMenu(event.currentTarget);
    setSelectedPromotionCode(compra);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleUpdatePromotionCodeStatus = async status => {
    setLoading(true);
    try {
      await api.patch(
        `/promotion-codes/toggle-status/${selectedPromotionCode.idCodigoPromocional}`,
        {
          status,
        }
      );
    } finally {
      handleCloseMenu();
      window.location.reload();
    }
  };

  const renderOptionsMenu = () => {
    if (!selectedPromotionCode) return '';

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={() => handleCloseMenu()}
      >
        <AccessControl can="update_promotion_codes">
          <MenuItem
            onClick={() => {
              history.push(
                `/promotion-codes/form/${selectedPromotionCode.idCodigoPromocional}`
              );
            }}
          >
            <MdEditNote size={25} />
            &nbsp;Editar
          </MenuItem>
        </AccessControl>
        {selectedPromotionCode.status === 'ativo' && (
          <MenuItem onClick={() => handleUpdatePromotionCodeStatus('inativo')}>
            <MdPauseCircleFilled size={20} />
            &nbsp;Desativar
          </MenuItem>
        )}
        {selectedPromotionCode.status === 'inativo' && (
          <MenuItem onClick={() => handleUpdatePromotionCodeStatus('ativo')}>
            <MdPlayCircleFilled size={20} />
            &nbsp;Reativar
          </MenuItem>
        )}
        <DeleteDialog
          style={{ padding: 12, minWidth: 0 }}
          promotionCode={selectedPromotionCode}
        >
          {loading ? <CircularProgress size={12} /> : <FaTrash />}
        </DeleteDialog>
      </Menu>
    );
  };

  return (
    <AccessControl
      can="read_promotion_codes"
      displayError
      renderNoAccess={UnauthorizedGhost}
    >
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={11}>
            <Header>
              <h1 style={{ fontWeight: 400, fontSize: 24, marginBottom: 20 }}>
                Códigos Promocionais
              </h1>
            </Header>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="none"
              label="Buscar por código"
              type="text"
              onChange={event => {
                sendFilters({ hash: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FiltersContainer>
              <AccessControl can="create_promotion_codes">
                <Grid container spacing={2}>
                  <Grid item xs={12} align="right">
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Link to="/promotion-codes/form">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Novo Código Promocional
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </AccessControl>
            </FiltersContainer>

            {!promotionCodes.length && !loading && (
              <NoRecord message="Nenhum código promocional encontrado" />
            )}

            {loading && (
              <Typography align="center" variantMapping={{ body1: 'div' }}>
                <CircularProgress />
              </Typography>
            )}

            {!!promotionCodes.length && <PromotionCodesHeader />}

            {promotionCodes.map(promotionCode => (
              <PromotionCodes
                key={String(promotionCode.id)}
                promotionCode={promotionCode}
                renderQuantity={renderQuantity}
                renderStatus={renderStatus}
                renderRestrictions={renderRestrictions}
                renderOptionsMenu={renderOptionsMenu}
                handleClickMenu={handleClickMenu}
              />
            ))}
          </Grid>

          <CustomPaginator {...{ loading, ...customPagination }} />
        </Grid>
      </div>
    </AccessControl>
  );
}
