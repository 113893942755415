import styled, { css } from 'styled-components';
import {
  Switch as BaseSwitch,
  IconButton as BaseIconButton,
} from '@material-ui/core';

export const Container = styled.article`
  padding: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 100%;
  min-width: 0;
  min-height: 0;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;
  position: relative;


  ${({ withAnimation }) =>
    withAnimation === true &&
    css`
      &:hover {
        transform: translateY(-10px);
      }
    `}

  ${({ semiTransparent }) =>
    semiTransparent === true &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: 5px;
        z-index: 2;
      }

      header .actions,
      .MuiSwitch-root {
        position: relative;
        z-index: 3;
      }
    `}

  ${({ transparent }) =>
    transparent === true &&
    css`
      background-color: transparent;
    `}

  ${({ dashed }) =>
    dashed === true &&
    css`
      border: 1px dashed rgba(0, 0, 0, 0.45);
    `}

  header {
    display: flex;
    margin-top: 5px;
    width: 100%;
    align-items: center;

    div.product-name {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    div.actions {
      display: flex;
      align-items: center;
      align-self: flex-end;
      margin-left: 15px;

      button {
        & + button {
          margin-left: 5px;
        }
      }
    }
  }
`;

export const StatusManagement = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
`;

export const Switch = styled(BaseSwitch)`
  && {
    width: 28px;
    height: 16px;
    padding: 0px;
    display: flex;

    .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
      box-shadow: none;
    }

    .MuiSwitch-switchBase {
      padding: 2px;

      &.Mui-checked {
        transform: translateX(12px);
      }
    }
  }
`;

export const IconButton = styled(BaseIconButton).attrs({
  size: 'small',
})``;

export const ValidityPreview = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  div.range {
    margin-left: auto;
    display: flex;
    flex-direction: column;

    p {
      font-size: 13px;
      margin: 0px 0px 5px 0px;
      padding: 0px;
      font-weight: 500;
      text-align: right;
    }
  }
`;

export const PreloadContainer = styled.picture`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
`;

export const BannerImagePreview = styled.img`
  max-width: 100%;
`;

export const LoadingPlaceholderContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  left: 0;
  z-index: 99;
  top: 0px;
  border-radius: 5px;
  display: none;

  ${props =>
    props.displayPlaceholder &&
    css`
      display: flex;
    `}
`;

export const BallonImagePreview = styled.img`
  width: 65px;
  height: 90px;
  position: absolute;
  margin-top: -18px;
`;

export const ContainerBallon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
