import React from 'react';

import PropTypes from 'prop-types';
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import { Title, Content } from './styles';

function Detail({ data, onClose }) {
  const {
    protocolo,
    atendente,
    agencia,
    nomeAgencia,
    codDiretoria,
    diretoria,
    uf,
    municipio,
  } = data;

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <Content style={{ flexDirection: 'column' }}>
        <Title>
          <b>Informações do atendimento #{protocolo}</b>
        </Title>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                <h3>Atendente:</h3>
                {atendente}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                <h3>Agência:</h3>
                {agencia}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                <h3>Nome da Agência:</h3>
                {nomeAgencia}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                <h3>Código diretoria:</h3>
                {codDiretoria}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                <h3>Diretoria:</h3>
                {diretoria}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ color: '#000' }}>
                <h3>Município:</h3>
                {`${municipio} / ${uf}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Content>
    </Dialog>
  );
}

export default Detail;

Detail.defaultProps = {
  onClose: () => {},
  data: {},
};

Detail.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.shape(),
};
