import styled from 'styled-components';

export const Container = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ItemContainer = styled.button`
  all: unset;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  color: ${({ color }) => color};
`;
