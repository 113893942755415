import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default function TextMaskQuota(props) {
  const { inputRef, ...other } = props;
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    decimalLimit: 4,
    integerLimit: 3,
    allowDecimal: true,
    decimalSymbol: ',',
    includeThousandsSeparator: false,
  });

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
      placeholderChar="_"
      showMask={false}
    />
  );
}

TextMaskQuota.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
