import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, isAfter, isBefore, parseISO, isValid } from 'date-fns';
import { get } from 'lodash';
import {
  CircularProgress,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  FormControl,
} from '@material-ui/core';
import { FaCalendar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import api from '~/services/api';
import { IDatePicker, ProductAutocomplete } from '~/components';
import NoRecord from '~/components/NoRecord';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import ChargeBackButton from './ChargeBackButton';
import ConfirmationMenu from '../Purchase/List/confirmationMenu';
import { GridCustom, Product } from './styles';
import { CardCoupon } from './cardCoupon';

export default function Log({ person }) {
  const [result, setResult] = useState([]);
  const [buyDate, setBuyDate] = useState(null);
  const [raffleDate, setRaffleDate] = useState(null);
  const [statusType, setStatusType] = useState('');
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationMenu, setConfirmationMenu] = useState({});
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const getCoupons = async () => {
    setLoading(true);
    try {
      if (hasPermission('read_coupons')) {
        const { data } =
          (await api.get(
            `/persons/${person.idCliente}/coupons?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`,
            {
              params: {
                buyDate,
                raffleDate,
                statusType,
                product,
              },
            }
          )) || {};

        if (data) {
          setResult(data);
          const { limit, page, pages, total } = data;
          setPagination({
            ...{ limit, page, pages, totals: total },
          });
        }
        // set paginator.
      }
    } finally {
      setLoading(false);
    }
  };

  function handleSendCouponEmail({ idCoupon }) {
    setLoading(true);

    api
      .post(`/persons/${person.idCliente}/coupons/${idCoupon}/resend-email`)
      .finally(() => setLoading(false));
  }

  function isEstorno(data = {}) {
    const produto = get(data, 'promocao.produto.titulo', null);
    return String(produto).toLocaleLowerCase() === 'estorno';
  }

  useEffect(() => {
    if (person) getCoupons();

    // TODO:
    // eslint-disable-next-line
  }, [
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    buyDate,
    raffleDate,
    statusType,
    product,
  ]);

  useEffect(() => {
    setPagination({ page: 1 });
  }, [buyDate, raffleDate, statusType, product, setPagination]);

  const renderFilter = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <IDatePicker
            label="Data de Compra"
            margin="normal"
            variant="inline"
            inputVariant="outlined"
            onChange={value => {
              if (isValid(value)) {
                setBuyDate(format(value, 'yyyy-MM-dd'));
              } else {
                setBuyDate(undefined);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <IDatePicker
            label="Data do Sorteio"
            margin="normal"
            variant="inline"
            inputVariant="outlined"
            onChange={value => {
              if (isValid(value)) {
                setRaffleDate(format(value, 'yyyy-MM-dd'));
              } else {
                setRaffleDate(undefined);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl variant="outlined" style={{ marginTop: 16 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Situação
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Situação"
              onChange={event => setStatusType(event.target.value)}
              style={{ width: '100%' }}
              value={statusType}
            >
              <MenuItem value="">Geral</MenuItem>
              <MenuItem value="inValidity">Em vigencia</MenuItem>
              <MenuItem value="outOfValidity">Fora de vigencia</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <InputLabel htmlFor="tipo">&nbsp;</InputLabel>
          <ProductAutocomplete
            disabled={product === 'estorno'}
            label="Produto"
            valueKey="cod"
            variant="outlined"
            margin="none"
            onChange={event => setProduct(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <FormControlLabel
            style={{ marginTop: 25 }}
            control={
              <Checkbox
                onChange={() => {
                  if (product === 'estorno') {
                    setProduct();
                  } else {
                    setProduct('estorno');
                  }
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Estornado"
          />
        </Grid>
      </Grid>
    );
  };
  let localColor;
  let localPromotion = null;
  const renderCard = item => {
    if (item.promocao.idPromocao !== localPromotion) {
      localPromotion = item.promocao.idPromocao;
    }

    if (
      isAfter(new Date(), parseISO(item.promocao.dataVigenciaInicio)) &&
      isBefore(new Date(), parseISO(item.promocao.dataVigenciaFim))
    ) {
      localColor = '#80bcff';
    } else {
      localColor = '#d0d0d0';
    }
    if (item.promocao.produto.codigo === 'ESTORNO') {
      localColor = '#ffbbb8';
    }

    if (
      isAfter(new Date(), parseISO(item.promocao.dataVigenciaInicio)) &&
      isBefore(new Date(), parseISO(item.promocao.dataVigenciaFim))
    ) {
      localColor = '#80bcff';
    } else {
      localColor = '#d0d0d0';
    }
    if (item.promocao.produto.codigo === 'ESTORNO') {
      localColor = '#ffbbb8';
    }
    if (
      isAfter(new Date(), parseISO(item.promocao.dataVigenciaInicio)) &&
      isBefore(new Date(), parseISO(item.promocao.dataVigenciaFim))
    ) {
      localColor = '#80bcff';
    } else {
      localColor = '#d0d0d0';
    }
    if (item.promocao.produto.codigo === 'ESTORNO') {
      localColor = '#ffbbb8';
    }
    return (
      <Grid key={item.idTituloPromocao} item xs={12} md={6}>
        <GridCustom container>
          {/* header */}
          <Grid
            className="title"
            item
            xs={12}
            md={12}
            style={{ backgroundColor: localColor }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FaCalendar size={20} />
                Promoção&nbsp;
                {item.promocao.dataSorteioPrincipal
                  ? format(
                      parseISO(item.promocao.dataSorteioPrincipal),
                      'dd/MM/yyyy HH:mm'
                    )
                  : ''}
              </Grid>
              <Grid item xs={6} md={6} align="right">
                <p>CHANCE {item.promocao.categoria}</p>
              </Grid>
            </Grid>
          </Grid>

          {/* body */}
          <Grid className="body" item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Product image={item.promocao.produto.icone} />
                <Grid item xs={12} md={12} align="left">
                  <p>Origem: {item?.acao?.nome}</p>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <CardCoupon item={item} />
                {!isEstorno(item) && (
                  <>
                    <Grid container>
                      <Grid item align="right" xs={12} md={12}>
                        <Button
                          onClick={({ currentTarget }) =>
                            setConfirmationMenu({
                              anchor: currentTarget,
                              onOk: () =>
                                handleSendCouponEmail({
                                  idCoupon: item.idTituloPromocao,
                                }),
                              onCancel: () => setConfirmationMenu({}),
                            })
                          }
                          color="primary"
                          variant="contained"
                          size="small"
                          style={{ marginTop: 10 }}
                        >
                          Enviar e-mail
                        </Button>
                      </Grid>
                      <Grid item align="right">
                        {person && (
                          <ChargeBackButton
                            item={item}
                            person={person}
                            getCoupons={getCoupons}
                          />
                        )}
                      </Grid>
                      {item.compra && item.compra.idPessoaPresenteada !== null && (
                        <Grid item align="right">
                          <Link
                            to={`/person/${item.compra.idPessoa}`}
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              style={{ margin: '10px 0px 0px 10px' }}
                            >
                              Ver Amigo
                            </Button>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* body */}
          <Grid className="footer" item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item align="left" xs={12} md={6}>
                {item.autenticacao}
              </Grid>
              <Grid item align="right" xs={12} md={6}>
                {item.promocao.produto.codigo === 'ESTORNO' &&
                  format(
                    parseISO(item.dataUpdate || item.dataTituloPromocao),
                    'dd/MM/yyyy HH:mm'
                  )}
                {item.promocao.produto.codigo !== 'ESTORNO' &&
                  item.dataTituloPromocao &&
                  format(parseISO(item.dataTituloPromocao), 'dd/MM/yyyy HH:mm')}
              </Grid>
            </Grid>
          </Grid>
        </GridCustom>
      </Grid>
    );
  };

  if (loading && !result.docs) {
    return <CircularProgress size={24} />;
  }
  return (
    <>
      <ConfirmationMenu {...confirmationMenu} />
      <Grid style={{ marginBottom: 20 }}>{renderFilter()}</Grid>
      {loading && <CircularProgress size={24} />}
      {result && result.docs && result.docs.length > 0 ? (
        <Grid container spacing={4}>
          {result.docs.map(item => renderCard(item))}
        </Grid>
      ) : (
        <NoRecord />
      )}

      <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
      {loading && <CircularProgress size={24} />}
    </>
  );
}

Log.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]),
};

Log.defaultProps = {
  person: null,
};
