import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, StepConnector, Step, StepLabel } from '@material-ui/core';
import historyRoute from '~/services/history';
import { format } from 'date-fns';
import { LinkPerson, SubTitle } from './styles';

export default function Steps({ data, personIdCliente }) {
  const changeArrayHistory = history => {
    history.push({
      id: history.at(-1).id + 1,
      idTituloPromocao: history[0].idTituloPromocao,
      idPessoaOrigem: history.at(-1).pessoaDestino.idCliente,
      idPessoaDestino: null,
      dataInsert: history.at(-1).dataInsert,
      pessoaOrigem: {
        idCliente: history.at(-1).pessoaDestino.idCliente,
        nomeCompleto: history.at(-1).pessoaDestino.nomeCompleto,
      },
      pessoaDestino: {
        idCliente: null,
        nomeCompleto: null,
      },
    });
    return history;
  };

  return (
    <Stepper
      connector={
        <StepConnector style={{ minWidth: '64px', maxWidth: '64px' }} />
      }
      style={{
        backgroundColor: 'transparent',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem',
      }}
    >
      {changeArrayHistory(data.history).map(
        ({ id, pessoaOrigem, dataInsert }, index, row) => {
          return (
            <Step
              active={personIdCliente === pessoaOrigem.idCliente}
              key={id}
              completed={index + 1 === row.length}
            >
              <StepLabel
                style={{ width: '200px', wordWrap: 'break-word' }}
                optional={
                  <>
                    <SubTitle>ID APCAP: {pessoaOrigem.idCliente}</SubTitle>
                    <SubTitle>
                      {index + 1 === row.length && index > 0 ? (
                        'Destino Final'
                      ) : (
                        <>
                          {format(new Date(dataInsert), 'dd/MM/yyyy')} -{' '}
                          {format(new Date(dataInsert), 'hh:mm')}
                        </>
                      )}
                    </SubTitle>
                  </>
                }
              >
                <LinkPerson
                  onClick={() =>
                    historyRoute.push(`/person/${pessoaOrigem.idCliente}`)
                  }
                >
                  {pessoaOrigem.nomeCompleto}
                </LinkPerson>
              </StepLabel>
            </Step>
          );
        }
      )}
    </Stepper>
  );
}

Steps.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  personIdCliente: PropTypes.number.isRequired,
};
