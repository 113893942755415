import styled from 'styled-components';

export const Table = styled.table`
  text-align: left;
  width: 100%;

  tr td,
  tr th {
    padding: 4px;
    font-size: 16px;
  }

  tbody tr:hover {
    background-color: #eaeaea;
  }

  tbody tr:nth-child(even) {
    background-color: #fafafa;
  }

  thead tr {
    background-color: #3f51b5;
    color: white;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;
