import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingContainer = styled.div`
  align-self: center;
`;

export const Item = styled.div`
  height: 224px;
  width: 431px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  font-family: 'Roboto';
  padding-bottom: 4px;

  .header {
    display: flex;
    flex-direction: row;
    height: 44px;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding-left: 8px;

    p {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding: 8px;

    img {
      margin-right: 12px;
      max-height: 96px;
    }

    div {
      color: #000000;
      width: 60%;
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    background: #f8f8f8;
    padding: 8px;
  }
`;

export const CloseText = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  color: #3f51b5;
  align-self: flex-end;
  margin: 20px;
  cursor: pointer;
`;
