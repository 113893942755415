import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import api from '~/services/api';

const schema = Yup.object().shape({
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});
function ChargebackDialog({ item, onClose }) {
  const { id, idPessoa } = item;
  const [loading, setLoading] = useState(false);

  const handleConfirmar = async ({ reason }) => {
    try {
      setLoading(true);
      await api.put(`/persons/${idPessoa}/pix/chargeback`, {
        idPix: id,
        reason,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div>
      <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
        <Form schema={schema} onSubmit={handleConfirmar}>
          <DialogTitle id="form-dialog-title">
            Motivo do cancelamento
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Justifique o motivo do estorno
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="reason"
              name="reason"
              label="Motivo"
              fullWidth
              multiline
              maxRows="4"
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={onClose} disabled={loading}>
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

ChargebackDialog.propTypes = {
  item: PropTypes.shape(),
  onClose: PropTypes.func,
};

ChargebackDialog.defaultProps = {
  item: null,
  onClose: false,
};

export default ChargebackDialog;
