import React from 'react';
import PropTypes from 'prop-types';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';

export default function ApplicationItemDate({ date, onChange, ...rest }) {
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DateTimePicker
        disablePast
        showToolbar={false}
        hideTabs
        ampm={false}
        inputFormat="dd/MM/yyyy HH:mm"
        value={date}
        onChange={dateValue => onChange(dateValue)}
        renderInput={props => {
          const helperText = props.error ? 'Data inválida' : undefined; // eslint-disable-line
          return (
            <TextField
              label="Envio em"
              {...props}
              inputProps={{ ...props.inputProps, readOnly: true }} // eslint-disable-line
              helperText={helperText}
            />
          );
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}

ApplicationItemDate.defaultProps = {
  onChange: () => {},
};

ApplicationItemDate.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.any.isRequired, // eslint-disable-line
};
