import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import PushNotificationsList from './List';

function PushNotification() {
  return (
    <ConfirmProvider>
      <PushNotificationsList />
    </ConfirmProvider>
  );
}

export default PushNotification;
