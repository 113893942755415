import React from 'react';
import { ColorColor } from '~/pages/Backoffice/Promotion/Config/Form/styles';

/**
 * return hexa color random.
 *
 * @returns {String}
 */
export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    // eslint-disable-next-line spaced-comment
    color += letters[Math.floor(Math.random() * 16)]; //NOSONAR
  }
  return color;
}

const rgbToHex = rgb => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

export function rgbColorToHex(r, g, b) {
  const red = rgbToHex(r);
  const green = rgbToHex(g);
  const blue = rgbToHex(b);
  return `#${red}${green}${blue}`;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1,
      }
    : null;
}

export function getPromotionColors() {
  return [
    { value: 0, label: <ColorColor color="#773386">&nbsp;#773386</ColorColor> },
    { value: 1, label: <ColorColor color="#ac0808">&nbsp;#ac0808</ColorColor> },
    { value: 2, label: <ColorColor color="#faa21d">&nbsp;#faa21d</ColorColor> },
    { value: 3, label: <ColorColor color="#8b5528">&nbsp;#8b5528</ColorColor> },
    { value: 4, label: <ColorColor color="#00a7b9">&nbsp;#00a7b9</ColorColor> },
  ];
}
