import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import formatCPF from '@brazilian-utils/format-cpf';
import { Link } from 'react-router-dom';
import api from '~/services/api';
import { NoRecord } from '~/components';

function ConsultPurchases({ onClose }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await api.get('/contestation/consultPurchase');
      setLoading(false);
      setList(results.data);
    })();
  }, []);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Clientes bloqueados com compras ativas.</DialogTitle>
      <DialogContent align="center">
        {loading && <CircularProgress align="center" size={24} />}
        {!!list.length && (
          <Table>
            <TableHead style={{ backgroundColor: '#3f51b5' }}>
              <TableRow>
                <TableCell style={{ color: '#fff', padding: 5 }}>
                  Nome
                </TableCell>
                <TableCell style={{ color: '#fff', padding: 5 }}>CPF</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map(item => (
                <TableRow key={item.idCliente}>
                  <TableCell>{item.nomeCompleto}</TableCell>
                  <TableCell>
                    <Link
                      to={`/person/${item.cpf}`}
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      {formatCPF(item.cpf)}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {!loading && !list.length && <NoRecord />}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConsultPurchases.defaultProps = {
  onClose: false,
};

ConsultPurchases.propTypes = {
  onClose: PropTypes.func,
};

export default ConsultPurchases;
