import styled from 'styled-components';

export const Container = styled.div`
  background: #fcfcfc;
  border: 1px dashed #222222;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  width: 300px;

  p {
    flex: 1;
    text-align: center;
  }

  img.preview-image {
    width: 100%;
    max-width: 100px;
    height: 100px;
  }

  .picture-button {
    border: 0px;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
