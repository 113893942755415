import { TextMaskCEP } from '~/components/masks';

export default [
  {
    name: 'logradouro',
    label: 'Logradouro',
    placeholder: 'Informe a rua',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'numero',
    label: 'Número',
    placeholder: 'Informe o n',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'bairro',
    label: 'Bairro',
    placeholder: 'Informe o bairro',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'cidade',
    label: 'Cidade',
    placeholder: 'Informe a cidade',
    cardLabelProps: {},
    textFieldProps: {},
  },
  {
    name: 'estado',
    label: 'Estado',
    placeholder: 'Informe o estado (Ex.: SP)',
    cardLabelProps: {},
    textFieldProps: { inputProps: { maxLength: 2 } },
  },
  {
    name: 'cep',
    label: 'CEP',
    placeholder: 'Informe o CEP',
    cardLabelProps: {},
    textFieldProps: {
      InputProps: { inputComponent: TextMaskCEP },
    },
  },
  {
    name: 'complemento',
    label: 'Complemento',
    placeholder: 'Casa / Apto. / Empresarial',
    cardLabelProps: { className: 'complemento' },
    textFieldProps: {},
  },
];
