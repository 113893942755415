import React, { useEffect, useState, useCallback } from 'react';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { MdMenu, MdDescription } from 'react-icons/md';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { decimalFormat } from '~/helpers/number';
import DetailInfo from '../Dialog';
import NoRecord from '~/components/NoRecord';
import api from '~/services/api';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';

import { Table } from './styles';

function List({ person }) {
  const [loading, setLoading] = useState(true);
  const [protocols, setProtocols] = useState([]);
  const [detail, setDetail] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [infoModal, setInfoModal] = useState([]);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const handleList = useCallback(async () => {
    const { idCliente } = person;
    const params = {
      page: customPaginator.pagination.page,
      limit: customPaginator.pagination.limit,
    };
    const result = await api.get(`/persons/${idCliente}/post-office/`, {
      params,
    });

    if (result.data) {
      setLoading(false);
      setProtocols(result.data.rows);
      const { count, rows } = result.data;
      setPagination({
        ...{
          limit: customPaginator.pagination.limit,
          page: customPaginator.pagination.page,
          pages: Math.ceil(count / rows),
          totals: count,
        },
      });
    }
  }, [
    person,
    customPaginator.pagination.page,
    customPaginator.pagination.limit,
    setPagination,
  ]);

  useEffect(() => {
    if (person) {
      handleList();
    }
  }, [person, handleList]);

  function handleClickMenu(event, item) {
    setAnchorElMenu(event.currentTarget);
    setSelectedMenu(item);
  }

  function handleCloseMenu() {
    setAnchorElMenu(null);
  }

  const loadInfo = useCallback(async () => {
    const { idPessoa, protocolo } = selectedMenu;

    handleCloseMenu();

    const result = await api.get(
      `/persons/${idPessoa}/post-office/protocol/${protocolo}`
    );

    if (!result) return;
    setInfoModal(result.data);
    setDetail(true);
  }, [selectedMenu]);

  const renderOptionsMenu = () => {
    if (!selectedMenu && !anchorElMenu) return '';
    return (
      <Menu
        d="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={loadInfo}>
          <MdDescription size={25} />
          &nbsp;Detalhes
        </MenuItem>
      </Menu>
    );
  };

  function handleStatus(value) {
    if (value === 0) {
      return <span style={{ color: '#3f51b5' }}>Confirmado</span>;
    }
    if (value === 99) {
      return <span style={{ color: '#f50057' }}>Cancelado</span>;
    }
    return <span style={{ color: '#000000' }}>Pendente</span>;
  }

  if (loading) {
    return <CircularProgress align="center" size={24} />;
  }

  if (!loading && protocols.length > 0) {
    return (
      <>
        {renderOptionsMenu()}
        <Table size="small">
          <thead>
            <tr>
              <th>Protocolo</th>
              <th>Valor</th>
              <th>Atendimento</th>
              <th>Confirmação</th>
              <th>Processamento</th>
              <th>Status</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {protocols.map(item => (
              <tr key={item.idTransacao}>
                <td>{item.protocolo}</td>
                <td>{decimalFormat(item.valor)}</td>
                <td>
                  {format(
                    parseISO(item.dataTransacao),
                    'dd/MM/yyyy - hh:mm:ss'
                  )}
                </td>
                <td>
                  {item.dataConfirmacao
                    ? format(
                        parseISO(item.dataConfirmacao),
                        'dd/MM/yyyy - hh:mm:ss'
                      )
                    : '--'}
                </td>
                <td>
                  {item.dataProcessamentoCron
                    ? format(
                        parseISO(item.dataProcessamentoCron),
                        'dd/MM/yyyy - hh:mm:ss'
                      )
                    : '--'}
                </td>
                <td>{handleStatus(item.statusTransacao)}</td>
                <td>
                  <MdMenu
                    size={30}
                    onClick={event => handleClickMenu(event, item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}
        {detail && (
          <DetailInfo data={infoModal} onClose={() => setDetail(null)} />
        )}
      </>
    );
  }
  return <NoRecord />;
}

List.propTypes = {
  person: PropTypes.shape(),
};

List.defaultProps = {
  person: null,
};

export default List;
