import styled, { css } from 'styled-components';

export const Container = styled.section`
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 400px;
  display: flex;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: ${`url('${backgroundImage}')`};
    `}

  ${({ device }) =>
    device === 'iphone' &&
    css`
      padding: 53px 23.5px 0px 23.5px;
    `}

  ${({ device }) =>
    device === 'android' &&
    css`
      padding: 15.2% 4.66% 0;
    `}

  & + & {
    margin-top: 30px;
  }
`;

export const Ballon = styled.div`
  margin: 4px;
  overflow: hidden;
  padding: 8px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 60px;

  h1 {
    font-size: 13px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    margin-bottom: 1px;
  }

  p {
    font-size: 11px;
    text-overflow: ellipsis;
    font-weight: normal;
    overflow: hidden;
    white-space: pre;
  }
`;
