import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';
import { store } from '~/store';

export default function PermissionsSelect({ itemsChecks, setItems }) {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const { token } = store.getState().auth;

  const getRoles = async () => {
    try {
      setLoading(true);
      if (hasPermission('read_roles')) {
        const { data } = await api.get('/roles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!isEmpty(data)) {
          setRoles(data);
          if (!isEmpty(itemsChecks)) {
            setItems(
              data.filter(role =>
                itemsChecks.some(item => item.roleId === role.roleId)
              )
            );
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    setItems(uniqBy(value, 'roleId'));
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">Permissões</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={itemsChecks}
            onChange={handleChange}
            renderValue={selected =>
              selected.map(x => x.roleAttributes.roleName).join(', ')
            }
          >
            {roles.map(variant => {
              return (
                <MenuItem key={variant.roleId} value={variant}>
                  <ListItemText primary={variant.roleAttributes.roleName} />
                  <Checkbox
                    checked={
                      itemsChecks.findIndex(
                        item => item.roleId === variant.roleId
                      ) >= 0
                    }
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
}

PermissionsSelect.propTypes = {
  itemsChecks: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setItems: PropTypes.func.isRequired,
};
