import styled from 'styled-components';
import { IDatePicker } from '~/components';
import { Grid, Button, TableCell } from '@material-ui/core';
import CsvDownloader from 'react-csv-downloader';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const Cell = styled(TableCell)`
  div.MuiChip-root {
    &.error {
      background-color: #f9a19a;
    }

    &.success {
      background-color: #b0ddb2;
    }
  }
`;

export const DateTimeField = styled(IDatePicker)`
  & + & {
    margin-top: 15px;
  }

  &:last-child {
    margin-bottom: 15px;
  }
`;

export const TabIcon = styled(Grid)`
  text-align-last: center;
  font-weight: 600;
  cursor: pointer;

  &.active {
    color: #3f51b5;
    border-bottom: 2px solid #3f51b5;
  }
`;

export const CsvDownload = styled(CsvDownloader)`
  display: inline;
`;

export const ColorButton = styled(Button)`
  background-color: #05c46b;
  margin-left: 10px;
  &:hover {
    background-color: #16a085;
  }
`;
