export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInFirstSuccess(user, token) {
  return {
    type: '@auth/SIGN_IN_FIRST_SUCCESS',
    payload: { user, token },
  };
}

export function signInFinal(pin, user, token, password) {
  return {
    type: '@auth/SIGN_IN_FINAL',
    payload: { pin, user, token, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function resetPassword(user) {
  return {
    type: '@auth/RESET_PASSWORD',
    payload: { user },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
