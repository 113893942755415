import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props =>
    props.minWidth &&
    css`
      min-width: 300px;
    `}
  ${props =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft}px;
    `}
`;

export const Label = styled.label`
  font-size: 12px;
  line-height: 14px;
  color: #969696;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  margin-bottom: 4px;
  white-space: nowrap;
`;

export const ContentWrapper = styled.div`
  color: #000;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  text-transform: uppercase;

  a {
    color: #000;
    text-decoration: underline;
  }

  input[type='text'] {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
    background-color: transparent;
    border: 0px;
  }
`;
