import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';

import api from '~/services/api';
import { hasPermission } from '../AccessControl';

const giftSpec = applySpec({
  id: prop('idAcao'),
  titulo: prop('nome'),
  cod: prop('codigo'),
});

const getGifts = pipe(prop('rows'), map(giftSpec));

export default function useGifts() {
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (hasPermission('read_promotion_actions', { toast: false })) {
        setLoading(true);
        api
          .get('/promotions/actions')
          .then(({ data }) => {
            if (data) setGifts(getGifts(data.docs));
          })
          .finally(() => setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return { gifts, loading };
}
