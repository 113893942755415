import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { MdClear } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

function UploadField({ name }) {
  const [fileName, setFilename] = useState('');
  const { fieldName, registerField, error } = useField(name);
  const empty = 'Selecione um arquivo...';

  const onDrop = useCallback(([File]) => {
    setFilename(File.name);
  }, []);

  const { getRootProps, getInputProps, inputRef } = useDropzone({ onDrop });

  const handleClear = useCallback(() => {
    setFilename(empty);
    inputRef.current.value = '';
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue() {
        handleClear();
      },
    });
  }, [fieldName, registerField, inputRef, handleClear]);

  const nameLabel = useMemo(() => {
    if (fileName) {
      return fileName;
    }
    return empty;
  }, [fileName]);

  const hasLabel = useMemo(() => {
    return fileName !== empty;
  }, [fileName]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="button"
          color="primary"
          variant="contained"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          Escolher Arquivo
        </Button>
        <span style={{ marginLeft: 10, flex: 1, textAlign: 'left' }}>
          {nameLabel}
        </span>

        {hasLabel && (
          <MdClear
            size={22}
            style={{ cursor: 'pointer' }}
            onClick={handleClear}
          />
        )}
      </div>
      {error && <p style={{ color: '#f00', marginTop: '10px' }}>{error}</p>}
    </>
  );
}

UploadField.defaultProps = {
  name: '',
};

UploadField.propTypes = {
  name: PropTypes.string,
};

export default UploadField;
