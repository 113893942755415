import React from 'react';
import PropTypes from 'prop-types';

import { Container, Ballon } from './styles';

const previews = {
  iphone: require('~/assets/iphone-preview.png'), // eslint-disable-line
  android: require('~/assets/android-preview.png'), // eslint-disable-line
};

export default function PushPreview({ title, body, devices }) {
  return devices.map(device => (
    <Container key={device} backgroundImage={previews[device]} device={device}>
      <Ballon>
        <h1>{title}</h1>
        <p>{body}</p>
      </Ballon>
    </Container>
  ));
}

PushPreview.defaultProps = {
  devices: ['iphone', 'android'],
};

PushPreview.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.string),
};
