import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';

import Form from './form';

const PushNotificationCreate = () => (
  <ConfirmProvider>
    <Form />
  </ConfirmProvider>
);

export default PushNotificationCreate;
