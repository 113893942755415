import React, { useState } from 'react';
import { Toolbar, Icon, IconButton, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { MdArrowDropDown } from 'react-icons/md';
import { signOut } from '~/store/modules/auth/actions';
import ProductDrawer from '~/components/ProductDrawer';
import { setProduct } from '~/store/modules/user/actions';
import { Container, MenuButton, Title } from './styles';

export default function AppBar({
  drawerWidth = 0,
  expanded = false,
  onToggle,
}) {
  const { profile: userProfile, product, params } = useSelector(
    ({ user }) => user
  );
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpened(open);
  };

  const handleSelectProduct = prod => {
    setProduct(prod);
    dispatch(setProduct(prod));
    setOpened(false);
  };

  const renderLink = prod => {
    return (
      <>
        {prod.imagem ? (
          <img
            style={{ marginRight: 10 }}
            src={prod.imagem}
            width={40}
            alt={prod.titulo}
          />
        ) : (
          <MdArrowDropDown size={40} />
        )}
        <span>
          {!!prod && prod.titulo ? prod.titulo : 'Selecione o Produto'}&nbsp;
        </span>
      </>
    );
  };

  return (
    <>
      <Container drawerWidth={drawerWidth} expanded={expanded}>
        <Toolbar>
          <MenuButton
            style={{ padding: 0 }}
            hide={!expanded}
            onClick={onToggle}
          >
            <Icon>menu</Icon>
          </MenuButton>

          <Title>Backoffice</Title>

          {params && params.useFilterByProduct && (
            <span className="animated bounceInDown delay-500ms">
              <Button
                variant="text"
                style={{ color: 'white', marginRight: 20 }}
                onClick={toggleDrawer(true)}
              >
                {product && renderLink(product)}
              </Button>
            </span>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '10px',
            }}
          >
            <span>{`${userProfile.firstName} ${userProfile.lastName}`}</span>
            <span>{userProfile.email}</span>
          </div>

          <IconButton color="inherit" onClick={() => dispatch(signOut())}>
            <Icon>exit_to_app</Icon>
          </IconButton>
        </Toolbar>
      </Container>
      <ProductDrawer
        opened={opened}
        toggleDrawer={toggleDrawer}
        handleSelectProduct={handleSelectProduct}
      />
    </>
  );
}

AppBar.defaultProps = {
  onToggle: () => {},
};

AppBar.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
};
