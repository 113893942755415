import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EbookAutoComplete from '~/components/EbookAutoComplete';
import api from '~/services/api';

import { Container, Content } from './styles';

export default function SelectEbook({ open, onClose, promotionId }) {
  const [idEbookSelect, setIdEbookSelect] = useState();
  const [loading, setLoading] = useState(false);

  const handleOnChange = async ebookId => {
    setIdEbookSelect(ebookId);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await api.post(`/promotions/${promotionId}/ebook/${idEbookSelect}`);
      onClose(true);
    } finally {
      setLoading(false);
      onClose(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Escolher e-book</DialogTitle>
      <DialogContent>
        <Container>
          <EbookAutoComplete
            inputNotEnabled
            onChange={e => handleOnChange(e.target.value)}
          />
        </Container>

        <Content>
          <Button
            disabled={false}
            onClick={onClose}
            color="secondary"
            variant="text"
          >
            Cancelar
          </Button>

          <Button
            disabled={false}
            type="submit"
            color="primary"
            variant="text"
            onClick={() => handleSubmit()}
          >
            {loading ? <CircularProgress size={18} /> : 'Cadastrar'}
          </Button>
        </Content>
      </DialogContent>
    </Dialog>
  );
}

SelectEbook.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  promotionId: PropTypes.string.isRequired,
};
