import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
`;

export const Box = styled.div`
  height: 260px;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.8rem;
  flex-direction: column;

  .thumbnail {
    width: 160px;
    height: 160px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div {
    width: 100%;

    h1 {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    div {
      display: flex;

      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
`;
