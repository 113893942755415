import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';

// -- components
import { UnauthorizedGhost } from '~/components';
import AccessControl from '~/components/AccessControl';

// -- hooks
import { ConfigsProvider } from '~/contexts/useConfig';
import { isEmpty } from 'lodash';
import usePromotion from './usePromotion';

// -- context
import { PromotionContextProvider } from './context';
import PromotionResultsContext from './PromotionContext/Results';
import PromotionAllContext from './PromotionContext/All';

export default function PromotionView() {
  const { id, context } = useParams();

  const promotionContext = usePromotion({ id, pretty: context !== 'all' });

  const isContext = useCallback(
    compareContext => {
      return context === compareContext;
    },
    [context]
  );

  return (
    <ConfirmProvider>
      <PromotionContextProvider value={promotionContext}>
        <ConfigsProvider
          codigo={
            !isEmpty(promotionContext.promotion.produto) &&
            promotionContext.promotion.produto.codigo
          }
        >
          <AccessControl
            can="read_promotion"
            displayError
            renderNoAccess={UnauthorizedGhost}
          >
            <>
              {isContext('results') && <PromotionResultsContext />}
              {isContext('all') && <PromotionAllContext />}
            </>
          </AccessControl>
        </ConfigsProvider>
      </PromotionContextProvider>
    </ConfirmProvider>
  );
}
