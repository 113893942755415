import React from 'react';
import Table from '@material-ui/core/Table';
import { TableCell } from '@material-ui/core';
import formatCpf from '@brazilian-utils/format-cpf';
import TableHead from '@material-ui/core/TableHead';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { Content } from '~/styles/global';
import { hasPermission } from '~/components/AccessControl';
import { TableRow, TableBody, LinkPage } from './styles';

export default function PersonList({
  data: { docs: rows },
  paginator: Paginator,
}) {
  const linkRedirection = row => {
    if (hasPermission(['read_persons', 'support_reset_password'])) {
      return `/person/${row}`;
    }
    return '';
  };

  return (
    <Content>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CPF</TableCell>
            <TableCell align="left">Nome Completo</TableCell>
            <TableCell align="left">Nome Social</TableCell>
            <TableCell align="left">Sexo</TableCell>
            <TableCell align="left">Dt. Nascimento</TableCell>
            <TableCell align="left">email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.idCliente}>
              <TableCell className="cpf" align="left">
                <LinkPage to={linkRedirection(row.cpf)}>
                  {formatCpf(row.cpf)}
                </LinkPage>
              </TableCell>
              <TableCell align="left">{row.nomeCompleto}</TableCell>
              <TableCell align="left">{row.nomeSocial}</TableCell>
              <TableCell align="left">{row.sexo}</TableCell>
              <TableCell align="left">
                {row.dataNascimento
                  ? format(parseISO(row.dataNascimento), 'dd/MM/yyyy')
                  : null}
              </TableCell>
              <TableCell align="left">{row.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginator />
    </Content>
  );
}

PersonList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  paginator: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
