import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineImage } from 'react-icons/md';
import { NoImage, Container } from './styles';

export default function RenderEbook({ ebook }) {
  return (
    <Container>
      <NoImage>
        {ebook.urlThumbnail ? (
          <img src={ebook.urlThumbnail} alt="thumbnail" width="160px" />
        ) : (
          <MdOutlineImage style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={80} />
        )}
      </NoImage>
      <span>
        {ebook.nome} &nbsp;({ebook.autor})
      </span>
    </Container>
  );
}

RenderEbook.propTypes = {
  ebook: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
