import React from 'react';
import PropTypes from 'prop-types';
import {
  FaExclamationCircle,
  FaCheckCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';

import { Container } from './styles';

export default function Toast({ message, error, warn }) {
  function injectMessage() {
    return { __html: message };
  }
  return (
    <>
      <Container>
        {error && !warn && <FaExclamationCircle size={40} />}
        {warn && !error && <FaExclamationTriangle size={40} />}
        {!warn && !error && <FaCheckCircle size={40} />}
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={injectMessage()} />
      </Container>
    </>
  );
}

Toast.defaultProps = {
  error: false,
  warn: false,
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
  warn: PropTypes.bool,
};
