import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';

// -- components
import { NoRecord } from '~/components';
import Sweepstake from './sweepstake';

// -- context
import { PromotionContext } from './context';

export default function Promotion() {
  const { promotion } = useContext(PromotionContext);
  const hasSorteios = get(promotion, 'sorteios.length', 0);

  if (!hasSorteios) {
    return <NoRecord message="Não há sorteios disponível" />;
  }

  return (
    <Grid container spacing={2}>
      {promotion.sorteios.map(({ idSorteio, ...sorteio }) => (
        <Sweepstake
          key={String(idSorteio)}
          data={{ ...sorteio, idSorteio }}
          promotion={promotion}
        />
      ))}
    </Grid>
  );
}
