import { useState, useCallback } from 'react';
import { applySpec, prop } from 'ramda';
import api from '~/services/api';

const paginationSpec = applySpec({
  totals: prop('total'),
  limit: prop('limit'),
  page: prop('page'),
  pages: prop('pages'),
});

export default function useActivities({ setPagination, ...custom }) {
  const [filters, setFilters] = useState([]);
  const [paginationHandle, setPaginationHandle] = useState(false);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renderFilters = useCallback(async () => {
    setLoading(true);
    Promise.all([
      await api
        .get('/activities', {
          params: {
            page: custom.pagination.page,
            limit: custom.pagination.limit,
            ...filters,
            order,
            orderBy,
          },
        })
        .then(res => {
          if (res && res.data) {
            const { docs } = res.data;
            setActivities(docs);
            setPagination(paginationSpec(res.data));
            setPaginationHandle(true);
          }
        }),
    ]).finally(() => setLoading(false));
  }, [
    filters,
    custom.pagination.page,
    custom.pagination.limit,
    order,
    orderBy,
    setPagination,
  ]);

  function handleFilters(data) {
    setPagination({ page: 1 });
    setFilters({ ...filters, ...data });
  }

  // useEffect(() => {
  //   renderFilters();
  // }, [filters, order, orderBy]);

  // useEffect(() => {
  //   if (!isEmpty(filters)) {
  //     setLoading(true);

  //     api
  //       .get('/activities', {
  //         params: {
  //           page: pagination.page,
  //           limit: pagination.limit,
  //           period: ['2018-01-29T00:00:00', '2018-02-05T23:59:59'],
  //           ...filters,
  //           order,
  //           orderBy,
  //           promotion: 1,
  //         },
  //       })
  //       .then(res => {
  //         if (res && res.data) {
  //           const { docs } = res.data;
  //           setActivities(docs);
  //         }
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // }, [filters, order, orderBy]); // eslint-disable-line

  function handlePersonUpdate({ pessoa }) {
    const route = `/person/${pessoa.cpf.replace(/[^0-9]/g, '')}`;

    window.open(route);
  }

  return {
    activities,
    loading,
    setFilters,
    filters,
    renderFilters,
    handleRequestSort,
    order,
    orderBy,
    setActivities,
    handlePersonUpdate,
    paginationHandle,
    setPaginationHandle,
    setLoading,
    setPagination,
    handleFilters,
  };
}
