import React from 'react';
import styled, { css } from 'styled-components';
import { AppBar, IconButton, Typography } from '@material-ui/core';

export const Container = styled(({ drawerWidth, expanded, ...props }) => (
  <AppBar {...props} />
)).attrs({
  position: 'fixed',
})`
  z-index: 1201 !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;

  ${({ expanded }) =>
    !expanded &&
    css`
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
      margin-left: ${({ drawerWidth }) => `${drawerWidth}px`} !important;
      width: ${({ drawerWidth }) => `calc(100% - ${drawerWidth}px)`} !important;
    `};
`;

export const MenuButton = styled(({ hide, ...props }) => (
  <IconButton {...props} />
)).attrs({
  edge: 'start',
  color: 'inherit',
})`
  display: ${({ hide }) => (hide ? 'none' : 'block')} !important;
`;

export const Title = styled(Typography).attrs({
  component: 'h1',
  variant: 'h6',
  noWrap: true,
  color: 'inherit',
})`
  flex-grow: 1;
  padding-left: 12px;
`;
