import React, { useState } from 'react';
import { CircularProgress, Popover } from '@material-ui/core';
import { FaTrashAlt, FaCheck } from 'react-icons/fa';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';
import PropTypes from 'prop-types';
import { store } from '~/store';
import { Container, ItemContainer } from './styles';

export default function PopoverUserActions({
  open,
  onClose,
  anchorEl,
  user,
  setRefetch,
  refetchState,
  closeModal,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  const { token } = store.getState().auth;

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (hasPermission('update_user')) {
        await api.post(
          `/users/${user.email}`,
          {
            enabled: !user.enabled,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
    } finally {
      closeModal();
      setLoading(false);
      setRefetch(!refetchState);
    }
  };

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={!loading && onClose}
    >
      <Container>
        <ItemContainer
          onClick={() => handleDelete()}
          color={user.enabled ? '#F44336' : '#4153af'}
        >
          {loading ? (
            <CircularProgress size={12} />
          ) : (
            <>
              {user.enabled ? <FaTrashAlt /> : <FaCheck />}
              <span>{user.enabled ? 'Excluir' : 'Ativar'}</span>
            </>
          )}
        </ItemContainer>
      </Container>
    </Popover>
  );
}

PopoverUserActions.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
    createdTimestamp: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    clientRoles: PropTypes.oneOfType([PropTypes.array]),
    attributes: PropTypes.shape({
      productPermissions: PropTypes.oneOfType([PropTypes.array]),
    }),
  }).isRequired,
  setRefetch: PropTypes.func.isRequired,
  refetchState: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
