/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Form } from '@rocketseat/unform';
import {
  Grid,
  Button,
  CircularProgress,
  Icon,
  InputLabel,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { TextField, Select } from 'unform-material-ui';
import * as Yup from 'yup';
import { IoMdPaper } from 'react-icons/io';
import { AccessControl } from '~/components';
import { Content, Title } from '~/styles/global';
import api from '~/services/api';
import history from '~/services/history';
import { hasPermission } from '~/components/AccessControl';
import { TextMaskNumber, TextMaskQuota } from '~/components/masks';
import useProducts from '~/components/ProductAutocomplete/useProducts';
import { toNumber } from '~/helpers/number';
import { Container } from './styles';

// sign of the function compoment
export default function CgSusepForm({ match }) {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingThird, setLoadingThird] = useState(false);
  // const [initialData, setInitialData] = useState();
  const [cgSusep, setCgSusep] = useState();
  const [arrayIds, setArrayIds] = useState([]);
  const { products } = useProducts();

  useEffect(() => {
    if (products) {
      const NewProducts = products.map(item => ({
        value: item.id,
        label: item.titulo,
      }));
      setArrayIds(NewProducts);
    }
  }, [products]);

  // Yup form schema validation
  const toYupNumber = (_, value) => {
    return +String(toNumber(value)).replace(/\./, '');
  };
  const toYupDecimal = (_, value) => {
    if (value.includes('.')) {
      return null;
    }
    return +value.replace(/,/, '.');
  };

  const schema = Yup.object().shape({
    nomePlano: Yup.string().required('Campo obrigatório'),
    nomeComercial: Yup.string().required('Campo obrigatório'),
    processoSusep: Yup.string().required('Campo obrigatório'),
    chance: Yup.string().required('Campo obrigatório'),
    ...(cgSusep && cgSusep.id
      ? { idProduto: Yup.number().required('Campo obrigatório') }
      : { idProduto: Yup.array().required('Campo obrigatório') }),
    tamanhoSerie: Yup.number()
      .transform(toYupNumber)
      .required('Campo obrigatório'),
    vendaMinima: Yup.number()
      .transform(toYupNumber)
      .required('Campo obrigatório'),
    vendaNecessaria: Yup.number()
      .transform(toYupNumber)
      .required('Campo obrigatório'),
    expectativa: Yup.number()
      .transform(toYupNumber)
      .required('Campo obrigatório'),
    quotaSorteio: Yup.number()
      .transform(toYupDecimal)
      .required('Campo obrigatório'),
    quotaResgate: Yup.number()
      .transform(toYupDecimal)
      .required('Campo obrigatório'),
    quotaCapitalMinimo: Yup.number()
      .transform(toYupDecimal)
      .required('Campo obrigatório'),
  });

  // Send form to api
  const handleSubmit = async dataUpdate => {
    let updated = false;
    try {
      setLoading(true);
      const verb = cgSusep.id ? 'put' : 'post';
      const {
        idProduto,
        nomePlano,
        nomeComercial,
        processoSusep,
        chance,
        tamanhoSerie,
        vendaMinima,
        vendaNecessaria,
        expectativa,
        quotaSorteio,
        quotaResgate,
        quotaCapitalMinimo,
      } = dataUpdate;

      if (
        (hasPermission('create_cg_susep') && verb === 'post') ||
        (hasPermission('update_cg_susep') && verb === 'put')
      ) {
        const { data } =
          (await api[verb](`/cgs-susep/${cgSusep.id || ''}`, {
            nomePlano,
            nomeComercial,
            processoSusep,
            chance,
            idProduto,
            tamanhoSerie,
            vendaMinima,
            vendaNecessaria,
            expectativa,
            quotaSorteio,
            quotaResgate,
            quotaCapitalMinimo,
          })) || {};
        updated = data;
      }
    } finally {
      setLoading(false);
      if (updated) {
        history.push(`/cgs-susep`);
      }
    }
  };
  // Send form to api
  const handleDelete = async () => {
    let updated = false;
    try {
      setLoadingDelete(true);
      if (
        hasPermission('delete_cg_susep') ||
        hasPermission('undelete_cg_susep')
      ) {
        const verb = cgSusep.status === 0 ? 'put' : 'delete';
        const { status } =
          (await api[verb](
            `/cgs-susep/${cgSusep.id}${cgSusep.status === 0 ? '/undelete' : ''}`
          )) || {};
        updated = status === 204;
      }
    } finally {
      setLoadingDelete(false);
      if (updated) {
        history.push(`/cgs-susep`);
      }
    }
  };

  const getCgSusep = async id => {
    try {
      setLoading(true);
      if (hasPermission('read_cg_susep')) {
        const { data } = (await api.get(`/cgs-susep/${id}`)) || {};
        if (data) {
          if (data.quotaCapitalMinimo) {
            data.quotaCapitalMinimo = String(data.quotaCapitalMinimo).replace(
              '.',
              ','
            );
          }
          if (data.quotaResgate) {
            data.quotaResgate = String(data.quotaResgate).replace('.', ',');
          }
          if (data.quotaSorteio) {
            data.quotaSorteio = String(data.quotaSorteio).replace('.', ',');
          }
          setCgSusep(data);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (match && match.params && match.params.id) {
      getCgSusep(match.params.id);
    } else {
      setCgSusep({});
    }
  }, [match]);

  const handlePageSusep = processo => {
    setLoadingThird(true);
    api.get(`/cgs-susep/third?numeroProcesso=${processo}`).then(({ data }) => {
      const newWindow = window.open('', '_blank');
      newWindow.document.write(data);
      setLoadingThird(false);
    });
  };

  // Main Render
  return !cgSusep ? (
    ''
  ) : (
    <Content>
      <Form
        schema={schema}
        autoComplete="off"
        autoCapitalize="off"
        noValidate
        onSubmit={handleSubmit}
        initialData={cgSusep}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Container>
              <IoMdPaper size="25" color="gray" />
              <Title className="title">&nbsp;Cg Susep</Title>
            </Container>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="nomePlano"
              id="nomePlano"
              label="Nome Plano"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="nomeComercial"
              id="nomeComercial"
              label="Nome Comercial"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="processoSusep"
              id="processoSusep"
              label="Processo Susep"
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ fontWeight: 'bold' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handlePageSusep(cgSusep.processoSusep)}
                      disabled={!cgSusep.processoSusep}
                    >
                      {!loadingThird && cgSusep.processoSusep && (
                        <Icon>find_in_page_rounded</Icon>
                      )}
                      {loadingThird && <CircularProgress size={25} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputLabel htmlFor="chance">&nbsp;</InputLabel>
            <Select
              id="chance"
              name="chance"
              label="Chance"
              variant="outlined"
              options={[
                { value: 'S', label: 'Simples' },
                { value: 'D', label: 'Dupla' },
                { value: 'T', label: 'Tripla' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={8} style={{ marginTop: 15, maxWidth: 690 }}>
            <Select
              id="idProduto"
              name="idProduto"
              label="Produto"
              variant="outlined"
              margin="normal"
              multiple={!cgSusep.id}
              fullWidth
              options={arrayIds}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="tamanhoSerie"
              label="Tamanho Série"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="vendaMinima"
              label="Venda Mímima"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="vendaNecessaria"
              label="Venda Necessária"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              InputProps={{
                inputComponent: TextMaskNumber,
              }}
              name="expectativa"
              label="Expectativa"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              InputProps={{
                inputComponent: TextMaskQuota,
              }}
              name="quotaSorteio"
              label="Quota Sorteio %"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              InputProps={{
                inputComponent: TextMaskQuota,
              }}
              name="quotaResgate"
              label="Quota Resgate %"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              InputProps={{
                inputComponent: TextMaskQuota,
              }}
              name="quotaCapitalMinimo"
              label="Quota Capital Minimo %"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Botoes */}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4} align="right">
            <AccessControl can="delete_user">
              <Button
                type="button"
                variant="contained"
                disabled={loading || loadingDelete}
                color="secondary"
                onClick={() => handleDelete()}
              >
                {loadingDelete ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    {cgSusep.status === 0 ? (
                      <Icon>undo</Icon>
                    ) : (
                      <Icon style={{ marginRight: 2 }}>block</Icon>
                    )}
                    {cgSusep.status === 0 ? 'Reativar' : 'Inativar'}
                  </>
                )}
              </Button>
            </AccessControl>
            &nbsp;&nbsp;
            <AccessControl can={['update_user', 'create_user']}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading || loadingDelete || cgSusep.deleted}
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Salvar
                  </>
                )}
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </Form>
    </Content>
  );
}
