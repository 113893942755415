import styled from 'styled-components';

export const Product = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    height: 30px;
    padding-right: 10px;
    max-width: 36px;
  }
`;

export const Presenteado = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  p {
    text-align: left;
    padding-left: 5px;
    font-size: 13px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  tbody td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #3f51b520;
  }
  thead {
    background-color: #3f51b5;
    color: white;
  }
`;
