import styled from 'styled-components';

import { TextField as TF } from 'unform-material-ui';

export const Product = styled.td`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    height: 60px;
    padding-right: 10px;
    max-width: 66px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  tbody td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #3f51b520;
  }
  thead {
    background-color: #3f51b5;
    color: white;
  }
`;

export const TextField = styled(TF)`
  input {
    transition: font-size 250ms;
    animation: ease-in-out;
    font-size: 12px;
  }
  input:focus {
    color: #3e51b5;
    font-size: 50px;
  }
`;

export const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-content: center;
`;

export const Button = styled.button`
  padding: 18.5px 14px;
  align-content: stretch;
  height: 96.38;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
