import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import PropTypes from 'prop-types';
import {
  CircleIcon,
  Container,
  Header,
  Content,
  Footer,
  IconRefresh,
  DialogContent,
} from './styles';
import { DialogFinancial } from '../dialog';

export const Card = ({ title, value, icon }) => {
  const [show, setShow] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [generateSale, setGenerateSale] = useState('');

  const currencyFormat = Intl.NumberFormat('pt-BR');

  const handleCloseDialog = () => {
    setShow(false);
  };

  const validateToRangeValue = valueWrite => {
    if (Number(valueWrite) > Number(value)) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
  };

  return (
    <Container>
      <Header>
        <CircleIcon>{icon}</CircleIcon>
        <h1>{title}</h1>
      </Header>
      <Content>
        <div>
          <h1>{value ? `R$ ${currencyFormat.format(value)}` : 'TUDO CERTO'}</h1>
          <span>{value ? 'a pagar hoje' : 'nada a pagar hoje'}</span>
        </div>
        <IconRefresh>
          <MdRefresh size={35} color="#3F51B5" />
        </IconRefresh>
      </Content>
      <Footer>
        <Button
          onClick={() => setShow(true)}
          disabled={!value}
          variant="contained"
          color="primary"
        >
          Gerar liquidação
        </Button>
      </Footer>
      <DialogFinancial
        open={show}
        close={handleCloseDialog}
        title={title}
        disabled={invalid || !generateSale}
      >
        <DialogContent
          style={{ display: 'flex', alignItems: 'center', gap: '4rem' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1>R$ {currencyFormat.format(value)}</h1>
            <span>restantes</span>
          </div>
          <TextField
            error={invalid}
            style={{ width: '11rem' }}
            helperText={invalid && 'Valor máximo excedido'}
            label="Valor desta liquidação"
            onChange={e => {
              validateToRangeValue(e.target.value);
              setGenerateSale(e.target.value);
            }}
            InputProps={{
              startAdornment: <span style={{ paddingRight: '10px' }}>R$</span>,
            }}
          />
        </DialogContent>
      </DialogFinancial>
    </Container>
  );
};

Card.defaultProps = {
  title: '',
  value: '',
  icon: <></>,
};

Card.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.element,
};
