import styled from 'styled-components';
import { TableRow as TR, TableBody as TB } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const TableRow = styled(TR)`
  td {
    font-size: 18px;
  }
`;

export const TableBody = styled(TB)``;

export const LinkPage = styled(Link)`
  color: #000;
  text-decoration: underline;

  &:active {
    color: #000;
    text-decoration: underline;
  }
`;
