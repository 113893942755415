import styled, { css } from 'styled-components';
import { Card } from '../../components';

export const Container = styled(Card)`
  && {
    width: 100%;
    background-color: #fff;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: unset !important;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    transition: 400ms all;

    ${props =>
      props.loading === 1 &&
      css`
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 30;
          background-color: rgba(255, 255, 255, 0.8);
        }
      `}
  }
`;

export const LeftContainer = styled.div`
  width: 80%;
  margin-right: 32px;

  .card-label-content {
    &.content-wrapper,
    &.label {
      white-space: nowrap;
    }
  }
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .upload-area-wrapper + .upload-area-wrapper {
    margin-top: 8px;
  }
`;

export const DataGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px 32px;
`;

export const FormGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px 32px;

  .complemento,
  .notas {
    grid-column: 1 / 4;
  }

  .MuiTextField-root input {
    font-weight: normal;
    text-transform: initial !important;
  }
`;

export const SectionTitle = styled.h2`
  margin: 24px 0px;
  padding: 0px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  display: flex;

  span.MuiIcon-root {
    margin-left: -8px;
  }
`;

export const LogContainer = styled.div`
  .last-updated-by {
    font-size: 14px;
    text-decoration: underline;
    font-style: italic;
    text-align: right;
    width: 100%;
    display: block;
  }
`;
