import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { MdMenu } from 'react-icons/md';
import { FaInfoCircle } from 'react-icons/fa';
import history from '~/services/history';
import { decimalFormat } from '~/helpers/number';
import { Button, CircularProgress } from '@material-ui/core';
import api from '~/services/api';
import {
  Product,
  Presenteado,
  ButtonShowMultiProducts,
  IconArrow,
} from './styles';

export default function PurchaseItem({
  item,
  index,
  person,
  handleToggleMessage,
  handleClickMenu,
  setOpenMessage,
  addDefaultSrc,
  renderFormaPagamento,
  renderAllStatus,
  renderReturnCode,
}) {
  const [showMultiProducts, setShowMultiProducts] = useState(false);
  const [multiProducts, setMultiProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetMultiProducts = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get(
        `/persons/${person.idCliente}/purchases/multiProducts/${item.idCompra}`
      );

      if (data) {
        setMultiProducts(data);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [showMultiProducts]);

  useEffect(() => {
    if (!showMultiProducts) return;

    handleGetMultiProducts();
  }, [showMultiProducts]);

  return (
    <>
      <tr
        style={{
          backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
        }}
      >
        <td>{format(parseISO(item.dataCompra), 'dd/MM/yyyy HH:mm')}</td>
        <td>
          {item?.produto ? (
            <Product>
              <span style={{ fontSize: 17, fontWeight: 'bold' }}>
                {item.totalItens || 1}x &nbsp;
              </span>
              <img
                src={item.produto.icone}
                alt={item.produto.descricao}
                onError={addDefaultSrc}
              />
              {item.produto.titulo}
            </Product>
          ) : (
            <Product>
              <span>Múltiplos produtos</span>
            </Product>
          )}
          {item?.pessoaPresenteada ? (
            <Presenteado>
              <p>
                <b>Presenteado</b>
              </p>
              <Button
                onClick={() =>
                  history.push(`/person/${item.pessoaPresenteada.cpf}`)
                }
              >
                {item.pessoaPresenteada.nomeCompleto}
              </Button>
              <p>
                ({item.pessoaPresenteada.ddd}) {item.pessoaPresenteada.celular}
              </p>
              <p>{item.pessoaPresenteada.email}</p>
            </Presenteado>
          ) : (
            ''
          )}
        </td>

        <td>
          {item.promocao && item.produto.usadoComoBrinde
            ? format(parseISO(item.promocao.dataReferencia), 'dd/MM/yyyy')
            : 'Não possui promoção própria'}
        </td>
        <td>{renderFormaPagamento(item)}</td>
        <td>{decimalFormat(item.valor)}</td>
        <td>{renderAllStatus(item)}</td>
        <td
          style={{
            textAlign: 'center',
          }}
        >
          {renderReturnCode(item)}
        </td>
        <td
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: 32,
            position: 'relative',
          }}
        >
          <MdMenu size={30} onClick={event => handleClickMenu(event, item)} />
          {item.transacao &&
            (item.transacao.returnCode !== null ||
              item.transacao.returnMessage !== null ||
              item.transacao.retornoCielo !== null) && (
              <>
                <button
                  type="button"
                  onMouseOver={handleToggleMessage('top-end', item)}
                  onFocus={handleToggleMessage('top-end', item)}
                  onMouseLeave={() => setOpenMessage(false)}
                  className="messageCielo"
                >
                  <FaInfoCircle size={22} />
                </button>
              </>
            )}
          {!item.produto && (
            <ButtonShowMultiProducts
              onClick={() => setShowMultiProducts(!showMultiProducts)}
            >
              <IconArrow rotate={showMultiProducts} size={28} />
            </ButtonShowMultiProducts>
          )}
        </td>
      </tr>
      {showMultiProducts && (
        <tr>
          <td />
          <td style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            {loading && <CircularProgress />}
            {multiProducts.map(multiProduct => {
              return (
                <Product>
                  <span style={{ fontSize: 17, fontWeight: 'bold' }}>
                    {multiProduct.quantidade}x &nbsp;
                  </span>
                  <img
                    src={multiProduct.produto.icone}
                    alt={multiProduct.produto.descricao}
                    onError={addDefaultSrc}
                  />
                  <span>{multiProduct.produto.titulo}</span>
                </Product>
              );
            })}
          </td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
      )}
    </>
  );
}

PurchaseItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleToggleMessage: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
  setOpenMessage: PropTypes.func.isRequired,
  addDefaultSrc: PropTypes.func.isRequired,
  renderFormaPagamento: PropTypes.func.isRequired,
  renderAllStatus: PropTypes.func.isRequired,
  renderReturnCode: PropTypes.func.isRequired,
};
