import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';

const encryptor = createEncryptor({
  secretKey: '*wCjX`^:JAl/w"Xu|~[e"~aHp*Wc0WA6bKo/ZaQcnXe6)^R3-Oks!+r{fO,/LP;',
});

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'sac-apcap__',
      storage,
      whitelist: ['auth', 'user'],
      transforms: [encryptor],
    },
    reducers
  );
  return persistedReducer;
};
