import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import { toLower } from 'lodash';
import api from '~/services/api';

const schema = Yup.object().shape({
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function DialogReason({
  open,
  toggleDialog,
  person,
  card,
  action,
}) {
  const [titulo, setTitulo] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const title = card.status ? 'Excluir' : 'Reativar';
    setTitulo(title);
  }, [card, action, card.status]);

  const handleConfirmar = ({ reason }) => {
    setLoading(true);

    api
      .put(
        `/persons/${person.idCliente}/payment-methods/${card.idFormaDePagamento}`,
        {
          reason,
          quarantine: action === 'quarantine' ? !card.quarentena : undefined,
          delete: action === 'quarantine' ? !card.quarentena : card.status,
        }
      )
      .then(() => {
        setLoading(false);
        toggleDialog(card);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <Form schema={schema} onSubmit={handleConfirmar}>
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Justifique o motivo para {toLower(titulo)}
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="reason"
              name="reason"
              label="Motivo"
              fullWidth
              multiline
              rowsMax="4"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog} color="secondary" disabled={loading}>
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

DialogReason.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  card: PropTypes.oneOfType([PropTypes.object]).isRequired,
  action: PropTypes.string.isRequired,
};
