import React, { useState } from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';
import PropTypes from 'prop-types';

function Copy({ element }) {
  const [status, setStatus] = useState(true);
  const defaultElement = element || 'code';

  const handleClick = () => {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = document.getElementById(defaultElement).value;
    input.select();
    document.execCommand('copy');
    input.remove();

    setStatus(false);

    setTimeout(() => {
      setStatus(true);
    }, 2000);
  };

  return (
    <>
      {status === true ? (
        <FaCopy
          size={20}
          style={{ marginLeft: '10px' }}
          color="#3f51b5"
          title="Copiar linha digitável"
          onClick={handleClick}
        />
      ) : (
        <FaCheck size={20} color="green" />
      )}
    </>
  );
}

Copy.propTypes = {
  element: PropTypes.string,
};

Copy.defaultProps = {
  element: null,
};

export default Copy;
