import React, { useCallback, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { CircularProgress, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';

let timeClick;
export default function DeleteButton({
  draft,
  count,
  updateDraft,
  isDoubleCheck = false,
  setList,
  item,
}) {
  const [clickDelete, setClickDelete] = useState(0);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleRemoveGift = useCallback(async () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      timeClick = setTimeout(() => setClickDelete(0), 3000);
      return true;
    }
    setLoadingDelete(true);
    if (timeClick) clearTimeout(timeClick);
    setDisable(true);
    try {
      const result = await api.delete(
        `/promotions/draft/${draft}/gifts/${count}?isDoubleCheck=${isDoubleCheck}`
      );
      if (isDoubleCheck) {
        setList(currentList =>
          currentList.filter(
            itm =>
              !(itm.idAcao === item.idAcao && itm.idBrinde === item.idBrinde)
          )
        );
      }
      updateDraft(result.data);

      return true;
    } finally {
      setLoadingDelete(false);
    }
  }, [clickDelete, draft, count, updateDraft]);

  return (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      fullWidth
      onClick={handleRemoveGift}
      disabled={disable}
      startIcon={
        loadingDelete || disable ? (
          <CircularProgress size={20} style={{ color: 'white' }} />
        ) : (
          <MdDelete />
        )
      }
    >
      {clickDelete || disable ? 'Confirma ?' : 'Excluir'}
    </Button>
  );
}

DeleteButton.defaultProps = {
  isDoubleCheck: false,
  setList: () => {},
  item: {},
};

DeleteButton.propTypes = {
  count: PropTypes.oneOfType([PropTypes.element]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isDoubleCheck: PropTypes.bool,
  setList: PropTypes.func,
  item: PropTypes.oneOfType([PropTypes.object]),
};
