import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import { IDatePicker } from '~/components';

export const Container = styled.div`
  display: flex;
`;

export const PreviewContainer = styled.div`
  width: 250px;
`;

export const FormContainer = styled(Form)`
  flex: 1;
  margin-left: 15px;
`;

export const ImageFieldContainer = styled.div`
  cursor: pointer;
`;

export const ArchiveFieldContainer = styled.div`
  cursor: pointer;
`;

export const TwoColumnsField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 15px;
`;

export const DateTimeField = styled(IDatePicker)`
  & + & {
    margin-top: 15px;
  }

  &:last-child {
    margin-bottom: 15px;
  }
`;
