import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Icon,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { AccessControl } from '~/components';
import { DropContainer, DropZone, Embed } from './styles';

export default function Upload({
  data: pdf,
  onUpdate,
  draft,
  loading,
  handleSubmit,
}) {
  // const errorFiles = rejectedFiles.map(_file => {
  //   // setTimeout(() => {
  //   //   setPdf(null);
  //   // }, 100);
  //   return (
  //     <div>
  //       <Typography variant="h5" style={{ color: 'red' }}>
  //         Aquivo {_file.name} inválido
  //       </Typography>
  //     </div>
  //   );
  // });

  const [files, setFiles] = useState();
  const onDrop = useCallback(
    acceptedFiles => {
      setFiles(
        acceptedFiles.map(_file => {
          onUpdate(_file);
          return (
            <div key={_file.path}>
              <Typography variant="body1">Arquivo do regulamento</Typography>
              <div>
                <Typography variant="h5">
                  <strong>{_file.path}</strong>
                </Typography>
                <Typography variant="h5">
                  Tamanho: {_file.size} bytes
                </Typography>
              </div>
            </div>
          );
        })
      );
    },
    [onUpdate]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  return (
    <Grid container spacing={2}>
      {draft.status !== 'approved' && (
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            {/* caixa de drop */}
            <Grid item xs={12} md={12}>
              <DropContainer>
                <DropZone {...getRootProps()}>
                  <input name="regulamento" {...getInputProps()} />
                  {!isDragActive && (
                    <>
                      <p>Solte o arquivo aqui, ou clique para selecionar</p>
                      <em>(Somente *.pdf serão aceitos)</em>
                    </>
                  )}
                  {isDragActive && 'Solte aqui'}
                </DropZone>
              </DropContainer>
              <br />
              <div>{files}</div>
            </Grid>

            {/* botão enviar */}
            <Grid item xs={12} md={12}>
              {draft.status !== 'approved' && files && (
                <AccessControl
                  can={['update_promotion_draft', 'create_promotion_draft']}
                >
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading || !files}
                    style={{ width: '100%' }}
                    color="primary"
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <Icon>cloud_upload</Icon>&nbsp;Enviar
                      </>
                    )}
                  </Button>
                </AccessControl>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={draft.status !== 'approved' ? 8 : 12}>
        {pdf && <Embed src={pdf} />}
      </Grid>
    </Grid>
  );
}

Upload.propTypes = {
  data: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
