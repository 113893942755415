import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import { BsCheck } from 'react-icons/bs';
import PropTypes from 'prop-types';

export default function Tip({ open, handlePopoverClose, anchorEl }) {
  return (
    <>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        style={{ pointerEvents: 'none' }}
        disableRestoreFocus
      >
        <div style={{ padding: 10 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Dicas de como efetuar buscas
          </Typography>
          <div>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Digite <u>tid:[Número da Tid]</u> para pesquisar por tid,
                      exemplo:&nbsp;
                      <i>tid:111350674917B8TEP76E</i>
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Digite <u>protocolo:[Nº do protocolo]</u> para pesquisar
                      pelo protocolo, exemplo:&nbsp;
                      <i>protocolo:20170003336730</i>
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Digite <u>cel:[Nº do celular]</u> para pesquisar pelo
                      celular, exemplo:&nbsp;
                      <i>cel:11912341234</i>
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Digite <u>email:[Email da pessoa]</u> para pesquisar pelo
                      email, exemplo:&nbsp;
                      <i>email:ideamaker@ideamaker.com.br</i>
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Digite <u>cpf:[CPF da pessoa]</u> para pesquisar pelo cpf,
                      exemplo:&nbsp;
                      <i>cpf:999.999.999-99</i>
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BsCheck />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Digite <u>id:[ID da pessoa]</u> para pesquisar pelo id,
                      exemplo:&nbsp;
                      <i>id:99999</i>
                    </span>
                  }
                />
              </ListItem>
            </List>
          </div>
        </div>
      </Popover>
    </>
  );
}

Tip.defaultProps = {
  anchorEl: null,
};

Tip.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool]).isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};
