import React, { useEffect, useRef } from 'react';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import PropTypes from 'prop-types';

import { useField } from '@unform/core';
import BaseCgsSusepAutocomplete from '~/components/CgsSusepAutocomplete';

export default function CgsSusepAutocomplete({ name, ...rest }) {
  const refValue = useRef(null);
  const { registerField, fieldName } = useField(name);
  const render = useForceUpdate();

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        clearValue() {
          refValue.current = null;
          render();
        },
        setValue(_, value) {
          refValue.current = value;
          render();
        },
        getValue() {
          return refValue.current;
        },
      });
    }
  }, [registerField, fieldName, render]);

  return (
    <BaseCgsSusepAutocomplete
      {...rest}
      value={refValue.current}
      onChange={evt => {
        refValue.current = evt.target.value;
        render();
      }}
    />
  );
}

CgsSusepAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
};
