import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import { toLower } from 'lodash';

const schema = Yup.object().shape({
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function DialogReasonSituation({
  loading,
  open,
  onClose,
  card,
  action,
  addCard,
  removeCard,
}) {
  const [titulo, setTitulo] = useState();

  useEffect(() => {
    const title = card.situacao === 'bloqueado' ? 'Desbloquear' : 'Bloquear';
    setTitulo(title);
  }, [card, action, card.situacao]);

  const handleConfirm = formData => {
    if (card.situacao === 'bloqueado') {
      removeCard(formData);
      onClose();
    } else {
      addCard(formData);
      onClose();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <Form schema={schema} onSubmit={handleConfirm}>
          <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Justifique o motivo para {toLower(titulo)}
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="reason"
              name="reason"
              label="Motivo"
              fullWidth
              multiline
              rowsMax="4"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

DialogReasonSituation.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired,
  card: PropTypes.oneOfType([PropTypes.object]).isRequired,
  action: PropTypes.string.isRequired,
};
