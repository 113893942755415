import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { isValid, format, differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

import RaffleTypesAutocomplete from '~/components/RaffleTypesAutocomplete';

import { IDatePicker } from '~/components';
import * as S from './styles';

function Header({ clearFilter, setFilters, filters }) {
  const [localFilters, setLocalFilters] = useState(filters);
  const [openDialog, setOpenDialog] = useState(false);
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [outOfRange, setOutOfRange] = useState('');

  const handleFilter = (field, value) => {
    setLocalFilters(old => ({ ...old, [field]: value }));
  };

  const handleButtonFilter = useCallback(() => {
    if (typeof setFilters === 'function') setFilters(localFilters);
  }, [setFilters, localFilters]);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleClear = () => {
    clearFilter();
    const btnClearRaffleType = document.querySelector(
      'div:nth-child(3) > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty'
    );
    if (btnClearRaffleType) {
      btnClearRaffleType.click();
      document.getElementById('raffleType').blur();
    }
    const btnClearStatus = document.querySelector(
      'div:nth-child(5) > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty'
    );

    if (btnClearStatus) {
      btnClearStatus.click();
      document.getElementById('status').blur();
    }

    const btnClearPeriod = document.querySelector(
      'div:nth-child(2) > div > div > div > button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty'
    );

    if (btnClearPeriod) {
      btnClearPeriod.click();
      document.getElementById('period').blur();
    }

    setLocalFilters(filters);
    document.getElementById('search').value = '';
    document.getElementById('edition').value = '';
  };

  const handleSubmit = () => {
    const initialDate = new Date(firstDate);
    const finalDate = new Date(lastDate);
    if (differenceInDays(finalDate, initialDate) > 30) {
      setOutOfRange(
        'O período é maior que 30 dias, selecione um período menor'
      );
      return;
    }
    if (differenceInDays(finalDate, initialDate) < 0) {
      setOutOfRange('A data final é anterior a data inicial');
      return;
    }
    setOutOfRange('');
    setOpenDialog(false);
    handleButtonFilter();
  };

  return (
    <S.Container>
      <S.Header>
        <S.Title>Triagem de Ganhadores</S.Title>
        <div>
          <Button variant="text" color="secondary" onClick={handleClear}>
            Limpar filtros
          </Button>
          <Button
            onClick={handleButtonFilter}
            color="primary"
            variant="contained"
          >
            Filtrar
          </Button>
        </div>
      </S.Header>
      <S.FilterContainer>
        <TextField
          variant="outlined"
          placeholder="Pesquisar por nome, CPF ou Descrição do Sorteio. Ex: sorteio:iphone"
          onChange={evt => handleFilter('search', evt.target.value)}
          id="search"
          value={localFilters.search}
        />
        <Autocomplete
          id="period"
          options={[
            { value: 30, label: 'Últimos 30 dias' },
            { value: 60, label: 'Últimos 60 dias' },
            { value: 90, label: 'Últimos 90 dias' },
            { value: 'custom', label: 'Período personalizado' },
          ]}
          onChange={(_, option) => {
            const value = option && option.value ? option.value : '';
            if (option && option.value === 'custom') {
              setOpenDialog(true);
            }
            handleFilter('period', value);
          }}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              value={localFilters.status}
              variant="outlined"
              margin="normal"
              {...params}
              label="Período"
            />
          )}
        />
        <RaffleTypesAutocomplete
          label="Tipo do Sorteio"
          onChange={evt => handleFilter('raffleType', evt.target.value)}
          valueKey="cod"
          id="raffleType"
          value={localFilters.raffleType}
        />
        <TextField
          variant="outlined"
          placeholder="Edição / Extração"
          onChange={evt => handleFilter('edition', evt.target.value)}
          id="edition"
          value={localFilters.edition}
        />
        <Autocomplete
          id="status"
          options={[
            { value: 'aguardando', label: 'Aguardando' },
            { value: 'pendente', label: 'Pendente' },
            { value: 'agendado', label: 'Agendado' },
            { value: 'processando', label: 'Processando' },
            { value: 'entregue', label: 'Entregue' },
            { value: 'finalizado', label: 'Finalizado' },
            { value: 'cancelado', label: 'Cancelado' },
            { value: 'enviado', label: 'Enviado' },
          ]}
          onChange={(_, option) => {
            const value = option && option.value ? option.value : '';
            handleFilter('status', value);
          }}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              value={localFilters.status}
              variant="outlined"
              margin="normal"
              {...params}
              label="Situação"
            />
          )}
        />
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Período personalizado</DialogTitle>
          <DialogContent
            style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}
          >
            <div style={{ display: 'flex', gap: '2rem' }}>
              <IDatePicker
                id="firstDate"
                label="Data inicial"
                onChange={value => {
                  const formated =
                    value && isValid(value)
                      ? `${format(value, 'yyyy-MM-dd')} 00:00:00`
                      : undefined;
                  setFirstDate(`${value} 00:00:00`);
                  handleFilter('firstDate', formated);
                }}
                value={firstDate}
              />
              <IDatePicker
                id="lastDate"
                label="Data final"
                onChange={value => {
                  const formated =
                    value && isValid(value)
                      ? `${format(value, 'yyyy-MM-dd')} 23:59:59`
                      : undefined;
                  setLastDate(`${value} 23:59:59`);
                  handleFilter('lastDate', formated);
                }}
                value={lastDate}
              />
            </div>
            {!!outOfRange && <span style={{ color: 'red' }}>{outOfRange}</span>}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialog(false);
                setOutOfRange('');
                setFirstDate(null);
                setLastDate(null);
                handleClear();
              }}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              disabled={!firstDate || !lastDate}
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </S.FilterContainer>
    </S.Container>
  );
}

Header.propTypes = {
  clearFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Header;