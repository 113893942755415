import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import { compareArrays } from '~/helpers/compareArray';
import api from '~/services/api';
import { AccessControl } from '~/components';
import EbookTabs from './tabs';

export default function Ebook({
  draft,
  updateDraft,
  setNextStep,
  isDoubleCheck,
  setStepsStatus,
  doubleCheckEbooks,
  setDoubleCheckEbooks,
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNextStep(
      (!isDoubleCheck || draft.status === 'approved') &&
        draft.data.regulation &&
        draft.data.banners?.length &&
        draft.data.ebooks?.length &&
        draft._id
    );
    // eslint-disable-next-line
  }, [draft]);

  const handleDoubleCheck = useCallback(async () => {
    setLoading(true);
    const { data: updatedDraft } = await api.get(
      `/promotions/drafts/${draft._id}`
    );

    if (updatedDraft) {
      updateDraft(updatedDraft);
    }
    if (!doubleCheckEbooks.length) {
      setLoading(false);
      return toast.error(
        <Toast
          message="Você deve adicionar pelo menos um ebook para conferir."
          error
        />
      );
    }
    const draftEbooks = updatedDraft.data.ebooks;
    const fields = Object.keys(draftEbooks[0]);

    if (compareArrays(draftEbooks, doubleCheckEbooks, { id: '_id', fields })) {
      setStepsStatus(prevStatus => ({ ...prevStatus, ebook: true }));
      setLoading(false);
      return toast.success(
        <Toast message="Os dados foram confirmados com sucesso." />
      );
    }
    setLoading(false);
    setStepsStatus(prevStatus => ({ ...prevStatus, ebook: false }));

    return toast.error(
      <Toast
        message="Os dados conferidos estão incorretos, verifique novamente."
        error
      />
    );
  }, [setStepsStatus, doubleCheckEbooks, draft._id, updateDraft]);

  return (
    <Grid style={{ backgroundColor: '#fff' }} container spacing={2}>
      <Grid
        style={{
          padding: '3rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          boxSizing: 'border-box',
        }}
        item
        xs={12}
      >
        <EbookTabs
          draft={draft}
          updateDraft={updateDraft}
          isDoubleCheck={isDoubleCheck}
          doubleCheckEbooks={doubleCheckEbooks}
          setDoubleCheckEbooks={setDoubleCheckEbooks}
        />
      </Grid>
      {isDoubleCheck && (
        <AccessControl can={['double_check_promotion_draft']}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDoubleCheck}
                disabled={loading || draft.status === 'approved'}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Conferir
                  </>
                )}
              </Button>
            </Box>
          </Grid>
        </AccessControl>
      )}
    </Grid>
  );
}

Ebook.defaultProps = {
  isDoubleCheck: false,
  setStepsStatus: () => {},
  doubleCheckEbooks: [],
  setDoubleCheckEbooks: () => {},
};

Ebook.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  isDoubleCheck: PropTypes.bool,
  setStepsStatus: PropTypes.func,
  doubleCheckEbooks: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      ordem: PropTypes.number,
      id: PropTypes.number,
      nome: PropTypes.string,
      autor: PropTypes.string,
      descricao: PropTypes.string,
      urlThumbnail: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  setDoubleCheckEbooks: PropTypes.func,
};
