import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';

export default function Paginator({
  page,
  totals,
  limit,
  onChangePage,
  onChangeLimit,
  component,
  ...rest
}) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 15, 20, 30, 50]}
      {...rest}
      page={page <= 0 ? 0 : page - 1}
      count={totals}
      rowsPerPage={limit}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeLimit}
      component={component}
    />
  );
}

Paginator.defaultProps = {
  page: 0,
  totals: 0,
  limit: 10,
  component: undefined,
};

Paginator.propTypes = {
  page: PropTypes.number,
  totals: PropTypes.number,
  limit: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  component: PropTypes.string,
};
