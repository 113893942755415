import React from 'react';

import PromotionAll from './all';
import { PromotionAllPermissionProvider } from './context-permissions';

// eslint-disable-next-line
export default function PromotionAllContainer() {
  return (
    <PromotionAllPermissionProvider>
      <PromotionAll />
    </PromotionAllPermissionProvider>
  );
}
