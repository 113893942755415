import qs from 'qs';
import {
  complement,
  compose,
  either,
  filter,
  isEmpty,
  isNil,
  join,
  map,
  toPairs,
} from 'ramda';

const isValidParameter = complement(either(isEmpty, isNil));

const build = compose(
  join('&'),
  map(join('=')),
  toPairs,
  filter(isValidParameter)
);

const parse = query => qs.parse(query, { ignoreQueryPrefix: true });

export default {
  build,
  parse,
};
