import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

export const Container = styled(Dialog).attrs({
  fullWidth: true,
  scroll: 'body',
  maxWidth: 'xs',
})`
  h2 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

export const Title = styled(DialogTitle)``;

export const Content = styled(DialogContent)`
  .MuiFormControl-root {
    margin-bottom: 15px;
  }
`;

export const Footer = styled(DialogActions)``;

export const Avatar = styled.div`
  margin: 0 auto;
  border-radius: 100px;
  width: 130px;
  height: 130px;
  background-size: cover;
  background-position: center center;
  background-image: url("${({ url }) => url}");
  margin-bottom: 20px;
  cursor: pointer;
`;
