import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Button, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import api from '~/services/api';

import GiftsAutoComplete from '~/components/GiftsAutocomplete';
import ActionsAutoComplete from '~/components/ActionsAutocomplete';
import { ContentPromoCad } from '../styles';

export default function PromotionActionGiftForm({
  draft,
  updateDraft,
  isDoubleCheck,
  setList,
}) {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('');
  const [gift, setGift] = useState('');

  const schema = Yup.object().shape({
    idAcao: Yup.string()
      .required('Campo obrigatório')
      .min(1, 'Campo não pode ser vazio'),
    idBrinde: Yup.string()
      .required('Campo obrigatório')
      .min(1, 'Campo não pode ser vazio'),
  });
  const handleSubmitGift = async (data, { resetForm }) => {
    try {
      setLoading(true);
      const result = await api.post(`/promotions/draft/${draft._id}/gifts`, {
        ...data,
        isDoubleCheck,
      });
      if (isDoubleCheck) {
        setList(currentList => [...currentList, ...result.data.actionGifts]);
        return true;
      }
      await updateDraft(result.data);

      resetForm();
      setAction('');
      setGift('');

      return true;
    } finally {
      setLoading(false);
    }
  };

  const handleActionChange = event => {
    const { value } = event.target;
    setAction(value);
  };

  const handleGiftChange = event => {
    const { value } = event.target;
    setGift(value);
  };

  useEffect(() => {
    document.getElementById('idAcao').value = action;
  }, [action]);

  useEffect(() => {
    document.getElementById('idBrinde').value = gift;
  }, [gift]);

  return (
    <>
      <div>
        <h3 style={{ marginLeft: 10, marginBottom: 20, marginTop: 40 }}>
          CADASTRO AÇÃO BRINDE
        </h3>
      </div>
      <hr style={{ margin: 10 }} />
      <Form schema={schema} onSubmit={handleSubmitGift} noValidate>
        <ContentPromoCad>
          <Grid item xs={12} md={12}>
            <ActionsAutoComplete value={action} onChange={handleActionChange} />
            <div style={{ display: 'none' }}>
              <TextField name="idAcao" type="text" />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <GiftsAutoComplete value={gift} onChange={handleGiftChange} />
            <div style={{ display: 'none' }}>
              <TextField name="idBrinde" type="text" />
            </div>
          </Grid>
          <Grid item xs={12} md={12} align="right">
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              color="primary"
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Icon>save</Icon>&nbsp;Salvar
                </>
              )}
            </Button>
          </Grid>
        </ContentPromoCad>
      </Form>
    </>
  );
}

PromotionActionGiftForm.defaultProps = {
  isDoubleCheck: false,
};

PromotionActionGiftForm.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setList: PropTypes.func.isRequired,
  isDoubleCheck: PropTypes.bool,
};
