import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from '@rocketseat/unform';
import { TextField } from 'unform-material-ui';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import { decimalFormat } from '~/helpers/number';
import Toast from '~/components/Toast';

const schema = Yup.object().shape({
  reason: Yup.string()
    .min(10, ' No mínimo 10 caracteres')
    .required('Campo obrigatório'),
});

export default function RefundDialog({
  open,
  toggleDialog,
  person,
  bankslip,
  loadingList,
}) {
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();

  /**
   * Function for a refund through confirmation.
   */
  const refundThroughConfirmation = useCallback(
    async (reason, confirmation) => {
      try {
        const response = await api.put(
          `/persons/${person.idCliente}/bank-slips/${bankslip.id}/refund`,
          { reason, confirmation }
        );
        if (response) {
          toast.success(<Toast message="OK" />);
          // Set object 'voidedDate' to alter state of situation in thr list
          // of the bank slips.
          bankslip.voidedDate = Date.now();
        }
      } catch (err) {
        // eslint-disable-line
      }
    },
    [person.idCliente, bankslip.id, bankslip.voidedDate]
  );

  /**
   * Function to show confirmation of the refund.
   */
  const handleConfirmRefund = useCallback(
    async (reason, confirmation, refund, balance) => {
      try {
        refund = decimalFormat(refund);
        balance = decimalFormat(balance);
        // Mount description to confirm refund.
        const description = `O valor do boleto a ser estornado R$ ${refund}
        é maior que o saldo em carteira R$ ${balance}, deseja continuar?`;
        // Create confirmation alert.
        await confirm({
          title: 'Confirma estorno?',
          description,
          dialogProps: { fullWidth: true, maxWidth: 'xs' },
          cancellationText: 'Cancelar',
          confirmationText: 'Confirmar',
          cancellationButtonProps: { color: 'secondary' },
        });
        loadingList(true);
        // Refund bank slip.
        await refundThroughConfirmation(reason, confirmation);
      } catch (err) {
        // eslint-disable-line
      } finally {
        loadingList(false);
      }
    },
    [loadingList, refundThroughConfirmation, confirm]
  );

  /**
   * Function to refund by dialog.
   */
  const handleRefund = async ({ reason }) => {
    setLoading(true);
    try {
      if (hasPermission('cancel_bank_slip')) {
        const response = await api.put(
          `/persons/${person.idCliente}/bank-slips/${bankslip.id}/refund`,
          { reason }
        );
        if (response) {
          // Check if API returned status code '202' or data.
          if (response.status === 202 && response.data) {
            // Close the refund dialog.
            toggleDialog();
            setLoading(false);
            // Get values returned to confirmation.
            const { confirmation, refund, balance } = response.data;
            // Call the function to show confirmation refund.
            await handleConfirmRefund(reason, confirmation, refund, balance);
          } else {
            toast.success(<Toast message="OK" />);
            // Set object 'voidedDate' to alter state of situation in the list
            // of the bank slips.
            bankslip.voidedDate = Date.now();
          }
        }
      }
    } finally {
      toggleDialog();
      setLoading(false);
    }
  };
  // Shows dialog with requesting the reason.
  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
      >
        <Form schema={schema} onSubmit={handleRefund}>
          <DialogTitle id="form-dialog-title">Motivo do estorno</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Justifique o motivo do estorno
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              name="reason"
              label="Motivo"
              fullWidth
              multiline
              rowsMax="4"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog} color="secondary" disabled={loading}>
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Confirmar'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
// Define properties to the function.
RefundDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  bankslip: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loadingList: PropTypes.func.isRequired,
};
