import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { TextField } from 'unform-material-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  MdImageSearch,
  MdOutlineImage,
  MdOutlineUploadFile,
} from 'react-icons/md';
import api from '~/services/api';
import * as Yup from 'yup';
import { Container, Form, Thumbnail, ThumbnailContainer } from './styles';

const schema = Yup.object().shape({
  nome: Yup.string()
    .required('O campo nome obrigatório')
    .min(5, 'O campo nome, deve conter no mínimo 5 caracteres.'),
  autor: Yup.string()
    .required('O campo autor obrigatório')
    .min(5, 'O campo autor, deve conter no mínimo 5 caracteres.'),
});

export default function NewEbook({
  open,
  onClose,
  ebook,
  promotionId,
  newEbookInPromotion,
}) {
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [urlThumbnail, setUrlThumbnail] = useState();
  const [pdf, setPdf] = useState();
  const [nome, setNome] = useState();
  const [autor, setAutor] = useState();
  const uploadPdf = useRef(null);
  const uploadThumbnail = useRef(null);

  useEffect(() => {
    if (!isEmpty(ebook)) {
      setUrlThumbnail(ebook.urlThumbnail);
      setNome(ebook.nome);
      setAutor(ebook.autor);
    }
  }, [ebook]);

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('nome', nome);
    formData.append('autor', autor);
    formData.append('thumbnail', thumbnail);
    formData.append('ebook', pdf);

    setLoading(true);
    if (isEmpty(ebook)) {
      let url = '';

      if (!newEbookInPromotion) {
        url = '/ebook';
      } else {
        url = `/promotions/${promotionId}/ebook`;
      }

      try {
        await api.post(url, formData);
        onClose(true);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        if (!thumbnail) {
          formData.delete('thumbnail');
        }
        formData.delete('ebook');
        await api.put(`/promotions/${promotionId}/ebook/${ebook.id}`, formData);
        onClose(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Novo e-book</DialogTitle>
      <DialogContent>
        <Container>
          <ThumbnailContainer>
            <Thumbnail>
              {thumbnail || urlThumbnail ? (
                <img
                  src={
                    thumbnail ? URL.createObjectURL(thumbnail) : urlThumbnail
                  }
                  alt="thumbnail"
                  width="160px"
                />
              ) : (
                <MdOutlineImage
                  style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                  size={80}
                />
              )}
            </Thumbnail>
            <input
              type="file"
              onChange={e => setThumbnail(e.target.files[0])}
              ref={uploadThumbnail}
              hidden
            />
            <Button
              disabled={loading}
              style={{ color: 'rgba(0, 0, 0, 0.26)' }}
              variant="text"
              startIcon={<MdImageSearch />}
              onClick={() => uploadThumbnail.current.click()}
            >
              {thumbnail ? 'Trocar imagem' : 'Adicionar imagem'}
            </Button>
          </ThumbnailContainer>
          <Form schema={schema} onSubmit={handleSubmit}>
            <TextField
              name="nome"
              id="nome"
              disabled={loading}
              onChange={e => setNome(e.target.value)}
              value={nome}
              label="Título"
              variant="outlined"
            />
            <TextField
              name="autor"
              id="autor"
              disabled={loading}
              onChange={e => setAutor(e.target.value)}
              value={autor}
              label="Autor"
              variant="outlined"
            />
            <input
              type="file"
              onChange={e => setPdf(e.target.files[0])}
              ref={uploadPdf}
              hidden
            />
            <Button
              disabled={loading || !isEmpty(ebook)}
              variant="outlined"
              color="primary"
              startIcon={<MdOutlineUploadFile />}
              onClick={() => uploadPdf.current.click()}
              fullWidth
            >
              {pdf && pdf.name}
              {!isEmpty(ebook) && 'arquivo.pdf'}
              {!pdf && isEmpty(ebook) && 'Selecionar pdf'}
            </Button>
            <div style={{ display: 'flex' }}>
              <Button
                disabled={loading}
                onClick={onClose}
                color="secondary"
                variant="text"
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                type="submit"
                color="primary"
                variant="text"
              >
                {loading ? <CircularProgress /> : 'Cadastrar'}
              </Button>
            </div>
          </Form>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

NewEbook.defaultProps = {
  ebook: {},
  newEbookInPromotion: false,
};

NewEbook.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ebook: PropTypes.oneOfType([PropTypes.object]),
  promotionId: PropTypes.number.isRequired,
  newEbookInPromotion: PropTypes.bool,
  // setRefetch: PropTypes.func.isRequired,
  // refetch: PropTypes.bool.isRequired,
};
