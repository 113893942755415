import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { MdCheck, MdCancel } from 'react-icons/md';
import PropTypes from 'prop-types';

export default function ConfirmationMenu({ anchor, onOk, onCancel }) {
  return (
    <Menu
      style={{ zIndex: 9999 }}
      anchorEl={anchor}
      keepMounted
      open={!!anchor}
      onClose={onCancel}
    >
      <MenuItem onClick={() => !!onOk && onOk()}>
        <MdCheck size={25} />
        &nbsp;Enviar Email
      </MenuItem>
      <MenuItem onClick={() => onCancel()}>
        <MdCancel size={25} />
        &nbsp;Cancelar
      </MenuItem>
    </Menu>
  );
}

ConfirmationMenu.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
};

ConfirmationMenu.propTypes = {
  anchor: PropTypes.any, // eslint-disable-line
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};
