import styled from 'styled-components';

export const Embed = styled.embed`
  min-height: calc(100vh - 370px);
  width: 100%;
`;

export const DropZone = styled.div`
  && {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }
  && {
    :focus {
      border-color: #2196f3;
    }
`;

export const DropContainer = styled.section`
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
`;

export const PhotoContainer = styled.div`
  display: block;
`;

export const PhotoImage = styled.figure`
  max-width: 100%;
  max-height: auto;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: top center;
  background-image: url("${({ url }) => url}");
  box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.15);
  border: 3px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
