import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { LoadingPlaceholderContainer } from './styles';

const LoadingPlaceholderBannerItem = ({ loading = false }) => {
  return (
    <LoadingPlaceholderContainer displayPlaceholder={loading}>
      <CircularProgress size={50} />
    </LoadingPlaceholderContainer>
  );
};

LoadingPlaceholderBannerItem.propTypes = {
  loading: PropTypes.bool,
};

LoadingPlaceholderBannerItem.defaultProps = {
  loading: false,
};

export default LoadingPlaceholderBannerItem;
