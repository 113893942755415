import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';
import { store } from '~/store';

export default function FilterPermissions({
  open,
  onClose,
  permissions,
  setPermissions,
  handleFilterPermissions,
}) {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const { token } = store.getState().auth;

  const getRoles = async () => {
    try {
      setLoading(true);
      if (hasPermission('read_roles')) {
        const { data } = await api.get('/roles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (data) {
          setRoles(data);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    setPermissions(uniqBy(value, 'roleId'));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <h3>Filtrar permissões</h3>
        <FormControl>
          <InputLabel id="demo-multiple-checkbox-label">Permissões</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={permissions}
            onChange={handleChange}
            renderValue={selected =>
              selected.map(x => x.roleAttributes.roleName).join(', ')
            }
          >
            {roles.map(variant => (
              <MenuItem key={variant.roleId} value={variant}>
                <ListItemText primary={variant.roleAttributes.roleName} />
                <Checkbox
                  checked={
                    permissions.findIndex(
                      item => item.roleId === variant.roleId
                    ) >= 0
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleFilterPermissions}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          {loading ? 'Carregando' : 'Aplicar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FilterPermissions.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setPermissions: PropTypes.func.isRequired,
  handleFilterPermissions: PropTypes.func.isRequired,
};
